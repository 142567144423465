import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, MultipleSelect, Select, TextArea } from '@dinbog/ui';
import { useLocation } from '../../../../hooks';
import { ACTIONS } from '../reducer';
import { CustomPhoneInput } from '../../../shared';

function CompanyInformationContent() {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext();

  const { countries, states, cities } = useLocation(
    watch(ACTIONS.COUNTRY) ?? '',
    watch(ACTIONS.STATE) ?? ''
  );

  const formValidations = {
    [ACTIONS.NAME]: {
      required: t('required'),
    },
    [ACTIONS.COUNTRY]: {
      required: t('required'),
    },
    [ACTIONS.EMAIL]: {
      required: t('required'),
      pattern: {
        value:
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        message: t('invalidEmail'),
      },
    },
    [ACTIONS.STATE]: {
      required: t('required'),
    },
    [ACTIONS.CITY]: {
      required: t('required'),
    },
    [ACTIONS.DESCRIPTION]: {
      required: t('required'),
    },
  };

  return (
    <div className="space-y-8">
      <div className="inputs-container">
        <div className="w-full">
          <Input
            label={`${t('auth.signUp.wizard.business.url')} (${t('optional')})`}
            {...register(ACTIONS.PAGE_URL, formValidations[ACTIONS.PAGE_URL])}
            className="w-full"
            error={errors[ACTIONS.PAGE_URL]?.message as string}
            placeholder="https://dinbog.com"
          />
        </div>
        <div className="w-full">
          <CustomPhoneInput
            name={ACTIONS.PHONE}
            label={`${t('auth.signUp.wizard.business.phone')} (${t(
              'optional'
            )})`}
            error={errors[ACTIONS.PHONE]?.message as string}
            validations={formValidations[ACTIONS.PHONE]}
            control={control}
            controlled
          />
        </div>
      </div>
      <div className="inputs-container">
        <div className="w-full">
          <Select
            name={ACTIONS.COUNTRY}
            className="w-full"
            defaultValue=""
            label={`${t('auth.signUp.wizard.business.country')} *`}
            error={errors[ACTIONS.COUNTRY]?.message as string}
            {...register(ACTIONS.COUNTRY, formValidations[ACTIONS.COUNTRY])}
          >
            <option key={-1} value="" disabled>
              {t('select')}
            </option>
            {countries?.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <Select
            name={ACTIONS.STATE}
            className="w-full"
            defaultValue=""
            label={`${t('auth.signUp.wizard.business.state')} *`}
            error={errors[ACTIONS.STATE]?.message as string}
            {...register(ACTIONS.STATE, formValidations[ACTIONS.STATE])}
          >
            <option key={-1} value="" disabled>
              {t('select')}
            </option>
            {states?.map((hqState) => (
              <option key={hqState.isoCode} value={hqState.isoCode}>
                {hqState.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <Select
            name={ACTIONS.CITY}
            defaultValue=""
            label={`${t('auth.signUp.wizard.business.city')} *`}
            error={errors[ACTIONS.CITY]?.message as string}
            disabled={cities.length <= 0}
            {...register(ACTIONS.CITY, formValidations[ACTIONS.CITY])}
          >
            <option key={-1} value="" disabled>
              {t('select')}
            </option>
            {cities?.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <MultipleSelect
            className="w-full"
            name={ACTIONS.BRANCH_OFFICE}
            defaultSelect={
              []
              // getValues(ACTIONS.BRANCH_OFFICE)?.map((branchOffice) => ({
              //   _id: branchOffice.isoCode,
              //   value: branchOffice.isoCode,
              //   name: branchOffice.name,
              // })) ?? []
            }
            label={`${t('auth.signUp.wizard.business.branchOffice')} (${t(
              'optional'
            )})`}
            options={
              countries.map((country) => ({
                _id: country.isoCode,
                value: country.isoCode,
                name: country.name,
              })) ?? []
            }
            onChangeSelect={(e) => {
              setValue(ACTIONS.BRANCH_OFFICE, e);
            }}
          />
        </div>
      </div>
      <div className="inputs-container">
        <TextArea
          name={ACTIONS.DESCRIPTION}
          className="h-36"
          label={`${t('auth.signUp.wizard.business.description.title')} *`}
          placeholder={t('auth.signUp.wizard.business.description.placeholder')}
          error={errors[ACTIONS.DESCRIPTION]?.message as string}
          {...register(
            ACTIONS.DESCRIPTION,
            formValidations[ACTIONS.DESCRIPTION]
          )}
        />
      </div>
    </div>
  );
}

export default CompanyInformationContent;
