import dayjs from 'dayjs';

export function getEventStatus(startDate: string, endDate: string) {
  const now = dayjs();
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  if (now.isBefore(start)) {
    return 'upcoming';
  }
  if (now.isAfter(start) && now.isBefore(end)) {
    return 'active';
  }
  if (now.isAfter(end)) {
    return 'closed';
  }
}
