import React from 'react';
import { Pagination, Profile, User } from '@dinbog/models';
import { InfiniteScroll } from '@dinbog/ui';
import SuggestedUserItem from '../shared/feed/suggestions/SuggestedUserItem';

interface SuggestionsProps {
  setPagination: React.Dispatch<
    React.SetStateAction<{
      page: number;
      perPage: number;
    }>
  >;
  data: Pagination<User & { profile: Profile }>;
  loading: boolean;
  refetch?: any;
}
function Suggestions({
  setPagination,
  data,
  loading,
  refetch,
}: SuggestionsProps) {
  const [loadedData, setLoadedData] = React.useState([]);

  React.useEffect(() => {
    if (data?.pageInfo?.page === 1) {
      setLoadedData([
        ...(data?.items ?? []).map((u) => ({
          ...u,
          following: false,
          requestedFollow: false,
          requestedConnection: false,
        })),
      ]);
    } else {
      setLoadedData((prev) => [
        ...prev,
        ...(data?.items ?? []).map((u) => ({
          ...u,
          following: false,
          requestedFollow: false,
          requestedConnection: false,
        })),
      ]);
    }
  }, [data]);

  return (
    <InfiniteScroll
      setPagination={setPagination}
      data={data}
      loading={loading}
      setLoadedData={setLoadedData}
      fixedHeight={false}
      updateLoadedDataOnPageChange={false}
    >
      {/* center Suggestions */}
      <div className="w-full flex flex-col gap-6 items-center">
        {loadedData.map((u, index) => (
          <SuggestedUserItem
            user_={u}
            key={index}
            setUsersData={setLoadedData}
            refetch={refetch}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
}

export default Suggestions;
