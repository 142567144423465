import React from 'react';
import { Input, MultipleSelect, ToggleInput } from '@dinbog/ui';
import {
  SpecificationTemplate,
  VacantSpecification,
  VacantSpecificationType,
} from '@dinbog/models';
import { validateString } from 'avilatek-utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SpecificationTagsInput from './SpecificationTagsInput';

interface SpecificationsFormContentProps {
  specifications: VacantSpecification[];
  setSpecifications: React.Dispatch<
    React.SetStateAction<VacantSpecification[]>
  >;
}

function SpecificationsFormContent({
  specifications,
  setSpecifications,
}: SpecificationsFormContentProps) {
  const { t, i18n } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const formValidations = {};
  specifications.forEach(({ _id }) => {
    formValidations[_id] = { required: 'Required' };
  });

  const handleSetRequired = (specificationId, required) => {
    setSpecifications((prev) =>
      prev.map((spec) => ({
        ...spec,
        ...(spec._id === specificationId ? { required } : {}),
      }))
    );
  };
  return (
    <div className="flex flex-col gap-7">
      {specifications.map(
        ({
          _id,
          specificationTemplate,
          multipleValues,
          type,
          required,
        }: {
          _id: string;
          specificationTemplate: SpecificationTemplate;
          multipleValues: string[];
          type: VacantSpecificationType;
          required: boolean;
        }) => {
          if (!_id) return null;
          switch (type) {
            case 'tags':
              return (
                <div className="w-full flex flex-col gap-1">
                  <SpecificationTagsInput
                    key={_id}
                    setSpecifications={setSpecifications}
                    specificationId={_id}
                    defaultTags={multipleValues}
                    label={specificationTemplate?.name[i18n?.language ?? 'en']}
                  />
                  <label className="text-sm space-x-2 py-4">
                    <input
                      type="checkbox"
                      className="rounded"
                      defaultChecked={required}
                      onChange={(e) => handleSetRequired(_id, e.target.checked)}
                    />
                    <span className="">{t('required')}</span>
                  </label>
                </div>
              );
            case 'boolean':
              return (
                <div className="w-full flex flex-col gap-1">
                  <ToggleInput
                    key={_id}
                    label={`${
                      specificationTemplate?.name[i18n?.language ?? 'en']
                    } ${
                      validateString(specificationTemplate.measure)
                        ? `(${specificationTemplate.measure})`
                        : ''
                    }`}
                    name={_id}
                    error={errors[_id] ? (errors[_id]?.message as string) : ''}
                    {...register(_id)}
                  />
                  <label className="text-sm space-x-2 py-4">
                    <input
                      type="checkbox"
                      className="rounded"
                      defaultChecked={required}
                      onChange={(e) => handleSetRequired(_id, e.target.checked)}
                    />
                    <span className="">{t('required')}</span>
                  </label>
                </div>
              );
            case 'range':
              return (
                <div className="w-full flex flex-col gap-1">
                  <Input
                    key={`min-${_id}`}
                    label={`Min ${
                      specificationTemplate?.name[i18n?.language ?? 'en']
                    } ${
                      validateString(specificationTemplate.measure)
                        ? `(${specificationTemplate.measure})`
                        : ''
                    }`}
                    type="number"
                    min="0"
                    step="0.01"
                    lang="en-US"
                    name={`min-${_id}`}
                    error={
                      errors[`min-${_id}`]
                        ? (errors[`min-${_id}`]?.message as string)
                        : ''
                    }
                    {...register(`min-${_id}`, formValidations[`min-${_id}`])}
                  />
                  <Input
                    key={`max-${_id}`}
                    label={`Max ${
                      specificationTemplate?.name[i18n?.language ?? 'en']
                    } ${
                      validateString(specificationTemplate.measure)
                        ? `(${specificationTemplate.measure})`
                        : ''
                    }`}
                    type="number"
                    min={watch(`min-${_id}`) ?? '0'}
                    step="0.01"
                    lang="en-US"
                    name={`max-${_id}`}
                    error={
                      errors[`max-${_id}`]
                        ? (errors[`max-${_id}`]?.message as string)
                        : ''
                    }
                    {...register(`max-${_id}`, formValidations[`max-${_id}`])}
                  />
                  <label className="text-sm space-x-2 py-4">
                    <input
                      type="checkbox"
                      className="rounded"
                      defaultChecked={required}
                      onChange={(e) => handleSetRequired(_id, e.target.checked)}
                    />
                    <span className="">{t('required')}</span>
                  </label>
                </div>
              );

            case 'multiselect':
              return (
                <div className="w-full flex flex-col gap-1">
                  <MultipleSelect
                    className="w-full"
                    name={_id}
                    defaultSelect={
                      multipleValues.map((value) => ({
                        _id: (
                          specificationTemplate?.options.find(
                            (op) => op?.value === value
                          ) as any
                        )?._id,
                        value,
                        name: specificationTemplate?.options.find(
                          (op) => op?.value === value
                        )?.name[i18n?.language ?? 'en'],
                      })) ?? []
                    }
                    label={`${
                      specificationTemplate?.name[i18n?.language ?? 'en']
                    } ${
                      validateString(specificationTemplate.measure)
                        ? `(${specificationTemplate.measure})`
                        : ''
                    }`}
                    options={
                      specificationTemplate.options?.map((option) => ({
                        _id: (option as any)._id,
                        value: option.value,
                        name: option.name[i18n?.language ?? 'en'],
                      })) ?? []
                    }
                    onChangeSelect={(value) => {
                      setSpecifications(
                        specifications.map((spec) => ({
                          ...spec,
                          ...(spec._id === _id
                            ? {
                                ...spec,
                                multipleValues: [...value]?.map(
                                  (val) => val?._id
                                ),
                              }
                            : {}),
                        }))
                      );
                    }}
                  />
                  <label className="text-sm space-x-2 py-4">
                    <input
                      type="checkbox"
                      className="rounded"
                      defaultChecked={required}
                      onChange={(e) => handleSetRequired(_id, e.target.checked)}
                    />
                    <span className="">{t('required')}</span>
                  </label>
                </div>
              );

            default:
              return null;
          }
        }
      )}
    </div>
  );
}

export default SpecificationsFormContent;
