import React from 'react';
import { WorkReferenceSelectedUser } from '@dinbog/models';
import { TrashIcon } from '@dinbog/ui';
import UserItem from '../UserItem';

interface UserItemListProps {
  selectedUsers: WorkReferenceSelectedUser[];
  isUpdate?: boolean;
  handleRemoveUser: (user_: WorkReferenceSelectedUser) => Promise<void>;
}

function UserItemList({
  selectedUsers,
  isUpdate,
  handleRemoveUser,
}: UserItemListProps) {
  return (
    <>
      {selectedUsers.map((item) => (
        <UserItem
          className="border-none"
          icon={<TrashIcon className="w-5 h-5 text-neutral-700" />}
          onClick={() => handleRemoveUser(item)}
          user={item}
          showButton={!isUpdate}
        />
      ))}
    </>
  );
}

export default UserItemList;
