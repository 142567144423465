import React from 'react';
import { Track } from '@dinbog/models';
import { PauseIcon, PlayIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

function TrackPreview({
  track,
  idx,
  isPlaying,
  handleChangeState,
}: {
  track: Track;
  idx: number;
  isPlaying: boolean;
  handleChangeState: (track_: Track, isPlaying_: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <div className="w-full px-4 py-2 flex justify-between items-center">
      <div className="flex gap-6">
        <p className=" text-lg font-semibold">{idx}</p>
        <div>
          <span className=" text-lg font-semibold">{track.title}</span>
          <p className=" text-xs text-neutral-300 ">
            {track.stats?.likes} {t('stats.likes')} - {track?.stats?.comments}{' '}
            {t('stats.comments')}
          </p>
        </div>
      </div>
      <button
        className="px-[10px] py-[2px] rounded-sm flex items-center justify-center gap-[6px] text-primary-500 bg-background-300"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          if (isPlaying) {
            handleChangeState(track, false);
            return;
          }
          handleChangeState(track, true);
        }}
      >
        {isPlaying ? (
          <PauseIcon className="w-[10px] h-[10px] text-primary-500" />
        ) : (
          <PlayIcon className="w-[10px] h-[10px] text-primary-500" />
        )}
        {t(isPlaying ? 'pause' : 'play')}
      </button>
    </div>
  );
}

export default TrackPreview;
