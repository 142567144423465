import React from 'react';
import { useTranslation } from 'react-i18next';

interface ChangeLanguageItemProps {
  flag: React.ReactNode;
  language: string;
  languageLabel: string;
}

function ChangeLanguageItem({
  flag,
  language,
  languageLabel,
}: ChangeLanguageItemProps) {
  const { i18n } = useTranslation();

  const handleChangeLanguage = () => {
    i18n.changeLanguage(language);
    localStorage.setItem('dinbog.lang', language);
  };

  return (
    <div className="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9">
      <button
        onClick={() => handleChangeLanguage()}
        type="button"
        className="flex items-center"
      >
        {flag}
        <span className="font-normal ml-3 block truncate">{languageLabel}</span>
      </button>
    </div>
  );
}

export default ChangeLanguageItem;
