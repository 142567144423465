import { AxiosError } from 'axios';
import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { Profile, User } from '@dinbog/models';
import { axios } from '../../api';
import { SignUpFields } from '../../models/auxModels';

export function useSignIn() {
  const mutation = useMutation<
    { user: User; token: string },
    AxiosError,
    { email: string; password: string }
  >(async (credentials) => {
    const { data } = await axios.post<{ user: User; token: string }>(
      '/api/users/login/v1',
      credentials
    );
    if (typeof window !== 'undefined') {
      localStorage.setItem('token', data.token);
    }
    return data;
  });
  return mutation;
}

export function useSignUp() {
  const mutation = useMutation<User, AxiosError, any>(async (newUser) => {
    const { data } = await axios.post('/api/users/sign-up/v1', newUser);
    return data;
  });
  return mutation;
}

export function useCurrentUser() {
  const mutation = useMutation<any, AxiosError, any>(async (_data) => {
    const { data } = await axios.post('/api/users/current/v1', _data);
    return data;
  });
  return mutation;
}

export function useSignUpBusinessWithUser() {
  const mutation = useMutation<User, AxiosError, SignUpFields>(
    async (newUser) => {
      const { data } = await axios.post(
        '/api/users/add-business-login/v1',
        newUser
      );
      return data;
    }
  );
  return mutation;
}

export function useAddBusiness() {
  const mutation = useMutation<
    { user: User; token: string; business: Profile },
    AxiosError,
    SignUpFields & { token: string }
  >(async (newUser) => {
    const { data } = await axios.post('/api/users/add-business/v1', newUser);
    return data;
  });
  return mutation;
}

export function useForgotPassword() {
  const mutation = useMutation<boolean, AxiosError, { email: string }>(
    async (email) => {
      const { data } = await axios.post('/api/users/forgot-password/v1', email);
      return data;
    }
  );
  return mutation;
}

export function useValidateChangePasswordToken() {
  const mutation = useMutation<
    boolean,
    AxiosError,
    { token: string; email: string }
  >(async (_data) => {
    const { data } = await axios.post(
      '/api/users/validate-password-token/v1',
      _data
    );
    return data;
  });
  return mutation;
}

export function useChangePassword() {
  const mutation = useMutation<
    User,
    AxiosError,
    { token: string; newPassword: string; email: string }
  >(async (_data) => {
    const { data } = await axios.post('/api/users/change-password/v1', _data);
    return data;
  });
  return mutation;
}

export function useChangeAccount(
  filters: { token: string; user: string; type: string },
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['changeAccount']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['changeAccount', filters],
    async () => {
      const { data } = await axios.get('/api/users/change-account/v1', {
        params: { ...filters },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      });
      return data;
    },
    options
  );
  return query;
}

export function useChangeOldPassword(token) {
  const mutation = useMutation<
    User,
    AxiosError,
    { user: string; newPassword: string; previousPassword: string }
  >(async (_data) => {
    const { data } = await axios.patch(
      '/api/users/change-previous-password/v1',
      _data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  });
  return mutation;
}

export function useChangeCurrentEmail(token) {
  const mutation = useMutation<
    User,
    AxiosError,
    { user: string; newEmail: string; password: string }
  >(async (_data) => {
    const { data } = await axios.patch('/api/users/change-email/v1', _data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  });
  return mutation;
}

export function useDisableUser() {
  const mutation = useMutation<User, AxiosError, { user: string }>(
    async (_data) => {
      const { data } = await axios.patch('/api/users/disable-user/v1', _data);
      return data;
    }
  );
  return mutation;
}

export function useConfirmEmail() {
  const mutation = useMutation<any, AxiosError, { token: string }>(
    async (_data) => {
      const { data } = await axios.post('/api/users/confirm-email/v1', _data);
      return data;
    }
  );
  return mutation;
}

export function useResendEmailConfirmation(token) {
  const mutation = useMutation<any, AxiosError, void>(async () => {
    const { data } = await axios.get(
      '/api/communications/email/resend-confirmation/v1',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  });
  return mutation;
}
