import React from 'react';
import { SpinnerIcon } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { useCategorySelect } from '../../../../context/CategorySelectContext';
import { useSpecs } from '../../../../hooks/api/specifications';
import { SpecificationsFormContent } from '../../../shared';

function SpecificationFilterContainer() {
  const { selectedLabel } = useCategorySelect();
  const { t } = useTranslation();
  const isModelOrActor =
    selectedLabel === t('actor') || selectedLabel === t('model');
  const {
    data: specificationTemplateData,
    isLoading: specificationTemplateLoading,
  } = useSpecs({}, { enabled: !!selectedLabel });

  const transformedspecificationTemplateData = React.useMemo(
    () =>
      specificationTemplateData?.map((item) => ({
        _id: item.tag,
        specificationTemplate: { ...item, _id: item.tag },
        value: '',
        active: true,
        multipleValues: [],
      })) || [],
    [specificationTemplateData]
  );

  if (specificationTemplateLoading && isModelOrActor) {
    return (
      <>
        <SpinnerIcon className="m-auto w-20 h-20 text-gray-200 animate-spin  fill-primary-500" />
        <span className="sr-only">Loading...</span>
      </>
    );
  }

  return isModelOrActor && !specificationTemplateLoading ? (
    // <SpecificationFilter specificationTemplate={specificationTemplateData} />
    <SpecificationsFormContent
      specifications={transformedspecificationTemplateData}
      containerClassName="flex flex-col gap-7 justify-center items-center"
    />
  ) : null;
}

export default SpecificationFilterContainer;
