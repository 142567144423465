import React from 'react';
import { LikeItem } from '@dinbog/models';
import { useRouter } from 'next/router';

interface LikeDetailProps {
  like: LikeItem;
}

function LikeDetail({ like }: LikeDetailProps) {
  const router = useRouter();
  return (
    <div className="pr-4 w-full flex justify-between gap-2 items-center ">
      <div className="flex gap-4 items-center w-full ">
        <button
          type="button"
          onClick={() =>
            router?.push({
              pathname: `/[slug]`,
              query: { slug: like?.slug },
            })
          }
        >
          <img
            className="rounded-full h-[36px] w-[36px] object-cover border"
            src={like?.photo?.url ?? '/images/fallback/user.png'}
            alt="profilePic"
          />
        </button>

        <div className="w-full">
          <div className="flex gap-2 items-center">
            <button
              type="button"
              onClick={() =>
                router?.push({
                  pathname: `/[slug]`,
                  query: { slug: like?.slug },
                })
              }
            >
              <span className="font-semibold">
                {like?.firstName} {like?.lastName}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LikeDetail;
