/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { Profile, User } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { Card } from '../shared';
import LoadingSuggestedUser from './LoadingSuggestedUser';
import SuggestedUserItem from '../shared/feed/suggestions/SuggestedUserItem';

interface SuggestedUsersCardProps {
  users: Array<User & { profile: Profile }>;
  loadingSuggestedUsers: boolean;
  type: 'talents' | 'businesses';
  refetch;
}

function SuggestedUsersCard({
  users,
  loadingSuggestedUsers,
  type,
  refetch,
}: SuggestedUsersCardProps) {
  const { t } = useTranslation();
  const [showAll, setShowAll] = React.useState(false);
  const [usersData, setUsersData] = React.useState(
    (users ?? []).map((u) => ({
      ...u,
      following: false,
      requestedFollow: false,
      requestedConnection: false,
    }))
  );

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  React.useEffect(() => {
    if (users)
      setUsersData(
        users.map((u) => ({
          ...u,
          following: false,
          requestedFollow: false,
          requestedConnection: false,
        }))
      );
  }, [users]);

  return (
    <Card className="min-w-[348px] p-6 m-0 mb-4">
      <div className="w-full flex flex-col gap-6 pb-4">
        <h3 className=" text-lg font-semibold">
          {t(`feed.suggestions.${type}`)}
        </h3>
        <div className="w-full pl-4 ">
          <div className="w-full space-y-4 ">
            {loadingSuggestedUsers && (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <LoadingSuggestedUser key={index} />
                ))}
              </>
            )}
            {!loadingSuggestedUsers &&
              usersData?.length === 0 &&
              users.length === 0 && <p>{t('feed.suggestions.noUsersFound')}</p>}
            {usersData?.slice(0, 3)?.map((u, index) => (
              <SuggestedUserItem
                refetch={refetch}
                user_={u}
                key={index}
                setUsersData={setUsersData}
              />
            ))}
          </div>
          <AnimatePresence>
            <motion.div
              initial={false}
              className="w-full space-y-4 overflow-hidden"
              animate={{
                height: showAll ? 'auto' : '0px',
              }}
              transition={{ duration: 0.5 }}
            >
              <div />
              {usersData?.slice(3)?.map((u, index) => (
                <SuggestedUserItem
                  refetch={refetch}
                  user_={u}
                  key={index}
                  setUsersData={setUsersData}
                />
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      {users?.length > 3 && (
        <button
          type="button"
          onClick={toggleShowAll}
          className="w-fit font-semibold text-primary-500 px-4 py-3 hover:opacity-50 transition duration-100 ease-in-out"
        >
          {showAll
            ? t(`feed.suggestions.seeLess`)
            : t(`feed.suggestions.seeMore`)}
        </button>
      )}
      <div>
        <Link
          href="/suggestions"
          className="px-4 py-3 text-primary-500 font-semibold hover:opacity-50 transition duration-100 ease-in-out"
        >
          {t('feed.suggestions.seeAll')}
        </Link>
      </div>
    </Card>
  );
}

export default SuggestedUsersCard;
