/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import OtherActionsDropdown from '../OtherActionsDropdown';
import { ConnectionButton, FollowButton, UnblockButton } from './buttons';

export default function RelationsButtons({
  user,
  block,
  refreshUser,
}: {
  user: any;
  block?: { to: boolean; from: boolean };
  refreshUser?: () => void;
}) {
  const [openOtherActions, setOpenOtherActions] = React.useState(false);
  const isTalentProfile = user?.profile?.categories?.length > 0;

  return (
    <div className="flex gap-4 relative">
      {block?.to ? (
        <UnblockButton user={user} refreshUser={refreshUser} />
      ) : null}
      {isTalentProfile && !block?.to && !block?.from ? (
        <>
          <FollowButton user={user} refreshUser={refreshUser} />
          <ConnectionButton user={user} refreshUser={refreshUser} />
        </>
      ) : null}
      <button
        className=""
        type="button"
        onClick={() => setOpenOtherActions(!openOtherActions)}
      >
        <FontAwesomeIcon icon={faEllipsis} className="h-5" />
      </button>
      {openOtherActions ? (
        <OtherActionsDropdown
          user={user}
          blocked={block?.to ?? false}
          refreshUser={refreshUser}
        />
      ) : null}
    </div>
  );
}
