import React from 'react';
import { useTranslation } from 'react-i18next';

interface PersonItemHeaderProps {
  user;
  children?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
}

function PersonItemHeader({
  user,
  children,
  size = 'md',
}: PersonItemHeaderProps) {
  return (
    <div className="text-left">
      <span
        className={`${
          size === 'sm' ? 'text-sm' : 'text-base'
        } font-semibold overflow-hidden whitespace-nowrap`}
      >
        {user?.profile?.firstName} {user?.profile?.lastName ?? ''}
      </span>
      {children}
    </div>
  );
}

export default PersonItemHeader;
