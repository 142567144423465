import React from 'react';
import { Button } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';

interface ActionsButtonsProps {
  disabled: boolean;
  user;
  handleFollow: (toId: string) => void;
  handleUnfollow: (toId: string) => void;
  handleConnect: (toId: string) => void;
  handleDisconnect: (toId: string) => void;
  buttonClassName?: string;
}

export default function ActionsButtons({
  disabled,
  user,
  handleFollow,
  handleUnfollow,
  handleConnect,
  handleDisconnect,
  buttonClassName,
}: ActionsButtonsProps) {
  const { t } = useTranslation();
  const followedButtonLabel =
    user?.profile?.status === 'followed'
      ? t('profile.relations.actions.unfollow')
      : t('profile.relations.actions.follow');

  let connectedButtonLabel;

  if (user?.profile?.connection === 'connected') {
    connectedButtonLabel = t('profile.relations.actions.disconnect');
  } else if (user?.profile?.connection === 'requested') {
    connectedButtonLabel = t('profile.relations.actions.requested');
  } else {
    connectedButtonLabel = t('profile.relations.actions.connect');
  }

  const connectionCall = () => {
    if (user?.profile?.connection === 'connected') {
      handleDisconnect(user?.profile?.user?._id);
    } else {
      handleConnect(user?.profile?.user?._id);
    }
  };

  return (
    <div className="flex gap-2 items-center w-full">
      <Button
        tooltip={followedButtonLabel}
        className={`btn-secondary px-3 h-7 ${buttonClassName}`}
        size="sm"
        disabled={disabled}
        onClick={() =>
          user?.profile?.status === 'followed'
            ? handleUnfollow(user?.profile?.user?._id)
            : handleFollow(user?.profile?.user?._id)
        }
      >
        {followedButtonLabel}
      </Button>
      <Button
        tooltip={connectedButtonLabel}
        className={`btn-primary px-3 h-7 ${buttonClassName}`}
        size="sm"
        disabled={disabled}
        onClick={() => {
          connectionCall();
        }}
      >
        {connectedButtonLabel}
      </Button>
    </div>
  );
}
