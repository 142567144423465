import React from 'react';
import { Book, Image, Multimedia } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useTruncatedText } from '../../../hooks';
import FullPhoto from '../media/FullPhoto';

function BookContent({ book }: { book: Book & { images?: Image[] } }) {
  const { t } = useTranslation();
  const [selectedPhoto, setSelectedPhoto] = React.useState(null);
  const [images, setImages] = React.useState<Multimedia[]>(
    book !== null
      ? [book?.cover, ...(book?.images?.map((img) => img?.multimedia) ?? [])]
      : []
  );

  const { truncatedContent, toggle, showFullText, maxChars } = useTruncatedText(
    book?.description ?? ''
  );

  React.useEffect(() => {
    if (book) {
      setImages([
        book?.cover,
        ...(book?.images?.map((img) => img?.multimedia) ?? []),
      ]);
    }
  }, [book]);

  return (
    <div className="w-full py-6 flex flex-col gap-6 b border-b-[1px] border-neutral-200">
      {/* book photos */}
      <div className="flex flex-wrap lg:gap-x-10 gap-y-4 lg:items-start items-center">
        {images?.map((image, idx) => (
          <div key={idx}>
            <button
              type="button"
              className="sm:w-[200px] w-48 sm:h-[200px] h-48 relative bg-transparent flex flex-row justify-center items-center"
              onClick={() => setSelectedPhoto(image)}
            >
              <img
                alt={image?.fileName}
                src={image?.url as string}
                className="w-full h-full object-cover rounded-lg"
              />
            </button>
            {selectedPhoto?.url === image?.url ? (
              <FullPhoto photo={image} setSelected={setSelectedPhoto} />
            ) : null}
          </div>
        ))}
      </div>
      {/* book description */}
      <div className="description w-full text-neutral-500  text-justify ">
        {truncatedContent}
        {book?.description.length > maxChars && (
          <>
            {!showFullText ? '... ' : ' '}
            <button
              type="button"
              className="text-blue-500 cursor-pointer"
              onClick={toggle}
            >
              {showFullText ? t('readLess') : t('readMore')}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default BookContent;
