import React from 'react';
import { useRouter } from 'next/router';
import { validateString } from 'avilatek-utils';
import { CategoryTypes, Profile, User, WorkReference } from '@dinbog/models';
import { useVideos } from '../../../hooks/api/videos';
import { useCategories } from '../../../hooks/api/categories';
import { useAlbums } from '../../../hooks/api/albums';
import { useGetTalentPosts } from '../../../hooks/api/posts';
import { useBooks } from '../../../hooks/api/books';
import {
  useInfiniteWorkReferences,
  useWorkReferences,
} from '../../../hooks/api/workReferences';
import { Tab } from '../../shared';
import {
  AudioTab,
  VideoTab,
  TalentTab,
  BookTab,
  PostTab,
  WorkTab,
} from '../shared/tabs';
import ProfileInfoCard from '../shared/profileInfoCard/ProfileInfoCard';
import { useUser } from '../../../hooks';

interface BusinessProfileProps {
  user: {
    _id?: string;
    profile: Profile;
    relations?: { follow: string; connection: string };
  };
  findUserBySlug?: () => void;
}

export default function BusinessProfile({
  user,
  findUserBySlug = null,
}: BusinessProfileProps) {
  const router = useRouter();
  const { currentUser } = useUser();
  const [tabSelected, setTabSelected] = React.useState('talents');
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 10,
  });

  const [categoryFilter, setCategoryFilter] = React.useState(''); // id of the category to filter media

  const [talentsFilters, setTalentsFilters] = React.useState({
    status: undefined,
    company: (user?.profile?.user as User)?._id || user?.profile?.user,
  });

  const profileUser = user?.profile?.user;
  const userId =
    typeof profileUser === 'object' ? profileUser?._id : profileUser;

  const isNotOwner = userId !== (currentUser?.currentUser?.user as User)?._id;

  const {
    data: dataTalents,
    status: statusTalents,
    isLoading: isLoadingTalents,
    error: errorTalents,
    refetch: refetchTalents,
  } = useWorkReferences({
    ...pagination,
    filter: JSON.stringify({
      ...talentsFilters,
    }),
  });

  const {
    data: dataPosts,
    status: statusPosts,
    isLoading: isLoadingPosts,
    error: errorPosts,
    refetch: refetchPosts,
  } = useGetTalentPosts({
    ...pagination,
    user: isNotOwner ? profileUser : userId,
    active: true,
    sort: 'createdAt',
  });

  const { data: dataVideos, isLoading: loadingVideos } = useVideos({
    user: isNotOwner ? profileUser : userId,
    ...pagination,
    categories:
      validateString(categoryFilter) && tabSelected === 'videos'
        ? ([categoryFilter] as string[])
        : null, // only filter when the tab is selected
  });

  const { data: dataAlbums, isLoading: loadingAlbums } = useAlbums({
    user: isNotOwner ? profileUser : userId,
    ...pagination,
    categories:
      validateString(categoryFilter) && tabSelected === 'audios'
        ? ([categoryFilter] as string[])
        : null, // only filter when the tab is selected
  });

  const { data: dataBooks, isLoading: loadingBooks } = useBooks({
    user: isNotOwner ? (profileUser as string) : userId,
    ...pagination,
    category:
      validateString(categoryFilter) && tabSelected === 'books'
        ? categoryFilter
        : null, // only filter when the tab is selected
  });

  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategories(
      {
        type: tabSelected?.replace(/s$/, '') as CategoryTypes,
      },
      'portfolios', // To get categories from ms-portfolios
      {
        refetchOnWindowFocus: false,
      }
    );

  const {
    data: dataWorks,
    fetchNextPage,
    isLoading: loadingWorks,
  } = useInfiniteWorkReferences(
    {
      filter: JSON.stringify({
        user: isNotOwner ? profileUser : userId,
        status: isNotOwner ? 'approved' : undefined,
        active: true,
      }), // only show approved works if the user is not the owner
      sortBy: 'startDate',
      order: -1,
      page: pagination.page,
      perPage: pagination.perPage,
    },
    {
      enabled: tabSelected === 'works' || (categoryFilter === '' && !!user),
    }
  );
  const worksData = React.useMemo(() => {
    const pages = dataWorks?.pages || [];
    const flattenedItems = pages.reduce(
      (acc, page) => [...acc, ...page.items],
      [] as WorkReference[]
    );
    const totalCount = pages[0]?.count || 0;
    const pageInfo = pages[pages.length - 1]?.pageInfo;

    return {
      count: totalCount,
      items: flattenedItems,
      pageInfo,
    };
  }, [dataWorks]);

  const items = React.useMemo(
    () => ({
      talents: {
        title: 'Talents',
        number: talentsFilters?.status === 'approved' ? dataTalents?.count : 0,
        itemKey: 'talents',
        component: (
          <TalentTab
            viewedUser={user}
            talentsData={dataTalents}
            loading={isLoadingTalents}
            setPagination={setPagination}
            setFilters={setTalentsFilters}
          />
        ),
      },
      posts: {
        title: 'Posts',
        number: dataPosts?.count ?? 0,
        itemKey: 'posts',
        component: (
          <PostTab
            postsData={dataPosts}
            loading={isLoadingPosts}
            setPagination={setPagination}
          />
        ),
      },
      books: {
        title: 'Books',
        number: dataBooks?.count ?? 0,
        itemKey: 'books',
        component: (
          <BookTab
            setPagination={setPagination}
            bookData={dataBooks}
            loading={loadingBooks}
            categories={dataCategories}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
          />
        ),
      },
      videos: {
        title: 'Videos',
        number: dataVideos?.count ?? 0,
        itemKey: 'videos',
        component: (
          <VideoTab
            setPagination={setPagination}
            videoData={dataVideos}
            loading={loadingVideos}
            categories={dataCategories}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
          />
        ),
      },
      audios: {
        title: 'Audios',
        number: dataAlbums?.count ?? 0,
        itemKey: 'audios',
        component: (
          <AudioTab
            setPagination={setPagination}
            albumData={dataAlbums}
            loading={loadingAlbums}
            categories={dataCategories}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
          />
        ),
      },
      works: {
        title: 'CV',
        number: worksData?.count ?? 0,
        itemKey: 'works',
        component: (
          <WorkTab
            workReferenceData={worksData}
            user={user} // the owner of the profile
            fetchNextPage={fetchNextPage}
          />
        ),
      },
    }),
    [dataTalents, dataPosts, dataVideos, dataAlbums, dataCategories, worksData]
  );

  React.useEffect(() => {
    setCategoryFilter(''); // reset filter if tab changes
    // reset pagination
    if (tabSelected !== router?.query?.tab) {
      setPagination((prev: { page: number; perPage: number }) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [tabSelected]);

  React.useEffect(() => {
    if (router?.query?.tab === 'posts') {
      if (router?.pathname === '/[slug]') {
        router.push(
          {
            pathname: `${router.pathname}`,
            query: {
              slug: router?.query?.slug,
              tab: 'talents',
            },
          },
          undefined,
          { shallow: true }
        );
      } else {
        router.push(
          {
            pathname: `${router.pathname}`,
            query: {
              tab: 'talents',
            },
          },
          undefined,
          { shallow: true }
        );
      }
    }
  }, []);

  return (
    <div className="space-y-12">
      <ProfileInfoCard user={user} findUserBySlug={findUserBySlug} />
      <div className="px-12">
        <Tab
          items={Object.values(items)}
          currentItem={tabSelected}
          setCurrentItem={setTabSelected}
          isQueryParam
        />
        <div className="sm:px-16 sm:py-12 py-6 px-8 w-full">
          {router.isReady ? items[tabSelected]?.component : null}
        </div>
      </div>
    </div>
  );
}
