import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { ItemCollectionEnum, User } from '@dinbog/models';
import {
  faThumbsUp,
  faComment,
  faPaperPlane,
} from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as likedIcon } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useLike, useDislike } from '../../hooks/api/likes';
import { useNotify, useUser } from '../../hooks';
import { useSharePost } from '../../hooks/api/posts';

interface LikeCommentShareButtonsProps {
  item: string;
  slug: string;
  ownerSlug: string;
  itemCollection: ItemCollectionEnum;
  buttonsSize?: string;
  buttonsGap?: string;
  ms: 'posts' | 'portfolios';
  onHitCommentButton: () => void;
  liked: boolean;
  onSuccessLike?: () => void;
  placeEnd?: boolean;
}

function LikeCommentShareButtons({
  item, // item id
  slug, // slug of the item
  ownerSlug, // slug of the owner of the item
  itemCollection,
  buttonsSize = 'h-[12px]',
  buttonsGap = 'gap-4',
  ms, // micro service
  onHitCommentButton, // to open the comments modal or do something else
  liked, // to show the liked icon
  onSuccessLike = () => {}, // to call refetch queries as needed
  placeEnd = false,
}: LikeCommentShareButtonsProps) {
  const notify = useNotify();
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState(false);
  const { currentUser: user } = useUser();

  // queries/mutations
  const like = useLike(ms); // micro service
  const dislike = useDislike(ms); // micro service
  const sharePost = useSharePost();

  const onHitLikeButton = async () => {
    setDisabled(true);

    try {
      await (liked ? dislike : like).mutateAsync(
        {
          item,
          itemCollection,
          user: user?._id,
        },
        {
          onSuccess: onSuccessLike,
        }
      );
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onShare = async () => {
    setDisabled(true);
    try {
      const shareUrl = await sharePost.mutateAsync({
        collection: itemCollection,
        slugCollection: slug,
        slugUser: ownerSlug || 'profile',
      });
      navigator.clipboard.writeText(shareUrl);
      notify(t('copyToClipboard'), 'success');
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
    setDisabled(false);
  };

  return (
    <div
      className={`flex flex-row ${buttonsGap} ${placeEnd ? ' self-end' : ''}`}
    >
      <button
        type="button"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          onHitLikeButton();
        }}
        className="flex flex-start"
        aria-label="Like button"
      >
        <FontAwesomeIcon
          icon={liked ? likedIcon : faThumbsUp}
          className={`${liked ? 'text-primary-500' : ''} ${buttonsSize}`}
        />
      </button>
      <button
        type="button"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          onHitCommentButton();
        }}
        className="flex flex-start"
        aria-label="Comment button"
      >
        <FontAwesomeIcon icon={faComment} className={buttonsSize} />
      </button>

      <button
        type="button"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          onShare();
        }}
        className="flex flex-start"
        aria-label="Share button"
      >
        <FontAwesomeIcon icon={faPaperPlane} className={buttonsSize} />
      </button>
    </div>
  );
}

export default LikeCommentShareButtons;
