import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { DotIcon } from '@dinbog/ui';
import userFallback from '../../../../../../../public/images/fallback/user.png';

function ChatDropdownItem() {
  return (
    <button
      type="button"
      className="flex  w-full text-left border-none"
      // onClick={() => onClick()}
    >
      <div className="dropdown-item py-5">
        <img
          className="rounded-full w-8 h-8 mr-2"
          src={userFallback.src}
          alt="user"
        />
        <div className="flex flex-col">
          <span className="text-text-black">Amanda Smith</span>
          <div className=" flex ">
            <CheckIcon className="w-5 h-5 text-text-gray mx-2  my-auto" />
            <p className="text-text-gray text-sm w-full">
              Lorem Ipsum es simplemente el texto de relleno de las imprentas y
              archivos de texto.
            </p>
          </div>
        </div>
        <div className=" flex flex-col">
          <span className="text-text-gray text-sm">9:00am</span>
          <DotIcon className="w-5 h-5 text-text-gray mx-auto" />
        </div>
      </div>
    </button>
  );
}

export default ChatDropdownItem;
