import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { genderOptions } from '@dinbog/models';
import { MultipleSelect, Select, TextArea } from '@dinbog/ui';
import { useLocation, useNotify, useUser } from '../../../../hooks';
import { useUpdateProfile } from '../../../../hooks/api/profiles';
import { DocumentModel } from '../../../../models';
import { ACTIONS } from '../reducer';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../utils/formatDocumentModel';
import { languages } from '../../../../lib/languages';
import FormButtons from '../../shared/FormButtons';
import { CustomPhoneInput } from '../../../shared';
import PersonalInfoPictures from './PersonalInfoPictures';

interface PersonalInfoProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formsLength: number;
}

export default function PersonalInfo({
  currentStep = 1,
  setCurrentStep,
  formsLength,
}: PersonalInfoProps) {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const notify = useNotify();
  const { t, i18n } = useTranslation();
  const updateMutation = useUpdateProfile();

  const [disabled, setDisabled] = React.useState<boolean>(false);

  const methods = useForm<any>({
    defaultValues: {
      [ACTIONS.BIRTH_DATE]: user?.profile?.birthDate ?? '',
      [ACTIONS.GENDER]: user?.profile?.gender ?? '',
      [ACTIONS.DESCRIPTION]: user?.profile?.description ?? '',
      [ACTIONS.BIRTH_COUNTRY]: user?.profile?.birthPlace?.country ?? '',
      [ACTIONS.BIRTH_STATE]: user?.profile?.birthPlace?.state ?? '',
      [ACTIONS.BIRTH_CITY]: user?.profile?.birthPlace?.city ?? '',
      [ACTIONS.CURRENT_COUNTRY]: user?.profile?.address?.country ?? '',
      [ACTIONS.CURRENT_STATE]: user?.profile?.address?.state ?? '',
      [ACTIONS.CURRENT_CITY]: user?.profile?.address?.city ?? '',
      [ACTIONS.PHONE]:
        `${user?.profile?.phone?.prefix}${user?.profile?.phone?.number}` ?? '',
      photo: user?.profile?.photo ?? null,
      cover: user?.profile?.cover ?? null,
      languages: user?.profile?.languages ?? [],
    },
  }); // FIXME: ADD TYPE

  const {
    countries: birthCountries,
    states: birthStates,
    cities: birthCities,
  } = useLocation(
    methods?.watch(ACTIONS.BIRTH_COUNTRY) ?? '',
    methods?.watch(ACTIONS.BIRTH_STATE) ?? ''
  );
  const {
    countries: currentCountries,
    states: currentStates,
    cities: currentCities,
  } = useLocation(
    methods?.watch(ACTIONS.CURRENT_COUNTRY) ?? '',
    methods?.watch(ACTIONS.CURRENT_STATE) ?? ''
  );

  const formValidations = {
    [ACTIONS.BIRTH_DATE]: { required: t('required') },
    [ACTIONS.GENDER]: { required: t('required') },
    [ACTIONS.DESCRIPTION]: { required: t('required') },
    [ACTIONS.BIRTH_COUNTRY]: { required: t('required') },
    [ACTIONS.CURRENT_COUNTRY]: { required: t('required') },
    [ACTIONS.CURRENT_STATE]:
      currentStates?.length > 0 ? { required: t('required') } : {},
    [ACTIONS.CURRENT_CITY]:
      currentCities?.length > 0 ? { required: t('required') } : {},
  };

  const [image, setImage] = React.useState<DocumentModel[]>(
    user?.profile?.photo ? multimediaToDocument([user?.profile?.photo]) : []
  );
  const updateURLs = React.useCallback(setImage, [setImage]);
  const [cover, setCover] = React.useState<DocumentModel[]>(
    user?.profile?.cover ? multimediaToDocument([user?.profile?.cover]) : []
  );
  const updateCoverURLs = React.useCallback(setCover, [setCover]);

  const onSubmit = async (e) => {
    setDisabled(true);
    try {
      const profilePhoto = image?.length > 0 ? documentToMedia(image) : null;
      const coverPhoto = cover?.length > 0 ? documentToMedia(cover) : null;

      if (!profilePhoto) {
        return notify(t('auth.signUp.wizard.notifs.profilePicture'), 'error');
      }

      if (
        e.phone &&
        e.phone?.split('-')[1]?.length < 7 &&
        e.phone?.split('-')[1]?.length > 0
      ) {
        return notify(t('auth.signUp.wizard.notifs.shortPhone'), 'error');
      }

      const data = await updateMutation.mutateAsync({
        user: user?._id,
        birthPlace: {
          country: e.birthCountry,
          city: e.birthCity ?? null,
          state: e?.birthState ?? null,
        },
        address: {
          country: e.currentCountry,
          city: e.currentCity ?? null,
          state: e?.currentState ?? null,
        },
        description: e?.description,
        gender: e.gender,
        phone:
          e?.phone && e.phone?.split('-')[1]?.length > 0
            ? {
                prefix: e?.phone?.split('-')[0],
                number: e.phone?.split('-')[1],
              }
            : null,
        ...(image?.length > 0
          ? { photo: { ...profilePhoto[0], type: 'image' } }
          : { photo: undefined }),
        ...(cover?.length > 0
          ? { cover: { ...coverPhoto[0], type: 'image' } }
          : { cover: undefined }),
        registrationStep: '2',
        appLanguage: i18n?.language ?? 'en',
        languages: e?.languages,
      });
      if (!data) {
        return notify(t('auth.signUp.notifs.failure'), 'error');
      }
      setUser({
        ...user,
        _id: user?._id,
        profile: data,
        token: user?.token,
        currentUser: user?.currentUser,
        accounts: user?.accounts,
      });
      setCurrentStep((cs) => cs + 1);
    } catch (err) {
      // console.log(err);
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <form method="post" onSubmit={methods?.handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-y-10">
        <h3 className="font-semibold text-lg">
          {t('auth.signUp.wizard.personal.title')}
        </h3>
        <PersonalInfoPictures
          profileImage={image}
          coverImage={cover}
          updateProfileURLs={updateURLs}
          updateCoverURLs={updateCoverURLs}
        />
        <div className="space-y-8 flex flex-col">
          <div className="flex md:flex-row flex-col gap-x-8 gap-y-4">
            <div className="w-full">
              <Select
                isRequired
                error={
                  methods?.formState?.errors[ACTIONS.GENDER]?.message as string
                }
                className={`w-full `}
                label={t('auth.signUp.wizard.personal.gender.title') ?? ''}
                {...methods?.register(
                  ACTIONS.GENDER,
                  formValidations[ACTIONS.GENDER]
                )}
              >
                <option key={-1} value="">
                  {t('auth.signUp.wizard.personal.gender.options.default')}
                </option>
                {genderOptions?.map((gender, i) => (
                  <option key={i + 1} value={gender}>
                    {t(`auth.signUp.wizard.personal.gender.options.${gender}`)}
                  </option>
                ))}
              </Select>
            </div>
            <CustomPhoneInput
              name={ACTIONS.PHONE}
              label={t('auth.signUp.wizard.personal.phone')}
              error={
                methods?.formState?.errors[ACTIONS.PHONE]?.message as string
              }
              validations={formValidations[ACTIONS.PHONE]}
              control={methods?.control}
              controlled
            />
          </div>
          <div className="">
            <TextArea
              isRequired
              error={
                methods?.formState?.errors[ACTIONS.DESCRIPTION]
                  ?.message as string
              }
              className="h-24"
              label={t('auth.signUp.wizard.personal.description.title') ?? ''}
              placeholder={
                t('auth.signUp.wizard.personal.description.description') ?? ''
              }
              {...methods?.register(
                ACTIONS.DESCRIPTION,
                formValidations[ACTIONS.DESCRIPTION]
              )}
            />
          </div>
          {/* spoken languages multiselect */}
          <div className="w-full">
            <MultipleSelect
              disabled={disabled}
              options={languages?.map((lang) => ({
                name: lang?.name,
                _id: lang?.lang,
              }))}
              defaultSelect={
                methods?.getValues('languages')?.map((language_) => {
                  const language = languages.find(
                    (_language) => _language.lang === language_
                  );
                  if (!language) return null;
                  return {
                    _id: language.lang,
                    value: language.name,
                    name: language.name,
                  };
                }) ?? []
              }
              label={t('auth.signUp.wizard.personal.languages') ?? ''}
              onChangeSelect={(selected) => {
                methods?.setValue(
                  'languages',
                  selected.map((lang) => lang._id)
                );
              }}
              /* defaultSelect={categoriesDefault} */
              {...methods?.register('languages')}
            />
          </div>
          <div className="text-sm space-y-4">
            <p className="font-semibold">
              {t('auth.signUp.wizard.personal.birthLocation.title')}
            </p>
            <div className="flex md:flex-row flex-col gap-x-8 gap-y-4">
              <div className="w-full">
                <Select
                  isRequired
                  name={ACTIONS.BIRTH_COUNTRY}
                  error={
                    methods?.formState?.errors[ACTIONS.BIRTH_COUNTRY]
                      ?.message as string
                  }
                  className={`w-full `}
                  label={
                    t('auth.signUp.wizard.personal.birthLocation.country') ?? ''
                  }
                  {...methods?.register(
                    ACTIONS.BIRTH_COUNTRY,
                    formValidations[ACTIONS.BIRTH_COUNTRY]
                  )}
                >
                  <option key={-1} value="" disabled>
                    {t('auth.signUp.wizard.personal.birthLocation.select')}
                  </option>
                  {birthCountries?.map((country, i) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="w-full">
                <Select
                  name={ACTIONS.BIRTH_STATE}
                  error={
                    methods?.formState?.errors[ACTIONS.BIRTH_STATE]
                      ?.message as string
                  }
                  className={`w-full `}
                  label={
                    t('auth.signUp.wizard.personal.birthLocation.state') ?? ''
                  }
                  {...methods?.register(
                    ACTIONS.BIRTH_STATE,
                    formValidations[ACTIONS.BIRTH_STATE]
                  )}
                >
                  <option key={-1} value="" disabled>
                    {t('auth.signUp.wizard.personal.birthLocation.select')}
                  </option>
                  {birthStates?.map((s, i) => (
                    <option
                      key={s.isoCode}
                      value={s.isoCode}
                      selected={
                        methods?.watch(ACTIONS.BIRTH_STATE) === s.isoCode
                      }
                    >
                      {s.name}
                    </option>
                  ))}
                </Select>
              </div>
              <Select
                name={ACTIONS.BIRTH_CITY}
                className="w-full"
                label={
                  t('auth.signUp.wizard.personal.birthLocation.city') ?? ''
                }
                error={
                  methods?.formState?.errors[ACTIONS.BIRTH_CITY]
                    ?.message as string
                }
                disabled={birthCities.length <= 0}
                {...methods?.register(
                  ACTIONS.BIRTH_CITY,
                  formValidations[ACTIONS.BIRTH_CITY]
                )}
              >
                <option key={-1} value="">
                  {t('auth.signUp.wizard.personal.birthLocation.select')}
                </option>
                {birthCities?.map((city, i) => (
                  <option
                    key={i}
                    value={city.name}
                    selected={methods?.watch(ACTIONS.BIRTH_CITY) === city.name}
                  >
                    {city.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <div className="text-sm space-y-4">
            <p className="font-semibold">
              {t('auth.signUp.wizard.personal.currentLocation.title')}
            </p>
            <div className="flex md:flex-row flex-col gap-x-8 gap-y-4">
              <div className="w-full">
                <Select
                  isRequired
                  name={ACTIONS.CURRENT_COUNTRY}
                  error={
                    methods?.formState?.errors[ACTIONS.CURRENT_COUNTRY]
                      ?.message as string
                  }
                  className="w-full"
                  label={
                    t('auth.signUp.wizard.personal.currentLocation.country') ??
                    ''
                  }
                  {...methods?.register(
                    ACTIONS.CURRENT_COUNTRY,
                    formValidations[ACTIONS.CURRENT_COUNTRY]
                  )}
                >
                  <option key={-1} value="">
                    {t('auth.signUp.wizard.personal.currentLocation.select')}
                  </option>
                  {currentCountries?.map((country, i) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="w-full">
                <Select
                  isRequired={currentStates?.length > 0}
                  name={ACTIONS.CURRENT_STATE}
                  error={
                    methods?.formState?.errors[ACTIONS.CURRENT_STATE]
                      ?.message as string
                  }
                  className="w-full"
                  label={
                    `${t(
                      'auth.signUp.wizard.personal.currentLocation.state'
                    )} *` ?? ''
                  }
                  {...methods?.register(
                    ACTIONS.CURRENT_STATE,
                    formValidations[ACTIONS.CURRENT_STATE]
                  )}
                >
                  <option key={-1} value="">
                    {t('auth.signUp.wizard.personal.currentLocation.select')}
                  </option>
                  {currentStates?.map((s, i) => (
                    <option
                      key={s.isoCode}
                      value={s.isoCode}
                      selected={
                        methods?.watch(ACTIONS.CURRENT_STATE) === s.isoCode
                      }
                    >
                      {s.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="w-full">
                <Select
                  isRequired
                  className="w-full"
                  error={
                    methods?.formState?.errors[ACTIONS.CURRENT_CITY]
                      ?.message as string
                  }
                  label={
                    t('auth.signUp.wizard.personal.currentLocation.city') ?? ''
                  }
                  {...methods?.register(
                    ACTIONS.CURRENT_CITY,
                    formValidations[ACTIONS.CURRENT_CITY]
                  )}
                >
                  <option key={-1} value="">
                    {t('auth.signUp.wizard.personal.currentLocation.select')}
                  </option>
                  {currentCities?.map((city, i) => (
                    <option
                      key={i}
                      value={city.name ?? ''}
                      selected={
                        methods?.watch(ACTIONS.CURRENT_CITY) === city.name
                      }
                    >
                      {city.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormButtons
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formsLength={formsLength}
        canSkip={false}
      />
    </form>
  );
}
