import React from 'react';
import NotificationCard from './NotificationCard';
import InfiniteList from '../shared/infinite-list/InfiniteList';

interface NotificationListProps {
  notificationData;
  fetchNextPage;
}

function NotificationList({
  notificationData,
  fetchNextPage,
}: NotificationListProps) {
  return (
    <div className="max-h-[500px] overflow-y-auto">
      <InfiniteList
        items={notificationData.items}
        fetchNextPage={fetchNextPage}
      >
        {(notification) => (
          <NotificationCard
            notification={notification}
            key={notification._id}
          />
        )}
      </InfiniteList>
    </div>
  );
}

export default NotificationList;
