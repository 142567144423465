export const languages = [
  { lang: 'ar', name: 'العربية' },
  { lang: 'ast', name: 'Asturianu' },
  { lang: 'az', name: 'Azərbaycan dili' },
  { lang: 'be', name: 'Беларуская' },
  { lang: 'bn', name: 'বাংলা' },
  { lang: 'br', name: 'Brezhoneg' },
  { lang: 'bs', name: 'Bosanski' },
  { lang: 'ca', name: 'Català' },
  { lang: 'cs', name: 'Čeština' },
  { lang: 'cy', name: 'Cymraeg' },
  { lang: 'da', name: 'Dansk' },
  { lang: 'de', name: 'Deutsch' },
  { lang: 'el', name: 'Ελληνικά' },
  { lang: 'en', name: 'English' },
  { lang: 'es', name: 'Español' },
  { lang: 'et', name: 'Eesti' },
  { lang: 'eu', name: 'Euskara' },
  { lang: 'fa', name: 'فارسی' },
  { lang: 'fi', name: 'Suomi' },
  { lang: 'fo', name: 'Føroyskt' },
  { lang: 'fr', name: 'Français' },
  { lang: 'ga', name: 'Gaeilge' },
  { lang: 'gd', name: 'Gàidhlig' },
  { lang: 'gl', name: 'Galego' },
  { lang: 'he', name: 'עברית' },
  { lang: 'hi', name: 'हिन्दी' },
  { lang: 'hr', name: 'Hrvatski' },
  { lang: 'hu', name: 'Magyar' },
  { lang: 'hy', name: 'Հայերեն' },
  { lang: 'id', name: 'Bahasa Indonesia' },
  { lang: 'is', name: 'Íslenska' },
  { lang: 'it', name: 'Italiano' },
  { lang: 'ja', name: '日本語' },
  { lang: 'kk', name: 'Қазақ тілі' },
  { lang: 'kl', name: 'Kalaallisut' },
  { lang: 'ko', name: '한국어' },
  { lang: 'kw', name: 'Kernewek' },
  { lang: 'ky', name: 'Кыргызча' },
  { lang: 'lt', name: 'Lietuvių' },
  { lang: 'lv', name: 'Latviešu' },
  { lang: 'mk', name: 'Македонски' },
  { lang: 'mn', name: 'Монгол' },
  { lang: 'nl', name: 'Nederlands' },
  { lang: 'nn', name: 'Nynorsk' },
  { lang: 'no', name: 'Norsk' },
  { lang: 'pl', name: 'Polski' },
  { lang: 'pt', name: 'Português' },
  { lang: 'ro', name: 'Română' },
  { lang: 'ru', name: 'Русский' },
  { lang: 'se', name: 'Sámegiella' },
  { lang: 'sk', name: 'Slovenčina' },
  { lang: 'sl', name: 'Slovenščina' },
  { lang: 'sma', name: 'Åarjelsaemien giele' },
  { lang: 'sme', name: 'Davvisámegiella' },
  { lang: 'smj', name: 'Julevsámegiella' },
  { lang: 'sms', name: 'Säämǩiõll' },
  { lang: 'sq', name: 'Shqip' },
  { lang: 'sr', name: 'Српски' },
  { lang: 'sv', name: 'Svenska' },
  { lang: 'tg', name: 'Тоҷикӣ' },
  { lang: 'th', name: 'ไทย' },
  { lang: 'tr', name: 'Türkçe' },
  { lang: 'uk', name: 'Українська' },
  { lang: 'uz', name: "O'zbek" },
  { lang: 'vi', name: 'Tiếng Việt' },
  { lang: 'zh', name: '中文' },
];
