import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt } from '@fortawesome/free-solid-svg-icons';
import { CropImageModal } from '../../../shared';
import { useSignS3 } from '../../../../hooks/api/s3';
import { useNotify } from '../../../../hooks';
import { uploadFile } from '../../../../lib/uploadFile';

interface ProfilePictureProps {
  profileImage;
  updateProfileURLs;
}

export default function ProfilePicture({
  profileImage,
  updateProfileURLs,
}: ProfilePictureProps) {
  const { t } = useTranslation();
  const notify = useNotify();
  const [uploading, setUploading] = React.useState(false);
  const createMutation = useSignS3();
  const [photoModalIsOpen, setPhotoModalIsOpen] = React.useState(false);
  const [cropProps, setCropProps] = React.useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    size: { width: 100, height: 100 },
  });

  const onSubmit = () => {
    uploadFile(
      profileImage[0],
      setUploading,
      updateProfileURLs,
      createMutation,
      notify,
      t
    );
    setPhotoModalIsOpen(false);
  };

  return (
    <>
      <div className="space-y-4">
        <p className="font-semibold text-sm">
          {t('auth.signUp.wizard.personal.picture.profilePictureTitle')} *
        </p>
        <div className="flex lg:flex-row flex-col lg:gap-x-8 gap-y-4 lg:items-start items-center">
          <div className="">
            <div className="relative sm:w-[200px] w-48 sm:h-[200px] h-48">
              <button
                type="button"
                className="sm:w-[200px] w-48 sm:h-[200px] h-48"
                onClick={() => setPhotoModalIsOpen(true)}
              >
                {/* show profile picture if chosen already */}
                {profileImage?.length > 0 ? (
                  <div className="w-full h-full relative">
                    <div className="absolute w-full h-full rounded-full opacity-0 hover:opacity-100 bg-black/40 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={faCameraAlt}
                        className="h-6 w-6 text-background-300"
                      />
                    </div>
                    <img
                      alt={profileImage[0]?.name}
                      src={profileImage[0]?.src}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                ) : (
                  <div className="h-full w-full rounded-lg bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2">
                    <FontAwesomeIcon icon={faCameraAlt} className="w-5 h-5" />
                    <p className="font-semibold text-sm">
                      {t(
                        `auth.signUp.wizard.personal.picture.uploadProfilePicture`
                      )}
                    </p>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CropImageModal
        title={t('auth.signUp.wizard.personal.picture.uploadProfilePicture')}
        isOpen={photoModalIsOpen}
        setIsOpen={setPhotoModalIsOpen}
        image={profileImage}
        updateURLs={updateProfileURLs}
        defaultCrop={cropProps}
        onSubmit={onSubmit}
        cropShape="round"
      />
    </>
  );
}
