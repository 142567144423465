import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Country, State } from 'country-state-city';
import { Profile, Specification, SpecificationTemplate } from '@dinbog/models';
import SpecsColumn from '../../../../shared/specs/SpecsColumn';
import { useGetTalentSpecs } from '../../../../../hooks/api/specifications';
import { SkeletonWrapper } from '../../../../shared';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

interface ProfileSpecsProps {
  profile: Profile;
  userId: string;
  isModal?: boolean;
}

function ProfileSpecs({ profile, userId, isModal = false }: ProfileSpecsProps) {
  const { t } = useTranslation();
  // talent specs
  const { data: dataSpecs, isLoading: isLoadingSpecs } = useGetTalentSpecs(
    {
      user: userId ?? '',
    },
    { refetchOnWindowFocus: false }
  );

  const [specs, setSpecs] = React.useState<
    (Specification & { type: string })[]
  >(
    dataSpecs?.map((spec) => ({
      ...spec,
      type: (spec?.specificationTemplate as SpecificationTemplate)?.type,
    })) ?? []
  );

  const [distributedSpecs, setDistributedSpecs] = React.useState<
    Specification[][]
  >([]);

  React.useEffect(() => {
    if (specs && !isLoadingSpecs) {
      setSpecs(
        dataSpecs?.map((spec) => ({
          ...spec,
          type: (spec?.specificationTemplate as SpecificationTemplate)?.type,
        }))
      );
    }
  }, [dataSpecs, isLoadingSpecs]);

  const distributeSpecs = () => [
    specs.slice(0, Math.floor(specs.length / 2) + 1),
    specs.slice(Math.floor(specs.length / 2) + 1, specs.length),
  ];

  const getProfileInfo = () => [
    {
      name: t('profile.generalInfo.gender'),
      value: profile?.gender ?? '-',
    },
    {
      name: t('profile.generalInfo.birthDate'),
      value:
        dayjs(profile?.birthDate?.toString()?.split('T')[0]).format('ll') ??
        '-',
    },
    {
      name: t('profile.generalInfo.languages'),
      value:
        profile?.languages?.length > 0 ? profile?.languages?.join(' ') : '-',
    },
    {
      name: t('profile.generalInfo.birthCountry'),
      value:
        Country.getCountryByCode(profile?.birthPlace?.country)?.name ?? '-',
    },
    {
      name: t('profile.generalInfo.birthState'),
      value:
        State.getStateByCodeAndCountry(
          profile?.birthPlace?.state,
          profile?.birthPlace?.country
        )?.name ?? '-',
    },
    {
      name: t('profile.generalInfo.birthCity'),
      value: profile?.birthPlace?.city ?? '-',
    },
  ];

  React.useEffect(() => {
    setDistributedSpecs(distributeSpecs());
  }, [profile, userId, dataSpecs, isLoadingSpecs, specs]);

  return (
    <SkeletonWrapper isLoading={isLoadingSpecs}>
      <div className={`w-full flex ${isModal ? 'flex-col' : 'flex-wrap'}`}>
        <SpecsColumn
          isModal={isModal}
          key="spec-col-0"
          idx={0}
          info={getProfileInfo()}
        />
        {distributedSpecs?.map(
          (specGroup, idx) =>
            specGroup.length > 0 && (
              <SpecsColumn
                isModal={isModal}
                key={`spec-col-${idx + 1}`}
                idx={idx + 1}
                info={specGroup}
                fromTemplate
                hasMoreColumns={idx < distributedSpecs.length - 1}
              />
            )
        )}
      </div>
    </SkeletonWrapper>
  );
}

export default ProfileSpecs;
