import { useState } from 'react';

interface UseTruncatedTextOutput {
  toggle: () => void;
  truncatedContent: string;
  showFullText: boolean;
  maxChars: number;
}

function useTruncatedText(
  text: string,
  defaultValue: boolean = false,
  maxChars: number = 300
): UseTruncatedTextOutput {
  const [showFullText, setShowFullText] = useState(defaultValue);
  const truncatedContent = showFullText ? text : text?.slice(0, maxChars);

  const toggle = () => {
    setShowFullText(!showFullText);
  };

  return { truncatedContent, toggle, showFullText, maxChars };
}

export default useTruncatedText;
