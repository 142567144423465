import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  Event as EventModel,
  User,
  Profile,
  PageInfo,
  Vacant,
} from '@dinbog/models';
import { useUser } from '../../../../hooks';
import { useInfiniteOtherVacancies } from '../../../../hooks/api/vacancies';
import { Tab } from '../../../shared';
import { EventHeader } from '.';
import EventInfo from './tabs/info/EventInfo';
import VacancyTab from './tabs/vacancies/VacancyTab';

export default function EventProfile({
  item,
}: {
  item: EventModel & { user: Profile };
}) {
  const { currentUser: user } = useUser();
  const router = useRouter();
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = React.useState<'info' | 'vacancies'>(
    'info'
  );

  const [pagination, setPagination] = React.useState<Partial<PageInfo>>({
    page: 1,
    perPage: 10,
  });

  const {
    data: vacanciesData,
    isLoading: vacanciesLoading,
    fetchNextPage,
  } = useInfiniteOtherVacancies(
    {
      token: user?.token,
      filter: JSON.stringify({
        'event._id': item?._id as string,
        active: true,
      }),
      ...pagination,
    },
    { enabled: !!user?.token }
  );

  const normalizeVacanciesData = React.useMemo(() => {
    const pages = vacanciesData?.pages || [];
    const flattenedItems = pages.reduce(
      (acc, page) => [...acc, ...page.items],
      [] as Vacant[]
    );
    const totalCount = pages[0]?.count || 0;
    const pageInfo = pages[pages.length - 1]?.pageInfo;

    return {
      count: totalCount,
      items: flattenedItems,
      pageInfo,
    };
  }, [vacanciesData]);

  const isOwner =
    (user?.currentUser?.user as User)?._id === (item?.user?.user as User)?._id;

  const items = React.useMemo(
    () => ({
      info: {
        title: t('events.casting.vacancies.tabs.info'),
        itemKey: 'info',
        component: <EventInfo item={item} />,
      },
      vacancies: {
        title: t('events.casting.vacancies.tabs.vacancies'),
        number: normalizeVacanciesData?.count ?? 0,
        itemKey: 'vacancies',
        component: (
          <VacancyTab
            vacancies={normalizeVacanciesData}
            loading={vacanciesLoading}
            setPagination={setPagination}
            isOwner={isOwner}
            fetchNextPage={fetchNextPage}
          />
        ),
      },
    }),
    [item, vacanciesData, vacanciesLoading, pagination, isOwner]
  );

  return (
    <div className="mt-6 flex flex-col gap-6">
      <EventHeader item={item} />
      {isOwner ? (
        <Tab
          items={Object.values(items)}
          currentItem={tabSelected}
          setCurrentItem={setTabSelected}
          isQueryParam
        />
      ) : null}
      {router.isReady ? items[tabSelected]?.component : null}
    </div>
  );
}
