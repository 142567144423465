/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Event as EventModel, User } from '@dinbog/models';
import { useUser } from '../../../../../../hooks';
import { PersonItem } from '../../../../../shared';
import SummaryCard from './SummaryCard';

export default function EventInfo({
  item,
  application,
}: {
  item: any;
  application?: any;
}) {
  const { currentUser: user } = useUser();
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-6">
      {/* info */}
      <div className="flex flex-col-reverse sm:flex-row sm:justify-between h-fit gap-8">
        <div className="h-fit flex flex-col gap-6">
          {/* owner info */}
          <button
            className=""
            type="button"
            onClick={() =>
              router.push({
                pathname: `/[slug]`,
                query: {
                  slug: item?.user?.user?.slug,
                },
              })
            }
          >
            <PersonItem user={{ profile: item?.user }} isHorizontal size="md">
              <div className="">
                <PersonItem.Header user={{ profile: item?.user }} />
                <PersonItem.Body
                  withLocation
                  user={{
                    profile: item?.user,
                    business: item?.user?.business,
                  }}
                />
              </div>
            </PersonItem>
          </button>
          {/* description */}
          <div className="flex flex-col gap-2 w-full">
            <span className="font-semibold text-lg">
              {t('events.casting.about')}
            </span>
            <span className="font-medium">{item?.description}</span>
          </div>
        </div>
        {/* startDate, endDate, talent category and location */}
        <SummaryCard item={item} />
      </div>
      {/* preferences */}
      {/* <Preferences /> */}

      {/* full address. only visible if current user owns the event or is selected */}
      {(user?.currentUser?.user as User)?._id === item?.user?.user?._id ||
      (application && application?.status === 'selected') ? (
        <div className="flex flex-col gap-2 w-full">
          <span className="font-semibold text-lg">
            {t('events.fullAddress')}
          </span>
          <span className="font-medium">{item?.address?.address1}</span>
        </div>
      ) : null}
    </div>
  );
}
