export const ACTIONS = {
  USER_TYPE: 'userType',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  EMAIL: 'email',
  PAGE_URL: 'websiteUrl',
  COUNTRY: 'country',
  STATE: 'hqState',
  CITY: 'city',
  BRANCH_OFFICE: 'branchOffice',
  DESCRIPTION: 'description',
  CATEGORY: 'category',
  DEFAULT: 'default',
} as const;

export type ACTIONS_TYPE =
  | { type: 'userType'; payload: string }
  | { type: 'name'; payload: string }
  | { type: 'phone'; payload: string }
  | { type: 'gender'; payload: string }
  | { type: 'email'; payload: string }
  | { type: 'websiteUrl'; payload: string }
  | { type: 'country'; payload: string }
  | { type: 'hqState'; payload: string }
  | { type: 'city'; payload: string }
  | { type: 'branchOffice'; payload: string }
  | { type: 'description'; payload: string }
  | { type: 'category'; payload: string }
  | { type: 'default'; payload: any }; // ADD TYPE

export function reducer(
  state, // FIXME ADD TYPE
  action: ACTIONS_TYPE
) {
  //  FIXME ADD TYPE to return
  switch (action.type) {
    case ACTIONS.USER_TYPE:
      return { ...state, userType: action.payload };
    case ACTIONS.NAME:
      return { ...state, name: action.payload };
    case ACTIONS.PHONE:
      return { ...state, phone: action.payload };
    case ACTIONS.GENDER:
      return { ...state, gender: action.payload };
    case ACTIONS.EMAIL:
      return { ...state, email: action.payload };
    case ACTIONS.PAGE_URL:
      return { ...state, websiteUrl: action.payload };
    case ACTIONS.COUNTRY:
      return { ...state, country: action.payload };
    case ACTIONS.STATE:
      return { ...state, hqState: action.payload };
    case ACTIONS.CITY:
      return { ...state, city: action.payload };
    case ACTIONS.BRANCH_OFFICE:
      return { ...state, branchOffice: action.payload };
    case ACTIONS.DESCRIPTION:
      return { ...state, description: action.payload };
    case ACTIONS.CATEGORY:
      return { ...state, category: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
