import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from '@dinbog/models';
import { Button, Select } from '@dinbog/ui';
import { useNotify, useUser } from '../../../hooks';
import { useAddBusiness, useChangeAccount } from '../../../hooks/api/auth';
import { SignUpFields } from '../../../models/auxModels';
import SignUpBusinessContent from '../signUpForm/signUpBusinessContent/SignUpBusinessContent';

export default function LoggedBusinessSignUp() {
  const { t } = useTranslation();
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const router = useRouter();
  const notify = useNotify();
  const session = useSession();
  const [newUserToken, setNewUserToken] = React.useState<string>('');
  const [newBusinessId, setNewBusinessId] = React.useState<string>('');
  const addBusinessMutation = useAddBusiness();

  const { data, isLoading, refetch, isRefetching } = useChangeAccount(
    {
      user: newBusinessId,
      token: newUserToken,
      type: 'business',
    },
    {
      enabled: false,
    }
  );

  const methods = useForm<SignUpFields>();

  const formValidations = {
    companyName: { required: t('required') },
    companyEmail: { required: t('required') },
  };

  const onSubmit = async (formData: SignUpFields) => {
    try {
      // mutation
      const response = await addBusinessMutation.mutateAsync({
        companyEmail: formData.companyEmail,
        companyName: formData.companyName,
        token: user?.token,
      });
      if (response) {
        notify(t('auth.signUp.notifs.success'), 'success');
        setNewUserToken(response?.token);
        setNewBusinessId((response?.business?.user as User)?._id);
      } else {
        return notify(t('auth.signUp.notifs.failure'), 'error');
      }
    } catch (err) {
      return notify(err.response.data.message, 'error');
    }
  };

  // refetch change account query
  React.useEffect(() => {
    if (newBusinessId !== '' && newUserToken !== '') {
      refetch();
    }
  }, [newBusinessId, newUserToken]);

  const updateSession = async () => {
    await session.update({
      ...session.data,
      user: {
        ...session.data.user,
        token: data?.token,
      },
    });
  };

  // set new user info when the query is done fetching
  React.useEffect(() => {
    if (!isRefetching && !isLoading) {
      setUser({
        _id: data?.currentUser?._id ?? user?._id,
        profile: data?.currentUser?.profile ?? user?.profile,
        accounts: data?.currentUser?.accounts ?? user?.accounts,
        currentUser: data?.currentUser?.currentUser ?? user?.currentUser,
        token: data?.token ?? user?.token,
      });
      updateSession();
      router.push('/sign-up/wizard');
    }
  }, [data, isRefetching, isLoading]);

  return (
    <div className="w-full h-full flex lg:flex-row flex-col-reverse xl:justify-center items-center justify-between">
      <div className="xl:w-full lg:w-fit w-full flex flex-col h-full lg:pt-36 lg:pb-16 pt-0 items-center px-14">
        <div className="bg-white shadow p-12 flex flex-col w-full max-w-[616px] items-center gap-10 rounded-2xl">
          <div className="space-y-2">
            <h2 className="font-semibold text-3xl text-center">
              {t('auth.signUp.addBusiness.title')}
            </h2>
            <p className="text-center">{t('auth.signUp.addBusiness.text')}</p>
          </div>
          <div className="w-full">
            <FormProvider {...methods}>
              <form
                className="w-full flex flex-col space-y-4"
                onSubmit={methods?.handleSubmit(onSubmit)}
                method="post"
              >
                <SignUpBusinessContent alreadyLogged />
                <div className="flex flex-col">
                  <label className="text-sm space-x-2 py-4">
                    <input
                      type="checkbox"
                      {...methods?.register('termsCheckbox', {
                        required: true,
                      })}
                      className={`rounded ${
                        methods?.formState?.errors.termsCheckbox &&
                        methods?.formState?.errors.termsCheckbox.type ===
                          'required'
                          ? 'border-danger-300'
                          : ''
                      }`}
                    />
                    <span className="">
                      {t('auth.signUp.terms.text')}{' '}
                      <button
                        className="text-primary-500 hover:text-primary-500/80"
                        type="button"
                      >
                        {t('auth.signUp.terms.link')}
                      </button>
                    </span>
                  </label>
                  {methods?.formState?.errors.termsCheckbox &&
                  methods?.formState?.errors.termsCheckbox.type ===
                    'required' ? (
                    <span className="text-sm text-danger-300">
                      {t('auth.signUp.notifs.terms')}
                    </span>
                  ) : null}
                </div>

                <Button
                  type="submit"
                  tooltip={t('auth.signUp.submit')}
                  className="text-white bg-primary-500"
                >
                  {t('auth.signUp.submit')}
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
