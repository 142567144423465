import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { PageInfo, Pagination, Post as PostModel } from '@dinbog/models';
import { InfiniteScroll, SpinnerIcon } from '@dinbog/ui';
import { PostWithProfile } from '../../../../../models/auxModels';
import CreatePostCard from '../../../../post/CreatePostCard';
import PostList from '../../../../post/PostList';
import { InfoWithImage } from '../../../../shared';

interface PostTabProps {
  postsData: Pagination<PostWithProfile>;
  loading: boolean;
  setPagination: React.Dispatch<React.SetStateAction<Partial<PageInfo>>>;
  onSuccessLike?: () => void;
}

export default function PostTab({
  postsData,
  loading,
  setPagination,
  onSuccessLike,
}: PostTabProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const [posts, setPosts] = React.useState(postsData?.items ?? []);

  return (
    <InfiniteScroll
      containerClassName="flex flex-col gap-6 w-full items-center overflow-y-auto scrollbar-hide"
      loading={loading}
      setLoadedData={setPosts}
      data={postsData}
      setPagination={setPagination}
      fixedHeight={false}
    >
      {router?.pathname === '/profile' || router?.pathname === '' ? (
        <CreatePostCard />
      ) : null}
      {!loading && postsData?.count === 0 ? (
        <InfoWithImage
          image="/images/fallback/no-posts.png"
          title={
            router?.pathname === '/profile'
              ? t('emptyStates.noPosts.self.title')
              : t('emptyStates.noPosts.other.title')
          }
          text={
            router?.pathname === '/profile'
              ? t('emptyStates.noPosts.self.text')
              : ''
          }
        />
      ) : (
        <PostList posts={posts} onSuccessLike={onSuccessLike} />
      )}
    </InfiniteScroll>
  );
}
