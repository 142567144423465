import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { User, Profile, Follow, FiltersInput } from '@dinbog/models';
import { axios } from '../../api';

export function useFollowUser() {
  const mutation = useMutation<
    Follow,
    AxiosError,
    { from: string; to: string }
  >(async (params) => {
    const { data } = await axios.post(`/api/users/follow/v1`, params);
    return data;
  });
  return mutation;
}

export function useUnfollowUser() {
  const mutation = useMutation<
    Follow,
    AxiosError,
    { from: string; to: string }
  >(async (params) => {
    const { data } = await axios.patch(`/api/users/unfollow/v1`, params);
    return data;
  });
  return mutation;
}

export function useGetFollowers(
  filters,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['followers']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['followers', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<any>(`/api/users/followers/v1`, {
          params: {
            ...filters,
          },
        });

        return data;
      }
    },
    options
  );
  return query;
}

export function useGetFollowing(
  filters,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['following']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['following', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<any>(`/api/users/following/v1`, {
          params: {
            ...filters,
          },
        });

        return data;
      }
    },
    options
  );
  return query;
}

export function useAcceptFollow() {
  const mutation = useMutation<
    Follow,
    AxiosError,
    { _id: string; accepted: boolean }
  >(async (params) => {
    const { data } = await axios.patch(`/api/users/accept-follow/v1`, params);
    return data;
  });
  return mutation;
}

/*
/users/following/v1
/users/followers/v1
*/
