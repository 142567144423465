/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useSearchContext } from '../../../context';
import InfiniteList from '../../shared/infinite-list/InfiniteList';
import PostResultItem from './PostResultItem';

export default function PostResults() {
  const { searchData, fetchNextPage } = useSearchContext();
  return (
    <InfiniteList
      items={searchData?.items}
      fetchNextPage={fetchNextPage}
      containerClassName="flex flex-wrap gap-4"
    >
      {(postItem) => <PostResultItem postItem={postItem} />}
    </InfiniteList>
  );
}
