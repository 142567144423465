import React from 'react';
import {
  Business,
  BusinessUserRole,
  Profile,
  StatusEnum,
  User,
} from '@dinbog/models';
import { Button } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateMembers } from '../../../hooks/api/businesses';
import { MembersFormContent } from '../../shared';

function MembersForm() {
  const { t } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);

  const queryClient = useQueryClient();
  const updateMembersMutation = useUpdateMembers();

  const [members, setMembers] = React.useState<
    (User & {
      profile?: Profile;
      business?: Business;
      role?: BusinessUserRole;
      isNew?: boolean;
      status?: StatusEnum;
    })[]
  >([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (disabled) return;
      setDisabled(true);

      const membersList = members
        ?.filter((member) => member?.isNew)
        .map((user_) => ({
          user: user_?._id,
          role: user_?.role,
          email: user_?.email,
          action: 'add',
        })); // solo miembros nuevos

      // conseguir los miembros que estaban y ya no
      membersList.push(
        ...(user?.currentUser?.business?.members
          ?.filter((m) => !members?.find((mm) => mm?._id === m?.user))
          .map((user_) => ({
            user: user_?._id,
            role: user_?.role,
            email: (user_ as User)?.email as string, // Ensure email is of type string
            action: 'remove',
          })) || [])
      );

      // tomar en cuenta otros casos

      const promises = [
        // add admins/editors
        updateMembersMutation.mutateAsync({
          token: user?.token,
          business: (user?.currentUser?.user as User)?._id as string,
          members: membersList,
        }),
      ];

      await Promise.all(promises).then(() => {
        queryClient.refetchQueries(['businessMembers', { user: user?._id }]);
        notify(t('settings.notifs.success'), 'success');
      });
    } catch (err) {
      notify(
        err?.response?.data?.message || t('settings.notifs.error'),
        'error'
      );
    } finally {
      setDisabled(false);
    }
  };

  return (
    <form className="flex flex-col gap-6" method="post" onSubmit={onSubmit}>
      <h3 className="font-semibold text-lg ">
        {t('auth.signUp.wizard.selectAdmin.title')}
      </h3>
      <hr className="w-full bg-neutral-200" />

      <p>{t('auth.signUp.wizard.selectAdmin.titleDescription')}</p>

      <MembersFormContent members={members} setMembers={setMembers} />
      <div className="w-full flex gap-5 justify-end mt-5">
        <Button
          tooltip={t('save')}
          type="submit"
          className="bg-primary-500 text-white font-normal"
        >
          {t('save')}
        </Button>
      </div>
    </form>
  );
}

export default MembersForm;
