import React from 'react';
import CircledIcon from './CircledIcon';

interface InfoWithIcon {
  title: string;
  text: string;
  complimentaryText?: string;
  icon;
  size?: 'sm' | 'lg';
  type?: 'info' | 'danger';
}

export default function InfoWithIcon({
  title,
  text,
  complimentaryText,
  icon,
  size = 'sm',
  type = 'info',
}: InfoWithIcon) {
  return (
    <div className="flex flex-col items-center gap-y-9">
      <CircledIcon icon={icon} type={type} />
      <div className="space-y-4">
        <h2
          className={`text-center font-semibold ${
            size === 'lg'
              ? 'md:text-3xl sm:text-2xl text-xl'
              : 'md:text-xl sm:text-lg text-base'
          }`}
        >
          {title}
        </h2>
        <p
          className={`text-center ${
            size === 'lg' ? 'md:text-xl text-base' : 'md:text-base text-xs'
          }`}
        >
          {text}
        </p>
        {complimentaryText ? (
          <p className="md:text-lg text-sm text-center">{complimentaryText}</p>
        ) : null}
      </div>
    </div>
  );
}
