import React from 'react';
import { Alert, InfiniteScroll } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@dinbog/models';
import { useGetBlackListProps } from '../../../hooks/api/block';
import UserItem from '../../shared/UserItem';
import { BlockModal } from '../../shared';

function BlockedProfilesList({
  data,
  isLoading,
  setPagination,
  refetch,
}: {
  data: Pagination<useGetBlackListProps>;
  isLoading: boolean;
  setPagination: React.Dispatch<
    React.SetStateAction<{
      page: number;
      perPage: number;
    }>
  >;
  refetch: () => void;
}) {
  const [blockedProfiles, setBlockedProfiles] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!isOpen) setSelectedUser(null);
  }, [isOpen]);

  return (
    <div className="flex flex-col gap-6">
      <BlockModal
        isOpen={isOpen}
        setOpen={setIsOpen}
        unblock
        user={selectedUser}
        onSuccess={() => {
          refetch();
        }}
      />

      <h3 className="font-semibold text-lg">{t('settings.blockedProfiles')}</h3>
      <hr className="w-full bg-neutral-200" />

      <InfiniteScroll
        data={data}
        setPagination={setPagination}
        loading={isLoading}
        fixedHeight={false}
        setLoadedData={setBlockedProfiles}
      >
        {blockedProfiles.length === 0 && (
          <Alert
            title={t('settings.noBlockedProfilesFound')}
            text={t('settings.noBlockedProfilesFoundDescription')}
            show
          />
        )}
        {blockedProfiles.map((profile) => (
          <UserItem
            key={profile?._id}
            user={profile}
            icon={
              <div className="py-1 px-2 border border-primary-500 rounded text-primary-500 text-xs">
                {t('unblock.word')}
              </div>
            }
            className="md:max-w-[500px] justify-between "
            onClick={() => {
              setIsOpen(true);
              setSelectedUser({
                _id: profile?.profile?.user,
                profile: profile?.profile,
              });
            }}
            size="large"
            includeFlag
          />
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default BlockedProfilesList;
