/* eslint-disable no-nested-ternary */
import React from 'react';
import { Modal } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { PageInfo, User } from '@dinbog/models';
import {
  useGetFollowers,
  useGetFollowing,
} from '../../../../hooks/api/follows';
import { useUser } from '../../../../hooks';
import { useGetConnections } from '../../../../hooks/api/connections';
import SearchInput from '../../../shared/SearchInput';
import { UsersList } from '../../../shared';

interface RelationsModalProps {
  userId: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  relation: 'followers' | 'following' | 'connections';
}

export default function RelationsModal({
  userId,
  isOpen = false,
  setOpen,
  relation = 'following',
}: RelationsModalProps) {
  const { currentUser: user } = useUser();
  const { t } = useTranslation();
  const [users, setUsers] = React.useState([]);
  const [pagination, setPagination] = React.useState<Partial<PageInfo>>({
    page: 1,
    perPage: 10,
  });

  // queries to get list of followers/following/connections
  const {
    data: dataFollowers,
    isLoading: isLoadingFollowers,
    refetch: refetchFollowers,
    error: errorFollowers,
  } = useGetFollowers(
    {
      user: userId,
      ...pagination,
      viewerUser: (user?.currentUser?.user as User)?._id as string,
    },
    { enabled: relation === 'followers' }
  );
  const {
    data: dataFollowing,
    isLoading: isLoadingFollowing,
    refetch: refetchFollowing,
    error: errorFollowing,
  } = useGetFollowing(
    {
      user: userId,
      ...pagination,
      viewerUser: (user?.currentUser?.user as User)?._id as string,
    },
    { enabled: relation === 'following' }
  );
  const {
    data: dataConnections,
    isLoading: isLoadingConnections,
    refetch: refetchConnections,
    error: errorConnections,
  } = useGetConnections(
    {
      from: userId,
      status: 'approved',
      ...pagination,
      active: true,
      viewerUser: (user?.currentUser?.user as User)?._id as string,
    },
    { enabled: relation === 'connections' }
  ); // REVISAR ESTE

  /*
  from: z.string(),
  page: z.number().optional(),
  perPage: z.number().optional(),
  status: z.union([
    z.literal('on_hold'),
    z.literal('approved'),
    z.literal('rejected'),
    z.literal('block'),
  ]),
  active: z.string().optional(),
  sort: z.string().optional(),
  sortOrder: z.string().optional(),
  searchString: z.string().optional(),
  */

  const getDataInfo = () => {
    switch (relation) {
      case 'connections':
        return { loading: isLoadingConnections, data: dataConnections };
      case 'following':
        return { loading: isLoadingFollowing, data: dataFollowing };
      case 'followers':
        return { loading: isLoadingFollowers, data: dataFollowers };

      default:
        return { loading: false, data: null };
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      className="bg-background-50 p-6 space-y-6 max-w-[464px] sm:h-2/3"
    >
      <Modal.Header exitBtn className="p-0">
        <h2 className="text-xl font-semibold">
          {t(`profile.relations.${relation}`)}
        </h2>
      </Modal.Header>
      <Modal.Body className="flex flex-col gap-6 overflow-y-auto">
        {/* <SearchInput
          value=""
          onChange={() => null}
          hasSearchIcon
          placeholder={t('search')}
        /> */}
        <UsersList
          users={users}
          relation={relation}
          loading={getDataInfo().loading}
          setPagination={setPagination}
          setUsers={setUsers}
          paginationData={getDataInfo().data}
          setOpen={setOpen}
          refectConnections={refetchConnections}
          refetchFollowing={refetchFollowing}
        />
      </Modal.Body>
    </Modal>
  );
}
