import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Specification, SpecificationTemplate } from '@dinbog/models';
import { axios } from '../../api';

export function useGetTalentSpecs(
  filters,
  options: Omit<
    UseQueryOptions<
      Array<Specification>,
      AxiosError,
      Array<Specification>,
      ['talentSpecs']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Array<Specification>, AxiosError>(
    ['talentSpecs', filters],
    async () => {
      const { data } = await axios.get<Array<Specification>>(
        '/api/users/profile/specs/v1',
        {
          params: { ...filters },
        }
      );

      return data;
    },
    options
  );
  return query;
}

export function useSpecs(
  filters?: Partial<SpecificationTemplate>,
  options: Omit<
    UseQueryOptions<
      SpecificationTemplate[],
      AxiosError,
      SpecificationTemplate[],
      ['specifications']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<SpecificationTemplate[], AxiosError>(
    ['specs'],
    async () => {
      const { data } = await axios.get<SpecificationTemplate[]>(
        '/api/users/specification-templates/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}
