import React from 'react';
import { useTranslation } from 'react-i18next';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { Button, Select, TextArea } from '@dinbog/ui';
import { useForm } from 'react-hook-form';
import InfoWithIcon from '../../InfoWithIcon';

interface ReportFormProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (data) => void;
  disabled: boolean;
  item: string;
}

export default function ReportForm({
  setOpen,
  onSubmit,
  disabled = false,
  item = 'post',
}: ReportFormProps) {
  const { t } = useTranslation();
  const methods = useForm<any>();

  const formValidations = {
    type: { required: t('required') },
    details: { required: t('required') },
  };

  return (
    <form
      method="post"
      onSubmit={methods?.handleSubmit(onSubmit)}
      className="flex flex-col gap-8 items-center"
    >
      <p className="text-xl font-semibold w-full">
        {t('report.title')} {item}
      </p>
      <Select
        isRequired
        error={methods?.formState?.errors?.type?.message as string}
        className="w-full"
        label={t('report.inputs.type') ?? ''}
        {...methods?.register('type', formValidations?.type)}
      >
        <option key={-1} value="">
          {t('select')}
        </option>
        {(t('report.types', { returnObjects: true }) as Array<string>)?.map(
          (type, i) => (
            <option key={type} value={i}>
              {type}
            </option>
          )
        )}
      </Select>
      <TextArea
        isRequired
        placeholder={t('report.inputs.detailsPlaceholder')}
        label={t('report.inputs.details')}
        className=""
        {...methods?.register('details', formValidations?.details)}
        error={methods?.formState?.errors?.details?.message as string}
      />
      <div className="flex flex-row w-full justify-evenly gap-4">
        <Button
          tooltip={t('cancel')}
          disabled={disabled}
          className="text-primary-500 w-full active:opacity-70"
          onClick={() => setOpen(false)}
        >
          {t('cancel')}
        </Button>
        <Button
          tooltip={t('report.submitBtn')}
          disabled={disabled}
          className="btn-primary active:opacity-70 w-full"
          type="submit"
        >
          {t('report.submitBtn')}
        </Button>
      </div>
    </form>
  );
}
