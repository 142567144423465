import React from 'react';
import { Button, InfiniteScroll } from '@dinbog/ui';
import { useRouter } from 'next/router';
import {
  PageInfo,
  Pagination,
  Event as EventModel,
  Vacant,
} from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Tab } from '../shared';

export default function Events({
  tab = '',
  setTab,
  items,
}: {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  items;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <div className="">
      <div className="flex gap-8">
        <Tab
          items={Object.values(items)}
          currentItem={tab}
          setCurrentItem={setTab}
          isQueryParam
        />
        {/* create casting button */}
        <Button
          tooltip={t('events.casting.create')}
          className="btn-primary md:block hidden"
          onClick={() => router.push('/briefcase/castings/create')}
        >
          {t('events.casting.create')}
        </Button>
        <Button
          tooltip={t('events.casting.create')}
          className="btn-primary md:hidden block w-fit h-fit"
          onClick={() => router.push('/briefcase/castings/create')}
        >
          <FontAwesomeIcon icon={faPlus} className="text-text-white h-4 w-4" />
        </Button>
      </div>
      <div className="sm:px-16 sm:py-12 py-6 px-0 w-full">
        {router.isReady ? items[tab]?.component : null}
      </div>
    </div>
  );
}
