import React from 'react';
import dayjs from 'dayjs';
import { NextRouter, useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { Button } from '@dinbog/ui';
import {
  Application,
  Event as EventModel,
  Profile,
  User,
} from '@dinbog/models';
import { useNotify, useUser } from '../../../../hooks';
import {
  useCreateApplication,
  useUpdateApplication,
} from '../../../../hooks/api/applications';
import DeleteCastingModal from '../../../shared/modal/deleteModal/DeleteCastingModal';
import { useUpdateEvent } from '../../../../hooks/api/events';
import StatusTag from '../StatusTag';
import { getEventStatus } from '../../../../utils/getEventStatus';
import { useSharePost } from '../../../../hooks/api/posts';

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.extend(localizedFormat);
dayjs().format('L LT');
interface Account {
  _id: string;
  type: 'user' | 'business';
  email: string;
  profile?: Profile;
}
interface CurrentUser {
  _id: string;
  profile?: Profile;
  currentUser?: Profile;
  accounts?: Account[];
  token: string;
}

export default function EventHeader({
  item,
  vacancyId,
  application,
}: {
  item: EventModel & { user?: Profile };
  vacancyId?: string;
  application?: Application;
}) {
  const { t } = useTranslation();
  const { currentUser: user } = useUser();
  const [disabled, setDisabled] = React.useState(false);
  const router = useRouter();
  const notify = useNotify();
  const { slug: eventSlug, vacancy: vacancySlug } = router.query;
  const applyMutation = useCreateApplication();
  const updateApplicationMutation = useUpdateApplication();
  const updateEventMutation = useUpdateEvent();
  const sharePost = useSharePost();
  const queryClient = useQueryClient();
  const [openDeleteCasting, setOpenDeleteCasting] =
    React.useState<boolean>(false);

  const onApply = async () => {
    try {
      const data = await applyMutation.mutateAsync({
        user: (user?.currentUser?.user as User)?._id,
        vacant: vacancyId,
      });
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
  };

  const onDeleteCasting = async () => {
    try {
      await updateEventMutation.mutateAsync(
        {
          _id: (item?._id as string) ?? undefined,
          active: false,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries([`events`]);
            queryClient.refetchQueries([`vacancies`]);
            router.push('/briefcase/castings?tab=myCastings');
          },
        }
      );
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
  };

  const onWithdraw = async () => {
    try {
      const data = await updateApplicationMutation.mutateAsync({
        _id: application?._id as string,
        active: false,
      });
      if (data) {
        queryClient.refetchQueries(['vacant']);
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
  };

  const onShare = async () => {
    setDisabled(true);
    try {
      const shareUrl = await sharePost.mutateAsync({
        collection: 'vacant',
        slugCollection: vacancySlug,
        slugEvent: eventSlug,
      });
      navigator.clipboard.writeText(shareUrl);
      notify(t('copyToClipboard'), 'success');
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
    setDisabled(false);
  };

  function shouldShowApplyButton(
    userParam: CurrentUser,
    itemParam: EventModel & { user?: Profile },
    applicationParam: Application,
    routerParam: NextRouter
  ) {
    // 1. Current user and item user should be different
    if (
      (userParam?.currentUser?.user as User)?._id ===
      (itemParam?.user?.user as User)?._id
    )
      return false;

    // 2. Current user should be a person
    if (userParam?.currentUser?.type !== 'person') return false;

    // 3. Current user should have at least one category
    if (userParam?.currentUser?.categories?.length === 0) return false;

    // 4. Current date should be within the event's start and end dates
    if (
      !dayjs().isAfter(dayjs(itemParam?.startDate)) ||
      !dayjs().isBefore(dayjs(itemParam?.endDate))
    )
      return false;

    // 5. User should not have already applied
    if (applicationParam) return false;

    // 6. Casting profile query parameter should not be present
    if (routerParam.query.castingProfile) return false;

    return true;
  }

  const eventStatus = getEventStatus(
    item?.startDate as string,
    item?.endDate as string
  );

  return (
    <div className="flex flex-col gap-6">
      {/* cover */}
      <img
        className="rounded w-full h-[300px] object-cover"
        src={item?.cover?.url}
        alt={item?.cover?.fileName}
      />
      {/* title, date and actions */}
      <div className="flex justify-between flex-col md:flex-row">
        {/* title and date */}
        <div className="flex flex-col gap-2 mb-5 md:mb-0">
          <div className="flex flex-row justify-stretch gap-2 items-center">
            <span className="font-semibold text-lg text-left ">
              {item?.title}
            </span>
            {vacancySlug && eventStatus !== 'closed' ? (
              <Button
                type="button"
                tooltip={t('profile.otherActions.share')}
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  onShare();
                }}
                className="flex flex-start"
                aria-label="Share button"
              >
                <FontAwesomeIcon icon={faPaperPlane} className="h-4 w-4" />
              </Button>
            ) : null}
          </div>

          <span className="text-neutral-500">
            {t('events.casting.closesOn')}{' '}
            {dayjs(item?.endDate).utc().format('llll')}
          </span>
          <div className="w-1/4">
            <StatusTag status={eventStatus} />
          </div>
        </div>
        {/* actions */}
        <div className="flex gap-5 flex-col md:flex-row md:gap-3 ">
          {(user?.currentUser?.user as User)?._id ===
          (item?.user?.user as User)?._id ? (
            <Button
              tooltip={t('events.delete')}
              className="text-danger-300 border-danger-300 btn-secondary flex gap-3 px-12 items-center w-fit h-fit"
              onClick={() => {
                setOpenDeleteCasting(true);
              }}
            >
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="w-4 h-4 text-danger-300"
              />
              {t('events.delete')}
            </Button>
          ) : null}
          {shouldShowApplyButton(user, item, application, router) ? (
            <Button
              tooltip={t('events.apply')}
              className="btn-primary w-fit px-8 h-fit"
              onClick={() => onApply()}
            >
              {t('events.apply')}
            </Button>
          ) : null}

          {/* ver si cambiar el texto acá según el status de la application */}
          {application ? (
            <Button
              tooltip={t('events.casting.tabs.enrolled')}
              disabled={application?.status !== 'initial'}
              className={`${
                application?.status === 'initial'
                  ? 'btn-secondary'
                  : 'text-neutral-300 border border-neutral-300'
              } h-fit`}
              onClick={() => onWithdraw()}
            >
              {t('events.casting.tabs.enrolled')}
            </Button>
          ) : null}

          {/* edit casting if current user created it */}
          {(user?.currentUser?.user as User)?._id ===
            (item?.user?.user as User)?._id && eventStatus !== 'closed' ? (
            <Button
              className="btn-secondary flex gap-3 px-12 items-center w-fit h-fit"
              onClick={() =>
                router?.push({
                  pathname: `/briefcase/castings/[slug]/edit`,
                  query: { slug: router?.query?.slug },
                })
              }
              size="md"
              tooltip={t('events.casting.edit')}
            >
              <FontAwesomeIcon icon={faPencilAlt} className="w-4 h-4" />
              <span className="whitespace-nowrap">
                {t('events.casting.edit')}
              </span>
            </Button>
          ) : null}
        </div>
      </div>
      <DeleteCastingModal
        handleDelete={() => {
          onDeleteCasting();
        }}
        isOpen={openDeleteCasting}
        setOpen={setOpenDeleteCasting}
      />
    </div>
  );
}
