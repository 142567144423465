import {
  Specification,
  SpecificationTemplate,
  SpecificationsFormProps,
} from '@dinbog/models';

/**
 * This function receives a talent specifications and an object with the their new values.
 * @param specifications - original specifications to update
 * @param _data - updated specifications object { [specification._id]: valueUpdated }
 * @returns {Specification[]} - specifications array updated
 */
export function talentSpecificationsFormatToUpdate(
  specifications: Specification[],
  _data: SpecificationsFormProps
): Specification[] {
  const specsToUpdate = [];

  specifications.forEach((specification) => {
    const specificationTemplate =
      specification.specificationTemplate as SpecificationTemplate;

    const specType = specificationTemplate?.type;

    let value = String(_data[specificationTemplate?._id]);
    let multipleValues = [];

    switch (specType) {
      case 'number':
        value = value.replace(',', '.'); // replace comma with dot to save in db as a valid number in string format
        break;
      case 'multiselect':
        value = ''; // when multiselect, value is an array and it is saved in multipleValues
        multipleValues = _data[specificationTemplate._id] as string[];
        break;
      default:
        break;
    }

    specsToUpdate.push({
      _id: specification._id,
      tag: specification?.tag ?? '',
      multipleValues,
      value,
      specificationTemplate: specificationTemplate._id,
    });
  });
  return specsToUpdate;
}

/**
 * This function receives a talent specifications and returns an object with the their default values to fill the form.
 * @param specifications - original specifications to update
 * @returns {SpecificationsFormProps} - object with the default values to fill the form
 */
export function generateTalentSpecificationInputDefaultValues(
  specifications: Specification[]
): SpecificationsFormProps {
  const defaultValues: SpecificationsFormProps = {};

  // fill default values with specifications profile specs
  specifications?.forEach((element) => {
    const specificationTemplate =
      element.specificationTemplate as SpecificationTemplate;
    if (specificationTemplate.type === 'multiselect') {
      defaultValues[`${specificationTemplate._id}`] = element.multipleValues; // value is an array
    } else if (specificationTemplate.type === 'boolean') {
      defaultValues[`${specificationTemplate._id}`] = element.value === 'true'; // value is a boolean
    } else {
      defaultValues[`${specificationTemplate._id}`] = element.value; // value is a string
    }
  });
  return defaultValues;
}
