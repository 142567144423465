import React from 'react';
import {
  Address,
  Album,
  AlbumStats,
  Book,
  BookStats,
  Image,
  Post as PostModel,
  PostContentEnum,
  PostStats,
  Profile,
  TrackStats,
  Video,
  VideoStats,
} from '@dinbog/models';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';
import AudioContent from './audioContent/AudioContent';
import VideoContent from './VideoContent';
import PostContent from './PostContent';
import PostHeader from './PostHeader';
import Card from '../card/Card';
import CommentSection from '../../comment/CommentSection';
import BookContent from './BookContent';
import PostMentions from './PostMentions';
import PostLocation from './PostLocation';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

/**
 * Get the media content component based on the media type
 * @param mediaType: PostContentEnum
 * @returns: React.ReactNode
 */
function GetMediaContent(
  mediaType: PostContentEnum,
  item: Book | Video | Album | PostModel,
  owner?: any,
  onSuccessLike?: () => void
) {
  switch (mediaType) {
    case 'album':
      return (
        <AudioContent
          album={item as Album}
          ownerSlug={owner?.user?.slug}
          onSuccessLike={onSuccessLike}
        />
      );
    case 'video':
      return <VideoContent video={item as Video} />;
    case 'book':
      return <BookContent book={item as Book & { images?: Image[] }} />;
    case 'post':
      return <PostContent post={item as PostModel} owner={owner} />;
    default:
      return <PostContent post={item as PostModel} owner={owner} />;
  }
}

interface PostProps {
  content?: PostModel | Book | Video | Album;
  contentType?: PostContentEnum; // From feed, it always will be post. From profile, it can be any of the other three based on the selected tab
  owner?: any; // owner of the post
  onSuccessLike?: () => void; // refetch queries to update stats
}
function Post({
  content,
  contentType = 'post',
  owner,
  onSuccessLike,
}: PostProps) {
  const { i18n } = useTranslation();
  React.useEffect(() => {
    dayjs.locale(i18n?.language ?? 'en');
  }, [i18n?.language]);
  return (
    <div className="w-full flex justify-center">
      {/* post header */}
      {contentType === 'post' ? (
        <Card className="p-9 w-full max-w-[800px]">
          <PostHeader
            userProfile={owner}
            userType={owner?.type}
            postId={content?._id}
            contentType={contentType}
          />
          {/* post content */}
          {GetMediaContent(contentType, content, owner, onSuccessLike)}
          <div className="flex md:flex-row flex-col md:justify-between">
            {/* mentions */}
            {((content as PostModel)?.mentions as unknown as Profile[])
              ?.length > 0 ? (
              <PostMentions
                mentions={
                  (content as PostModel)?.mentions as unknown as Profile[]
                }
              />
            ) : null}
            {/* location */}
            <PostLocation
              location={((content as PostModel)?.address as Address) ?? null}
            />
          </div>
          {/* comments/likes/etc */}
          <CommentSection
            item={{
              _id: content?._id,
              owner: owner?.user?._id,
              type: 'post',
              slug: (content as PostModel)?.slug,
              ownerSlug: owner?.user?.slug,
            }}
            liked={(content as unknown as any)?.liked}
            stats={content?.stats as PostStats}
            onSuccessLike={onSuccessLike}
          />
          {/* post date */}
          <p className="mt-4 text-neutral-500">
            {dayjs(content?.createdAt).format('ll')}
          </p>
        </Card>
      ) : (
        // media title and date (IN THIS CASE, THE DOTS MENU IS IN MediaProfileLayout COMPONENT)
        <div className="space-y-4 w-full">
          <div className=" flex flex-col gap-1 pb-6 b border-b-[1px] border-neutral-200 ">
            <h3 className=" text-xl font-semibold">
              {(content as Album | Book | Video)?.title}
            </h3>
            <p className=" text-sm text-neutral-500">
              {dayjs(content?.createdAt).format('ll')}
            </p>
          </div>
          {/* post content */}
          {GetMediaContent(contentType, content, owner, onSuccessLike)}
          {/* comments/likes/etc */}
          <CommentSection
            item={{
              _id: content?._id,
              owner: owner?.user?._id,
              type: contentType,
              slug: (content as Album | Book | Video)?.slug,
              ownerSlug: owner?.user?.slug,
            }}
            liked={(content as unknown as any)?.liked}
            stats={
              content?.stats as AlbumStats | BookStats | VideoStats | TrackStats
            }
            onSuccessLike={onSuccessLike}
          />
        </div>
      )}
    </div>
  );
}

export default Post;
