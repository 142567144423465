import React from 'react';
import { Button, Input, Modal } from '@dinbog/ui';
import { User } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { validateEmail } from 'avilatek-utils';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useDisableUser } from '../../../hooks/api/auth';
import { useNotify, useUser } from '../../../hooks';
import { InfoWithIcon } from '../../shared';

interface DisableAccountProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DisableAccountModal({
  isOpen = false,
  setOpen,
}: DisableAccountProps) {
  const { t } = useTranslation();
  const notify = useNotify();
  const { currentUser, setCurrentUser: setUser } = useUser();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const disableUser = useDisableUser();
  const router = useRouter();

  const methods = useForm<any>({
    defaultValues: {
      email: '',
    },
  });

  const formValidations = {
    email: {
      required: t('required'),
      validate: (value) =>
        validateEmail(value) &&
        (currentUser?.currentUser?.user as User)?.email === value,
    },
  };

  const onSubmit = async () => {
    try {
      if (disabled) return;
      setDisabled(true);

      await disableUser.mutateAsync(
        {
          user: (currentUser?.currentUser?.user as User)?._id as string,
        },
        {
          onSuccess: () => {
            setUser(null);
            router.push('/sign-in');
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 p-8"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div className="flex flex-col gap-8 items-center">
        <InfoWithIcon
          title={t('settings.disableAccountModal.title')}
          text={t('settings.disableAccountModal.description')}
          icon={faExclamation}
          type="danger"
        />
        <div className="w-full ">
          <Input
            type="email"
            label={t('settings.disableAccountModal.email')}
            {...methods.register('email', formValidations.email)}
            error={methods.formState.errors.email?.message as string}
            placeholder={t('settings.disableAccountModal.email') ?? ''}
            autoComplete="off" // Disable browser autocomplete
            onPaste={(e) => e.preventDefault()} // Disable pasting
          />
        </div>
        <div className="flex flex-row w-full justify-evenly gap-4">
          <Button
            tooltip={t('settings.disableAccountModal.noBtn')}
            disabled={disabled}
            className={`${'text-danger-300'} w-full active:opacity-70`}
            onClick={() => setOpen(false)}
          >
            {t('settings.disableAccountModal.noBtn')}
          </Button>
          <Button
            tooltip={t('settings.disableAccountModal.yesBtn')}
            disabled={disabled || !methods.formState.isValid}
            className={`text-white bg-danger-300 ${
              !methods.formState.isValid ? 'opacity-30' : 'active:opacity-70'
            } w-full`}
            type="submit"
            onClick={onSubmit}
          >
            {t('settings.disableAccountModal.yesBtn')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
