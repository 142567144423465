export const ACTIONS = {
  BIRTH_DATE: 'birthDate',
  GENDER: 'gender',
  DESCRIPTION: 'description',
  BIRTH_COUNTRY: 'birthCountry',
  BIRTH_STATE: 'birthState',
  BIRTH_CITY: 'birthCity',
  CURRENT_COUNTRY: 'currentCountry',
  CURRENT_STATE: 'currentState',
  CURRENT_CITY: 'currentCity',
  PHONE: 'phone',
  DEFAULT: 'default',
} as const;

export type ACTIONS_TYPE =
  | { type: 'birthDate'; payload: string }
  | { type: 'gender'; payload: string }
  | { type: 'description'; payload: string }
  | { type: 'birthCountry'; payload: string }
  | { type: 'birthState'; payload: string }
  | { type: 'birthCity'; payload: string }
  | { type: 'currentCountry'; payload: string } // revisar
  | { type: 'currentState'; payload: string } // revisar
  | { type: 'currentCity'; payload: string } // revisar
  | { type: 'phone'; payload: string }
  | { type: 'default'; payload: Partial<any> }; // revisar

export function reducer(
  state, // : Partial<string> ojo con esto. dentro de Partial debe ir User o algo así. El modelo que guarda la info de la persona
  action: ACTIONS_TYPE
) {
  // : Partial<string> . después del paréntesis del reducer va esto
  switch (action.type) {
    case ACTIONS.BIRTH_DATE:
      return { ...state, birthDate: action.payload };
    case ACTIONS.GENDER:
      return { ...state, gender: action.payload };
    case ACTIONS.DESCRIPTION:
      return { ...state, description: action.payload };
    case ACTIONS.BIRTH_CITY:
      return { ...state, birthCity: action.payload };
    case ACTIONS.BIRTH_COUNTRY:
      return { ...state, birthCountry: action.payload };
    case ACTIONS.BIRTH_STATE:
      return { ...state, birthState: action.payload };
    case ACTIONS.CURRENT_COUNTRY:
      return { ...state, currentCountry: action.payload };
    case ACTIONS.CURRENT_STATE:
      return { ...state, currentState: action.payload };
    case ACTIONS.CURRENT_CITY:
      return { ...state, currentCity: action.payload };
    case ACTIONS.PHONE:
      return { ...state, phone: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...action.payload };

    default:
      return { ...state };
  }
}
