import React from 'react';
import {
  Business,
  BusinessUserRole,
  Profile,
  StatusEnum,
  User,
} from '@dinbog/models';
import { UserSelectSearch } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { useUserPagination } from '../../../../hooks/api/users';
import { useBusinessMembers } from '../../../../hooks/api/businesses';
import { InviteUserModal } from '../../modal';
import { useNotify, useUser } from '../../../../hooks';
import UsersList from './UsersList';

function SelectMembers({
  selectedUsers,
  setSelectedUsers,
  type,
}: {
  selectedUsers: (User & {
    profile?: Profile;
    business?: Business;
    role?: BusinessUserRole;
    isNew?: boolean;
    status?: StatusEnum;
  })[];
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<
      (User & {
        profile?: Profile;
        business?: Business;
        role?: BusinessUserRole;
        isNew?: boolean;
        status?: StatusEnum;
      })[]
    >
  >;
  type: 'admin' | 'editor';
}) {
  const { currentUser: user } = useUser();
  const { t } = useTranslation();
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);

  // select users data
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 30 });
  const [searchText, setSearchText] = React.useState('');
  // users data
  const { data, isLoading } = useUserPagination({
    page: pagination.page,
    perPage: pagination.perPage,
    search: searchText,
    // users members should not be companies
    filter: JSON.stringify({
      'profile.type': {
        $ne: 'business',
      },
      'profile.categories.0': { $exists: true },
    }),
  });

  // selectedMembers data
  const [membersPagination, setMembersPagination] = React.useState({
    page: 1,
    perPage: 10,
  });
  const { data: dataMembers, isLoading: isLoadingMembers } = useBusinessMembers(
    {
      user: ((user?.currentUser?.user as User)?._id as string) ?? '',
      page: membersPagination.page,
      perPage: membersPagination.perPage,
      role: type,
      status: ['pending', 'approved'],
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div className="flex flex-col lg:flex-row gap-5 ">
      <InviteUserModal isOpen={open} setOpen={setOpen} email={searchText} />
      <div className="w-full">
        <UserSelectSearch
          inviteText={t('inviteUserModal.invite')}
          handleInviteUser={() => {
            setOpen(true);
          }}
          setPagination={setPagination}
          setSearchText={setSearchText}
          searchText={searchText}
          usersData={data}
          usersLoading={isLoading}
          onSelectUser={(selectedUser_) => {
            const member = user?.currentUser?.business?.members?.find(
              (member_) => member_?.user === selectedUser_?._id
            );
            if (member && member?.status !== 'removed') {
              notify(
                t(
                  `auth.signUp.wizard.selectAdmin.${member?.role}AlreadyExists`
                ),
                'error'
              );
              return;
            }

            setSelectedUsers((prev: any) => [
              ...prev.filter((_user) => _user?._id !== selectedUser_?._id),
              { ...selectedUser_, isNew: true, role: type, status: null },
            ]);
          }}
          inputPlaceholder={t(
            'auth.signUp.wizard.selectAdmin.searchUsersPlaceholder'
          )}
          loadingText={t('auth.signUp.wizard.selectAdmin.searchUsersLoading')}
          noResultsText={t(
            'auth.signUp.wizard.selectAdmin.searchUsersNoResults'
          )}
        />
      </div>
      <UsersList
        usersData={dataMembers}
        type={type}
        usersLoading={isLoadingMembers}
        setPagination={setMembersPagination}
        users={selectedUsers?.filter((uu) => uu?.role === type) ?? []}
        setUsers={setSelectedUsers}
        title={t(`auth.signUp.wizard.selectAdmin.${type}ListTitle`)}
        emptyTitle={t(`auth.signUp.wizard.selectAdmin.${type}ListTitle`)}
        emptyText={t(
          `auth.signUp.wizard.selectAdmin.empty${
            type.charAt(0).toUpperCase() + type.slice(1)
          }s`
        )}
      />
    </div>
  );
}

export default SelectMembers;
