import React from 'react';
import { InfiniteScroll } from '@dinbog/ui';
import { User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { useNotify, useUser } from '../../hooks';
import { useAcceptFollow } from '../../hooks/api/follows';
import {
  useAcceptConnection,
  useDisconnect,
} from '../../hooks/api/connections';
import RequestCard from './RequestCard';
import InfiniteList from '../shared/infinite-list/InfiniteList';

export default function RequestsList({
  data,
  loading = false,
  fetchNextPage,
  refetchRequests,
  isDropDown = false,
}: {
  data;
  loading: boolean;
  fetchNextPage;
  refetchRequests;
  isDropDown?: boolean;
}) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const [requests, setRequests] = React.useState([]);

  const queryClient = useQueryClient();
  const acceptFollowMutation = useAcceptFollow();
  const connectMutation = useAcceptConnection();
  const disconnectMutation = useDisconnect();
  const manageRequest = (type: string, accepted: boolean, item: string) => {
    if (type === 'follow') {
      onAcceptOrRejectFollow(accepted, item);
    } else {
      onAcceptOrRejectConnection(accepted, item);
    }
  };

  const onAcceptOrRejectFollow = async (accepted: boolean, item: string) => {
    try {
      await acceptFollowMutation.mutateAsync({
        _id: item,
        accepted,
      });
      await refetchRequests();
      // refetch query de requests
      await queryClient.refetchQueries(['userRequests']);
      await queryClient.refetchQueries(['userRequestsCount']);
    } catch (error) {
      notify(error.response.data.message, 'error');
    }
  };

  const onAcceptOrRejectConnection = async (
    accepted: boolean,
    item: string
  ) => {
    try {
      if (accepted) {
        await connectMutation.mutateAsync({
          _id: item,
          user: (user?.currentUser?.user as User)?._id as string,
        });
        await refetchRequests();
      } else {
        await disconnectMutation.mutateAsync({
          from: item,
          to: (user?.currentUser?.user as User)?._id as string,
        });
        await refetchRequests();
      }
      // refetch query de requests
      await queryClient.refetchQueries(['userRequests']);
      await queryClient.refetchQueries(['userRequestsCount']);
    } catch (error) {
      notify(error.response.data.message, 'error');
    }
  };

  return (
    <div className="max-h-[500px] overflow-y-auto">
      <InfiniteList items={data.items} fetchNextPage={fetchNextPage}>
        {(request) => (
          <RequestCard
            key={`${request._id}-${request.createdAt}`}
            request={request}
            onAcceptOrRejectRequest={manageRequest}
            refetchRequests={refetchRequests}
            isDropDown={isDropDown}
          />
        )}
      </InfiniteList>
    </div>
  );
}
