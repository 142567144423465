import React from 'react';

interface PersonItemProps {
  user;
  hasBackground?: boolean;
  isHorizontal?: boolean;
  children?: React.ReactNode;
  type?: string;
  size?: 'sm' | 'md' | 'lg';
  onClick?: () => void;
  cardPadding?: string;
  isModal?: boolean;
}

function PersonItem({
  user,
  hasBackground = false,
  isHorizontal = false,
  children,
  type,
  size = 'md',
  onClick,
  cardPadding = 'p-4',
  isModal = false,
}: PersonItemProps) {
  return (
    <div
      className={`w-full h-full flex ${
        hasBackground ? 'bg-background-50 rounded-md shadow' : ''
      } ${
        isHorizontal
          ? 'flex-row gap-4'
          : 'flex-col justify-center items-center space-y-2'
      } ${cardPadding}`}
    >
      <button
        type="button"
        onClick={onClick}
        className={size === 'sm' ? 'w-9 h-9 shrink-0' : 'w-20 h-20 shrink-0'}
      >
        <img
          src={
            user?.profile?.photo?.url ??
            (user?.profile?.type === 'business'
              ? '/images/fallback/business.jpg'
              : '/images/fallback/user.png')
          }
          alt="person"
          className={`w-full h-full object-cover ${
            user?.profile?.type === 'business' ? 'rounded' : 'rounded-full'
          }`}
        />
      </button>
      <div
        className={`flex ${isModal ? 'w-full' : ''} ${
          size === 'sm'
            ? 'min-h-9 justify-between flex-grow'
            : 'min-h-[82.4px] flex-col w-[148px]'
        } h-auto gap-3 justify-between overflow-hidden`}
      >
        {children}
      </div>
    </div>
  );
}

export default PersonItem;
