import React from 'react';
import { useDropzone, FileRejection, DropEvent, Accept } from 'react-dropzone';
import { UploadIcon } from '@dinbog/ui';

// https://blog.logrocket.com/drag-and-drop-in-react/

interface DropZoneProps {
  dropZoneClassName?: string;
  icon?: boolean;
  dropZoneText?: string;
  accept?: Accept;
  customIcon?: React.ReactNode;
  dropZoneTextClassName?: string;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections?: FileRejection[],
    event?: DropEvent
  ) => void;
  horizontal?: boolean;
}

export default function DropZone({
  onDrop,
  accept,
  dropZoneClassName = 'border-2 bg-neutral-200 border-neutral-400 border-dashed',
  icon = true,
  dropZoneText = 'Arrastre las imágenes o haga click acá para buscarlas',
  customIcon = null,
  dropZoneTextClassName = '',
  horizontal = false,
}: DropZoneProps) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept,
    });
  return (
    <div
      {...getRootProps()}
      className={` rounded flex cursor-pointer ${
        dropZoneClassName.includes('w-') ? '' : 'w-full'
      } ${
        dropZoneClassName.includes('h-') ? '' : 'h-28'
      } ${dropZoneClassName} `}
      role="button"
    >
      <input className="dropzone-input" {...getInputProps()} />
      <div
        className={
          horizontal
            ? 'flex gap-2 justify-center items-center'
            : 'text-center my-auto text-cool-gray-600 w-full'
        }
      >
        {icon && !customIcon ? (
          <UploadIcon className="w-5 h-5 text-center m-auto mb-2" />
        ) : null}
        {customIcon != null ? customIcon : null}
        <p
          className={`text-center text-2base text-neutral-400 ${dropZoneTextClassName}`}
        >
          {dropZoneText}
        </p>
      </div>
    </div>
  );
}
