import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface EditableMentionsListProps {
  mentions;
  handleDeleteMention: (idx: string) => void;
}

export default function EditableMentionsList({
  mentions,
  handleDeleteMention,
}: EditableMentionsListProps) {
  return (
    <div className=" flex flex-wrap gap-2 mt-4">
      {mentions?.map((mention, idx) => (
        <div
          key={idx}
          className="p-2 text-sm bg-background-300 flex gap-2 rounded items-center"
        >
          <img
            className="rounded-full h-5 w-5 object-cover"
            src={
              mention?.profile?.photo?.url ??
              (mention?.profile?.type === 'business'
                ? '/images/fallback/business.jpg'
                : '/images/fallback/user.png')
            }
            alt={mention?.profile?.photo?.fileName}
          />
          <span className="font-semibold">
            {mention?.profile?.firstName} {mention?.profile?.lastName}
          </span>
          <button
            className=""
            type="button"
            onClick={() => handleDeleteMention(idx)}
            aria-label="Delete"
          >
            <FontAwesomeIcon
              icon={faX}
              className="w-[10px] h-[10px] text-text-black hover:text-danger-300"
            />
          </button>
        </div>
      ))}
    </div>
  );
}
