import React from 'react';
import Link from 'next/link';
import { Badge } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../../../../../../shared';
import { useScreenSize } from '../../../../../../../hooks';

import RequestDropdownHeader from './RequestDropdownHeader';
import RequestsList from '../../../../../../requests/RequestsList';

import { useRequests } from '../../../../../../../context/RequestContext';

function RequestDropdown() {
  const { t } = useTranslation();

  const { screenSize } = useScreenSize();

  const [isOpen, setIsOpen] = React.useState(false);

  const {
    requestsData,
    loading: isLoading,
    fetchRequestsNextPage: fetchNextPageRequests,
    refetchRequests: refetchInfiniteRequest,
  } = useRequests();

  return (
    <div>
      {/* in small screen size its only a link */}
      {screenSize >= 768 ? (
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className="h-full w-fit flex items-center"
        >
          <Dropdown.Button>
            <Badge
              count={requestsData.count}
              maxCount={99}
              badgeClassName="bg-primary-500"
            >
              <FontAwesomeIcon
                icon={faUserFriends}
                className={`w-6 h-6 ${
                  isOpen ? 'text-primary-500' : 'text-text-gray'
                }`}
              />
            </Badge>
          </Dropdown.Button>
          <Dropdown.ItemsContainer className="rounded-sm mt-8 px-6 py-6 space-y-6">
            <RequestDropdownHeader />
            <div className="space-y-4">
              {requestsData?.count > 0 ? (
                <RequestsList
                  data={requestsData}
                  loading={isLoading}
                  fetchNextPage={fetchNextPageRequests}
                  refetchRequests={refetchInfiniteRequest}
                  isDropDown
                />
              ) : (
                <div className="w-[300px]">
                  {t('emptyStates.noRequests.title')}
                </div>
              )}
              <Link
                href="/requests"
                className="flex w-full text-left border-none text-primary-500 font-semibold py-3 px-4"
              >
                {t('all')}{' '}
                {t(
                  'navbar.logged.notificationsDropdown.notifications'
                ).toLowerCase()}
              </Link>
            </div>
          </Dropdown.ItemsContainer>
        </Dropdown>
      ) : (
        <Link href="/requests">
          <FontAwesomeIcon
            icon={faUserFriends}
            className="text-text-gray w-6 h-6"
          />
        </Link>
      )}
    </div>
  );
}

export default RequestDropdown;
