import React from 'react';
import { Specification } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { SpecsColumn } from '../../../../../../shared';

export default function Preferences({ specs }: { specs }) {
  const { t } = useTranslation();
  const [distributedSpecs, setDistributedSpecs] = React.useState<
    Specification[][]
  >([]);

  const distributeSpecs = () => [
    specs.slice(0, Math.floor(specs.length / 2) + 1),
    specs.slice(Math.floor(specs.length / 2) + 1, specs.length),
  ];

  React.useEffect(() => {
    if (specs) {
      setDistributedSpecs(distributeSpecs());
    }
  }, [specs]);

  return (
    <div className="flex flex-col gap-2">
      <span className="font-semibold text-lg">
        {t('events.casting.vacancies.preferences')}
      </span>
      <div className="w-full flex flex-wrap">
        {distributedSpecs?.map((specGroup, idx) => (
          <SpecsColumn
            key={`spec-col-${idx + 1}`}
            idx={idx + 1}
            info={specGroup}
            fromTemplate
          />
        ))}
      </div>
    </div>
  );
}
