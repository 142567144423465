import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { BellIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../../../../../../shared';
import { useScreenSize } from '../../../../../../../hooks';
import NotificationDropdownItem from './NotificationDropdownItem';

function NotificationsDropdown() {
  const { t } = useTranslation();

  const { screenSize } = useScreenSize();

  const [isOpen, setIsOpen] = React.useState(false);

  const notifications = [
    {
      photo: null,
      name: 'John Doe',
      action: 'like',
      time: '10:00 AM',
    },
    {
      photo: null,
      name: 'John Doe',
      action: 'comment',
      time: 'yesterday',
    },
    {
      photo: null,
      name: 'John Doe',
      action: 'comment',
      time: '05/08/23',
    },
  ];

  return (
    <div>
      {/* in small screen size its only a link */}
      {screenSize >= 768 ? (
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className="h-full flex items-center"
        >
          <Dropdown.Button>
            <FontAwesomeIcon
              icon={faBell}
              className={`w-6 h-6 ${
                isOpen ? 'text-primary-500' : 'text-text-gray'
              }`}
            />
          </Dropdown.Button>
          <Dropdown.ItemsContainer className="rounded-sm mt-8 px-6 py-6 space-y-6">
            <div className="dropdown-header">
              {t('navbar.logged.notificationsDropdown.notifications')}
            </div>
            <div className="space-y-4">
              {notifications.map((notification, idx) => (
                <NotificationDropdownItem item={notification} key={idx} />
              ))}
              <Link
                href="/notifications"
                className="flex w-full text-left border-none text-primary-500 font-semibold py-3 px-4"
              >
                {t('all')}{' '}
                {t(
                  'navbar.logged.notificationsDropdown.notifications'
                ).toLowerCase()}
              </Link>
            </div>
          </Dropdown.ItemsContainer>
        </Dropdown>
      ) : (
        <Link href="/notifications">
          <BellIcon className="text-text-gray w-6 h-6" />
        </Link>
      )}
    </div>
  );
}

export default NotificationsDropdown;
