import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CategoryTypes } from '@dinbog/models';
import { useLocation } from '../../../hooks';
import { useCategories } from '../../../hooks/api/categories';
import { useSubcategories } from '../../../hooks/api/subcategories';
import SearchFilters from './SearchFilters';

interface BusinessFiltersProps {
  filters: {
    name: string;
    type: string;
    label: string;
    disabled?: boolean;
    iterable: Array<any>;
  }[];
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        type: string;
        label: string;
        disabled?: boolean;
        iterable: Array<any>;
      }[]
    >
  >;
}

export default function BusinessFilters({
  filters,
  setFilters,
}: BusinessFiltersProps) {
  const { t, i18n } = useTranslation();
  const { watch } = useFormContext();

  const { countries, states } = useLocation(
    watch('country') ?? '',
    watch('countryState') ?? ''
  );

  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategories(
      {
        type: 'business' as CategoryTypes,
      },
      'users', // To get categories from ms-users
      {
        refetchOnWindowFocus: false,
      }
    );

  const isEmpyCategory = watch('categories') === '';

  const {
    data: dataSubcategories,
    isLoading: isLoadingSubcategories,
    refetch: refetchSubcategories,
  } = useSubcategories(
    {
      filter: JSON.stringify({
        category: watch('categories') as string,
      }),
    },
    { enabled: !isEmpyCategory }
  );

  React.useEffect(() => {
    if (!isEmpyCategory) {
      refetchSubcategories();
    }
  }, [watch('categories')]);

  React.useEffect(() => {
    setFilters([
      {
        name: 'country',
        type: 'select:location',
        label: 'Country',
        iterable: countries,
      },
      {
        name: 'countryState',
        type: 'select:location',
        label: 'State',
        iterable: states,
      },
      {
        name: 'categories',
        type: 'select',
        label: 'Category',
        iterable: [], // query categorias
      },
      {
        name: 'subcategory',
        type: 'select:subcategory',
        label: 'Subcategory',
        disabled: !!isEmpyCategory,
        iterable:
          dataSubcategories?.map((subcategory) => ({
            value: subcategory?._id,
            text: subcategory?.name[i18n?.language ?? 'en'],
          })) ?? [], // query subcategorias
      },
    ]);
  }, [
    dataCategories,
    dataSubcategories,
    isLoadingCategories,
    isLoadingSubcategories,
  ]);
  return (
    <div className="w-full flex flex-col gap-6">
      <SearchFilters
        title={t('searchPage.searchFilters')}
        filters={filters}
        type="business"
      />
    </div>
  );
}
