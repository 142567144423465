import React from 'react';
import SpecRow from './SpecRow';
import { useScreenSize } from '../../../hooks';

interface SpecsColumnProps {
  idx: number;
  info: any[];
  fromTemplate?: boolean;
  isModal?: boolean;
  hasMoreColumns?: boolean;
}

function SpecsColumn({
  idx,
  info,
  fromTemplate = false,
  isModal = false,
  hasMoreColumns = true,
}: SpecsColumnProps) {
  const { screenSize } = useScreenSize();

  const showSeparator = React.useMemo(() => {
    if (!hasMoreColumns) return false;
    if (screenSize > 1024 && idx !== 2) return true;
    if (screenSize > 768 && idx !== 2) return true;
    if (screenSize > 640 && idx !== 2 && idx !== 1) return true;
    if (screenSize <= 640) return true;
  }, [screenSize, hasMoreColumns]);

  return (
    <div
      className={`flex-col flex w-full ${
        isModal ? '' : 'sm:flex-row sm:w-1/2 md:w-1/4'
      }`}
    >
      <div className="flex flex-col w-full">
        {info?.map((item, i) => (
          <SpecRow fromTemplate={fromTemplate} spec={item} key={i} />
        ))}
      </div>
      {showSeparator ? (
        <hr className="border-b sm:border-l h-full w-full sm:w-[0.5px] bg-neutral-200 my-3 sm:my-0 sm:mx-2 md:block hidden" />
      ) : null}
    </div>
  );
}

export default SpecsColumn;
