import React from 'react';
import { useRouter } from 'next/router';
import { Button } from '@dinbog/ui';
import { Category, Profile, ServicesEnum, User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import MediaOptionsDropdown from '../media/MediaOptionsDropdown';
import DeleteMediaConfirmationModal from '../media/DeleteMediaConfirmationModal';
import { useUpdatePost } from '../../../hooks/api/posts';
import { useNotify, useUser } from '../../../hooks';
import { ReportModal } from '../modal';

interface PostHeaderProps {
  userProfile: Profile;
  userType: string;
  postId: string;
  contentType: string;
}

export default function PostHeader({
  userProfile,
  userType = 'person',
  postId,
  contentType,
}: PostHeaderProps) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openReportModal, setOpenReportModal] = React.useState(false);
  const [openOptionsDropdown, setOpenOptionsDropdown] = React.useState(false);

  const queryClient = useQueryClient();
  const updateMutation = useUpdatePost();
  function getServiceEnum(contentTypeValue: string): ServicesEnum {
    switch (contentTypeValue) {
      case 'book':
      case 'video':
      case 'album':
        return 'portfolio';
      case 'post':
        return 'post';
      default:
        return 'user';
    }
  }

  const onDelete = async () => {
    try {
      const updateData = await updateMutation.mutateAsync(
        { _id: postId, active: false },
        {
          onSuccess: () => {
            queryClient.refetchQueries(['talentPosts']); // ojo. tengo que pasarle un filter?
            queryClient.refetchQueries(['feed']);
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
    }
  };

  return (
    <div className="flex justify-between">
      <button
        type="button"
        onClick={() =>
          router?.push({
            pathname: `/[slug]`,
            query: { slug: (userProfile?.user as User)?.slug },
          })
        }
        className="flex gap-4 text-left"
      >
        <img
          className={`w-12 h-12 border-[1px] border-neutral-200 object-cover ${
            userType === 'person' ? 'rounded-full' : 'rounded-sm'
          }`}
          src={
            userProfile?.photo?.url ??
            (userProfile?.type === 'business'
              ? '/images/fallback/business.jpg'
              : '/images/fallback/user.png')
          }
          alt={userProfile?.photo?.fileName}
        />
        <div className="flex flex-col">
          <span className="font-semibold md:text-xl text-lg">
            {userProfile?.firstName} {userProfile?.lastName ?? ''}
          </span>
          <span className="text-neutral-400 text-sm">
            {
              (userProfile?.categories?.[0] as Category)?.name?.[
                i18n?.language ?? 'en'
              ]
            }
          </span>
        </div>
      </button>
      {setOpenDeleteModal && (
        <div className="justify-end mt-1 relative">
          <Button
            tooltip={t('options')}
            className="flex flex-row"
            type="button"
            onClick={() => setOpenOptionsDropdown(!openOptionsDropdown)}
            aria-label="More options"
          >
            <FontAwesomeIcon icon={faEllipsis} className="h-5" />
          </Button>
          {openOptionsDropdown ? (
            <MediaOptionsDropdown
              setOpenMediaOptionsDropdown={setOpenOptionsDropdown}
              onDelete={
                (userProfile?.user as User)?._id ===
                (user?.currentUser?.user as User)?._id
                  ? () => {
                      setOpenDeleteModal(true);
                      setOpenOptionsDropdown(false);
                    }
                  : null
              }
              onReport={
                (userProfile?.user as User)?._id !==
                (user?.currentUser?.user as User)?._id
                  ? () => {
                      setOpenReportModal(true);
                      setOpenOptionsDropdown(false);
                    }
                  : null
              }
            />
          ) : null}
        </div>
      )}
      <DeleteMediaConfirmationModal
        isOpen={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={onDelete}
      />
      <ReportModal
        isOpen={openReportModal}
        setOpen={setOpenReportModal}
        item="post"
        service={getServiceEnum(contentType)}
        itemIdToBeReported={postId}
        itemOwner={(userProfile?.user as User)?._id}
      />
    </div>
  );
}
