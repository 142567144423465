import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Business, User } from '@dinbog/models';
import { DocumentModel } from '../../../../models';
import { useNotify, useUser } from '../../../../hooks';
import { useUpdateProfile } from '../../../../hooks/api/profiles';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../utils/formatDocumentModel';
import { ACTIONS, ACTIONS_TYPE } from '../reducer';
import { FormButtons } from '../../shared';
import CompanyInformationPictures from './CompanyInformationPictures';
import CompanyInformationContent from './CompanyInformationContent';

interface CompanyInformationProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  state?: Partial<any>; // FIXME: add type
  dispatch?: React.Dispatch<ACTIONS_TYPE>;
  formsLength?: number;
}

function CompanyInformation({
  currentStep,
  setCurrentStep,
  state,
  dispatch,
  formsLength,
}: CompanyInformationProps) {
  const { t, i18n } = useTranslation();
  const notify = useNotify();
  const { currentUser: user, setCurrentUser: setUser } = useUser();

  const methods = useForm<any>({
    defaultValues: {
      [ACTIONS.DESCRIPTION]: user?.currentUser?.description || '',
      [ACTIONS.PHONE]:
        `${user?.currentUser?.phone?.prefix}${user?.currentUser?.phone?.number}` ??
        '',
      [ACTIONS.PAGE_URL]: user?.currentUser?.business?.websiteUrl || '',
      [ACTIONS.COUNTRY]: user?.currentUser?.business?.headquarter || '',
      [ACTIONS.STATE]: user?.currentUser?.business?.state || '',
      [ACTIONS.CITY]: user?.currentUser?.business?.city || '',
      [ACTIONS.BRANCH_OFFICE]: user?.currentUser?.business?.branches || '',
      logo: user?.currentUser?.photo ?? null,
      cover: user?.currentUser?.cover ?? null,
    },
  }); // FIXME: ADD TYPE

  const [logo, setLogo] = React.useState<DocumentModel[]>(
    user?.currentUser?.photo
      ? multimediaToDocument([user?.currentUser?.photo])
      : []
  );
  const updateLogoURLs = React.useCallback(setLogo, [setLogo]);
  const [cover, setCover] = React.useState<DocumentModel[]>(
    user?.currentUser?.cover
      ? multimediaToDocument([user?.currentUser?.cover])
      : []
  );
  const updateCoverURLs = React.useCallback(setCover, [setCover]);

  // update mutation
  const updateMutation = useUpdateProfile();

  // FIXME: ADD TYPE
  const onSubmit = async (_data: any) => {
    try {
      // Set data in reducer state
      dispatch({ type: ACTIONS.DEFAULT, payload: _data });

      const logoPhoto = logo?.length > 0 ? documentToMedia(logo) : null;
      const coverPhoto = cover?.length > 0 ? documentToMedia(cover) : null;

      if (!logoPhoto) {
        return notify('auth.signUp.wizard.business.notifs.logo', 'error');
      }

      // mutation
      const data = await updateMutation.mutateAsync({
        // language: i18n?.language ?? 'en',
        business: {
          headquarter: _data?.country,
          city: _data?.city,
          state: _data?.hqState,
          websiteUrl: _data?.websiteUrl,
          branches: _data?.branchOffice,
        },
        description: _data?.description,

        user: (user?.currentUser?.user as User)?._id,
        phone: _data?.phone
          ? {
              prefix: _data?.phone?.split('-')[0],
              number: _data?.phone?.split('-')[1],
            }
          : null,
        ...(logo?.length > 0
          ? { photo: { ...logoPhoto[0], type: 'image' } }
          : { photo: undefined }),
        ...(cover?.length > 0
          ? { cover: { ...coverPhoto[0], type: 'image' } }
          : { cover: undefined }),
        appLanguage: i18n?.language ?? 'en',
        registrationStep: '2',
      });
      if (!data) {
        return notify(t('auth.signUp.notifs.failure'), 'error');
      }
      setUser({
        ...user,
        _id: user?._id,
        profile: user?.profile,
        token: user?.token,
        currentUser: data,
        accounts: user?.accounts,
      });
      // Go to next step
      setCurrentStep((cs) => cs + 1);
    } catch (err) {
      return notify(err.response.data.message, 'error');
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <h3 className="font-semibold text-lg ">
            {t('auth.signUp.wizard.business.title')}
          </h3>
          <CompanyInformationPictures
            logo={logo}
            updateLogoURLs={updateLogoURLs}
            coverImage={cover}
            updateCoverURLs={updateCoverURLs}
          />
          <CompanyInformationContent />
        </div>
        <FormButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={formsLength}
          onSubmit={methods.handleSubmit(onSubmit)}
          canSkip={false}
        />
      </form>
    </FormProvider>
  );
}

export default CompanyInformation;
