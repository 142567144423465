import React from 'react';
import { motion } from 'framer-motion';
import { Multimedia } from '@dinbog/models';
import { extractColors } from 'extract-colors';
import getPixels from 'get-pixels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SpinnerIcon } from '@dinbog/ui';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

export default function AudioFilePlayer({
  file,
  picture,
}: {
  file: Multimedia;
  picture: string;
}) {
  const [bgColor, setBgColor] = React.useState<{
    hex: string;
    lightness: number;
    url: string;
  }>({ hex: '#E7F2F9', lightness: 0.9, url: picture });

  // extract profile picture colors
  const extractPictureColors = async () => {
    getPixels(picture, async (err, pixels) => {
      if (!err) {
        const data = [...pixels.data];
        const width = Math.round(Math.sqrt(data.length / 4));
        const height = width;
        await extractColors({
          data,
          width,
          height,
        }).then((result) =>
          setBgColor({
            hex: result?.[0]?.hex,
            lightness: result?.[0]?.lightness,
            url: picture,
          })
        );
      }
    });
  };

  // audio related things
  const audioRef = React.useRef(null);
  const [duration, setDuration] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState(null);
  const [playPromise, setPlayPromise] = React.useState<boolean>(false);
  const [playing, setPlaying] = React.useState<boolean>(false);
  const [showAudioProgress, setShowAudioProgress] =
    React.useState<boolean>(false);
  const [currentTime, setCurrentTime] = React.useState<number>(0);

  const rangeInputRef = React.useRef(null);
  const [rangeWidth, setRangeWidth] = React.useState(0);

  React.useEffect(() => {
    //  get the inputs range width use it in the thumb styles
    if (rangeInputRef.current) {
      setRangeWidth(rangeInputRef.current.offsetWidth);
    }
  }, []);

  const togglePlay = () => {
    // when user hits the play/pause button, pause the other tracks
    if (playing || currentTime === duration) {
      if (!playPromise) audioRef?.current?.pause(); // pause the track if it's playing and its promise is resolved
      // when the track ends, pause it and set the current time to 0
      if (currentTime === duration) setCurrentTime(0);
    } else {
      setPlayPromise(true);
      audioRef?.current
        ?.play()
        .then(() => setPlayPromise(false))
        .catch((error) => setError(error));
    }
    setPlaying(!playing);
  };

  const handleTimeUpdate = () => {
    // when the track ends, pause it and set the current time to 0
    if (audioRef?.current?.currentTime === duration) {
      setPlaying(false);
      if (playing) setCurrentTime(0);
    } else if (audioRef?.current?.currentTime >= currentTime)
      // when the track is playing, update the current time, but only if it's greater than the previous one
      setCurrentTime(audioRef?.current?.currentTime);
    else if (audioRef?.current?.currentTime)
      // when we change the current track, update the current time to preserve the progress
      audioRef.current.currentTime = currentTime;
  };

  const handleSeek = (e) => {
    // when the user seeks the track (moves thumb in the input range), update the current time
    const seekTime = e.target.value;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const handleLoadedData = () => {
    // when the track is loaded, set the duration
    setDuration(
      Number.isFinite(audioRef?.current?.duration)
        ? audioRef?.current?.duration
        : 0
    );
  };

  React.useEffect(() => {
    // when the tracks changes its playing state from another component, pause it or play it
    if (!playing) {
      audioRef?.current?.pause();
    }
    if (playing && audioRef?.current?.paused) {
      setPlayPromise(true);
      audioRef?.current
        ?.play()
        .then(() => setPlayPromise(false))
        .catch((error) => setError(error));
    }
  }, [playing]);

  React.useEffect(() => {
    if (audioRef?.current?.duration > 0) {
      setDuration(audioRef?.current?.duration);
    }
  }, [audioRef?.current?.duration]);

  return (
    <div
      onMouseEnter={() => setShowAudioProgress(true)}
      onMouseLeave={() => setShowAudioProgress(false)}
      className={`flex gap-4 p-4 rounded-[5px] flex-col items-center justify-center w-full overflow-hidden border h-56 relative bg-neutral-300 ${
        bgColor?.lightness <= 0.5 ? 'text-text-white' : 'text-text-black'
      }`}
    >
      {/* background */}
      <img
        src={picture}
        alt="bg"
        className="absolute w-full h-full object-cover blur-lg z-0 opacity-80"
      />
      {/* user picture and play animation */}
      <div className="grid grid-flow-row grid-cols-3 grid-rows-3 justify-center items-center">
        <img
          src={picture}
          alt="pfp"
          className="rounded-full h-24 w-24 row-start-1 row-end-4 col-start-1 col-end-4 z-10 object-cover"
        />
        {playing ? (
          <>
            <motion.div
              className="rounded-full bg-background-50/40 h-16 w-16 row-start-1 row-end-4 col-start-1 col-end-4 self-center justify-self-center"
              animate={{
                scale: [1, 2, 1],
              }}
              transition={{
                duration: 3,
                ease: 'easeInOut',
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 0,
              }}
            />
            <motion.div
              className="rounded-full bg-background-50/10 h-16 w-16 row-start-1 row-end-4 col-start-1 col-end-4 self-center justify-self-center"
              animate={{
                scale: [1, 2, 3, 2, 1],
              }}
              transition={{
                duration: 4,
                ease: 'easeInOut',
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 0,
              }}
            />
          </>
        ) : null}
      </div>
      {/* progress bar with play/pause buttons */}
      <div
        className={`w-full absolute bottom-3 px-4 ${
          showAudioProgress ? '' : 'hidden'
        }`}
      >
        <audio
          onCanPlay={() => setLoading(false)}
          src={file?.url ?? ''}
          ref={audioRef}
          autoPlay={playing}
          onTimeUpdate={handleTimeUpdate}
          onLoadedData={handleLoadedData}
          onLoadStart={() => setLoading(true)}
        >
          <track kind="captions" srcLang="en" label="english_captions" />
        </audio>
        <div className="flex justify-center items-center gap-[10px]">
          <button
            type="button"
            className="mr-[6px] flex items-center justify-center"
            onClick={togglePlay}
            disabled={loading || playPromise || error}
          >
            {loading || playPromise ? (
              <>
                <SpinnerIcon className=" w-[10px] h-[10px] text-gray-200 animate-spin  fill-neutral-500" />
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <FontAwesomeIcon
                icon={playing ? faPause : faPlay}
                className={`h-[10px]  text-neutral-300 ${
                  error && 'opacity-50'
                }`}
              />
            )}
          </button>
          <span className=" text-neutral-300 text-xs">{`${Math.floor(
            currentTime / 60
          )}:${String(Math.floor(currentTime % 60)).padStart(2, '0')}`}</span>
          <input
            id="trackRange"
            ref={rangeInputRef}
            type="range"
            min={0}
            step={0.000001}
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            className="h-2 w-full appearance-none bg-gray-200 rounded-full focus:outline-none focus:ring-0 slider"
          />
          <style>
            {`input[type='range']::-webkit-slider-thumb {box-shadow: -${
              rangeWidth + 5
            }px 0 0 ${rangeWidth}px #097DC1;}`}
          </style>
          <span className=" text-neutral-300 text-xs">{`${Math.floor(
            duration / 60
          )}:${String(Math.floor(duration % 60)).padStart(2, '0')}`}</span>
        </div>
      </div>
    </div>
  );
}
