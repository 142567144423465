import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions, UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { FiltersInput, Pagination, WorkReference } from '@dinbog/models';
import { axios } from '../../api';

export function useWorkReferences(
  filters: Partial<WorkReference> & FiltersInput,
  options: Omit<
    UseQueryOptions<
      Pagination<WorkReference>,
      AxiosError,
      Pagination<WorkReference>,
      ['workReferences']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<WorkReference>, AxiosError>(
    ['workReferences', filters],
    async () => {
      const { data } = await axios.get<Pagination<WorkReference>>(
        '/api/portfolios/work-references/profile/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useInfiniteWorkReferences(
  filters: Partial<WorkReference> & FiltersInput,
  options: Omit<
    UseInfiniteQueryOptions<
      Pagination<WorkReference>,
      AxiosError,
      Pagination<WorkReference>,
      Pagination<WorkReference>,
      ['workReferences']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  async function fetchWorkReferences({ pageParam = 1 }) {
    const { data } = await axios.get<Pagination<WorkReference>>(
      '/api/portfolios/work-references/profile/v1',
      {
        params: { ...filters, page: pageParam },
      }
    );
    return data;
  }

  const {
    data,
    fetchNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery<Pagination<WorkReference>, AxiosError>(
    ['workReferences', filters],
    fetchWorkReferences,
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
      ...options,
    }
  );

  return {
    data,
    fetchNextPage,
    isLoading,
    isError,
  };
}

export function useWorkReference(
  filters: Partial<WorkReference> & FiltersInput,
  options: Omit<
    UseQueryOptions<
      WorkReference,
      AxiosError,
      WorkReference,
      ['workReference']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<WorkReference, AxiosError>(
    ['workReference', filters],
    async () => {
      const { data } = await axios.get<WorkReference>(
        '/api/portfolios/work-reference/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useCreateWorkReference() {
  const mutation = useMutation<WorkReference, AxiosError, Partial<any>>(
    async (newWorkReference) => {
      const { data } = await axios.post(
        '/api/portfolios/work-references/v1',
        newWorkReference
      );
      return data;
    }
  );
  return mutation;
}

export function useUpdateWorkReference() {
  const mutation = useMutation<
    WorkReference,
    AxiosError,
    Partial<WorkReference>
  >(async (workReference) => {
    const { data } = await axios.patch(
      `/api/portfolios/work-references/v1`,
      workReference
    );
    return data;
  });
  return mutation;
}

export function useAcceptOrRejectWorkReference() {
  const mutation = useMutation<
    WorkReference,
    AxiosError,
    {
      companyAdminId?: string;
      workReferenceId?: string;
      status?: 'approved' | 'rejected';
    }
  >(async (_data) => {
    const { data } = await axios.patch(
      `/api/portfolios/work-references/status/v1`,
      _data
    );
    return data;
  });
  return mutation;
}
