import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@dinbog/ui';
import { PersonItem } from '../../../../shared';

interface PeopleCarouselProps {
  onFollow: (e) => void;
  people: any;
  followLoading: boolean;
}

function PeopleCarousel({
  people,
  onFollow,
  followLoading,
}: PeopleCarouselProps) {
  const { t } = useTranslation();
  return (
    <div className="w-full relative flex flex-row justify-center items-center gap-6">
      <div className="flex flex-wrap justify-center gap-6 w-fit">
        {people?.map((person) => (
          <div className="w-[264px]" key={person?._id}>
            <PersonItem
              user={person}
              key={person?._id}
              isHorizontal
              hasBackground
            >
              <>
                <div className="">
                  <PersonItem.Header user={person} />
                  <PersonItem.Body user={person} />
                </div>
                <Button
                  tooltip={t('follow.follow')}
                  className="btn-primary text-sm w-full px-4"
                  size="sm"
                  disabled={followLoading}
                  data-id={person?._id}
                  onClick={onFollow}
                >
                  {t('follow.follow')}
                </Button>
              </>
            </PersonItem>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PeopleCarousel;
