import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useAlbum, useUpdateAlbum } from '../../../../../hooks/api/albums';
import { MediaProfileLayout } from '../../../../layout';
import AudioForm from './AudioForm';
import {
  DeleteMediaConfirmationModal,
  Post,
  ReportModal,
  SkeletonWrapper,
} from '../../../../shared';
import { useNotify, useUser } from '../../../../../hooks';

function AudioProfile({ categories }) {
  const router = useRouter();
  const { currentUser: user } = useUser();
  const { slug: userSlug } = router.query;
  const notify = useNotify();
  const [update, setUpdate] = React.useState(false);
  const [openDeleteForm, setOpenDeleteForm] = React.useState(false);
  const [openReportForm, setOpenReportForm] = React.useState(false);
  const { t } = useTranslation();
  const { data, isLoading, isError } = useAlbum(
    {
      _id: router.query.album as string,
      currentUser: user?._id,
    },
    { enabled: !!router.query.album }
  );

  if (isError && !router.query.album) {
    router.push('/profile?tab=audios');
  }

  const profileInfo = {
    user: {
      slug: userSlug as string,
    },
  };

  const dataWithSlugEqualstoId = {
    ...data,
    slug: router.query.album as string,
  };

  const updateAlbumMutation = useUpdateAlbum();
  const queryClient = useQueryClient();
  const onDelete = async () => {
    try {
      await updateAlbumMutation.mutateAsync(
        {
          _id: data?._id ?? undefined,
          active: false,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(['albums']);
            notify(t('profile.tabs.audio.update.success'), 'success');
            setOpenDeleteForm(false);
            router.push({
              pathname: `${router.pathname}`,
              query: {
                ...router.query,
                album: undefined,
              },
            });
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  return (
    <SkeletonWrapper isLoading={isLoading}>
      <MediaProfileLayout
        isOwner={router?.pathname === '/profile'}
        handleGoBack={() => {
          if (update) {
            return setUpdate(false);
          }

          router.push({
            pathname: `${router.pathname}`,
            query: {
              ...router.query,
              album: undefined,
            },
          });
        }}
        type={t('profile.tabs.audio.title')}
        setOpenEditForm={setUpdate}
        setOpenDeleteForm={setOpenDeleteForm}
        setOpenReportForm={setOpenReportForm}
      >
        {update ? (
          <AudioForm
            data={data}
            categories={categories}
            setIsInForm={setUpdate}
          />
        ) : (
          <Post
            content={dataWithSlugEqualstoId}
            owner={profileInfo}
            contentType="album"
            onSuccessLike={() => {
              queryClient.refetchQueries(['album', { _id: data?._id }]);
            }}
          />
        )}
        <DeleteMediaConfirmationModal
          handleDelete={() => {
            onDelete();
          }}
          isOpen={openDeleteForm}
          setOpen={setOpenDeleteForm}
        />
        <ReportModal
          isOpen={openReportForm}
          setOpen={setOpenReportForm}
          item="album"
          itemIdToBeReported={router.query.album as string}
          service="portfolio"
          itemOwner={data?.user as string}
        />
      </MediaProfileLayout>
    </SkeletonWrapper>
  );
}

export default AudioProfile;
