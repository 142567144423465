import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Track } from '@dinbog/models';
import { SpinnerIcon } from '@dinbog/ui';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

interface TrackAudioBarProps {
  track: {
    track: Track;
    playing: boolean;
    selected: boolean;
    currentTime: number;
  };
  setTracksPlayingState: React.Dispatch<
    React.SetStateAction<{ track: Track; playing: boolean }[]>
  >;
}

export default function TrackAudioBar({
  track,
  setTracksPlayingState,
}: TrackAudioBarProps) {
  const audioRef = React.useRef(null);
  const [duration, setDuration] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [playPromise, setPlayPromise] = React.useState(false);

  const rangeInputRef = React.useRef(null);
  const [rangeWidth, setRangeWidth] = React.useState(0);

  React.useEffect(() => {
    //  get the inputs range width use it in the thumb styles
    if (rangeInputRef.current) {
      setRangeWidth(rangeInputRef.current.offsetWidth);
    }
  }, []);

  const togglePlay = () => {
    // when user hits the play/pause button, pause the other tracks
    if (track.playing || track.currentTime === duration) {
      if (!playPromise) audioRef?.current?.pause(); // pause the track if it's playing and its promise is resolved
      // when the track ends, pause it and set the current time to 0
      if (track.currentTime === duration)
        setTracksPlayingState((prev) =>
          prev.map((_) => {
            if (_.track._id === track.track._id)
              return { ..._, currentTime: 0 };
            return _;
          })
        );
    } else {
      setPlayPromise(true);
      audioRef?.current
        ?.play()
        .then(() => setPlayPromise(false))
        .catch((error) => setError(error));
    }
    setTracksPlayingState((prev) =>
      prev.map((_) => {
        if (_.track._id === track.track._id)
          return { ..._, playing: !track.playing };
        return _;
      })
    );
  };

  const handleTimeUpdate = () => {
    // when the track ends, pause it and set the current time to 0
    if (audioRef?.current?.currentTime === duration) {
      setTracksPlayingState((prev) =>
        prev.map((_) => {
          if (_.track._id === track.track._id) return { ..._, playing: false };
          return _;
        })
      );
      if (track.playing)
        setTracksPlayingState((prev) =>
          prev.map((_) => {
            if (_.track._id === track.track._id)
              return { ..._, currentTime: 0 };
            return _;
          })
        );
    } else if (audioRef?.current?.currentTime >= track.currentTime)
      // when the track is playing, update the current time, but only if it's greater than the previous one
      setTracksPlayingState((prev) =>
        prev.map((_) => {
          if (_.track._id === track.track._id)
            return { ..._, currentTime: audioRef?.current?.currentTime };
          return _;
        })
      );
    else if (audioRef?.current?.currentTime)
      // when we change the current track, update the current time to preserve the progress
      audioRef.current.currentTime = track.currentTime;
  };

  const handleLoadedData = () => {
    // when the track is loaded, set the duration
    setDuration(
      Number.isFinite(audioRef?.current?.duration)
        ? audioRef?.current?.duration
        : 0
    );
  };

  const handleSeek = (e) => {
    // when the user seeks the track (moves thumb in the input range), update the current time
    const seekTime = e.target.value;
    audioRef.current.currentTime = seekTime;
    setTracksPlayingState((prev) =>
      prev.map((_) => {
        if (_.track._id === track.track._id)
          return { ..._, currentTime: seekTime };
        return _;
      })
    );
  };

  React.useEffect(() => {
    // when the tracks changes its playing state from another component, pause it or play it
    if (!track.playing) {
      audioRef?.current?.pause();
    }
    if (track.playing && audioRef?.current?.paused) {
      setPlayPromise(true);
      audioRef?.current
        ?.play()
        .then(() => setPlayPromise(false))
        .catch((error) => setError(error));
    }
  }, [track.playing]);

  React.useEffect(() => {
    if (audioRef?.current?.duration > 0) {
      setDuration(audioRef?.current?.duration);
    }
  }, [audioRef?.current?.duration]);

  return (
    track.track?.audio && (
      <div className="">
        <audio
          onCanPlay={() => setLoading(false)}
          src={track.track?.audio?.url ?? ''}
          ref={audioRef}
          autoPlay={track.playing}
          onTimeUpdate={handleTimeUpdate}
          onLoadedData={handleLoadedData}
          onLoadStart={() => setLoading(true)}
        >
          <track kind="captions" srcLang="en" label="english_captions" />
        </audio>
        <div className="flex justify-center items-center gap-[10px]">
          <button
            type="button"
            className="mr-[6px] flex items-center justify-center"
            onClick={togglePlay}
            disabled={loading || playPromise || error}
          >
            {loading || playPromise ? (
              <>
                <SpinnerIcon className=" w-[10px] h-[10px] text-gray-200 animate-spin  fill-neutral-500" />
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <FontAwesomeIcon
                icon={track?.playing ? faPause : faPlay}
                className={`h-[10px]  text-neutral-500 ${
                  error && 'opacity-50'
                }`}
              />
            )}
          </button>
          <span className=" text-neutral-300 text-xs">{`${Math.floor(
            track.currentTime / 60
          )}:${String(Math.floor(track.currentTime % 60)).padStart(
            2,
            '0'
          )}`}</span>
          <input
            id="trackRange"
            ref={rangeInputRef}
            type="range"
            min={0}
            step={0.000001}
            max={duration}
            value={track.currentTime}
            onChange={handleSeek}
            className="h-2 w-full appearance-none bg-gray-200 rounded-full focus:outline-none focus:ring-0 slider"
          />
          <style>
            {`input[type='range']::-webkit-slider-thumb {box-shadow: -${
              rangeWidth + 5
            }px 0 0 ${rangeWidth}px #097DC1;}`}
          </style>
          <span className=" text-neutral-300 text-xs">{`${Math.floor(
            duration / 60
          )}:${String(Math.floor(duration % 60)).padStart(2, '0')}`}</span>
        </div>
      </div>
    )
  );
}
