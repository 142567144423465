/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Input,
  Select,
  TextArea,
  TrashIcon,
  UserSelectSearch,
} from '@dinbog/ui';
import {
  Business,
  Profile,
  StatusEnum,
  User,
  WorkReference,
  workReferencePrivacyValues,
} from '@dinbog/models';
import { validateString } from 'avilatek-utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useUserPagination } from '../../../hooks/api/users';
import UserItem from '../UserItem';
import CompanyInput from '../work-reference/CompanyInput';
import UserItemList from '../work-reference/UserItemList';
import CompanySearch from '../work-reference/CompanySearch';

interface WorkReferenceFormContentProps {
  disabled?: boolean;
  isUpdate?: boolean;
  userType?: string;
}

/**
 * WORK REFERENCE FORM CONTENT TO CREATE OR UPDATE A TALENT WORK REFERENCE
 */
function WorkReferenceFormContent({
  disabled = false,
  isUpdate = false,
  userType = '',
}: WorkReferenceFormContentProps) {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    setValue,
    watch,
    unregister,
    getValues,
  } = useFormContext<WorkReference>();

  const [untilEnabled, setUntilEnabled] = React.useState(!!watch('endDate'));
  const [companyExists, setCompanyExists] = React.useState(
    validateString(watch('company')) ||
      validateString((watch('company') as User & { profile: Profile })?._id)
  ); // select a company that is registered in the platform

  const formValidations = {
    position: { required: t('required') },
    startDate: { required: t('required') },
    endDate: untilEnabled
      ? {
          required: t('required'),
          validate: {
            isValid: (value) => {
              const date = new Date(value);
              const today = new Date();
              return dayjs(date)?.isBefore(today) || t('invalidDate');
            },
          },
        }
      : {},
    description: { required: t('required') },
    // COMPANY NOT REGISTERED IN THE PLATFORM DATA
    companyName:
      userType === 'business' || companyExists
        ? {}
        : { required: t('required') },
    privacy: { required: t('required') },
    // ID OF THE REGISTERED COMPANY (BUSINESS USER)
    company:
      userType === 'business' || companyExists
        ? {}
        : { required: t('required') },
  };

  // select users (companies/business) data
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 30 });
  const [selectedUsers, setSelectedUsers] = React.useState<
    (User & { profile?: Profile; business?: Business; status?: StatusEnum })[]
  >([]);
  const [searchText, setSearchText] = React.useState('');

  const { data: dataUsers, isLoading } = useUserPagination({
    page: pagination.page,
    perPage: pagination.perPage,
    search: searchText,
    filter: JSON.stringify({
      'profile.type': 'business',
    }),
  });

  const handleRemoveUser = async (
    user_: User & {
      profile?: Profile;
      business?: Business;
      status?: StatusEnum;
    }
  ) => {
    setSelectedUsers(selectedUsers.filter((item) => item._id !== user_._id));
    setValue('company', null);
  };

  // this checkbox is checked if the company is NOT registered in the platform and the user has to fill the company data
  const companyExistsCheckBox = () => (
    <label className="text-sm space-x-2 py-4">
      <input
        disabled={isUpdate}
        onChange={(e) => {
          setCompanyExists(!e.target.checked);
          // CASE 1: COMPANY IS NOT REGISTERED IN THE PLATFORM
          if (e.target.checked) {
            // reset form validations for company id if company is not registered in the platform
            setValue('company', null);
            unregister('company');
            // register form validations for company not registered in the platform
            register('privacy', formValidations.privacy);
            register('companyName', formValidations.companyName);
          }
          // CASE 2: COMPANY IS REGISTERED IN THE PLATFORM
          if (!e.target.checked) {
            // reset form validations for company not registered in the platform

            setValue('companyName', null);
            unregister('companyName');
            // register form validations for company id if company is registered in the platform
            register('company', formValidations.company);
            register('privacy', formValidations.privacy);
          }
        }}
        name="untilCheckbox"
        type="checkbox"
        className="rounded"
        checked={!companyExists}
      />
      <span className="text-sm font-semibold">
        {t('auth.signUp.wizard.workReference.newCompany.newCompany') ?? ''}
      </span>
    </label>
  );

  React.useEffect(() => {
    if (
      companyExists &&
      selectedUsers.length === 0 &&
      validateString(
        (watch('company') as User & { profile: Profile; status?: StatusEnum })
          ?._id
      )
    ) {
      setSelectedUsers([
        watch('company') as User & { profile: Profile; status?: StatusEnum },
      ]);
      setValue(
        'company',
        (watch('company') as User & { profile: Profile })?._id
      );
    }
  }, [getValues('company')]);

  return (
    <div className="space-y-8 flex flex-col mb-4">
      <div className="w-full">
        <Input
          isRequired
          error={errors.position?.message as string}
          label={t('auth.signUp.wizard.workReference.position.title') ?? ''}
          placeholder={
            t('auth.signUp.wizard.workReference.position.description') ?? ''
          }
          {...register('position', formValidations.position)}
        />
      </div>
      <div className="w-full pb-4">
        <TextArea
          isRequired
          {...register('description', formValidations.description)}
          className="h-24"
          error={errors.description?.message as string}
          label={
            t('auth.signUp.wizard.workReference.workDescription.title') ?? ''
          }
          placeholder={
            t('auth.signUp.wizard.workReference.workDescription.description') ??
            ''
          }
        />
      </div>
      <div className="w-full">
        <Input
          isRequired
          label={t('auth.signUp.wizard.workReference.dates.from') ?? ''}
          type="date"
          max={new Date().toISOString().split('T')[0]}
          {...register('startDate', formValidations.startDate)}
          error={errors.startDate?.message as string}
        />
      </div>

      <div className="">
        <label className="text-sm space-x-2 py-4">
          <input
            onChange={(e) => {
              setUntilEnabled(e.target.checked);
              if (!e.target.checked) {
                setValue('endDate', null);
                unregister('endDate');
              }
              if (e.target.checked) {
                register('endDate', formValidations.endDate);
              }
            }}
            name="untilCheckbox"
            type="checkbox"
            className="rounded"
            checked={untilEnabled}
          />
          <span className="text-sm font-semibold">
            {t('auth.signUp.wizard.workReference.dates.until')}{' '}
          </span>
        </label>
        <Input
          {...register('endDate', formValidations.endDate)}
          className={`${!untilEnabled && 'opacity-50'} ${
            untilEnabled &&
            errors.endDate &&
            errors.endDate?.type === 'required'
              ? 'border-danger-300'
              : ''
          }`}
          error={errors.endDate?.message as string}
          disabled={!untilEnabled}
          type="date"
          min={String(getValues('startDate'))}
          max={new Date().toISOString().split('T')[0]}
        />
      </div>

      {userType !== 'business' ? (
        <div className="w-full space-y-4">
          {companyExistsCheckBox()}
          {!companyExists ? (
            <CompanyInput formValidations={formValidations} />
          ) : selectedUsers?.length > 0 ? (
            <UserItemList
              selectedUsers={selectedUsers}
              handleRemoveUser={handleRemoveUser}
              isUpdate={isUpdate}
            />
          ) : (
            <CompanySearch
              searchText={searchText}
              setSearchText={setSearchText}
              formValidations={formValidations}
              setSelectedUsers={setSelectedUsers}
              setPagination={setPagination}
              dataUsers={dataUsers}
              isLoading={isLoading}
            />
          )}
        </div>
      ) : null}
      <div className="w-full">
        <Select
          disabled={disabled}
          isRequired
          {...register('privacy', formValidations.privacy)}
          error={errors.privacy?.message as string}
          className="w-full"
          label={t('portfolio.privacy.title') ?? ''}
        >
          <option key={-1} value="" disabled>
            {t('portfolio.privacy.values.default')}
          </option>
          {workReferencePrivacyValues?.map((privacy, i) => (
            <option key={`${i + 1}-${privacy}`} value={privacy ?? ''}>
              {t(`portfolio.privacy.values.${privacy}`)}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default WorkReferenceFormContent;
