import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { v4 as uuid } from 'uuid';
import { DocumentModel } from '../../../models';
import { useNotify } from '../../../hooks';
import DropZone from '../DropZone';

interface CropDocumentFormProps {
  documents: Array<Partial<DocumentModel>>;
  updateURLs: React.Dispatch<React.SetStateAction<Partial<DocumentModel>[]>>;
  maximumAmount?: number;
  dropZoneClassName?: string;
  dropZoneText?: string;
  icon?: boolean;
  customIcon?: React.ReactNode;
  dropZoneTextClassName?: string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

function CropDocumentForm({
  updateURLs,
  documents,
  maximumAmount = 5,
  dropZoneClassName = '',
  dropZoneText = '',
  icon = true,
  customIcon = null,
  dropZoneTextClassName = '',
  setStep,
}: CropDocumentFormProps) {
  const notify = useNotify();
  const [docs, setDocs] = React.useState<Array<DocumentModel>>(
    documents?.map((doc) => ({
      id: uuid(),
      src: doc?.src,
      name: doc?.name ?? 'N/A',
      file: doc?.file,
    })) ?? []
  );
  const getBackend = () => {
    if (typeof window !== 'undefined' && 'ontouchstart' in window) {
      return TouchBackend;
    }
    return HTML5Backend;
  };
  const onDrop = React.useCallback(
    (acceptedFiles: File[]): void => {
      acceptedFiles
        .map((file: File) => {
          if (file.size > 1000000) {
            notify('Tu archivo debe pesar menos de 1MB', 'error');
            return null;
          }

          const reader = new FileReader();
          reader.onload = function onLoadFile(e) {
            setDocs((prevState) => [
              ...prevState,
              {
                id: uuid(),
                src: e.target.result,
                name: file?.name ?? uuid(),
                file,
              },
            ]);
            updateURLs([
              {
                id: uuid(),
                src: e.target.result,
                name: file?.name ?? uuid(),
                file,
              },
            ]);
          };
          setStep(1);
          reader.readAsDataURL(file);
          return file;
        })
        .filter((x) => !!x) as File[];
    },
    [docs.length, documents?.length, maximumAmount, notify]
  );

  const updateDocs = React.useCallback(setDocs, [setDocs]);

  const updateSrc = React.useCallback(
    (id: string, src: string, name: string) => {
      updateDocs((_docs) => {
        const copyOfDocs = _docs.slice();
        const idx = copyOfDocs.findIndex((img) => img.id === id);
        copyOfDocs[idx] = { id, src, name };
        return copyOfDocs;
      });
    },
    [updateDocs]
  );

  React.useEffect(() => {
    if (documents?.length > 0) {
      setDocs(
        documents?.map((doc) => ({
          id: uuid(),
          src: doc?.src,
          name: doc?.name ?? 'N/A',
          file: doc?.file,
        })) ?? []
      );
    } else if (documents?.length === 0 && docs?.length > 0) {
      setDocs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return (
    <DndProvider backend={getBackend()}>
      <section className="flex flex-col flex-wrap h-18">
        <DropZone
          dropZoneClassName={dropZoneClassName}
          onDrop={onDrop}
          accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
          icon={icon}
          dropZoneText={dropZoneText}
          customIcon={customIcon}
          dropZoneTextClassName={dropZoneTextClassName}
        />
        {/* <DocumentList
          docs={docs}
          updateDocs={updateDocs}
          moveDoc={moveDoc}
          updateURLs={updateURLs}
        /> */}
      </section>
    </DndProvider>
  );
}

export default CropDocumentForm;
