import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  VKontakteIcon,
  FacebookIcon,
  LinkedInIcon,
  CheckIcon,
} from '@dinbog/ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CircledIcon } from '../../shared';
import { useUser } from '../../../hooks';

export default function Success() {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentUser: user } = useUser();
  return (
    <div className="w-full h-full py-8 row-start-1 row-end-4 col-start-1 col-end-4">
      <div className="bg-white p-16 h-full flex flex-col justify-between rounded-lg shadow-sm">
        <div className="mb-8 flex flex-col items-center space-y-6">
          <CircledIcon>
            <CheckIcon className="h-12 w-12 text-primary-500" />
          </CircledIcon>
          <h1 className=" text-3xl ">
            {t('auth.signUp.wizard.confirmation.title')}
          </h1>
          <p className="text-center">
            {t('auth.signUp.wizard.confirmation.subtitle')}
          </p>
          <p className="text-sm">{t('auth.signUp.wizard.confirmation.text')}</p>
          <Button
            tooltip={t('auth.signUp.wizard.confirmation.button')}
            onClick={(e) =>
              router.push(
                {
                  pathname: '/profile',
                },
                `/${user?._id}`
              )
            }
            className="btn-primary w-auto"
          >
            <p className=" text-base font-normal">
              {t('auth.signUp.wizard.confirmation.button')}
            </p>
          </Button>
        </div>
        {/* <div className="space-y-6">
          <hr />
          <div>
            <h2 className="text-xl py-4">
              {t('auth.signUp.wizard.confirmation.invite.title')}
            </h2>
            <p className="">
              {t('auth.signUp.wizard.confirmation.invite.description')}
            </p>
          </div>
          <div className="flex flex-row gap-16">
            <button
              type="button"
              className="rounded-full h-12 w-12 bg-[#4C75A3] flex flex-row justify-center items-center"
            >
              <VKontakteIcon className="h-12 w-12 text-white" />
            </button>
            <button
              type="button"
              className="rounded-full h-12 w-12 bg-[#3b5998] flex flex-row justify-center items-center"
            >
              <FacebookIcon className="h-7 w-7 text-white" />
            </button>
            <button
              type="button"
              className="rounded-full h-12 w-12 bg-[#0e76a8] flex flex-row justify-center items-center"
            >
              <LinkedInIcon className="h-7 w-7 text-white" />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}
