import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from '../../../hooks';
import SearchFilters from './SearchFilters';

export default function PortfolioFilters({
  filters,
  setFilters,
}: {
  filters: {
    name: string;
    type: string;
    label: string;
    iterable: Array<any>;
  }[];
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        type: string;
        label: string;
        iterable: Array<any>;
      }[]
    >
  >;
}) {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const { t } = useTranslation();

  React.useEffect(() => {
    setFilters([
      {
        name: 'type',
        type: 'select',
        label: 'Type',
        iterable: [
          { value: 'book', text: 'Book' },
          { value: 'video', text: 'Video' },
          { value: 'audio', text: 'Audio' },
          { value: 'album', text: 'Album' },
        ],
        // poner acá algo de los errores, el state, etc
      },
    ]);
  }, []);
  return (
    <SearchFilters
      title={t('searchPage.searchFilters')}
      filters={filters}
      type="portfolios"
    />
  );
}
