import React from 'react';
import InfiniteList from '../../shared/infinite-list/InfiniteList';
import EventCard from '../../briefcase/shared/EventCard';
import { useSearchContext } from '../../../context';

function CastingResult() {
  const { searchData, fetchNextPage } = useSearchContext();
  return (
    <InfiniteList
      items={searchData?.items}
      fetchNextPage={fetchNextPage}
      containerClassName="flex flex-wrap gap-4"
    >
      {(castingItem) => (
        <EventCard
          key={castingItem?._id}
          item={castingItem?.event ?? castingItem}
          vacancy={null}
          fromSearch
        />
      )}
    </InfiniteList>
  );
}

export default CastingResult;
