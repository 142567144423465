import React from 'react';
import Link from 'next/link';
import { Button } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import ChangeLanguageSelect from '../changeLanguageDropdown/ChangeLanguageDropdown';

function UnLoggedOptions() {
  const { t } = useTranslation();

  return (
    <div className="ml-auto my-auto flex space-x-5 py-5">
      <div className="space-x-5 flex">
        <Link href="/sign-in">
          <Button
            className="btn-secondary px-10"
            tooltip={t('navbar.unLogged.login')}
          >
            {t('navbar.unLogged.login')}
          </Button>
        </Link>
      </div>
      <div className="hidden sm:flex justify-center">
        <ChangeLanguageSelect />
      </div>
    </div>
  );
}

export default UnLoggedOptions;
