import React from 'react';

interface CardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode;
}

export default function Card({
  children,
  className = '',
  ...props
}: CardProps) {
  return (
    <div
      className={`relative flex flex-col min-w-0 bg-white bg-clip-border break-words shadow-[0_2px_4px_rgba(0,0,0,0.05)] rounded ${className}`}
      style={{
        ...(props.style ?? {}),
      }}
      {...props}
    >
      {children}
    </div>
  );
}
