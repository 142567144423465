/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Button } from '@dinbog/ui';
import {
  Address,
  Category,
  Event as EventModel,
  Profile,
  Vacant,
  User,
  Application,
} from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useNotify, useUser } from '../../../hooks';
import {
  useCreateApplication,
  useUpdateApplication,
} from '../../../hooks/api/applications';
import { getFlag } from '../../../lib/getFlag';
import StatusTag from './StatusTag';

dayjs.extend(utc);
// dayjs.extend(timezone);
// // dayjs.extend(localizedFormat);
// dayjs().format('L LT');

interface EventCardProps {
  item: EventModel;
  vacancy?: Vacant & { application?: Application };
  fromSearch?: boolean;
}

export default function EventCard({
  item,
  vacancy,
  fromSearch = false,
}: EventCardProps) {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();

  const applyMutation = useCreateApplication();
  const updateApplicationMutation = useUpdateApplication();
  const queryClient = useQueryClient();

  // check if today's date is in event's startDate - endDate (range)
  const getEventStatus = () => {
    const now = dayjs();
    const startDate = dayjs(item?.startDate);
    const endDate = dayjs(item?.endDate);
    if (now.isBefore(startDate)) {
      return 'upcoming';
    }
    if (now.isAfter(startDate) && now.isBefore(endDate)) {
      return 'active';
    }
    if (now.isAfter(endDate)) {
      return 'closed';
    }
  };

  const formatCategories = () =>
    vacancy?.categories
      ?.map(
        (category) => (category as Category)?.name?.[i18n?.language ?? 'en']
      )
      ?.join(' | ');

  const onApply = async () => {
    try {
      const data = await applyMutation.mutateAsync({
        user: (user?.currentUser?.user as User)?._id as string,
        vacant: vacancy?._id,
      });
      if (data) {
        queryClient.refetchQueries(['vacancies']);
        queryClient.refetchQueries(['applications']);
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
  };

  const onWithdraw = async () => {
    try {
      const data = await updateApplicationMutation.mutateAsync({
        _id: (vacancy?.application as Application)?._id as string,
        active: false,
      });
      if (data) {
        queryClient.refetchQueries(['vacancies']);
        queryClient.refetchQueries(['applications']);
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
  };

  return (
    <div className="rounded-md bg-background-50 shadow px-4 py-2 w-[200px] h-[296px] flex flex-col gap-3">
      {/* location and status */}
      <div className="flex justify-between gap-1">
        {/* location */}
        <div className="gap-1 flex text-neutral-500 text-sm overflow-hidden">
          <span className="">
            {(item?.address as Address)?.country
              ? getFlag((item?.address as Address)?.country)
              : null}
          </span>
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
            {(item?.address as Address)?.city}
          </span>
        </div>
        <StatusTag status={getEventStatus()} />
      </div>
      {/* cover */}
      <img
        className="rounded-md shadow w-full h-20 object-cover"
        alt={item?.cover?.fileName}
        src={item?.cover?.url}
      />
      {/* info */}
      <div className="flex flex-col gap-1">
        <div className="font-semibold overflow-hidden whitespace-nowrap overflow-ellipsis">
          {vacancy ? `${vacancy?.title} | ` : null}
          {item?.title}
        </div>
        <div className="text-xs overflow-hidden whitespace-nowrap overflow-ellipsis">
          {formatCategories()}
        </div>
        <div className="text-xs overflow-hidden whitespace-nowrap overflow-ellipsis">
          {t('events.casting.publishedBy')} {(item?.user as Profile)?.firstName}{' '}
          {(item?.user as Profile)?.lastName}
        </div>
        <div className="text-xs overflow-hidden whitespace-nowrap overflow-ellipsis">
          {t('events.casting.closesOn')} {dayjs(item?.endDate).format('ll')}
        </div>
      </div>
      {/* actions */}
      <div className="flex text-xs justify-center justify-self-end items-center mt-auto">
        <Button
          tooltip={t('events.details')}
          size="sm"
          className="text-primary-500 w-full"
          onClick={() =>
            router.push({
              pathname: `/briefcase/castings/[slug]`,
              query: vacancy
                ? (user?.currentUser?.user as User)?._id !==
                  ((item?.user as Profile)?.user as User)?._id
                  ? {
                      slug: item?.slug,
                      // vacante como query
                      vacancy: vacancy?.slug,
                    }
                  : {
                      slug: item?.slug,
                      tab: 'vacancies',
                      // vacante como query
                      vacancy: vacancy?.slug,
                    }
                : {
                    slug: item?.slug,
                    castingProfile: fromSearch ? 'true' : null,
                  },
            })
          }
        >
          {t('events.details')}
        </Button>
        {(user?.currentUser?.user as User)?._id !==
          ((item?.user as Profile)?.user as User)?._id &&
        user?.currentUser?.type === 'person' &&
        user?.currentUser?.categories?.length > 0 &&
        !vacancy?.application &&
        !fromSearch &&
        getEventStatus() === 'active' ? (
          <Button
            tooltip={t('events.apply')}
            className="btn-primary h-fit"
            onClick={() => onApply()}
          >
            {t('events.apply')}
          </Button>
        ) : null}
        {vacancy?.application &&
        !fromSearch &&
        (vacancy?.application as Application)?.status !== 'selected' ? (
          <Button
            tooltip={t('events.casting.tabs.enrolled')}
            disabled={
              (vacancy?.application as Application)?.status !== 'initial'
            }
            className={`${
              (vacancy?.application as Application)?.status === 'initial'
                ? 'btn-secondary'
                : 'text-neutral-300 border border-neutral-300'
            } h-fit`}
            onClick={() => onWithdraw()}
          >
            {t('events.casting.tabs.enrolled')}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
