import { isError } from '@tanstack/react-query';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  es: {
    translation: {
      yes: 'Sí',
      all: 'Ver todos',
      less: 'Ver menos',
      accept: 'Aceptar',
      cancel: 'Cancelar',
      decline: 'Rechazar',
      apply: 'Aplicar',
      reject: 'Rechazar',
      withdraw: 'Retirar',
      required: 'Requerido',
      select: 'Seleccione',
      invalidEmail: 'Correo electrónico inválido',
      invalidAge: 'Debes tener por lo menos 14 años',
      invalidDate: 'Fecha inválida',
      uploadImage: 'Subir imagen',
      uploadFile: 'Subir archivo',
      return: 'Regresar',
      search: 'Buscar',
      edit: 'Editar',
      crop: 'Recortar',
      delete: 'Eliminar',
      continue: 'Continuar',
      save: 'Guardar',
      tag: 'Etiquetar',
      options: 'Opciones',
      successfulEmail: '¡Correo electrónico enviado con éxito!',
      failedEmail: 'Ocurrió un error enviando el correo electrónico.',
      readMore: 'Leer más',
      readLess: 'Leer menos',
      play: 'Reproducir',
      pause: 'Pausar',
      update: 'Actualizar',
      create: 'Crear',
      castings: 'Castings',
      failedImageUpload: 'Error cargando el archivo',
      successImageUpload: 'Imagen subida con éxito',
      about: 'Información',
      optional: 'Opcional',
      actor: 'Actor',
      model: 'Modelo',
      comment: {
        placeholder: 'Escribe un comentario...',
        send: 'Enviar',
        plural: 'Comentarios',
        singular: 'Comentario',
        viewAll: 'Ver todos los',
        noComments: '¡Sé el primero en comentar!',
        error: 'Ocurrió un error al publicar tu comentario',
        whitespace: 'No puedes enviar un comentario vacío',
      },
      stats: {
        likes: 'Me gusta',
        comments: 'Comentarios',
        reports: 'Reportes',
      },
      error: 'Ocurrió un error.',
      copyToClipboard: '¡Enlace copiado al portapapeles!',
      pageError: {
        title: 'Ups! No pudimos encontrar la página que solicitaste',
        text: 'Volver al feed',
      },
      feed: {
        suggestions: {
          talents: 'Talentos recomendados para ti',
          businesses: 'Empresas recomendadas para ti',
          seeMore: 'Ver más recomendaciones',
          seeAll: 'Descubrir usuarios',
          seeLess: 'Ver menos recomendaciones',
          noUsersFound: 'No se encontraron usuarios',
        },
      },
      settings: {
        profile: 'Perfil',
        account: 'Cuenta',
        changeEmail: 'Cambiar correo electrónico',
        disableAccountModal: {
          title: '¿Estás seguro que deseas desactivar tu cuenta?',
          description:
            'Si desactivas tu cuenta, no podrás iniciar sesión y tu perfil no será visible para otros usuarios.',
          email: 'Ingresa tu correo electrónico para confirmar',
          yesBtn: 'Sí, desactivar mi cuenta',
          noBtn: 'No, cancelar',
        },
        noBlockedProfilesFound: 'No se encontraron perfiles bloqueados',
        noBlockedProfilesFoundDescription:
          '¡No tienes perfiles bloqueados! \nCuando bloquees un perfil, aparecerá aquí.',
        oldPassword: 'Contraseña actual',
        oldPasswordPlaceholder: 'Ingrese su contraseña actual',
        changePassword: 'Cambiar contraseña',
        deactivateAccount: 'Desactivar cuenta',
        deactivateAccountDescription:
          'Si desactivas tu cuenta, no podrás iniciar sesión y tu perfil no será visible para otros usuarios.',
        deactivateAccountConfirmModal:
          '¿Estás seguro que deseas desactivar tu cuenta?',
        deactivateAccountConfirm: 'Sí, desactivar mi cuenta',
        deactivateAccountSuccess: '¡Cuenta desactivada con éxito!',
        deactivateAccountError: 'Ocurrió un error desactivando tu cuenta.',
        newEmail: 'Nuevo correo electrónico',
        newEmailPlaceholder: 'Ingresa tu nuevo correo electrónico',
        password: 'Contraseña actual',
        passwordPlaceholder: 'Ingresa tu contraseña actual',
        categories: 'Categorías',
        specifications: 'Especificaciones',
        administrators: 'Administradores',
        security: 'Seguridad',
        saved: 'Guardado',
        blockedProfiles: 'Perfiles bloqueados',
        preferences: 'Preferencias',
        categoriesNotifs: {
          noCategoriesBusiness:
            'Las empresas deben tener al menos una categoría',
        },
        appLanguage: {
          title: 'Idioma de la aplicación',
          default: 'Seleccione un idioma predeterminado',
        },
        notifs: {
          success: '¡Configuración actualizada!',
          error: 'Ocurrió un error.',
        },
        changeRoleConfirm: 'Sí, cambiar mi rol',
        changeRoleToFan: {
          title: 'Cambiar rol a fan',
          description:
            '¿Estás seguro que deseas cambiar tu rol a fan? \nLos fans no tienen categorías ni especificaciones y no pueden participar en castings. Si cambias tu rol a fan, perderás todas tus categorías, especificaciones y conexiones.',
        },
        changeRoleToTalent: {
          title: 'Cambiar rol a talento',
          description:
            '¿Estás seguro que deseas cambiar tu rol a talento? \nLos talentos tienen categorías y especificaciones y pueden participar en castings.',
        },
      },
      navbar: {
        logged: {
          briefcaseDropdown: {
            castings: 'Castings',
            events: 'Eventos',
            ads: 'Anuncios',
            contests: 'Concursos',
          },
          requestDropdown: {
            requests: 'Requests',
            find: 'Encontrar conexión',
            manage: 'Administrar conexiones',
          },
          notificationsDropdown: {
            markAll: 'Marcar todo como leído',
            notifications: 'Notificaciones',
          },
          userDropdown: {
            profile: 'Perfil',
            switchAccounts: 'Cambiar de cuenta',
            createBusinessPage: 'Crear perfil de empresa',
            settings: 'Ajustes',
            logOut: 'Cerrar sesión',
          },
          switchAccount: {
            title: 'Selecciona una cuenta',
            current: 'Actual',
            create: 'Crear cuenta de empresa',
          },
        },
        unLogged: {
          login: 'Iniciar sesión',
          signup: 'Registrarse',
        },
        onRegister: {
          button: 'Cerrar sesión',
        },
      },
      footer: {},
      home: {},
      auth: {
        text: {
          title: 'Mercado social de talento',
          subtitle: 'Impulsa la industria creativa y la economía naranja',
        },
        passwordRecovery: {
          title: 'Recuperar contraseña',
          inputLabel:
            'Ingresa tu correo electrónico para recibir un código de recuperación',
          requestLinkBtn: 'Solicitar código de recuperación',
          codeInputLabel:
            'Ingresa el código de 6 dígitos enviado a tu correo electrónico',
          requestNewCodeBtn: 'Solicitar nuevo código',
          wrongCode: 'Código inválido',
          resetPasswordBtn: 'Modificar contraseña',
          newPassword: 'Nueva contraseña',
          confirmNewPassword: 'Confirmar nueva contraseña',
          createPassword: 'Crea una nueva contraseña',
          setNewPassword:
            'Establece una nueva contraseña para tu cuenta de Dinbog',
          success: '¡Contraseña actualizada con éxito!',
          timeLeft: 'Tiempo restante',
        },
        signIn: {
          title: '¡Bienvenido de vuelta!',
          or: 'o',
          email: 'Correo',
          password: 'Contraseña',
          forgot: '¿Olvidaste tu contraseña?',
          enter: 'Ingresar',
          noAccount: '¿No tienes cuenta? Regístrate',
          notifs: {
            email: 'Por favor, ingresa un correo electrónico válido',
            password: 'Por favor, ingresa tu contraseña',
            success: '¡Inicio de sesión exitoso!',
            failure: 'Ha ocurrido un error.',
          },
        },
        signUp: {
          title: 'Estás a un click de ser descubierto',
          or: 'o',
          registerAs: 'Registrarse como',
          type: ['Registrarse como...', 'Talento', 'Empresa'],
          firstName: 'Nombre',
          name: 'Nombre',
          lastName: 'Apellido',
          email: 'Correo electrónico',
          companyEmail: 'Correo electrónico de la empresa',
          password: 'Contraseña',
          confirmPassword: 'Confirmar contraseña',
          hasAccount: '¿Ya tienes cuenta? Inicia sesión',
          hasPersonAccount: 'Ya tengo una cuenta personal',
          personalInfo: 'Información personal',
          businessInfo: 'Información de la empresa',
          companyName: 'Nombre de la empresa',
          submit: 'Registrarse',
          addBusiness: {
            title: 'Regístrate como empresa',
            text: 'Con este perfil podrás crear castings, publicar contenido y contactar a talentos y otras empresas',
          },
          terms: {
            text: 'He leído y acepto los ',
            link: 'Términos y Condiciones',
            childrenLink:
              'Términos y Condiciones para Usuarios entre 14 y 17 Años',
            modalTitle: 'Términos y Condiciones',
            modalTitleChildrens:
              'Términos y Condiciones para Usuarios entre 14 y 17 Años',
          },
          activate: {
            title: 'Activa tu cuenta',
            description:
              'Te hemos enviado un correo electrónico. Por favor, revisa tu bandeja de entrada y haz click en el link de verificación.',
            noEmail:
              '¿No has recibido el correo electrónico? Revisa tu bandeja de spam.',
            resend: 'Enviar correo nuevamente',
            later: 'Verificar luego',
            success: {
              title: '¡Cuenta activada!',
              subtitle:
                'Tu cuenta ha sido activada. Ahora puedes iniciar sesión y completar tu perfil.',
              btn: 'Iniciar sesión',
            },
          },
          notifs: {
            userType: 'Por favor, seleccione su tipo de perfil',
            firstName: 'Por favor, ingresa tu nombre',
            lastName: 'Por favor, ingresa tu apellido',
            email: 'Por favor, ingresa un correo electrónico válido',
            password: 'Por favor, ingresa una contraseña válida',
            confirmPassword: 'Por favor, confirma tu contraseña',
            passwordRequirements:
              'La contraseña debe contener 8 caracteres, una letra mayúscula, una minúscula, un número y un caracter especial',
            differentPasswordValues:
              'La Confirmación de Contraseña ingresada no coincide con la Contraseña ingresada',
            terms: 'Debes aceptar los Términos y Condiciones para continuar',
            success: '¡Registro exitoso!',
            failure: 'Ha ocurrido un error.',
          },
          wizard: {
            title: 'Vamos a comenzar con tu perfil',
            businessTitle: 'Vamos a configurar tu perfil de empresa',
            prevBtn: 'Anterior',
            nextBtn: 'Siguiente',
            skipBtn: 'Saltar este paso',
            finishBtn: 'Terminar',
            privacy: {
              title: 'Privacidad',
              description: 'Este contenido solo será visible para tus amigos',
            },
            categories: {
              title: 'Seleccione una categoría',
              error: 'Debes seleccionar al menos una categoría',
            },
            business: {
              title: 'Información de la empresa',
              pictures: {
                title: 'Logo',
                cover: 'Portada',
                button: 'Subir archivo',
                dragText: 'Arrastrar imagen',
              },
              category: {
                title: 'Categoría de la empresa',
                description:
                  'Seleccione hasta diez (10) categorías y diez (10) subcategorías por cada categoría.',
              },
              name: 'Nombre de la empresa',
              phone: 'Teléfono',
              email: 'E-mail',
              url: 'URL del sitio web',
              country: 'País de la sede principal',
              state: 'Estado',
              city: 'Ciudad',
              branchOffice: '¿Tienes sucursales?',
              description: {
                title: 'Descripción ',
                placeholder: 'p.e: Somos una agencia de modelos...',
              },
              notifs: {
                logo: 'Por favor, sube el logo de tu empresa',
                noCategories:
                  'Por favor, seleccione por lo menos una categoría',
              },
            },
            selectAdmin: {
              title: 'Miembros de la empresa',
              titleDescription:
                'Selecciona a los administradores y editores de tu empresa.',
              addAdmin: 'Administrador',
              addAdminDescription:
                'Miembros que pueden editar la información de tu empresa, agregar a otros administradores y publicar contenido en su nombre.',
              addEditor: 'Editor',
              addEditorDescription: 'Miembros que pueden publicar contenido.',
              emptyAdmins:
                'Aún no hay administradores. Agrega uno para continuar.',

              emptyEditors: 'Aún no hay editores. Agrega uno para continuar.',
              adminListTitle: 'Administradores: ',
              editorListTitle: 'Editores: ',
              searchUsersPlaceholder: 'Buscar usuarios',
              searchUsersNoResults: 'No se encontraron resultados',
              searchUsersLoading: 'Cargando...',
              ownerAlreadyExists: 'Este usuario es el propietario',
              adminAlreadyExists: 'Este usuario ya es administrador',
              editorAlreadyExists: 'Este usuario ya es editor',
            },
            fan: {
              title: 'Información personal',
              picture: {
                title: 'Foto de perfil',
              },
              gender: {
                title: 'Género',
                options: {
                  default: 'Selecciona',
                  female: 'Femenino',
                  male: 'Masculino',
                  preferNotToSay: 'Prefiero no decirlo',
                  other: 'Otro',
                },
              },
              birthdate: 'Fecha de nacimiento',
              description: {
                title: 'Descripción',
                description: 'p.e: He sido modelo por 5 años...',
              },
              birthLocation: {
                title: '¿Dónde naciste?',
                country: 'País',
                state: 'Estado (Opcional)',
                city: 'Ciudad (Opcional)',
                select: 'Seleccione',
              },
              currentLocation: {
                title: '¿Dónde vives actualmente?',
                country: 'País',
                state: 'Estado',
                city: 'Ciudad',
                select: 'Seleccione',
              },
              phone: 'Teléfono (Opcional)',
            },
            talentCategory: {
              title: 'Categoría del talento',
              subtitle: '',
              description:
                'Seleccione hasta diez (10) categorías y diez (10) subcategorías por cada categoría. Si no se selecciona ninguna, serás considerado un fanánico, y solo podrás ver contenido y participar en actividades sociales.',
              selected: 'Actividades seleccionadas',
              categories: 'Categorías',
              subcategories: 'Subcategorías',
              noSubcategories: 'No hay subcategorías disponibles',
              categoryLimit: 'Solo puedes seleccionar hasta 10 categorías',
              subcategoryLimit:
                'Solo puedes seleccionar hasta 10 subcategorías',
            },
            personal: {
              title: 'Información personal',
              picture: {
                profilePictureTitle: 'Foto de perfil',
                coverTitle: 'Portada (Opcional)',
                requirements: 'Requisitos',
                description: [
                  'Tu foto de perfil debe ser un headshot con poco o sin maquillaje y ningún obstáculo bloqueando tus rasgos faciales.',
                  'Asegúrate de que tu cara ocupe por lo menos un 60% del cuadro y de que estés mirando a la cámara.',
                  'Elije un fondo que no sea una distracción.',
                  'Debes ser la única persona que aparezca en la foto.',
                  'La foto debe ser de muy buena calidad, es decir, debe ser nítida y estar bien iluminada.',
                  'Si tu foto no cumple con estas condiciones, se te solicitará subir otra para poder continuar.',
                ],
                button: 'Subir archivo',
                uploadProfilePicture: 'Subir foto de perfil',
              },
              category: {
                title: 'Categoría del talento (max. 5)',
                options: ['Selecione', 'Modelo', 'Actor', 'Bailarín'],
              },
              birthDay: {
                title: 'Fecha de nacimiento',
              },
              gender: {
                title: 'Género',
                options: {
                  default: 'Selecciona',
                  female: 'Femenino',
                  male: 'Masculino',
                  preferNotToSay: 'Prefiero no decirlo',
                  other: 'Otro',
                },
              },
              agencyOrManager: {
                title: 'Tienes una agencia o un manager?',
                options: ['Seleccione', 'Agencia', 'Manager', 'Ninguno'],
              },
              description: {
                title: 'Descripción',
                description: 'p.e: He sido modelo por 5 años...',
              },
              birthLocation: {
                title: '¿Dónde naciste?',
                country: 'País',
                state: 'Estado (Opcional)',
                city: 'Ciudad (Opcional)',
                select: 'Seleccione',
              },
              currentLocation: {
                title: '¿Dónde vives?',
                country: 'País',
                state: 'Estado',
                city: 'Ciudad',
                select: 'Seleccione',
              },
              phone: 'Ingresa tu número telefónico (Opcional)',
              languages: 'Idiomas hablados',
            },
            specifications: {
              title: 'Especificaciones',
              description:
                'Información especifica relacionada con las categorías seleccionadas anteriormente.',
              noSpecsFoundDescription:
                'Parece que aún no existen especificaciones para las categorías seleccionadas.',
              noSpecsFound: 'No se encontraron especificaciones',
            },
            book: {
              title: 'Subir Book (Opcional)',
              subtitle: 'Publica tu primer book con 3 o más fotos.',
              pictureText: 'Subir book de fotografías',
              cover: 'Portada',
              category: {
                title: 'Categorías',
                options: ['Seleccione', 'Modelo', 'Actor', 'Bailarín'],
              },
              name: {
                title: 'Nombre del book',
                description: 'p.e: Verano del 2017',
              },
              bookDescription: {
                title: 'Descripción',
                description: 'p.e: Sesión de fotos del Verano del 2017',
              },
            },
            video: {
              title: 'Subir Video (Opcional)',
              subtitle: 'Publica tu primer video',
              category: {
                title: 'Categorías',
                options: ['Seleccione', 'Modelo', 'Actor', 'Bailarín'],
              },
              name: {
                title: 'Nombre del video',
                description: 'p.e: Promoción del Miami Fashion Week',
              },
              videoDescription: {
                title: 'Descripción',
                description: 'p.e: Pasarela de modelaje',
              },
              uploadVideo: 'Subir video',
              url: {
                title: 'URL',
                description: 'www.youtube/example-video.com',
                error: 'La URL no es válida.',
              },
              coverPhoto: {
                title: 'Portada del video',
                dragText: 'Subir portada',
              },
            },
            audio: {
              title: 'Audio (Opcional)',
              subtitle: 'Publica tu primer Audio',
              category: {
                title: 'Categorías',
                options: ['Seleccione', 'EP', 'LP', 'Audio en vivo'],
              },
              name: {
                title: 'Nombre del audio',
                description: 'p.e: Tarzán',
              },
              albumDescription: {
                title: 'Descripción',
                description: 'p.e: Voice Over para una película animada',
              },
              coverPhoto: {
                title: 'Portada del Audio',
                dragText: 'Subir portada',
              },
              tracks: {
                title: 'Pistas',
                description: 'Agrega las pistas de tu audio',
                trackName: {
                  title: 'Nombre de la pista',
                  description: 'p.e.: Escena 1',
                },
                trackUrl: {
                  title: 'URL',
                },
                uploadTrack: 'Subir pista',
              },
            },
            workReference: {
              title: 'Información sobre Referencia de Trabajo (opcional)',
              subtitle:
                'Información especifica relacionada a las categorías seleccionadas anteriormente.',
              position: {
                title: 'Posición / Rol',
                description: 'Modelo',
              },
              privacy: {
                title: 'Privacidad',
                values: {
                  default: 'Seleccionar',
                  public: 'Público',
                  private: 'Privado',
                  'followers-connections': 'Seguidores y Conexiones',
                  connections: 'Conexiones',
                },
              },
              dates: {
                from: 'Desde',
                until: 'Hasta',
              },
              company: {
                searchCompaniesPlaceholder:
                  'Buscar empresas por nombre o correo electrónico',
                searchCompaniesNoResults: 'No se encontraron resultados',
                searchCompaniesLoading: 'Cargando...',
                title: 'Empresa',
              },
              newCompany: {
                companyName: {
                  title: 'Nombre de la empresa',
                  description: 'Jk Stars Company',
                },
                newCompany: 'Mi empresa no se encuentra registrada en Dinbog',
              },
              workDescription: {
                title: 'Descripción',
                description: 'Participé en +10 pasarelas',
              },
            },
            confirmation: {
              title: '¡Hurra!',
              subtitle:
                'Nos emociona tenerte en Dinbog. Estás a solo un click de ser descubierto.',
              text: 'Mantén tu perfil actualizado con Portafolios. Trabaja para ser notado.',
              button: 'Ver Perfil',
              invite: {
                title: 'Invita a tus amigos',
                description:
                  'Invita a tus amigos de otras redes sociales a ser parte de la comunidad de Dinbog',
              },
            },

            notifs: {
              userType:
                'Por favor, selecciona por lo menos una opción en Categoría del Talento',
              birthDay: 'Por favor, ingresa tu fecha de nacimiento',
              gender: 'Por favor, ingresa tu género',
              shortPhone:
                'El número telefónico que ingresaste debe tener al menos 7 dígitos',
              agencyOrManager:
                'Por favor, indica si tienes una agencia o un manager',
              description: 'Por favor, escribe una descripción sobre ti',
              birthCountry: 'Por favor, selecciona tu país de nacimiento',
              currentCountry: 'Por favor, selecciona tu país de residencia',
              currentCity: 'Por favor, selecciona tu ciudad de residencia',
              talentCategory: 'Por favor, selecciona una categoría',
              profilePicture: 'Por favor, sube una foto de perfil',
              bookName: 'Por favor, ingresa un nombre para tu book',
              bookDescription:
                'Por favor, ingresa una descripción para tu book',
              bookLength: 'Tu book debe tener entre 3 y 10 imágenes',
              videoName: 'Por favor, ingresa un nombre para tu video',
              videoDescription:
                'Por favor, ingresa una descripción para tu video',
              videoUrl: 'Por favor, ingresa el URL de tu video',
              audioName: 'Por favor, ingresa un nombre para tu audio',
              audioDescription:
                'Por favor, ingresa una descripción para tu audio',
              trackName: 'Por favor, ingresa el nombre de la canción',
              trackUrlEmpty: 'Por favor, ingresa el URL de la canción',
              trackUrlInvalid: 'Por favor, ingresa un URL válido',
              workReferenceRole: 'Por favor, ingresa tu posición / rol',
              workReferenceFrom:
                'Por favor, ingresa la fecha en la que comenzaste a trabajar en esta posición',
              workReferenceUntil:
                'Por favor, ingresa la fecha en la que dejaste de trabajar en esta posición',
              workReferenceFromGreaterThanUntil:
                'La fecha "Desde" debe ser previa a la fecha "Hasta"',
              workReferenceCompany:
                'Por favor, ingresa el nombre de la empresa para la que trabajaste',
              workReferenceDescription:
                'Por favor, ingresa una descripción de tu trabajo',
            },
          },
        },
      },
      profile: {
        editBtn: 'Editar perfil',
        private: {
          title: 'Este perfil es privado',
          description:
            'Para ver este perfil, debes enviar una solicitud de conexión.',
        },
        block: {
          me: 'No puedes ver la información de este perfil',
          them: 'Has bloqueado a este perfil',
        },
        generalInfo: {
          gender: 'Género',
          birthDate: 'Fecha de nacimiento',
          languages: 'Idiomas',
          birthCountry: 'País de nacimiento',
          birthState: 'Estado de nacimiento',
          birthCity: 'Ciudad de nacimiento',
        },
        relations: {
          followers: 'Seguidores',
          following: 'Seguidos',
          connections: 'Conexiones',
          actions: {
            follow: 'Seguir',
            followBack: 'Seguir de vuelta',
            unfollow: 'Dejar de seguir',
            connect: 'Conectar',
            disconnect: 'Desconectar',
            unblock: 'Desbloquear',
            requested: 'Solicitado',
            fanRestriction:
              'Esta acción solo está disponible para talentos y empresas',
          },
          requested: 'Solicitado',
        },
        infoTabs: {
          about: 'Sobre mi',
          aboutUs: 'Sobre nosotros',
          specifications: 'Especificaciones',
          recommendations: 'Recomendaciones',
        },
        profileCompletion: {
          cta: '¡Sigue completando tu perfil!',
          profileCompletion: 'Completación del perfil',
          steps: {
            personalInfo: 'Información personal',
            specs: 'Especificaciones',
            book: 'Sube un book',
            video: 'Sube un video',
            album: 'Sube un audio',
            workReference: 'Sube una referencia de trabajo',
          },
        },
        deleteMedia: {
          title: '¿Estás seguro de que deseas borrar esto?',
          description:
            'Este contenido será eliminado permanentemente de tu perfil',
          keep: 'No, conservar',
          delete: 'Sí, eliminar',
        },
        tabs: {
          returnBtn: 'Regresar a ',
          book: {
            title: 'Books',
            uploadBtn: 'Subir book',
            update: {
              success: 'Book actualizado exitosamente',
            },
            delete: {
              success: 'Book eliminado exitosamente',
            },
          },
          video: {
            title: 'Videos',
            uploadButton: 'Subir video',
            update: {
              success: 'Video actualizado exitosamente',
            },
            delete: {
              success: 'Video eliminado exitosamente',
            },
          },
          audio: {
            title: 'Audios',
            uploadButton: 'Subir audio',
            update: {
              success: 'Audio actualizado exitosamente',
            },
            delete: {
              success: 'Audio eliminado exitosamente',
            },
          },
          workReference: {
            title: 'Trabajos',
            uploadButton: 'Subir CV',
            update: {
              success: 'Trabajo actualizado exitosamente',
            },
            delete: {
              success: 'Trabajo eliminado exitosamente',
            },
            present: 'Presente',
            status: {
              name: 'Status',
              approved: 'Aprobado',
              pending: 'Pendiente',
              rejected: 'Rechazado',
              removed: 'Eliminado',
            },
          },
          talents: {
            title: 'Talentos',
            pastTalents: 'Talentos pasados',
            requests: {
              title: 'Solicitudes',
              sent: 'Enviados',
              received: 'Recibidos',
            },
          },
          // CATEGORY FILTER IN ANY PORTFOLIO TAB
          category: {
            placeholder: 'Seleccionar categoría',
          },
        },
        changePictureModal: {
          title: 'Cambiar foto de perfil',
        },
        changeCoverModal: {
          title: 'Cambiar foto de portada',
        },
        otherActions: {
          share: 'Compartir',
          block: 'Bloquear',
          report: 'Reportar',
        },
      },
      confirmationModal: {
        requestSubmitted: 'Tu solicitud ha sido enviada',
        checkEmail: 'Revisa tu correo electrónico y sigue las instrucciones',
      },
      inviteUserModal: {
        title: 'Invita a un usuario',
        description:
          'Envía por correo una invitación a un usuario para que se una a Dinbog',
        invite: 'Enviar invitación',
        error: 'Ha ocurrido un error al enviar la invitación',
        success: 'La invitación ha sido enviada exitosamente',
      },
      portfolio: {
        privacy: {
          title: 'Privacidad',
          values: {
            default: 'Seleccionar',
            public: 'Público',
            private: 'Privado',
            'followers-connections': 'Seguidores y Conexiones',
            connections: 'Conexiones',
          },
        },
      },
      post: {
        addAttachments: 'Adjuntar archivos',
        postBtn: 'Publicar',
        addLocation: 'Agregar locación',
        tagPeople: 'Etiquetar personas',
        noConnections: 'No tienes conexiones para etiquetar',
        placeholder: 'Escribe algo...',
        invalidFileType:
          'Tipo de archivo inválido. Solo se permiten imágenes, videos y audios.',
        notifs: {
          attachSomething: 'Adjunta algo a tu publicación',
        },
      },
      block: {
        title: '¿Estás seguro de que deseas bloquear a',
        text: 'No podrás interactuar de ninguna forma con este perfil',
        noBtn: 'No, volver',
        yesBtn: 'Sí, bloquear',
        notif: 'Este perfil ha sido bloqueado',
      },
      deleteMember: {
        title: '¿Estás seguro de que deseas eliminar al miembro',
        text: 'El usuario no podrá modificar el perfil de la empresa',
        noBtn: 'No, volver',
        yesBtn: 'Sí, eliminar',
        notif: 'Este perfil ha sido eliminado',
      },
      unblock: {
        word: 'Desbloquear',
        title: '¿Estás seguro de que deseas desbloquear a',
        text: 'Podrás interactuar de nuevo con este perfil',
        noBtn: 'No, volver',
        yesBtn: 'Sí, desbloquear',
        notif: 'Este perfil ha sido desbloqueado',
      },
      report: {
        title: 'Reportar',
        inputs: {
          type: 'Tipo',
          details: 'Detalles',
          detailsPlaceholder: 'Agrega cualquier información relevante',
        },
        submitBtn: 'Enviar reporte',
        confirmationTitle: 'Tu reporte se ha enviado',
        confirmationText:
          'Nuestro equipo revisará tu reporte y tomará acciones de ser necesario',
        types: ['Inapropiado', 'Spam', 'Otro'],
      },
      connect: {
        title: 'Solicitud de conexión',
        input: 'Mensaje (Opcional)',
        submitBtn: 'Enviar solicitud',
        confirmationTitle: 'Tu solicitud se ha enviado',
        confirmationText:
          'Este perfil recibirá tu solicitud. Tendrás que esperar por su aprobación',
      },
      follow: {
        follow: 'Seguir',
        error: 'Ocurrió un error al seguir a este usuario',
        unfError: 'Ocurrió un error al dejar de seguir a este usuario',
      },
      location: {
        setLocation: 'Cambiar locación',
        country: 'País',
        state: 'Estado',
        city: 'Ciudad',
      },
      emptyStates: {
        noTalents: {
          title: 'Todavía no hay talentos asociados',
          text: 'Comienza a invitar talentos a que te agreguen a su CV. Aparecerán en esta pestaña',
        },
        noPastTalents: {
          title: 'Todavía no hay talentos pasados',
          text: 'Comienza a invitar talentos a que te agreguen a su CV. Los que hayan trabajado para esta empresa en el pasado aparecerán en esta sección',
        },
        noCVRequests: {
          title: 'Todavía no hay solicitudes',
          text: 'Comienza a invitar talentos a que te agreguen a su CV. Sus solicitudes aparecerán en esta sección',
        },
        noPosts: {
          self: {
            title: '¡Parece que aún no hay nada por aquí!',
            text: '¿Por qué no intentas publicar algo?',
          },
          other: {
            title: 'No hay posts',
          },
        },
        noBooks: {
          self: {
            title: '¡Parece que aún no hay nada por aquí!',
            text: '¿Por qué no intentas subir un book?',
          },
          other: {
            title: 'No hay books',
          },
        },
        noVideos: {
          self: {
            title: '¡Parece que aún no hay nada por aquí!',
            text: '¿Por qué no intentas subir un video?',
          },
          other: {
            title: 'No hay videos',
          },
        },
        noAudios: {
          self: {
            title: '¡Parece que aún no hay nada por aquí!',
            text: '¿Por qué no intentas subir un audio?',
          },
          other: {
            title: 'No hay audios',
          },
        },
        noWorks: {
          self: {
            title: '¡Parece que aún no hay nada por aquí!',
            text: '¿Por qué no intentas añadir algún trabajo?',
          },
          other: {
            title: 'No hay trabajos',
          },
        },
        noResults: {
          title: 'No pudimos encontrar resultados parar tu búsqueda',
          text: 'Intenta usar otra frase o cambiar tus filtros',
        },
        noRequests: {
          title: '¡No hay solicitudes pendientes!',
          text: '¿Por qué no intentas añadir gente nueva?',
        },
        noVacancies: {
          self: {
            title: '¡Parece que aún no hay nada por aquí!',
            text: '¿Por qué no intentas publicar una vacante?',
          },
          other: {
            title: 'No hay vacantes',
          },
        },
        events: {
          discover: 'No hay vacantes disponibles por el momento',
          enrolled: 'No te has postulado para ninguna vacante',
          preselected:
            'No has sido preseleccionado para ninguna vacante todavía',
          selected: 'No has sido seleccionado para ninguna vacante todavía',
          myCastings: {
            title: 'No has creado ningún casting todavía',
            text: '¿Por qué no intentas crear uno?',
          },
          asOwner: {
            enrolled: 'No hay talentos pendientes por revisar en esta etapa',
            preselected: 'No hay talentos pendientes por revisar en esta etapa',
            selected: 'No hay talentos pendientes por revisar en esta etapa',
            rejected: 'No hay talentos pendientes por revisar en esta etapa',
          },
        },
      },
      searchPage: {
        searchFilters: 'Filtros de búsqueda',
        resultsFor: 'Resultados de búsqueda para',
        reset: 'Reiniciar',
        categorySearch: 'Categoria',
      },
      requests: {
        title: 'Solicitudes',
        sent: 'Solicitudes enviadas',
        received: 'Solicitudes recibidas',
        followReq: 'te envió una solicitud de seguimiento',
        connectionReq: 'te envió una solicitud de conexión',
        isError: 'Ha ocurrido un error al cargar las solicitudes',
        sentReqs: {
          followReq: 'Enviaste una solicitud de seguimiento a',
          connectionReq: 'Enviaste una solicitud de conexión a',
        },
      },
      notifications: {
        title: 'Notificaciones',
        tab: 'Todos',
        isError: 'Ha ocurrido un error al cargar las notificaciones',
      },
      timeAgo: {
        hours: 'hace {{hours}} horas',
      },
      events: {
        casting: {
          deleteModal: {
            title: '¿Estás seguro de que deseas eliminar este casting?',
            description: 'Esta acción no se puede deshacer',
          },
          tabs: {
            discover: 'Descubre',
            enrolled: 'Postulado',
            preselected: 'Preseleccionado',
            selected: 'Seleccionado',
            rejected: 'Rechazado',
            myCastings: 'Mis castings',
          },
          create: 'Crear casting',
          edit: 'Editar casting',
          closesOn: 'Cierra el',
          publishedBy: 'Publicado por',
          about: 'Sobre este casting',
          formFields: {
            name: 'Nombre',
            publicationDate: 'Fecha y hora de publicación',
            startDate: 'Fecha y hora de inicio',
            endDate: 'Fecha y hora de finalización',
          },
          vacancies: {
            vacancy: 'Vacante',
            addBtn: 'Agregar vacante',
            specifications: 'Especificaciones del talento',
            specsCTA: 'Agrega especificaiones para este talento',
            about: 'Sobre esta vacante',
            delete: {
              title: '¿Estás seguro de que deseas eliminar esta vacante?',
              text: 'Esta acción no se puede deshacer',
              noBtn: 'No, volver',
              yesBtn: 'Sí, eliminar',
              success: 'Vacante eliminada con éxito',
            },
            draft: 'Borrador',
            selectCategory:
              'Selecciona una categoría para ver sus subcategorías correspondientes',
            categoriesAndSubcategories: 'Categorías y subcategorías',
            preferences: 'Preferencias',
            tabs: {
              info: 'Información',
              vacancies: 'Vacantes',
              otherVacancies: 'Otras vacantes',
            },
            formFields: {
              name: 'Nombre',
              status: 'Estatus',
              categories: 'Categorías',
              subcategories: 'Subcategorías',
              description: 'Descripción',
              specifications: 'Especificaciones',
            },
            status: {
              active: 'Activa',
              draft: 'Borrador',
              closed: 'Cerrada',
            },
            notifs: {
              successfulCreation: '¡Vacante creada con éxito!',
              successfulUpdate: '¡Vacante actualizada con éxito!',
            },
          },
          notifs: {
            successfulCreation: '¡Casting creado con éxito!',
            successfulUpdate: '¡Casting actualizado con éxito!',
          },
        },
        description: 'Descripción',
        recommended: 'Recomendados',
        showMore: 'Ver más',
        startDate: 'Fecha de inicio',
        startTime: 'Hora de inicio',
        endDate: 'Fecha de finalización',
        endTime: 'Hora de finalización',
        publishTime: 'Hora de publicación',
        location: 'Ubicación',
        fullAddress: 'Dirección completa',
        details: 'Ver detalles',
        castingDetails: 'Detalles del casting',
        apply: 'Postular',
        delete: 'Eliminar casting',
        states: {
          active: 'Activo',
          closed: 'Cerrado',
          upcoming: 'Próximo',
        },
      },
    },
  },

  en: {
    translation: {
      yes: 'Yes',
      all: 'See all',
      less: 'See less',
      accept: 'Accept',
      cancel: 'Cancel',
      decline: 'Decline',
      apply: 'Apply',
      reject: 'Reject',
      withdraw: 'Withdraw',
      required: 'Required',
      select: 'Select',
      search: 'Search',
      edit: 'Edit',
      delete: 'Delete',
      continue: 'Continue',
      crop: 'Crop',
      invalidEmail: 'Invalid email',
      invalidAge: 'You must be at least 14 years old',
      invalidDate: 'Invalid date',
      uploadImage: 'Upload picture',
      uploadFile: 'Upload file',
      return: 'Return',
      save: 'Save',
      tag: 'Tag',
      options: 'Options',
      successfulEmail: 'Email sent successfully!',
      failedEmail: 'There was an error sending the email.',
      readMore: 'Read more',
      readLess: 'Read less',
      play: 'Play',
      pause: 'Pause',
      update: 'Update',
      create: 'Create',
      castings: 'Castings',
      failedImageUpload: 'Error uploading file',
      successImageUpload: 'Image uploaded successfully',
      about: 'About',
      optional: 'Optional',
      actor: 'Actors',
      model: 'Model',
      comment: {
        placeholder: 'Add a comment...',
        send: 'Comment',
        plural: 'Comments',
        singular: 'Comment',
        viewAll: 'View all',
        noComments: 'Be the first one to comment!',
        error: 'There was an error posting your comment',
        whitespace: 'Comment cannot be only whitespace',
      },
      stats: {
        likes: 'Likes',
        comments: 'Comments',
        reports: 'Reports',
      },
      error: 'There has been an error.',
      copyToClipboard: 'Link copied to clipboard!',
      pageError: {
        title: "Oops! We couldn't find the page you requested",
        text: 'Return to your feed',
      },
      feed: {
        suggestions: {
          talents: 'Suggested talents for you',
          businesses: 'Suggested businesses for you',
          seeMore: 'Show more recommendations',
          seeAll: 'Discover people',
          seeLess: 'See less recommendations',
          noUsersFound: "We couldn't find any users",
        },
      },
      settings: {
        profile: 'Profile',
        account: 'Account',
        noBlockedProfilesFound: 'No blocked profiles found',
        noBlockedProfilesFoundDescription:
          'Profiles you block will appear here',
        disableAccountModal: {
          title: 'Are you sure you want to deactivate your account?',
          description:
            'This action cannot be undone. You will not be able to log in again with this account.',
          email: 'Enter your email to confirm',
          yesBtn: 'Yes, deactivate',
          noBtn: 'No, go back',
        },
        changeEmail: 'Change email',
        oldPassword: 'Current password',
        oldPasswordPlaceholder: 'Enter your current password',
        changePassword: 'Change password',
        deactivateAccount: 'Deactivate account',
        deactivateAccountDescription: 'This action cannot be undone.',
        deactivateAccountConfirmModal:
          'Are you sure you want to deactivate your account?',
        deactivateAccountConfirm: 'Yes, deactivate',
        deactivateAccountSuccess: 'Account deactivated successfully!',
        deactivateAccountError: 'There was an error deactivating the account.',
        newEmail: 'New email',
        newEmailPlaceholder: 'Enter your new email',
        password: 'Password',
        passwordPlaceholder: 'Enter your password',
        categories: 'Categories',
        specifications: 'Specifications',
        administrators: 'Administrators',
        security: 'Security',
        saved: 'Saved',
        blockedProfiles: 'Blocked profiles',
        preferences: 'Preferences',
        categoriesNotifs: {
          noCategoriesBusiness:
            'Businesses have to select at least one category',
        },
        appLanguage: {
          title: 'App language',
          default: 'Select your default language',
        },
        notifs: {
          success: 'Settings updated successfully!',
          error: 'There was an error updating the settings.',
        },
        changeRoleConfirm: 'Yes, change role',
        changeRoleToFan: {
          title: 'Change role to fan',
          description:
            "Are you sure you want to change your role to fan? \nFans don't have categories or specifications, they also can't connect with other talents or participate in Castings. You can change your role back to talent at any time.",
        },
        changeRoleToTalent: {
          title: 'Change role to talent',
          description:
            'Are you sure you want to change your role to talent? \nTalents can connect with other talents, participate in Castings and have categories and specifications.',
        },
      },
      navbar: {
        logged: {
          briefcaseDropdown: {
            castings: 'Castings',
            events: 'Events',
            ads: 'Ads',
            contests: 'Contests',
          },
          requestDropdown: {
            requests: 'Requests',
            find: 'Find Connection',
            manage: 'Manage all',
          },
          notificationsDropdown: {
            markAll: 'Mark all as read',
            notifications: 'Notifications',
          },
          userDropdown: {
            profile: 'Profile',
            switchAccounts: 'Switch account',
            createBusinessPage: 'Create business page',
            settings: 'Settings',
            logOut: 'Log out',
          },
          switchAccount: {
            title: 'Choose an account',
            current: 'Current',
            create: 'Create business page',
          },
        },
        unLogged: {
          login: 'Sign in',
          signup: 'Register',
        },
        onRegister: {
          button: 'Log Out',
        },
      },
      footer: {},
      home: {},
      auth: {
        text: {
          title: 'Social talent marketplace',
          subtitle: 'Boost the creative industry and the orange economy',
        },
        passwordRecovery: {
          title: 'Recover password',
          inputLabel: 'Enter your email to receive a recovery code',
          requestLinkBtn: 'Request recovery code',
          codeInputLabel: 'Enter the 6 digit code sent to your email',
          requestNewCodeBtn: 'Request new code',
          wrongCode: 'Wrong code',
          resetPasswordBtn: 'Reset password',
          newPassword: 'New password',
          confirmNewPassword: 'Confirm new password',
          createPassword: 'Create a new password',
          setNewPassword: 'Set a new password for your Dinbog account',
          success: 'Password updated successfully!',
          timeLeft: 'Time left',
        },
        signIn: {
          title: 'Welcome back!',
          or: 'or',
          email: 'Email',
          password: 'Password',
          forgot: 'Forgot your password?',
          enter: 'Log In',
          noAccount: "Don't have an account already? Sign up",
          notifs: {
            email: 'Please, enter a valid email',
            password: 'Please, enter a valid password',
            success: 'You signed in successfully!',
            failure: 'There has been an error.',
          },
        },
        signUp: {
          title: 'You are a click away from being discovered',
          or: 'or',
          registerAs: 'Register as',
          type: ['Register as...', 'Talent', 'Business'],
          firstName: 'First name',
          name: 'Name',
          lastName: 'Last name',
          email: 'Email',
          companyEmail: 'Company email',
          password: 'Password',
          confirmPassword: 'Confirm password',
          hasAccount: 'Already have an account? Sign in',
          hasPersonAccount: 'I already have a personal account',
          personalInfo: 'Personal information',
          businessInfo: 'Business information',
          companyName: 'Company name',
          submit: 'Register',
          addBusiness: {
            title: 'Sign up as a business',
            text: 'With this profile you can create castings, upload your content and contact talents and other companies',
          },
          terms: {
            text: 'I have read and accept the ',
            link: 'Terms and Conditions',
            childrenLink:
              'Terms and Conditions for Users Between 14 and 17 Years Old',
            modalTitle: 'Terms and Conditions',
            modalTitleChildrens:
              'Terms and Conditions for Users Between 14 and 17 Years Old',
          },
          activate: {
            title: 'Activate your account',
            description:
              'We have sent you an email. Please check your inbox and click on the verification link.',
            noEmail: 'You have not received the email? Check spam folder.',
            resend: 'Resend email',
            later: 'Verify later',
            success: {
              title: 'Account activated!',
              subtitle:
                'Your account has been activated. You can now sign in and complete your profile.',
              btn: 'Sign in',
            },
          },
          notifs: {
            userType: 'Please, select how you want to register as',
            firstName: 'Please, enter your first name',
            lastName: 'Please, enter your last name',
            email: 'Please, enter a valid email',
            password:
              'Please, enter a valid password. It must have at least 8 characters, an uppercase letter, a lowercase letter, a number and a special character',
            confirmPassword: 'Please, confirm your password',
            differentPasswordValues:
              'The Password Confirmation entered does not match the value entered for Password',
            passwordRequirements:
              'The Password must have at least 8 characters, an uppercase letter, a lowercase letter, a number and a special character',
            terms: 'You must accept the Terms and Conditions to continue',
            success: 'You signed up successfully!',
            failure: 'There has been an error.',
          },
          wizard: {
            title: "Let's get started with your profile",
            businessTitle: "Let's set up your business page",
            prevBtn: 'Previous',
            nextBtn: 'Next',
            skipBtn: 'Skip this step',
            finishBtn: 'Finish',
            privacy: {
              title: 'Privacy',
              description: 'This content will only be visible by your friends',
            },
            categories: {
              title: 'Select',
              error: 'Please select at least one category',
            },

            business: {
              title: 'Business information',
              pictures: {
                title: 'Logo',
                cover: 'Cover',
                button: 'Upload File',
                dragText: 'Drag Image',
              },
              category: {
                title: 'Business Category',
                description:
                  'Select up to ten (10) categories and ten (10) subcategories for each category.',
              },
              name: 'Company name',
              phone: 'Phone number',
              url: 'Website url',
              country: 'Headquater',
              state: 'State',
              city: 'City',
              branchOffice: 'Branch Office',
              description: {
                title: 'Description',
                placeholder: 'Write a description about your company',
              },
              notifs: {
                logo: 'Please, upload your company logo',
                noCategories: 'Please, select at least one category',
              },
            },
            selectAdmin: {
              title: 'Business Members',
              titleDescription:
                'Select the administrators and editors of your business.',
              addAdmin: 'Administrator',
              addAdminDescription:
                'Members that can edit business information, add other administrators, accept members and post content.',
              addEditor: 'Editor',
              addEditorDescription: 'Members that can post content.',
              emptyAdmins:
                'There are no administrators yet. Add one to continue.',

              emptyEditors: 'There are no editors yet. Add one to continue.',
              adminListTitle: 'Administrators: ',
              editorListTitle: 'Editors: ',
              searchUsersPlaceholder: 'Search users',
              searchUsersNoResults: 'No results found',
              searchUsersLoading: 'Loading...',
              ownerAlreadyExists: 'This user is the owner',
              adminAlreadyExists: 'This user is already an administrator',
              editorAlreadyExists: 'This user is already an editor',
            },
            fan: {
              title: 'Personal information',
              picture: {
                title: 'Profile Image',
              },
              gender: {
                title: 'Gender',
                options: {
                  default: 'Select',
                  female: 'Female',
                  male: 'Male',
                  preferNotToSay: 'Prefer not to say',
                  other: 'Other',
                },
              },
              birthdate: 'Birth date',
              description: {
                title: 'Description',
                description: 'i.e: I have been a model for 5 years...',
              },
              birthLocation: {
                title: 'Where were you born?',
                country: 'Country',
                state: 'State (Optional)',
                city: 'City (Optional)',
                select: 'Select',
              },
              currentLocation: {
                title: 'Where do you live?',
                country: 'Country',
                state: 'State',
                city: 'City',
                select: 'Select',
              },
              phone: 'Phone number (Optional)',
            },
            talentCategory: {
              title: 'Talent Category',
              subtitle: '',
              description:
                'Select up to ten (10) categories and ten (10) subcategories for each category. If none is chosen, you will be considered a fan, and you will only be able to see content and participate on social activities.',
              selected: 'Selected activities',
              categories: 'Categories',
              subcategories: 'Subcategories',
              noSubcategories: 'There are no subcategories available',
              categoryLimit: 'You can only choose up to 10 categories',
              subcategoryLimit: 'You can only choose up to 10 subcategories',
            },
            personal: {
              title: 'Personal information',
              picture: {
                profilePictureTitle: 'Profile image',
                coverTitle: 'Cover image (Optional)',
                requirements: 'Requirements',
                description: [
                  'Your profile picture must be a headshot with little or no makeup and any obstacle blocking your facial traits.',
                  'Make sure your face takes up at least 60% of the frame and you are facing the camera.',
                  "Choose a background that isn't distracting.",
                  'You must be the only person that appears on the photo.',
                  'The photo must be of great quality, that is, it must be sharp and have good lighting.',
                  "If your photo doesn't meet these conditions, you will be requested to upload another one to continue.",
                ],
                button: 'Upload File',
                uploadProfilePicture: 'Upload profile picture',
              },
              category: {
                title: 'Talent Category (max. 5)',
                options: ['Select', 'Model', 'Actor', 'Dancer'],
              },
              birthDay: {
                title: 'Birth Date',
              },
              gender: {
                title: 'Gender',
                options: {
                  default: 'Select',
                  female: 'Female',
                  male: 'Male',
                  preferNotToSay: 'Prefer not to say',
                  other: 'Other',
                },
              },
              agencyOrManager: {
                title: 'Do you have an Agency or a Manager?',
                options: ['Select', 'Agency', 'Manager', 'Neither'],
              },
              description: {
                title: 'Description',
                description: 'i.e: I have been a model for 5 years...',
              },
              birthLocation: {
                title: 'Where were you born?',
                country: 'Country',
                state: 'State (Optional)',
                city: 'City (Optional)',
                select: 'Select',
              },
              currentLocation: {
                title: 'Where do you live?',
                country: 'Country',
                state: 'State',
                city: 'City',
                select: 'Select',
              },
              phone: 'Phone number (Optional)',
              languages: 'Spoken languages',
            },
            specifications: {
              title: 'Specifications',
              description:
                'Specific info related to the categories you previously selected.',
              noSpecsFoundDescription:
                'It seems that there are no specifications for the categories you previously selected.',
              noSpecsFound: 'No specifications found',
            },
            book: {
              title: 'Upload Book (Optional)',
              subtitle: 'Upload your first book with 3 photos or more.',
              pictureText: 'Upload picture book',
              cover: 'Cover',
              category: {
                title: 'Categories',
                options: ['Select', 'Model', 'Actor', 'Dancer'],
              },
              name: {
                title: 'Name of Book',
                description: 'i.e: Summer of 2017',
              },
              bookDescription: {
                title: 'Description',
                description: 'i.e: Photo shoot of Summer of 2017',
              },
            },
            video: {
              title: 'Upload Video (Optional)',
              subtitle: 'Upload your first video',
              uploadVideo: 'Upload video',
              category: {
                title: 'Categories',
                options: ['Select', 'Model', 'Actor', 'Dancer'],
              },
              name: {
                title: 'Name of Video',
                description: 'i.e: Miami Fashion Week Promo',
              },
              videoDescription: {
                title: 'Description',
                description: 'i.e: Model runway',
              },
              url: {
                title: 'URL',
                description: 'www.youtube/example-video.com',
                error: 'Invalid URL',
              },
              coverPhoto: {
                title: 'Video thumbnail',
                dragText: 'Upload cover',
              },
            },
            audio: {
              title: 'Audio (Optional)',
              subtitle: 'Upload your first Audio',
              category: {
                title: 'Categories',
                options: ['Select', 'EP', 'LP', 'Live audio'],
              },
              name: {
                title: 'Name of Audio',
                description: 'i.e: Tarzan',
              },
              albumDescription: {
                title: 'Description',
                description: 'i.e: Voice Over for an animation movie',
              },
              coverPhoto: {
                title: 'Audio Cover',
                dragText: 'Upload cover',
              },
              tracks: {
                title: 'Tracks',
                description: 'Add the tracks of your audio',
                trackName: {
                  title: 'Name of track',
                  description: 'i.e.: Scene 1',
                },
                trackUrl: {
                  title: 'URL',
                },
                uploadTrack: 'Upload track',
              },
            },
            workReference: {
              title: 'Add Work Reference (Optional)',
              subtitle:
                'Specific info related to the categories you previously selected.',
              position: {
                title: 'Position / Role',
                description: 'Model',
              },
              privacy: {
                title: 'Privacy',
                values: {
                  default: 'Select',
                  public: 'Public',
                  private: 'Private',
                  'followers-connections': 'Followers & Connections',
                  connections: 'Connections',
                },
              },

              dates: {
                from: 'From',
                until: 'Until',
                month: 'Month',
                day: 'Day',
                year: 'Year',
              },
              company: {
                title: 'Company',
                searchCompaniesPlaceholder: 'Search companies by name or email',
                searchCompaniesNoResults: 'No results found',
                searchCompaniesLoading: 'Loading...',
              },
              newCompany: {
                companyName: {
                  title: 'Name of Company',
                  description: 'Jk Stars Company',
                },
                newCompany: "My company isn't registered",
              },
              workDescription: {
                title: 'Description',
                description: 'Participated in +10 runways',
              },
              status: {
                approved: 'Aprobado',
                pending: 'Pendiente',
                rejected: 'Rechazado',
                removed: 'Eliminado',
              },
            },
            confirmation: {
              title: 'Hurray!',
              subtitle:
                "We are excited to have you on Dinbog. You're just a click away from being discovered.",
              text: 'Keep your profile updated with Books. Work to get noticed.',
              button: 'View Profile',
              invite: {
                title: 'Invite your friends',
                description:
                  'Invite your friends from other social networks to be part of the Dinbog community',
              },
            },
            notifs: {
              userType: 'Please, select at least one option in Talent Category',
              birthDay: 'Please, enter your birthday',
              gender: 'Please, enter your gender',
              shortPhone:
                'The phone number you entered should have at least 7 digits',
              agencyOrManager:
                'Please, indicate whether you have an agency or a manager',
              description: 'Please, write a description about yourself',
              birthCountry: 'Please, select your birth country',
              currentCountry:
                'Please, select the country you currently live at',
              currentCity: 'Please, select the city you currently live at',
              talentCategory: 'Please, select a Category',
              profilePicture: 'Please, upload a profile picture',
              bookName: 'Please, enter a name for your Book',
              bookDescription: 'Please, enter a description for your Book',
              bookLength: 'Your Book must have between 3 and 10 images',
              videoName: 'Please, enter a name for your video',
              videoDescription: 'Please, enter a description for your video',
              videoUrl: 'Please, provide the URL of your video',
              audioName: 'Please, enter a name for your audio',
              audioDescription: 'Please, enter a description for your audio',
              trackName: 'Please, enter a name for your track',
              trackUrlEmpty: 'Please, provide the URL of your track',
              trackUrlInvalid: 'Please, enter a Soundcloud or Mixcloud URL',
              trackUrlNotUrl: 'Please, enter a valid URL',
              workReferenceRole: 'Please, enter your position or role',
              workReferenceFrom:
                'Please, enter the date you started working on this position',
              workReferenceUntil:
                'Please, enter the date you stopped working on this position',
              workReferenceFromGreaterThanUntil:
                '"From" date must be previous to "Until" date',
              workReferenceCompany:
                'Please, enter the name of the company your worked at',
              workReferenceDescription:
                'Please, enter a description of your job',
            },
          },
        },
      },
      profile: {
        editBtn: 'Edit profile',
        private: {
          title: 'This account is private',
          description: 'Follow this account to see their full profile.',
        },
        block: {
          me: "You're not allowed to see this profile's infortmation",
          them: 'You blocked this profile',
        },
        generalInfo: {
          gender: 'Gender',
          birthDate: 'Birth date',
          languages: 'Languages',
          birthCountry: 'Birth country',
          birthState: 'Birth state',
          birthCity: 'Birth city',
        },
        relations: {
          followers: 'Followers',
          following: 'Following',
          connections: 'Connections',
          actions: {
            follow: 'Follow',
            followBack: 'Follow back',
            unfollow: 'Unfollow',
            connect: 'Connect',
            disconnect: 'Disconnect',
            unblock: 'Unblock',
            requested: 'Requested',
            fanRestriction:
              'This action is only available for talents and businesses',
          },
          requested: 'Requested',
        },
        infoTabs: {
          about: 'About me',
          aboutUs: 'About us',
          specifications: 'Specifications',
          recommendations: 'Recommendations',
        },
        profileCompletion: {
          cta: 'Keep completing your profile!',
          profileCompletion: 'Profile completion',
          steps: {
            personalInfo: 'Personal info',
            specs: 'Specifications',
            book: 'Upload a book',
            video: 'Upload a video',
            album: 'Upload an audio',
            workReference: 'Upload a work reference',
          },
        },
        deleteMedia: {
          title: 'Are you sure you want to delete this?',
          description:
            'This content will be permanently deleted from your profile',
          keep: 'No, keep',
          delete: 'Yes, delete',
        },
        tabs: {
          returnBtn: 'Back to ',
          book: {
            title: 'Books',
            uploadButton: 'Upload book',
            uploadBtn: 'Upload book',
            update: {
              success: 'Book updated successfully',
            },
            delete: {
              success: 'Book eliminado exitosamente',
            },
          },
          video: {
            title: 'Videos',
            uploadButton: 'Upload video',
            update: {
              success: 'Video updated successfully',
            },
            delete: {
              success: 'Video eliminado exitosamente',
            },
          },
          audio: {
            title: 'Audios',
            uploadButton: 'Upload audio',
            update: {
              success: 'Audio updated successfully',
            },
            delete: {
              success: 'Audio deleted successfully',
            },
          },
          workReference: {
            title: 'Work References',
            uploadButton: 'Upload CV',
            update: {
              success: 'Work reference updated successfully',
            },
            delete: {
              success: 'Work reference deleted successfully',
            },
            present: 'Present',
            status: {
              approved: 'Approved',
              pending: 'Pending',
              rejected: 'Rejected',
              removed: 'Removed',
            },
          },
          talents: {
            title: 'Talents',
            pastTalents: 'Past talents',
            requests: {
              title: 'Requests',
              sent: 'Sent',
              received: 'Received',
            },
          },
          // CATEGORY FILTER IN ANY PORTFOLIO TAB
          category: {
            placeholder: 'Select category',
          },
        },
        changePictureModal: {
          title: 'Change profile picture',
        },
        changeCoverModal: {
          title: 'Change cover',
        },
        otherActions: {
          share: 'Share',
          block: 'Block',
          report: 'Report',
        },
      },
      confirmationModal: {
        requestSubmitted: 'Your request has been submitted',
        checkEmail: 'Check your email and follow the instructions',
      },
      inviteUserModal: {
        title: 'Invite user',
        description:
          'Invite an user by email to be part of the Dinbog community',
        invite: 'Invite',
        error: 'An error has occurred',
        success: 'Invitation sent successfully',
      },
      portfolio: {
        privacy: {
          title: 'Privacy',
          values: {
            default: 'Select',
            public: 'Public',
            private: 'Private',
            'followers-connections': 'Followers & Connections',
            connections: 'Connections',
          },
        },
      },
      post: {
        addAttachments: 'Add attachments',
        postBtn: 'Post',
        addLocation: 'Add location',
        tagPeople: 'Tag people',
        noConnections: 'No connections for tagging',
        placeholder: 'Write something...',
        invalidFileType:
          'Invalid file type. Only images, videos, and audios are allowed.',
        notifs: {
          attachSomething: 'Attach something to your post',
        },
      },
      block: {
        title: 'Are you sure you want to block',
        text: "You won't be able to interact with this profile at all",
        noBtn: 'No, return',
        yesBtn: 'Yes, block',
        notif: 'This profile has been blocked',
      },
      deleteMember: {
        title: 'Are you sure you want to delete',
        text: 'This member will be permanently deleted from your business',
        noBtn: 'No, return',
        yesBtn: 'Yes, delete',
        notif: 'This member has been deleted',
      },
      unblock: {
        word: 'Unblock',
        title: 'Are you sure you want to unblock',
        text: 'You will be able to interact with this profile again',
        noBtn: 'No, return',
        yesBtn: 'Yes, unblock',
        notif: 'This profile has been unblocked',
      },
      report: {
        title: 'Report',
        inputs: {
          type: 'Type',
          details: 'Details',
          detailsPlaceholder: 'Add any relevant information',
        },
        submitBtn: 'Send report',
        confirmationTitle: 'Your report has been sent',
        confirmationText:
          'Our team will review your report and take action if necessary',

        types: ['Inappropriate', 'Spam', 'Other'],
      },
      connect: {
        title: 'Connection request',
        input: 'Message (Optional)',
        submitBtn: 'Send request',
        confirmationTitle: 'Your request has been sent',
        confirmationText:
          "This profile will receive your request. You'll have to wait for their acceptance",
      },
      follow: {
        follow: 'Follow',
        error: 'There was an error when following this user',
        unfError: 'There was an error when unfollowing this user',
      },
      location: {
        setLocation: 'Change location',
        country: 'Country',
        state: 'State',
        city: 'City',
      },
      emptyStates: {
        noTalents: {
          title: 'There are no associated talents yet',
          text: 'Start inviting talents to add your business to their CV. They will appear in this tab',
        },
        noPastTalents: {
          title: 'There are no past talents yet',
          text: 'Start inviting talents to add your business to their CV. The ones that have worked for this business in the past will appear in this section',
        },
        noCVRequests: {
          title: 'There are no requests yet',
          text: 'Start inviting talents to add your business to their CV. Their requests will appear in this section',
        },
        noPosts: {
          self: {
            title: "It seems like there's nothing here yet!",
            text: "Why don't you try to post something?",
          },
          other: {
            title: 'No posts',
          },
        },
        noBooks: {
          self: {
            title: "It seems like there's nothing here yet!",
            text: "Why don't you try to upload a book?",
          },
          other: {
            title: 'No books',
          },
        },
        noVideos: {
          self: {
            title: "It seems like there's nothing here yet!",
            text: "Why don't you try to upload a video?",
          },
          other: {
            title: 'No videos',
          },
        },
        noAudios: {
          self: {
            title: "It seems like there's nothing here yet!",
            text: "Why don't you try to upload an audio?",
          },
          other: {
            title: 'No audios',
          },
        },
        noWorks: {
          self: {
            title: "It seems like there's nothing here yet!",
            text: "Why don't you try to add a work reference?",
          },
          other: {
            title: 'No works',
          },
        },
        noResults: {
          title: "We couldn't find any results for what you're looking for",
          text: 'Try using another phrase or changing your filters',
        },
        noRequests: {
          title: 'There are no pending requests!',
          text: "Why don't you try adding new people?",
        },
        noVacancies: {
          self: {
            title: 'It seems there is nothing here yet!',
            text: 'Why not try posting a vacancy?',
          },
          other: {
            title: 'No vacancies',
          },
        },
        events: {
          discover: 'There are no vacancies available at the moment',
          enrolled: "You haven't applied for any vacancy yet",
          preselected: "You haven't been preselected for any vacancy yet",
          selected: "You haven't been selected for any vacancy yet",
          myCastings: {
            title: "You haven't created any castings yet",
            text: "Why don't you try to create one?",
          },
          asOwner: {
            enrolled: 'There are no pending talents to review at this stage',
            preselected: 'There are no pending talents to review at this stage',
            selected: 'There are no pending talents to review at this stage',
            rejected: 'There are no pending talents to review at this stage',
          },
        },
      },
      searchPage: {
        searchFilters: 'Search filters',
        resultsFor: 'Search results for',
        reset: 'Reset',
        categorySearch: 'Category',
      },
      requests: {
        title: 'Requests',
        sent: 'Requests sent',
        received: 'Requests received',
        followReq: 'sent you a follow request!',
        connectionReq: 'sent you a connection request',
        isError: 'There was an error loading the requests',
        sentReqs: {
          followReq: 'You sent a follow request to',
          connectionReq: 'You sent a connection request to',
        },
      },
      notifications: {
        title: 'Notifications',
        tab: 'All',
        isError: 'There was an error loading the notifications',
      },
      timeAgo: {
        hours: '{{hours}} hours ago',
      },
      events: {
        casting: {
          deleteModal: {
            title: 'Are you sure you want to delete this casting?',
            description: "This action can't be undone",
          },
          tabs: {
            discover: 'Discover',
            enrolled: 'Enrolled',
            preselected: 'Preselected',
            selected: 'Selected',
            rejected: 'Rejected',
            myCastings: 'My castings',
          },
          create: 'Create casting',
          edit: 'Edit casting',
          closesOn: 'Closes on',
          publishedBy: 'Published by',
          about: 'About this casting',
          formFields: {
            name: 'Name',
            publicationDate: 'Publication date and time',
            startDate: 'Start date and time',
            endDate: 'End date and time',
          },
          vacancies: {
            vacancy: 'Vacancy',
            addBtn: 'Add vacancy',
            specifications: 'Talent specifications',
            specsCTA: 'Add specifications to this talent',
            about: 'About this vacancy',
            delete: {
              title: 'Are you sure you want to delete this vacancy?',
              text: "This action can't be undone",
              noBtn: 'No, keep',
              yesBtn: 'Yes, delete',
              success: 'Vacancy deleted successfully',
            },
            draft: 'Draft',
            selectCategory:
              'Select a category to see its corresponding subcategories',
            categoriesAndSubcategories: 'Categories and subcategories',
            preferences: 'Preferences',
            tabs: {
              info: 'Info',
              vacancies: 'Vacancies',
              otherVacancies: 'Other vacancies',
            },
            formFields: {
              name: 'Name',
              status: 'Status',
              categories: 'Categories',
              subcategories: 'Subcategories',
              description: 'Description',
              specifications: 'Specifications',
            },
            status: {
              active: 'Active',
              draft: 'Draft',
              closed: 'Closed',
            },
            notifs: {
              successfulCreation: 'Vacancy created successfully!',
              successfulUpdate: 'Vacancy updated successfully!',
            },
          },
          notifs: {
            successfulCreation: 'Casting created successfully!',
            successfulUpdate: 'Casting updated successfully!',
          },
        },
        description: 'Description',
        recommended: 'Recommended',
        showMore: 'Show more',
        startDate: 'Start date',
        startTime: 'Start time',
        endDate: 'End date',
        endTime: 'End time',
        publishTime: 'Publish time',
        location: 'Location',
        fullAddress: 'Full address',
        details: 'See details',
        castingDetails: 'Casting details',
        apply: 'Postulate',
        delete: 'Delete casting',
        enrolled: 'Enrolled',
        states: {
          active: 'Active',
          closed: 'Closed',
          upcoming: 'Upcoming',
        },
      },
    },
  },
};

i18next.use(initReactI18next).init({
  resources,
  // lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
