import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt } from '@fortawesome/free-solid-svg-icons';
import { CropImageModal, FaceRecInfo } from '../../../shared';
import { useNotify } from '../../../../hooks';
import { useSignS3 } from '../../../../hooks/api/s3';
import { useVerifyPhoto } from '../../../../hooks/api/users';
import { uploadFile } from '../../../../lib/uploadFile';

interface PersonalInfoPicturesProps {
  profileImage;
  updateProfileURLs;
  coverImage;
  updateCoverURLs;
}

export default function PersonalInfoPictures({
  profileImage,
  updateProfileURLs,
  coverImage,
  updateCoverURLs,
}: PersonalInfoPicturesProps) {
  const { t } = useTranslation();
  const notify = useNotify();
  const [uploading, setUploading] = React.useState(false);
  const createMutation = useSignS3();
  const [currentImage, setCurrentImage] = React.useState<'profile' | 'cover'>(
    'profile'
  );
  const [photoModalIsOpen, setPhotoModalIsOpen] = React.useState(false);
  const [cropProps, setCropProps] = React.useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    size: { width: currentImage === 'profile' ? 100 : 300, height: 100 },
  });

  const {
    data: dataVerifyPhoto,
    isLoading: loadingVerifyPhoto,
    error: errorVerifyPhoto,
    refetch: refetchVerifyPhoto,
  } = useVerifyPhoto(
    {
      key: profileImage[0]?.src?.toString().split('.com/')[1],
    },
    { enabled: false, retry: false }
  );

  const verifyPhoto = async () => {
    try {
      const response = await refetchVerifyPhoto();
      if (response.status === 'success') {
        setPhotoModalIsOpen(false);
      }
      if (response.status === 'error') {
        return notify((response.error.response.data as any).message, 'error');
      }
    } catch (err) {
      return notify(t('error'), 'error');
    }
  };

  const handleSetImage = (type) => {
    setCurrentImage(type);
    setCropProps((prev) => ({
      ...prev,
      size: { width: type === 'profile' ? 100 : 300, height: 100 },
    }));
    setPhotoModalIsOpen(true);
  };

  const onSubmit = async () => {
    await uploadFile(
      currentImage === 'profile' ? profileImage[0] : coverImage[0],
      setUploading,
      currentImage === 'profile' ? updateProfileURLs : updateCoverURLs,
      createMutation,
      notify,
      t
    );
    if (currentImage === 'cover') {
      setPhotoModalIsOpen(false);
    }
  };

  React.useEffect(() => {
    if (
      profileImage?.length > 0 &&
      (profileImage[0]?.src as string)?.startsWith('https://') /*  &&
      profileImage[0]?.src !== profilePicture?.url */
    ) {
      try {
        verifyPhoto(); // esto no está lanzando el error
      } catch (err) {
        notify(err.response.data.message, 'error');
      }
    }
  }, [profileImage]);

  return (
    <>
      <div className="space-y-4">
        <p className="font-semibold text-sm">
          {t('auth.signUp.wizard.personal.picture.profilePictureTitle')} *
        </p>
        <div className="flex lg:flex-row flex-col lg:gap-x-8 gap-y-4 lg:items-start items-center">
          {/* <div className="sm:w-72 w-48 sm:h-72 h-48 bg-slate-300">
            {t(`auth.signUp.wizard.personal.picture.dragText`)}
          </div> */}
          <div className="">
            <div className="relative sm:w-[200px] w-48 sm:h-[200px] h-48">
              <button
                type="button"
                className="sm:w-[200px] w-48 sm:h-[200px] h-48"
                onClick={() => handleSetImage('profile')}
              >
                {/* show profile picture if chosen already */}
                {profileImage?.length > 0 && dataVerifyPhoto ? (
                  <div className="w-full h-full relative">
                    <div className="absolute w-full h-full rounded-full opacity-0 hover:opacity-100 bg-black/40 flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={faCameraAlt}
                        className="h-6 w-6 text-background-300"
                      />
                    </div>
                    <img
                      alt={profileImage[0]?.name}
                      src={profileImage[0]?.src}
                      className="w-full h-full object-cover rounded-full"
                    />
                  </div>
                ) : (
                  <div className="h-full w-full rounded-lg bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2">
                    <FontAwesomeIcon icon={faCameraAlt} className="w-5 h-5" />
                    <p className="font-semibold text-sm">
                      {t(
                        `auth.signUp.wizard.personal.picture.uploadProfilePicture`
                      )}
                    </p>
                  </div>
                )}
              </button>
            </div>
          </div>
          <FaceRecInfo />
        </div>
      </div>
      <div className="space-y-4">
        <p className="font-semibold text-sm">
          {t('auth.signUp.wizard.personal.picture.coverTitle')}
        </p>
        <div className="relative w-full sm:h-[200px] h-24">
          <button
            type="button"
            className="w-full sm:h-[200px] h-24"
            onClick={() => handleSetImage('cover')}
          >
            {/* show cover picture if chosen already */}
            {coverImage?.length > 0 ? (
              <div className="w-full h-full relative">
                <div className="absolute w-full h-full rounded-lg opacity-0 hover:opacity-100 bg-black/40 flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faCameraAlt}
                    className="h-6 w-6 text-background-300"
                  />
                </div>
                <img
                  alt={coverImage[0]?.name}
                  src={coverImage[0]?.src}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ) : (
              <div className="h-full w-full rounded-lg bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2">
                <FontAwesomeIcon icon={faCameraAlt} className="w-5 h-5" />
                <p className="font-semibold text-sm">{t(`uploadImage`)}</p>
              </div>
            )}
          </button>
        </div>
      </div>
      <CropImageModal
        title={
          currentImage === 'profile'
            ? t('auth.signUp.wizard.personal.picture.uploadProfilePicture')
            : t(`uploadImage`)
        }
        isOpen={photoModalIsOpen}
        setIsOpen={setPhotoModalIsOpen}
        image={currentImage === 'profile' ? profileImage : coverImage}
        updateURLs={
          currentImage === 'profile' ? updateProfileURLs : updateCoverURLs
        }
        defaultCrop={cropProps}
        onSubmit={onSubmit}
        cropShape={currentImage === 'profile' ? 'round' : 'rect'}
        isPfp={currentImage === 'profile'}
      />
    </>
  );
}
