/* eslint-disable no-nested-ternary */
import React from 'react';
import { Multimedia, Post } from '@dinbog/models';
import { formatVideoUrl } from '../../../utils/formatUrl';
import AudioFilePlayer from './AudioFilePlayer';
import LinkPreview from '../LinkPreview';
import FullPhoto from '../media/FullPhoto';

interface PostContentProps {
  post: Post;
  owner?: any;
}

function PostContent({ post, owner }: PostContentProps) {
  const [selectedPhoto, setSelectedPhoto] = React.useState<Multimedia | null>(
    null
  );
  return (
    <div className="w-full py-6 flex flex-col gap-6">
      {/* text */}
      <p className="">{post?.text}</p>
      {/* multimedia */}
      <div className="flex flex-wrap gap-10 justify-center">
        {/* map sobre multimedia del post. según el tipo se usa el tag que corresponda */}
        {post?.multimedia?.map((item, idx) =>
          item?.type === 'image' ? (
            <div className="" key={idx}>
              <button
                type="button"
                className="w-full max-h-[500px] rounded"
                onClick={() => setSelectedPhoto(item)}
              >
                <img
                  className="w-full max-h-[500px] rounded object-cover"
                  src={item?.url}
                  alt={item?.fileName}
                />
              </button>
              {selectedPhoto?.url === item?.url ? (
                <FullPhoto photo={item} setSelected={setSelectedPhoto} />
              ) : null}
            </div>
          ) : item?.type === 'video' ? (
            !(
              item?.url?.includes('sfo3.digitaloceanspaces.com') ||
              item?.url?.includes('amazonaws.com')
            ) ? ( // REVISAR
              <iframe
                key={idx}
                src={formatVideoUrl(item)}
                className="w-full h-64"
                title={item?.fileName}
              />
            ) : (
              <video src={item?.url} className="h-64" controls key={idx}>
                <track default kind="captions" srcLang="en" />
              </video>
            )
          ) : item?.type === 'audio' ? (
            <AudioFilePlayer
              key={idx}
              file={item}
              picture={owner?.photo?.url}
            />
          ) : (
            <LinkPreview key={idx} url={item?.url} />
          )
        )}
      </div>
    </div>
  );
}

export default PostContent;
