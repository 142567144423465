import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useUser } from '../../../hooks';
import logo from '../../../public/images/logo/logo.png';
import { LoggedOptions, OnRegisterOptions, UnLoggedOptions } from './options';

type TNavbarState = 'logged' | 'unLogged' | 'onRegister';

function Navbar() {
  const { currentUser: user } = useUser();
  const [navbarState, setNavbarState] =
    React.useState<TNavbarState>('unLogged');
  // TODO: manejar el estado de loading
  React.useEffect(() => {
    if (user) {
      setNavbarState('logged');
      // refetch();
    } else {
      setNavbarState('unLogged');
    }
    // TODO: agregar caso de onRegister
  }, [user]);

  return (
    <header className="w-full fixed z-[100] bg-white flex px-5 sm:px-10 md:overflow-visible h-20 justify-between shadow-sm">
      {/* logo */}
      <Link href="/" className="w-28 sm:w-36 md:w-[10rem] py-5 my-auto">
        <Image src={logo} alt="Dinbog" />
      </Link>
      {/* Navbar options buttons */}
      {navbarState === 'logged' ? <LoggedOptions /> : null}
      {navbarState === 'unLogged' ? <UnLoggedOptions /> : null}
      {navbarState === 'onRegister' ? <OnRegisterOptions /> : null}
    </header>
  );
}

export default Navbar;
