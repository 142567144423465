import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, TextArea } from '@dinbog/ui';
import { genderOptions } from '@dinbog/models';
import { useFormContext } from 'react-hook-form';
import { CustomPhoneInput } from '../../../shared';
import { useLocation } from '../../../../hooks';

function FanAndTalentContent({ formValidations, disabled }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext();

  const {
    countries: birthCountries,
    states: birthStates,
    cities: birthCities,
  } = useLocation(watch('birthCountry') ?? '', watch('birthState') ?? '');

  const {
    countries: currentCountries,
    states: currentStates,
    cities: currentCities,
  } = useLocation(watch('currentCountry') ?? '', watch('currentState') ?? '');

  return (
    <div className="w-full flex flex-col gap-y-10">
      <div className="flex flex-col gap-y-6 w-full">
        <div className="inputs-container">
          <div className="w-full">
            <Input
              disabled={disabled}
              isRequired
              name="firstName"
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.firstName')}
              error={errors.firstName?.message as string}
              {...register('firstName', formValidations.firstName)}
            />
          </div>
          <div className="w-full md:pr-4">
            <Input
              disabled={disabled}
              isRequired
              name="lastName"
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.lastName')}
              error={errors.lastName?.message as string}
              {...register('lastName', formValidations.lastName)}
            />
          </div>
        </div>
        <div className="inputs-container">
          <div className="w-full md:pr-4">
            <Select
              disabled={disabled}
              isRequired
              {...register('private', formValidations.private)}
              error={errors.private?.message as string}
              className="w-full"
              label={t('portfolio.privacy.title') ?? ''}
            >
              <option key={-1} value="" disabled>
                {t('portfolio.privacy.values.default')}
              </option>
              {['public', 'private']?.map((privacy, i) => (
                <option
                  key={`${i + 1}-${privacy}`}
                  value={privacy === 'private' ? 'true' : 'false'}
                >
                  {t(`portfolio.privacy.values.${privacy}`)}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className="inputs-container">
          <div className="w-full">
            <Select
              disabled={disabled}
              isRequired
              name="gender"
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.wizard.fan.gender.title')}
              error={errors.gender?.message as string}
              {...register('gender', formValidations.gender)}
            >
              <option key={-1} value="">
                {t('auth.signUp.wizard.fan.gender.options.default')}
              </option>
              {genderOptions?.map((gender, i) => (
                <option key={i + 1} value={gender}>
                  {t(`auth.signUp.wizard.fan.gender.options.${gender}`)}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full md:pr-4">
            <CustomPhoneInput
              name="phone"
              label={t('auth.signUp.wizard.fan.phone')}
              error={errors.phone?.message as string}
              validations={formValidations.phone}
              control={control}
              controlled
            />
          </div>
        </div>
        <div className="inputs-container">
          <TextArea
            disabled={disabled}
            isRequired
            name="description"
            className="h-36"
            label={t('auth.signUp.wizard.fan.description.title')}
            placeholder={t('auth.signUp.wizard.fan.description.description')}
            error={errors.description?.message as string}
            {...register('description', formValidations.description)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        <p className="font-semibold">
          {t('auth.signUp.wizard.fan.birthLocation.title')}
        </p>
        <div className="inputs-container">
          <div className="w-full">
            <Select
              disabled={disabled}
              isRequired
              name="birthCountry"
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.wizard.personal.birthLocation.country')}
              error={errors.birthCountry?.message as string}
              {...register('birthCountry', formValidations.birthCountry)}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {birthCountries?.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              disabled={disabled}
              name="birthState"
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.wizard.personal.birthLocation.state')}
              error={errors.birthState?.message as string}
              {...register('birthState', formValidations.birthState)}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {birthStates?.map((state) => (
                <option
                  key={state.isoCode}
                  value={state.isoCode}
                  selected={watch('birthState') === state.isoCode}
                >
                  {state.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              name="birthCity"
              defaultValue=""
              label={t('auth.signUp.wizard.personal.birthLocation.city')}
              error={errors.birthCity?.message as string}
              disabled={birthCities.length <= 0 || disabled}
              {...register('birthCity', formValidations.birthCity)}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {birthCities?.map((city) => (
                <option
                  key={city.name}
                  value={city.name}
                  selected={watch('birthCity') === city.name}
                >
                  {city.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        <p className="font-semibold">
          {t('auth.signUp.wizard.fan.currentLocation.title')}
        </p>
        <div className="inputs-container">
          <div className="w-full">
            <Select
              disabled={disabled}
              isRequired
              name="currentCountry"
              className="w-full"
              label={t('auth.signUp.wizard.fan.currentLocation.country')}
              error={errors.currentCountry?.message as string}
              {...register('currentCountry', formValidations.currentCountry)}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {currentCountries?.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              disabled={disabled}
              isRequired={currentStates?.length > 0}
              name="currentState"
              className="w-full"
              label={t('auth.signUp.wizard.fan.currentLocation.state')}
              error={errors.currentState?.message as string}
              {...register('currentState', formValidations.currentState)}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {currentStates?.map((state) => (
                <option
                  key={state.isoCode}
                  value={state.isoCode}
                  selected={watch('currentState') === state.isoCode}
                >
                  {state.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              isRequired
              name="currentCity"
              label={t('auth.signUp.wizard.fan.currentLocation.city')}
              error={errors.currentCity?.message as string}
              disabled={currentCities.length <= 0 || disabled}
              {...register('currentCity', formValidations.currentCity)}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {currentCities?.map((city) => (
                <option
                  key={city.name}
                  value={city.name}
                  selected={watch('currentCity') === city.name}
                >
                  {city.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FanAndTalentContent;
