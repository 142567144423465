import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt } from '@fortawesome/free-solid-svg-icons';
import { Multimedia } from '@dinbog/models';
import { uploadFile } from '../../../../lib/uploadFile';
import { useSignS3 } from '../../../../hooks/api/s3';
import { DocumentModel } from '../../../../models';
import { CropImageModal } from '../../../shared';
import { useNotify, useUser } from '../../../../hooks';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../utils/formatDocumentModel';
import { useUpdateProfile } from '../../../../hooks/api/profiles';

interface ProfileCoverProps {
  cover?: Multimedia;
  setCover?: React.Dispatch<React.SetStateAction<Multimedia>>;
}

function ProfileCover({ cover, setCover }: ProfileCoverProps) {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const router = useRouter();
  const { t } = useTranslation();
  const notify = useNotify();
  const [coverModalIsOpen, setCoverModalIsOpen] = React.useState(false);
  const [image, setImage] = React.useState<DocumentModel[]>(
    cover ? multimediaToDocument([cover]) : []
  );
  const updateURLs = React.useCallback(setImage, [setImage]);
  const [uploading, setUploading] = React.useState(false);
  const createMutation = useSignS3();
  const updateMutation = useUpdateProfile();

  const cropProps = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    size: { width: 300, height: 100 },
  };

  const updateCover = async () => {
    try {
      const coverPhoto = image?.length > 0 ? documentToMedia(image) : null;
      // update profile cover
      const data = await updateMutation.mutateAsync({
        user: user?._id,
        ...(image?.length > 0
          ? { cover: { ...coverPhoto[0], type: 'image' } }
          : { cover }),
      });
      if (!data) {
        return notify(t('auth.signUp.notifs.failure'), 'error');
      }
      setUser({
        _id: user?._id,
        profile: data,
        token: user?.token,
        currentUser: user?.currentUser,
        accounts: user?.accounts,
      });
      setCover(data.cover);
      setCoverModalIsOpen(false);
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  const onSubmit = async () => {
    // upload to s3
    await uploadFile(
      image[0],
      setUploading,
      updateURLs,
      createMutation,
      notify,
      t
    );
  };

  React.useEffect(() => {
    if (
      image?.length > 0 &&
      (image[0]?.src as string)?.startsWith('https://') &&
      image[0]?.src !== cover?.url
    ) {
      updateCover();
    }
  }, [image]);

  return (
    <>
      <div className="lg:h-[240px] h-48 w-full rounded-t relative">
        {cover ? (
          <img
            src={cover?.url}
            alt=""
            className="h-full w-full rounded-t object-cover"
          />
        ) : (
          <div className="w-full h-full bg-linearGradient" />
        )}
        {router?.pathname === '/profile' ? (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            type="button"
            className="absolute right-4 bottom-4 z-50 bg-neutral-400 rounded-full opacity-75 h-9 w-9 flex justify-center items-center"
            onClick={() => setCoverModalIsOpen(true)}
          >
            <FontAwesomeIcon
              icon={faCameraAlt}
              className="w-5 h-5 mx-auto text-text-white "
            />
          </button>
        ) : null}
      </div>
      <CropImageModal
        title={t('profile.changeCoverModal.title')}
        isOpen={coverModalIsOpen}
        setIsOpen={setCoverModalIsOpen}
        image={image}
        updateURLs={updateURLs}
        defaultCrop={cropProps}
        onSubmit={onSubmit}
        cropShape="rect"
      />
    </>
  );
}

export default ProfileCover;
