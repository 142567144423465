import { Category, Subcategory } from '@dinbog/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import VacancyCategory from './VacancyCategory';

export default function VacancyCategories({
  categories,
  subcategories,
}: {
  categories: Category[];
  subcategories: Subcategory[];
}) {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = React.useState<string>('');
  const [filteredSubcategories, setFilteredSubcategories] = React.useState<
    Subcategory[]
  >([]);

  React.useEffect(() => {
    const filtered = subcategories?.filter((sc) => sc?.category === selected);
    setFilteredSubcategories(filtered);
  }, [selected]);

  return (
    <div className={selected === '' ? 'space-y-2' : 'space-y-3'}>
      {/* categories */}
      <div className="flex flex-wrap gap-2">
        {categories?.map((category) => (
          <VacancyCategory
            category={category}
            selected={selected}
            setSelected={setSelected}
            key={category._id}
          />
        ))}
      </div>
      {/* subcategories according to selected category */}
      <div className="flex flex-wrap gap-1">
        {filteredSubcategories?.map((sc, idx) => (
          <span className="">
            {sc?.name?.[i18n?.language ?? 'en']}
            {filteredSubcategories?.length > idx + 1 ? ',' : null}
          </span>
        ))}
      </div>

      {selected === '' ? (
        <p className="text-neutral-400">
          {t('events.casting.vacancies.selectCategory')}
        </p>
      ) : null}
    </div>
  );
}
