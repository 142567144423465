import React from 'react';
import {
  Country,
  City,
  ICountry,
  ICity,
  IState,
  State,
} from 'country-state-city';

type TUseLocation = {
  countries: ICountry[];
  states: IState[];
  cities: ICity[];
};

export function useLocation(
  currentCountry: string,
  currentState: string
): TUseLocation {
  const countries = Country.getAllCountries();
  const [cities, setCities] = React.useState<ICity[]>([]);
  const [states, setStates] = React.useState<IState[]>([]);

  React.useEffect(() => {
    setStates(
      State.getStatesOfCountry(currentCountry).map((_state: IState) => ({
        ..._state,
      }))
    );
  }, [currentCountry]);

  React.useEffect(() => {
    setCities(
      City.getCitiesOfState(currentCountry, currentState).map(
        (_city: ICity) => ({
          ..._city,
        })
      )
    );
  }, [currentState, currentCountry]);

  return { countries, states, cities };
}
