import React from 'react';
import { useTranslation } from 'react-i18next';
import { Profile, User } from '@dinbog/models';
import { useConnect, useDisconnect } from '../../../../hooks/api/connections';
import { useNotify, useUser } from '../../../../hooks';
import Dropdown from '../../dropdown';

function FollowDropdown({
  setUsersData,
  toUser,
}: {
  toUser: User & {
    profile: Profile;
    following: boolean;
    requestedFollow: boolean;
    requestedConnection: boolean;
  };
  setUsersData?: React.Dispatch<
    React.SetStateAction<
      Array<
        {
          following: boolean;
          requestedFollow: boolean;
          requestedConnection: boolean;
          profile: Profile;
        } & User
      >
    >
  >;
}) {
  const { t } = useTranslation();
  const notify = useNotify();
  const { currentUser: user } = useUser();
  const [isOpen, setIsOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const connectMutation = useConnect();

  const handleConnect = async () => {
    if (disabled) return;
    setDisabled(true);
    try {
      await connectMutation.mutateAsync(
        {
          from: user?._id,
          to: toUser._id,
          type: 'friend', // REVISAR
        },
        {
          onSuccess: (data) => {
            if (setUsersData)
              setUsersData((prev) =>
                prev.map((u) => ({
                  ...u,
                  requestedConnection:
                    data.to === u._id ? true : u.requestedConnection,
                }))
              );
          },
        }
      );
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const disconnectMutation = useDisconnect();

  const handleDisconnect = async () => {
    if (disabled) return;

    setDisabled(true);
    try {
      await disconnectMutation.mutateAsync(
        {
          from: user?._id,
          to: toUser._id,
        },
        {
          onSuccess: (data) => {
            if (setUsersData)
              setUsersData((prev) =>
                prev.map((u) => ({
                  ...u,
                  requestedConnection:
                    data.to === u._id ? false : u.requestedConnection,
                }))
              );
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Dropdown className="h-full" isOpen={isOpen} setIsOpen={setIsOpen}>
      <Dropdown.Button
        hasTriangleIcon
        triangleColor="white"
        style={{
          width: '8px',
          height: '8px',
          marginLeft: '4px',
        }}
        className="h-full"
      />
      <Dropdown.ItemsContainer className="mt-3">
        <button
          type="button"
          className="text-neutral-800"
          onClick={(e) => {
            e.preventDefault();
            if (toUser.requestedConnection) {
              handleDisconnect();
            } else {
              handleConnect();
            }
          }}
        >
          {toUser.requestedConnection
            ? t('profile.relations.requested')
            : t('profile.relations.actions.connect')}
        </button>
      </Dropdown.ItemsContainer>
    </Dropdown>
  );
}

export default FollowDropdown;
