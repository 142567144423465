import React from 'react';
import {
  Business,
  BusinessUserRole,
  Pagination,
  Profile,
  StatusEnum,
  User,
} from '@dinbog/models';
import { Alert, InfiniteScroll, TrashIcon } from '@dinbog/ui';
import { useUpdateMembers } from '../../../../hooks/api/businesses';
import { useNotify, useUser } from '../../../../hooks';
import { DeleteModal } from '../../modal';
import UserItem from '../../UserItem';

interface UsersListProps {
  users: (User & {
    profile?: Profile;
    business?: Business;
    role?: BusinessUserRole;
    isNew?: boolean;
    status?: StatusEnum;
  })[];
  setUsers: React.Dispatch<
    React.SetStateAction<
      (User & {
        profile?: Profile;
        business?: Business;
        role?: BusinessUserRole;
        isNew?: boolean;
        status?: StatusEnum;
      })[]
    >
  >;
  title: string;
  emptyTitle: string;
  emptyText: string;
  usersData: Pagination<{
    _id: string;
    profile: Profile;
    role: BusinessUserRole;
    status?: StatusEnum;
  }>;
  usersLoading: boolean;
  setPagination: React.Dispatch<
    React.SetStateAction<{
      page: number;
      perPage: number;
    }>
  >;
  type: 'admin' | 'editor';
}

function UsersList({
  title,
  users,
  setUsers,
  emptyTitle,
  emptyText,
  usersData,
  usersLoading,
  setPagination,
  type,
}: UsersListProps) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const updateMembersMutation = useUpdateMembers();
  const [selectedUser, setSelectedUser] = React.useState<
    User & {
      profile?: Profile;
      business?: Business;
      role?: BusinessUserRole;
      isNew?: boolean;
    }
  >(null);
  const [isOpen, setOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(0);

  const handleRemoveUser = async () => {
    setUsers((prev) => prev.filter((item) => item._id !== selectedUser._id));
    if (selectedUser?.isNew) return;

    try {
      await updateMembersMutation.mutateAsync(
        {
          token: user?.token,
          business: (user?.currentUser?.user as User)?._id as string,
          members: [
            {
              user: selectedUser?._id,
              role: selectedUser?.role,
              action: 'remove',
            },
          ],
        },
        {
          onSuccess: () => {
            setCurrent(current + 1);
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
    }
  };

  const empty =
    users.length === 0 ||
    (users?.length === 1 &&
      (users[0]?.profile?.user as User)?._id ===
        (user?.currentUser?.user as User)?._id); // if the logged user is the only member, the list is empty bc he can't remove himself

  React.useEffect(() => {
    if (Number(usersData?.pageInfo?.page) === 1)
      setUsers((prev) => [
        ...(prev ?? []).filter((item) => item?.role !== type), // only keep the users that are not of the type we are fetching
        ...(usersData?.items ?? []).map((user_) => ({
          ...((user_?.profile?.user as User) ?? {}),
          profile: user_?.profile,
          _id: user_?._id,
          role: user_?.role,
          isNew: false,
          status: user_?.status,
        })),
      ]);
    else
      setUsers((prev) => [
        ...prev,
        ...(usersData?.items ?? []).map((user_) => ({
          ...((user_?.profile?.user as User) ?? {}),
          profile: user_?.profile,
          _id: user_?._id,
          role: user_?.role,
          isNew: false,
          status: user_?.status,
        })),
      ]);
  }, [usersData]);

  React.useEffect(() => {
    if (!isOpen) {
      setSelectedUser(null);
      setCurrent(0);
    }
  }, [isOpen]);

  return (
    <div className="w-full block pr-10">
      <DeleteModal
        onSubmit={handleRemoveUser}
        current={current}
        user={selectedUser}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <div className="flex flex-col">
        {empty ? (
          <Alert title={emptyTitle} text={emptyText} show />
        ) : (
          <InfiniteScroll
            containerClassName=" w-full"
            maxHeight={200}
            setPagination={setPagination}
            data={usersData}
            setLoadedData={setUsers}
            loading={usersLoading}
            updateLoadedDataOnPageChange={false}
          >
            {users.map((item, idx) => {
              if (
                (user?.currentUser?.user as User)?._id ===
                  (item?.profile?.user as User)?._id ||
                item?.status === 'removed'
              )
                // you can't remove yourself or someone you already removed from the company
                return null;
              return (
                <UserItem
                  key={idx}
                  className="border-none"
                  icon={<TrashIcon className="w-5 h-5 text-neutral-700" />}
                  onClick={() => {
                    if (item?.isNew) {
                      setUsers((prev) =>
                        prev.filter((item_) => item_._id !== item._id)
                      );
                    } else {
                      setSelectedUser(item);
                      setOpen(true);
                    }
                  }}
                  isMember={!!item?.status}
                  user={item}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}

export default UsersList;
