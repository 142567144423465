import { AxiosError } from 'axios';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { axios } from '../../api';

export function useSearch(
  filters: {
    query?: string;
    filter?: string;
    page?: number;
    perPage?: number;
    // TODO: cambiar el colecction type para vacante cuando este definido
    collection?: 'profiles' | 'posts' | 'businesses' | 'vacancies';
  },
  token?: string,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['search']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError, any>(
    ['search', filters, token],
    async () => {
      if (!token) {
        return null;
      }
      const { data } = await axios.get('/api/search/v1', {
        params: { ...filters },
        headers: { Authorization: `${token}` },
      });
      return data;
    },
    options
  );
  return query;
}

export function useInfiniteSearch(
  filters: {
    query?: string;
    filter?: string;
    page?: number;
    perPage?: number;
    collection?: 'profiles' | 'posts' | 'businesses' | 'vacancies';
  },
  token?: string,
  options: Omit<
    UseInfiniteQueryOptions<any, AxiosError, any, any, ['search']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  async function fetchSearchResults({ pageParam = 1 }) {
    if (!token) {
      return null;
    }
    const { data } = await axios.get('/api/search/v1', {
      params: { ...filters, page: pageParam },
      headers: { Authorization: `${token}` },
    });
    return data;
  }

  const {
    data,
    fetchNextPage,
    isLoading,
    isError,
    refetch,
  } = useInfiniteQuery<any, AxiosError>(
    ['search', filters, token],
    fetchSearchResults,
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
      ...options,
    }
  );

  return {
    data,
    fetchNextPage,
    isLoading,
    isError,
    refetch,
  };
}
