import React from 'react';
import { PennantIcon } from '@dinbog/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNotify, useUser } from '../../../hooks';
import { BlockModal, ReportModal } from '../../shared';

interface OtherActionsDropdownProps {
  onEdit?: any;
  onDelete?: any;
  user?: any;
  blocked?: boolean;
  refreshUser?: () => void;
}

export default function OtherActionsDropdown({
  onEdit,
  onDelete,
  user,
  blocked = false,
  refreshUser,
}: OtherActionsDropdownProps) {
  const { t } = useTranslation();
  const notify = useNotify();
  const [openBlockModal, setOpenBlockModal] = React.useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = React.useState<boolean>(false);
  const onShare = () => {
    navigator.clipboard.writeText(window.location.toString());
    notify(t('copyToClipboard'), 'success');
  };

  // debería buscar el id según el slug, poder bloquear/reportar con el slug o traerme el id

  return (
    <div className="absolute top-12 right-0 bg-background-50 shadow border rounded flex flex-col w-36 z-50">
      <button
        type="button"
        className="flex flex-row items-center gap-x-2 px-4 py-2 hover:bg-neutral-200 active:opacity-80"
        onClick={() => onShare()}
      >
        <FontAwesomeIcon
          icon={faPaperPlane}
          className="h-4 w-4 text-text-black"
        />
        <span className="">{t('profile.otherActions.share')}</span>
      </button>
      {!blocked ? (
        <button
          type="button"
          className="flex flex-row items-center gap-x-2 px-4 py-2 hover:bg-neutral-200 active:opacity-80 text-danger-300"
          onClick={() => setOpenBlockModal(true)}
        >
          <FontAwesomeIcon icon={faBan} className="h-4 w-4" />
          <span className="">{t('profile.otherActions.block')}</span>
        </button>
      ) : null}
      <button
        type="button"
        className="flex flex-row items-center gap-x-2 px-4 py-2 hover:bg-neutral-200 active:opacity-80 text-danger-300"
        onClick={() => setOpenReportModal(true)}
      >
        <PennantIcon className="h-4 w-4" />
        <span className="">{t('profile.otherActions.report')}</span>
      </button>
      <BlockModal
        isOpen={openBlockModal}
        setOpen={setOpenBlockModal}
        user={user}
        onSuccess={refreshUser}
      />
      <ReportModal
        isOpen={openReportModal}
        setOpen={setOpenReportModal}
        item="user"
        itemIdToBeReported={user?._id}
        itemOwner={user?._id}
      />
    </div>
  );
}
