import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpainFlag, UKFlag } from '@dinbog/ui';
import ChangeLanguageItem from './ChangeLanguageItem';
import Dropdown from '../../../shared/dropdown';

function ChangeLanguageDropdown() {
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const languages = {
    en: {
      flag: <UKFlag className="w-5 h-5 rounded-full" />,
      language: 'en',
      languageLabel: 'EN',
    },
    es: {
      flag: <SpainFlag className="w-5 h-5 rounded-full" />,
      language: 'es',
      languageLabel: 'ES',
    },
  };

  return (
    <Dropdown className="my-auto" isOpen={isOpen} setIsOpen={setIsOpen}>
      <Dropdown.Button hasTriangleIcon>
        <span className="flex items-center text-primary-500">
          <span className="ml-3 truncate flex flex-row gap-2 items-center">
            {languages[i18n.language ?? 'en'].flag}{' '}
            {languages[i18n.language ?? 'en'].languageLabel}
          </span>
        </span>
      </Dropdown.Button>
      <Dropdown.ItemsContainer>
        {Object.values(languages).map((language) => (
          <ChangeLanguageItem
            key={language?.language}
            flag={language?.flag}
            language={language?.language}
            languageLabel={language?.languageLabel}
          />
        ))}
      </Dropdown.ItemsContainer>
    </Dropdown>
  );
}

export default ChangeLanguageDropdown;
