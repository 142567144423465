import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useNotify, useUser } from '../../../hooks';
import { ProgressBar } from '../../shared';
import { reducer } from './reducer';
import SelectCategory from './selectCategory/SelectCategory';
import CompanyInformation from './companyInformation/CompanyInformation';
import { AddMembersForm } from './addMembers';

// FORM STEPS AMOUNT
const formsLength = 3;

function BusinessSignUp() {
  const { t } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const router = useRouter();
  const [currentStep, setCurrentStep] = React.useState(
    parseInt(user?.currentUser?.registrationStep) ?? 0
  );
  const [userState, userDispatch] = React.useReducer(
    reducer,
    /* JSON.parse(localStorage.getItem('business')) || */ {}
  );

  const handleCompleteSignUp = () => {
    // Go to next step
    router.push('/sign-up/success');
  };

  const forms = React.useMemo(
    () => [
      <SelectCategory
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        state={userState}
        dispatch={userDispatch}
        formsLength={formsLength}
      />,
      <CompanyInformation
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        state={userState}
        dispatch={userDispatch}
        formsLength={formsLength}
      />,

      <AddMembersForm
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleCompleteSignUp={handleCompleteSignUp}
        formsLength={formsLength}
      />,
    ],
    [currentStep, userState]
  );

  React.useEffect(() => {
    setCurrentStep(parseInt(user?.currentUser?.registrationStep) ?? 0); // ojo con el +1 que estaba acá
  }, [user?.currentUser?.registrationStep]);

  return (
    <div className="bg-white rounded-sm shadow px-8 py-4 space-y-4 h-full">
      <h2 className="font-semibold text-2xl text-center my-8">
        {t('auth.signUp.wizard.businessTitle')}
      </h2>
      <div className="flex flex-row justify-center ">
        <ProgressBar currentStep={currentStep + 1} steps={formsLength} />
      </div>
      <div className="pt-10">{forms[currentStep]}</div>
    </div>
  );
}

export default BusinessSignUp;
