import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Specification, SpecificationsFormProps } from '@dinbog/models';
import { Alert, Button } from '@dinbog/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import {
  generateTalentSpecificationInputDefaultValues,
  talentSpecificationsFormatToUpdate,
} from '../../../utils/talentSpecificationsFormat';
import SpecificationsFormContent from '../../shared/formContents/SpecificationsFormContent';

interface SpecsFormProps {
  specifications: Array<Specification>;
}

export default function SpecificationsForm({ specifications }: SpecsFormProps) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const { t } = useTranslation();

  const [disabled, setDisabled] = React.useState(false);

  const queryClient = useQueryClient();
  const updateProfileMutation = useUpdateProfile();

  const methods = useForm<SpecificationsFormProps>({
    defaultValues:
      generateTalentSpecificationInputDefaultValues(specifications),
  });

  const onSubmit: SubmitHandler<SpecificationsFormProps> = async (
    _data: SpecificationsFormProps
  ) => {
    try {
      if (disabled) return;
      setDisabled(true);

      await updateProfileMutation.mutateAsync(
        {
          user: user?._id as string,
          // construct specifications array with values entered by user
          specifications: talentSpecificationsFormatToUpdate(
            specifications,
            _data
          ),
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(['talentSpecs', { user: user?._id }]);
            notify(t('settings.notifs.success'), 'success');
          },
        }
      );
    } catch (err) {
      notify(err.response.data.message, 'error');
      // return notify(t('auth.signUp.notifs.failure'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-10"
        method="post"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="space-y-3">
          <div>
            <h3 className="font-semibold text-lg">
              {t('auth.signUp.wizard.specifications.title')}
            </h3>
            <p>{t('auth.signUp.wizard.specifications.description')}</p>
          </div>
          {specifications?.length > 0 ? (
            <SpecificationsFormContent
              specifications={specifications ?? []}
              disabled={disabled}
            />
          ) : (
            <Alert
              title={t('auth.signUp.wizard.specifications.noSpecsFound')}
              text={t(
                'auth.signUp.wizard.specifications.noSpecsFoundDescription'
              )}
              show
            />
          )}
        </div>
        {specifications?.length > 0 && (
          <div className="w-full flex gap-5 justify-end mt-5">
            <Button
              tooltip={t('save')}
              type="submit"
              className="bg-primary-500 text-white font-normal"
            >
              {t('save')}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
}
