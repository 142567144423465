import React from 'react';
import { useRequests } from '../../context/RequestContext';
import SuggestedUsersCard from '../feed/SuggestedUsersCard';

function SuggestionSection() {
  const { recommendations, loadingRecommendations } = useRequests();
  return (
    <div className=" lg:block hidden">
      <SuggestedUsersCard
        users={recommendations.talents}
        loadingSuggestedUsers={loadingRecommendations}
        type="talents"
        refetch={recommendations.refetchTalents}
      />
      <SuggestedUsersCard
        users={recommendations.businesses}
        loadingSuggestedUsers={loadingRecommendations}
        type="businesses"
        refetch={recommendations.refetchBusinesses}
      />
    </div>
  );
}

export default SuggestionSection;
