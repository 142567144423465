import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  Album,
  LikeItem,
  PageInfo,
  Pagination,
  Track,
  User,
} from '@dinbog/models';
import { nFormatter } from '../../../../utils/nFormatter';
import { CommentsModal } from '../../modal';
import { useComments } from '../../../../hooks/api/comments';
import { useUser } from '../../../../hooks';
import TrackAudioBar from './TrackAudioBar';
import LikeCommentShareButtons from '../../LikeCommentShareButtons';
import LikeModal from '../../modal/likeModal/LikeModal';
import { useGetPortfolioLikes } from '../../../../hooks/api/likes';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

interface TrackDetailProps {
  track: {
    track: Track;
    playing: boolean;
    selected: boolean;
    currentTime: number;
  };
  setTracksPlayingState: React.Dispatch<
    React.SetStateAction<{ track: Track; playing: boolean }[]>
  >;
  album: Album;
  ownerSlug: string;
  onSuccessLikeAlbum?: () => void;
}

export default function TrackDetail({
  track,
  setTracksPlayingState,
  album,
  ownerSlug,
  onSuccessLikeAlbum,
}: TrackDetailProps) {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { currentUser: user } = useUser();

  // COMMENTS STUFF FOR COMMENTS MODAL
  const [openCommentsModal, setOpenCommentsModal] = React.useState(false);
  const [openLikesModal, setOpenLikesModal] = React.useState<boolean>(false);
  const [likesPagination, setLikesPagination] = React.useState<
    Partial<PageInfo>
  >({
    page: 1,
    perPage: 30,
  });
  const [commentsPagination, setCommentsPagination] = React.useState({
    page: 1,
    perPage: 50,
  });
  // comments pagination for the track
  const { data: dataComments, isLoading: loadingComments } = useComments(
    'portfolios',
    {
      user: (user?.currentUser?.user as User)?._id,
      item: track.track._id,
      itemCollection: 'track',
      ...commentsPagination,
      sort: 'createdAt',
      sortOrder: -1,
    }
  );
  const {
    data: likesData,
    isLoading: isLikesLoading,
    refetch: refetchModalLikes,
  } = useGetPortfolioLikes(
    {
      item: track.track._id,
      page: likesPagination.page,
      itemCollection: 'track',
      perPage: likesPagination.perPage,
      sort: 'createdAt',
      sortOrder: -1,
      token: user?.token,
      filter: { active: true },
    },
    {
      enabled: !!user?.token && openLikesModal,
    }
  );

  const queryClient = useQueryClient();

  const onLike = async () => {
    try {
      await onSuccessLikeAlbum();
      await refetchModalLikes();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex gap-4 p-4 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.10)] rounded-[5px] flex-col md:flex-row md:items-start items-center">
      <CommentsModal
        isOpen={openCommentsModal}
        setOpen={setOpenCommentsModal}
        item={{ _id: track.track._id, owner: album?.user, type: 'track' }}
        commentsData={dataComments}
        commentsLoading={loadingComments}
        ms="portfolios"
        setPagination={setCommentsPagination}
        onSuccessPostComment={() => {
          queryClient.refetchQueries([
            'comments',
            'portfolios',
            {
              user: user?._id,
              item: track.track._id,
              itemCollection: 'track',
              ...commentsPagination,
              sort: 'createdAt',
              sortOrder: -1,
            },
          ]);
        }}
      />
      <LikeModal
        isOpen={openLikesModal}
        setOpen={setOpenLikesModal}
        setPagination={setLikesPagination}
        item={{ _id: track.track._id, owner: album?.user, type: 'track' }}
        likesData={likesData}
        likesLoading={isLikesLoading}
      />
      {/* left: album cover */}
      <img
        className="w-[80px] h-[80px] object-cover rounded-md"
        src={album?.cover?.url}
        alt={album?.title}
      />
      {/* right: track details  */}
      <div className="w-full flex flex-col gap-2">
        <div className="w-full flex justify-between flex-col md:flex-row">
          <div className="">
            {/* title */}
            <p className=" font-semibold text-lg ">
              {`${track.track?.title?.charAt(0)?.toUpperCase() ?? ''}${
                track.track?.title?.slice(1) ?? ''
              }`}
            </p>
            {/* date */}
            <p className="text-xs text-neutral-500">
              {dayjs(track.track?.createdAt).format('ll')}
            </p>
          </div>
          <div className=" flex flex-col gap-2">
            {/* likes, comments */}
            <div className="flex gap-4 text-xs text-[#097DC1]">
              <button
                type="button"
                onClick={() => setOpenLikesModal(true)}
                disabled={
                  (likesData as Pagination<LikeItem>)?.items.length === 0
                }
              >
                <p>
                  {nFormatter(track.track?.stats?.likes) ?? 0}{' '}
                  {t('stats.likes')}
                </p>
              </button>
              <button type="button" onClick={() => setOpenCommentsModal(true)}>
                <p>
                  {nFormatter(dataComments?.count) ?? 0} {t('stats.comments')}
                </p>
              </button>
            </div>
            {/* buttons (like, comment, share) */}
            <LikeCommentShareButtons
              item={track?.track?._id}
              liked={track?.track?.liked ?? false}
              slug={album?._id}
              ownerSlug={ownerSlug}
              itemCollection="track"
              onHitCommentButton={() => setOpenCommentsModal(true)}
              onSuccessLike={() => onLike()}
              ms="portfolios"
              placeEnd
            />
          </div>
        </div>
        {/* progress bar with play/pause buttons */}
        <TrackAudioBar
          track={track}
          setTracksPlayingState={setTracksPlayingState}
        />
      </div>
    </div>
  );
}
