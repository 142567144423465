import React from 'react';
import {
  PageInfo,
  Pagination,
  WorkReference,
  WorkReferenceFormValidation,
  WorkReferenceSelectedUser,
} from '@dinbog/models';
import { UserSelectSearch } from '@dinbog/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CompanySearchProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  formValidations: WorkReferenceFormValidation;
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<WorkReferenceSelectedUser[]>
  >;
  setPagination: React.Dispatch<React.SetStateAction<PageInfo>>;
  dataUsers: Pagination<Omit<WorkReferenceSelectedUser, 'status'>>;
  isLoading: boolean;
}

function CompanySearch({
  searchText,
  setSearchText,
  formValidations,
  setSelectedUsers,
  setPagination,
  dataUsers,
  isLoading,
}: CompanySearchProps) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<WorkReference>();
  return (
    <UserSelectSearch
      {...register('company', formValidations.company)}
      error={errors.company?.message as string}
      setPagination={setPagination}
      setSearchText={setSearchText}
      searchText={searchText}
      usersData={dataUsers}
      usersLoading={isLoading}
      onSelectUser={(user_) => {
        setSelectedUsers((prev: any) => [
          ...prev.filter((_user) => _user?._id !== user_?._id),
          user_,
        ]);
        setValue('company', user_?._id);
        setValue('companyName', user_?.profile?.firstName);
      }}
      label={t('auth.signUp.wizard.workReference.company.title') ?? ''}
      inputPlaceholder={t(
        'auth.signUp.wizard.workReference.company.searchCompaniesPlaceholder'
      )}
      loadingText={t(
        'auth.signUp.wizard.workReference.company.searchCompaniesLoading'
      )}
      noResultsText={t(
        'auth.signUp.wizard.workReference.company.searchCompaniesNoResults'
      )}
      isRequired
    />
  );
}

export default CompanySearch;
