import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Category, PageInfo, Subcategory } from '@dinbog/models';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUser } from '../../../../../../../hooks';
import { useApplications } from '../../../../../../../hooks/api/applications';
import { useVacant } from '../../../../../../../hooks/api/vacancies';
import { useCategories } from '../../../../../../../hooks/api/categories';
import { useSpecs } from '../../../../../../../hooks/api/specifications';
import { InfoWithImage, SkeletonWrapper, Tab } from '../../../../../../shared';
import VacancyForm from '../../../../updateEvent/vacancy/VacancyForm';
import VacancyInfo from './VacancyInfo';
import VacancyApplications from './VacancyApplications';

export default function VacancyDetail({ vacancy }: { vacancy }) {
  const router = useRouter();
  const { currentUser: user } = useUser();
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = React.useState<
    'about' | 'enrolled' | 'preselected' | 'selected' | 'rejected'
  >('about');
  const [pagination, setPagination] = React.useState<Partial<PageInfo>>({
    page: 1,
    perPage: 12,
  });
  const [filters, setFilters] = React.useState({});
  const { data, isLoading } = useVacant({ slug: vacancy, token: user?.token });

  const { data: categoriesData, isLoading: categoriesLoading } = useCategories(
    {
      type: 'talent',
    },
    'users',
    { enabled: !!router?.query?.edit }
  );
  const { data: specsData, isLoading: specsLoading } = useSpecs(
    {},
    {
      enabled: !!router?.query?.edit,
    }
  );
  const { data: applicationsData, isLoading: applicationsLoading } =
    useApplications(
      {
        ...pagination,
        filter: JSON.stringify({ 'vacant.slug': vacancy, ...filters }),
      }
      /* {
        enabled:
          tabSelected === 'enrolled' ||
          tabSelected === 'preselected' ||
          tabSelected === 'selected' ||
          tabSelected === 'rejected',
      } */
    );

  React.useEffect(() => {
    if (tabSelected === 'enrolled') {
      setFilters({
        status: 'initial',
      });
    } else if (tabSelected === 'preselected') {
      setFilters({
        status: 'preselected',
      });
    } else if (tabSelected === 'selected') {
      setFilters({
        status: 'selected',
      });
    } else if (tabSelected === 'rejected') {
      setFilters({ status: 'rejected' });
    }
  }, [tabSelected]);

  const items = React.useMemo(
    () => ({
      about: {
        title: t('about'),
        itemKey: 'about',
        component: <VacancyInfo data={data} isLoading={isLoading} />,
      },
      enrolled: {
        title: t('events.casting.tabs.enrolled'),
        number: data?.applications?.pending ?? 0,
        itemKey: 'enrolled',
        component:
          applicationsData?.count === 0 ? (
            <div className="w-full p-16 flex items-center justify-center">
              <InfoWithImage
                image="/images/fallback/postulated-as-owner.png"
                title={t('emptyStates.events.asOwner.enrolled')}
                text=""
              />
            </div>
          ) : (
            <VacancyApplications
              data={applicationsData}
              isLoading={applicationsLoading}
              tab={tabSelected}
            />
          ),
      },
      preselected: {
        title: t('events.casting.tabs.preselected'),
        itemKey: 'preselected',
        number: data?.applications?.preselected ?? 0,
        component:
          applicationsData?.count === 0 ? (
            <div className="w-full p-16 flex items-center justify-center">
              <InfoWithImage
                image="/images/fallback/preselected-as-owner.png"
                title={t('emptyStates.events.asOwner.preselected')}
                text=""
              />
            </div>
          ) : (
            <VacancyApplications
              data={applicationsData}
              isLoading={applicationsLoading}
              tab={tabSelected}
            />
          ),
      },
      selected: {
        title: t('events.casting.tabs.selected'),
        itemKey: 'selected',
        number: data?.applications?.selected ?? 0,
        component:
          applicationsData?.count === 0 ? (
            <div className="w-full p-16 flex items-center justify-center">
              <InfoWithImage
                image="/images/fallback/selected-as-owner.png"
                title={t('emptyStates.events.asOwner.selected')}
                text=""
              />
            </div>
          ) : (
            <VacancyApplications
              data={applicationsData}
              isLoading={applicationsLoading}
              tab={tabSelected}
            />
          ),
      },
      rejected: {
        title: t('events.casting.tabs.rejected'),
        itemKey: 'rejected',
        number: data?.applications?.rejected ?? 0,
        component:
          applicationsData?.count === 0 ? (
            <div className="w-full p-16 flex items-center justify-center">
              <InfoWithImage
                image="/images/fallback/rejected-as-owner.png"
                title={t('emptyStates.events.asOwner.rejected')}
                text=""
              />
            </div>
          ) : (
            <VacancyApplications
              data={applicationsData}
              isLoading={applicationsLoading}
              tab={tabSelected}
            />
          ),
      },
    }),
    [data, isLoading, applicationsData, applicationsLoading, tabSelected]
  );

  return (
    <div className="flex flex-col gap-6">
      <button
        type="button"
        onClick={() =>
          router.push({
            pathname: `/briefcase/castings/[slug]`,
            query: { slug: router.query.slug, tab: 'vacancies' },
          })
        }
        className="flex gap-2 items-center w-fit"
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="h-3 w-3 text-text-black"
        />
        <span className="font-semibold">
          {t('profile.tabs.returnBtn')} vacancies
        </span>
      </button>
      {/* update vacancy form / info */}
      <SkeletonWrapper isLoading={isLoading && applicationsLoading}>
        {router?.query?.edit && !isLoading ? (
          <VacancyForm
            data={data}
            isLoading={isLoading}
            categories={categoriesData}
            specs={specsData}
          />
        ) : (
          <div className="space-y-6">
            {/* vacancy name */}
            <div className=" flex flex-col gap-1 ">
              <h3 className="text-xl font-semibold">{data?.title}</h3>
            </div>
            {/* tabs */}
            <Tab
              items={Object.values(items)}
              currentItem={tabSelected}
              setCurrentItem={setTabSelected}
              // isQueryParam
            />
            {/* details */}
            <div className="py-6 px-8 w-full">
              {router.isReady ? items[tabSelected]?.component : null}
            </div>
          </div>
        )}
      </SkeletonWrapper>
    </div>
  );
}
