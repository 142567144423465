import React from 'react';
import { Router } from 'next/router';

interface IDropdownContext {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DropdownContext = React.createContext<IDropdownContext>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsOpen: () => {},
});

interface DropdownProps {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useDropdown() {
  const { isOpen, setIsOpen } = React.useContext(DropdownContext);
  return [isOpen, setIsOpen] as const;
}

function Dropdown({ children, className, isOpen, setIsOpen }: DropdownProps) {
  Router.events.on('routeChangeStart', () => setIsOpen(false));

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isOpen]);

  const value = React.useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen, setIsOpen]
  );

  return (
    <DropdownContext.Provider value={value}>
      <div ref={ref} className={`relative ${className}`}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

export default Dropdown;
