/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { EyeIcon, Input, PasswordEyeIcon } from '@dinbog/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'avilatek-utils';

function LoginFields() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const formValidations = {
    email: {
      required: t('required'),
      validate: (value, formValues) => validateEmail(value),
    },
    password: {
      required: t('required'),
    },
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="">
        <Input
          type="email"
          {...register('email', formValidations.email)}
          error={errors.email?.message as string}
          placeholder={t('auth.signUp.email') ?? ''}
        />
      </div>
      <div className="">
        <Input
          type={showPassword ? 'text' : 'password'}
          {...register('password', formValidations.password)}
          placeholder={t('auth.signUp.password') ?? ''}
          rightIcon={
            !showPassword ? (
              <button
                className=""
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                <EyeIcon className="w-5 h-5" />
              </button>
            ) : (
              <button
                className=""
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                <PasswordEyeIcon className="w-5 h-5" />
              </button>
            )
          }
        />
      </div>
    </div>
  );
}

export default LoginFields;
