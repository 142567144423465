export const formatVideoUrl = (video) => {
  if (video.url.includes('youtu')) {
    return `https://www.youtube.com/embed/${video?.url?.split('/')[3]}`;
  }
  if (video.url.includes('vimeo')) {
    return video.url.replace('vimeo.com', 'player.vimeo.com/video');
  }
  if (video.url.includes('twitch')) {
    return `${video.url.replace(
      'twitch.tv',
      'player.twitch.tv/?video='
    )}&parent=${window.location.hostname}`;
  }
  return video?.url;
};
