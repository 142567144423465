import React from 'react';
import { DotIcon } from '@dinbog/ui';
import userFallback from '../../../../../../../public/images/fallback/user.png';

interface NotificationDropdownItemProps {
  item: {
    photo: string;
    name: string;
    action: string;
    time: string;
  };
}

function NotificationDropdownItem({ item }: NotificationDropdownItemProps) {
  const getMessage = (action) => {
    switch (action) {
      case 'comment':
        return 'mentioned you in a comment';
      case 'like':
        return 'liked your post';
      default:
        return '';
    }
  };
  return (
    <button
      type="button"
      className="flex w-full text-left border-none"
      // onClick={() => onClick()}
    >
      <div className="dropdown-item-alt py-2 flex justify-between gap-x-2">
        <div className="flex gap-4">
          <img
            className="rounded-full w-8 h-8"
            src={item?.photo ?? userFallback.src}
            alt="user"
          />
          <div className="text-text-black text-sm">
            <span className="font-semibold">{item?.name}</span>
            <span className=""> {getMessage(item?.action)}</span>
          </div>
        </div>
        <div className="flex flex-col text-xs">
          <span className="text-text-gray text-sm">{item?.time}</span>
          {/* <DotIcon className="w-5 h-5 text-text-gray mx-auto" /> */}
        </div>
      </div>
    </button>
  );
}

export default NotificationDropdownItem;
