import React from 'react';
import {
  CameraIcon,
  Input,
  MultipleSelect,
  Select,
  TextArea,
} from '@dinbog/ui';
import {
  Album,
  Category,
  DocumentModel,
  IOption,
  Track,
  albumPrivacyValues,
} from '@dinbog/models';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { DocumentForm } from '../../../document';
import NewTrackCard from './NewTrackCard';
import TrackCard from './TrackCard';
import MediaCoverFormContent from '../../MediaCoverFormContent';

interface AudioFormContentProps {
  disabled?: boolean;
  categories: Category[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  media: DocumentModel[];
  categoriesDefault?: IOption[];
  handleDeleteTrack: (trackId: string) => Promise<any>;
}

/**
 * UPLOAD AUDIO/ALBUM FORM CONTENT TO CREATE OR UPDATE A AUDIO/ALBUM
 */
function AudioFormContent({
  disabled = false,
  categories,
  updateURLs,
  media,
  categoriesDefault = [],
  handleDeleteTrack,
}: AudioFormContentProps) {
  const { i18n, t } = useTranslation();

  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext<Album>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tracks',
    rules: { minLength: 1 },
  });

  const formValidations = {
    title: { required: t('required') },
    description: { required: t('required') },
    privacy: { required: t('required') },
    categories: { required: t('required') },
    tracks: { required: t('required') },
  };

  return (
    <>
      <div className="flex lg:flex-row flex-col lg:gap-x-8 gap-y-4 lg:items-start items-center">
        <MediaCoverFormContent media={media} updateURLs={updateURLs} />
        <div className="space-y-4 flex flex-col w-full">
          <div className="w-full">
            <MultipleSelect
              disabled={disabled}
              options={categories?.map((cat) => ({
                name: cat?.name[i18n?.language ?? 'en'],
                _id: cat?._id,
              }))}
              label={t('auth.signUp.wizard.audio.category.title') ?? ''}
              onChangeSelect={(selected) => {
                setValue(
                  'categories',
                  selected.map((cat) => cat._id)
                );
              }}
              defaultSelect={categoriesDefault}
              {...register('categories', formValidations.categories)}
            />
            <span className="text-sm text-danger-300">
              {errors.categories && errors.categories.type === 'required'
                ? t('required')
                : ''}
            </span>
          </div>
          <div className="w-full">
            <Input
              disabled={disabled}
              isRequired
              {...register('title', formValidations.title)}
              className="w-full"
              error={errors.title?.message as string}
              label={t('auth.signUp.wizard.audio.name.title') ?? ''}
              placeholder={t('auth.signUp.wizard.audio.name.description') ?? ''}
            />
          </div>

          <div className="w-full">
            <TextArea
              disabled={disabled}
              isRequired
              {...register('description', formValidations.description)}
              error={errors.description?.message as string}
              className="h-24"
              label={t('auth.signUp.wizard.audio.albumDescription.title') ?? ''}
              placeholder={
                t('auth.signUp.wizard.audio.albumDescription.description') ?? ''
              }
            />
          </div>
          <div className="w-full">
            <Select
              disabled={disabled}
              isRequired
              {...register('privacy', formValidations.privacy)}
              error={errors.privacy?.message as string}
              className="w-full"
              label={t('portfolio.privacy.title') ?? ''}
            >
              <option key={-1} value="" disabled>
                {t('portfolio.privacy.values.default')}
              </option>
              {albumPrivacyValues?.map((privacy, i) => (
                <option key={`${i + 1}-${privacy}`} value={privacy ?? ''}>
                  {t(`portfolio.privacy.values.${privacy}`)}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="space-y-2 pt-4">
        <h4 className="font-semibold">
          {t('auth.signUp.wizard.audio.tracks.title')}
        </h4>
        <p className="">{t('auth.signUp.wizard.audio.tracks.description')}</p>
        <span className="text-sm text-danger-300">
          {errors.tracks ? t('required') : ''}
        </span>
        <NewTrackCard
          tracks={fields}
          append={append}
          {...register('tracks', formValidations.tracks)}
        />
        {fields.map((track, idx) => (
          <TrackCard
            track={track as Track}
            idx={idx + 1}
            key={idx}
            remove={remove}
            handleDeleteTrack={handleDeleteTrack}
          />
        ))}
      </div>
    </>
  );
}

export default AudioFormContent;
