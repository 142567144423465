"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  MultimediaTypeEnum: () => MultimediaTypeEnum,
  albumPrivacyValues: () => albumPrivacyValues,
  applicationStatusValues: () => applicationStatusValues,
  bookPrivacyValues: () => bookPrivacyValues,
  businessUserRole: () => businessUserRole,
  eventTypeEnum: () => eventTypeEnum,
  genderOptions: () => genderOptions,
  measureEnum: () => measureEnum,
  privacyEnum: () => privacyEnum,
  profileTypes: () => profileTypes,
  reportTypes: () => reportTypes,
  specificationTypeEnum: () => specificationTypeEnum,
  statusEnum: () => statusEnum,
  trackPrivacyValues: () => trackPrivacyValues,
  vacantSpecificationTypeEnum: () => vacantSpecificationTypeEnum,
  vacantStatusEnum: () => vacantStatusEnum,
  videoPrivacyValues: () => videoPrivacyValues,
  workReferencePrivacyValues: () => workReferencePrivacyValues
});
module.exports = __toCommonJS(src_exports);
var measureEnum = ["m", "kg", "s", "A", "K", "fps", "m/s", "m2"];
var specificationTypeEnum = [
  "select",
  "number",
  "text",
  "boolean",
  "multiselect"
];
var vacantSpecificationTypeEnum = [
  "select",
  "number",
  "text",
  "boolean",
  "range",
  "tags",
  "multiselect"
];
var MultimediaTypeEnum = ["video", "audio", "image", "url"];
var businessUserRole = ["admin", "user", "editor"];
var vacantStatusEnum = ["active", "draft", "closed"];
var eventTypeEnum = ["casting", "event", "job-offer"];
var privacyEnum = [
  "public",
  "private",
  "followers-connections",
  "connections"
];
var bookPrivacyValues = [
  "public",
  "private",
  "followers-connections",
  "connections"
];
var trackPrivacyValues = [
  "public",
  "private",
  "followers-connections",
  "connections"
];
var albumPrivacyValues = [
  "public",
  "private",
  "followers-connections",
  "connections"
];
var videoPrivacyValues = [
  "public",
  "private",
  "followers-connections",
  "connections"
];
var workReferencePrivacyValues = [
  "public",
  "private",
  "followers-connections",
  "connections"
];
var profileTypes = ["person", "business"];
var applicationStatusValues = [
  "initial",
  "preselected",
  "selected",
  "rejected"
];
var reportTypes = [
  "inappropriate",
  "spam",
  "other"
];
var statusEnum = ["pending", "approved", "rejected", "removed"];
var genderOptions = ["male", "female", "preferNotToSay", "other"];
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  MultimediaTypeEnum,
  albumPrivacyValues,
  applicationStatusValues,
  bookPrivacyValues,
  businessUserRole,
  eventTypeEnum,
  genderOptions,
  measureEnum,
  privacyEnum,
  profileTypes,
  reportTypes,
  specificationTypeEnum,
  statusEnum,
  trackPrivacyValues,
  vacantSpecificationTypeEnum,
  vacantStatusEnum,
  videoPrivacyValues,
  workReferencePrivacyValues
});
