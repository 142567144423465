import React from 'react';
import { Modal } from '@dinbog/ui';
import { Category, Subcategory, User } from '@dinbog/models';
import { t } from 'i18next';
import { useScreenSize } from '../../../../../../../hooks';
import { useProfile } from '../../../../../../../hooks/api/profiles';
import { PersonItem } from '../../../../../../shared';
import VacancyCategories from './vacancyCategories/VacancyCategories';
import ProfileSpecs from '../../../../../../profile/shared/profileInfoCard/profileSpecs/ProfileSpecs';

export default function UserInfoModal({
  profileId,
  isOpen = false,
  setOpen,
}: {
  profileId: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { screenSize } = useScreenSize();
  const { data, isLoading } = useProfile({
    filter: JSON.stringify({ _id: profileId }),
  });

  return (
    <Modal
      className="bg-white h-auto md:w-4/5 w-11/12"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <Modal.Header exitBtn />
      <Modal.Body>
        <PersonItem
          user={{ profile: data }}
          isHorizontal={screenSize > 640}
          size="md"
          isModal
        >
          <div className="">
            {/* flex flex-col items-center */}
            <PersonItem.Header user={{ profile: data }} />
            <PersonItem.Body
              user={{
                profile: data,
              }}
              withLocation
            />
          </div>
        </PersonItem>
        {/* details */}
        <div className="space-y-4">
          {/* categories and subcategories */}
          <h3 className="font-semibold text-lg">
            {t('events.casting.vacancies.categoriesAndSubcategories')}
          </h3>
          <VacancyCategories
            categories={data?.categories as Category[]}
            subcategories={data?.subcategories as Subcategory[]}
          />
        </div>
        <div className="space-y-4">
          {/* specs */}
          <h3 className="font-semibold text-lg">
            {t('events.casting.vacancies.specifications')}
          </h3>
          <ProfileSpecs
            profile={data}
            userId={(data?.user as User)?._id}
            isModal
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
