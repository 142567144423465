import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CircledIconProps {
  icon?: IconProp;
  type?: 'info' | 'danger';
  children?: React.ReactNode;
}

export default function CircledIcon({
  icon,
  type = 'info',
  children = null,
}: CircledIconProps) {
  let bgColor = 'bg-background-300';
  let textColor = 'text-primary-500';

  if (type === 'danger') {
    bgColor = 'bg-danger-100';
    textColor = 'text-danger-300';
  }
  return (
    <div className={`rounded-full p-8 shadow ${bgColor}`}>
      {children || (
        <FontAwesomeIcon
          icon={icon as IconProp}
          className={`w-12 h-12 ${textColor}`}
        />
      )}
    </div>
  );
}
