import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { Button, TextArea } from '@dinbog/ui';
import {
  Post,
  DocumentModel,
  User,
  MultimediaType,
  Address,
} from '@dinbog/models';
import {
  faMapMarkerAlt,
  faPaperclip,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import path from 'path';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUser, useLocation, useNotify, useScreenSize } from '../../hooks';
import { useSignS3 } from '../../hooks/api/s3';
import { useCreatePost } from '../../hooks/api/posts';
import { uploadFile } from '../../lib/uploadFile';
import { documentToMedia } from '../../utils/formatDocumentModel';
import { Card } from '../shared';
import { DocumentForm } from '../document';
import PostLocationModal from './PostLocationModal';
import PostAddMentionsModal from './postMentions/PostAddMentionsModal';
import PostMentions from '../shared/post/PostMentions';
import PostLocation from '../shared/post/PostLocation';

const allowedExtensions = [
  '.png',
  '.jpeg',
  '.jpg', // image extensions
  '.mp4',
  '.mov',
  '.m4a',
  '.avi', // video extensions
  '.mp3',
  '.wav',
  '.aac',
  '.ogg', // audio extensions
];

export default function CreatePostCard() {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const notify = useNotify();
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const uploadToS3Mutation = useSignS3();
  const createPostMutation = useCreatePost();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [mentionedUsers, setMentionedUsers] = React.useState([]);
  const [attachments, setAttachments] = React.useState<DocumentModel[]>([]);
  const updateURLs = React.useCallback(setAttachments, [setAttachments]);
  const [attachmentType, setAttachmentType] =
    React.useState<MultimediaType>('image');
  const [openLocationModal, setOpenLocationModal] =
    React.useState<boolean>(false);
  const [openMentionsModal, setOpenMentionsModal] =
    React.useState<boolean>(false);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const queryClient = useQueryClient();

  // resize text area according to text length
  /* React.useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;
      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef]); // tengo que pasarle el value del text area también */

  // form related things
  const methods = useForm<any>({
    defaultValues: {
      country:
        user?.currentUser?.type === 'business'
          ? user?.currentUser?.business?.headquarter
          : user?.currentUser?.address?.country ?? '',
      state:
        user?.currentUser?.type === 'business'
          ? user?.currentUser?.business?.state
          : user?.currentUser?.address?.state ?? '',
      city:
        user?.currentUser?.type === 'business'
          ? user?.currentUser?.business?.city
          : user?.currentUser?.address?.city ?? '',
    },
  });

  const formValidations = {
    text: { required: t('required') },
  };

  // manage attachments
  const removeAttachment = (idx) => {
    const newAttachments = attachments.filter((attachment, i) => i !== idx);
    setAttachments(newAttachments);
  };

  const validateAttachments = (attachment: DocumentModel) => {
    if (
      attachment.file.type === 'url' ||
      !allowedExtensions.includes(path.extname(attachment.file.name))
    ) {
      notify(t('post.invalidFileType'), 'error');
      setAttachments([]);
      return false;
    }
    return true;
  };

  const handleAttachmentsUpload = async () => {
    // upload media to s3
    const newAttachments = attachments?.map(async (attachment) => {
      if (!validateAttachments(attachment)) {
        return;
      }
      const newAtt = await uploadFile(
        attachment,
        setUploading,
        updateURLs,
        uploadToS3Mutation,
        notify,
        t
      );
      return newAtt;
    });
    return newAttachments;
  };

  const onSubmit = async (data) => {
    try {
      if (disabled) return;
      setDisabled(true);

      if (attachments?.length === 0) {
        return notify(t('post.notifs.attachSomething'), 'error');
      }

      if (!attachments.every(validateAttachments)) {
        setDisabled(false);
        return;
      }

      const returnedAttachments = await handleAttachmentsUpload();

      const fulfilledPromises = await Promise.all(returnedAttachments);

      const attachmentsAsDocs = attachments?.map((att, idx) => ({
        src: fulfilledPromises?.[idx]?.url ?? att?.src,
        name: att?.name,
        id: att?.id,
        file: att?.file,
      }));

      const attachmentsAsMedia =
        attachmentsAsDocs?.length > 0
          ? documentToMedia(attachmentsAsDocs)
          : null;

      const _data = await createPostMutation.mutateAsync({
        user: user?.currentUser?.user as User,
        text: data?.text,

        ...(attachments?.length > 0
          ? {
              multimedia: attachmentsAsMedia?.map((att) => ({
                ...att,
                type: att?.type?.split('/')?.[0] as MultimediaType, // esto no va a servir para url
              })),
            }
          : { multimedia: undefined }),

        // item no se usará en v1. posteriormente va a servir para attach cosas del portafolio al post
        /* item: z
            .object({
            title: z.string(),
            description: z.string().optional(),
            multimedia: z.array(createMultimediaInput).optional(),
            collectionId: z.string(),
            collectionName: z.string(),
            })
            .optional(), */

        mentions: mentionedUsers?.map((u) => u?.profile?.user?._id),

        address: {
          country: data?.country,
          state: data?.state,
          city: data?.city,
        },
      });
      if (_data) {
        notify(t('success'), 'success');
        queryClient.refetchQueries([
          'talentPosts',
          { user: (user?.currentUser?.user as User)?._id },
        ]);
        queryClient.refetchQueries(['feed']);
        // empty inputs
        methods?.reset();
        setAttachments([]);
        setMentionedUsers([]);
      } else {
        notify(t('error'), 'error');
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      // refetch posts
      setDisabled(false);
    }
  };

  const getAddress = () => {
    const [country, state, city] = methods.getValues([
      'country',
      'state',
      'city',
    ]);
    return {
      country,
      state,
      city,
    };
  };

  return (
    <Card className="p-7 w-full max-w-[800px]">
      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-3"
          method="post"
          onSubmit={methods?.handleSubmit(onSubmit)}
        >
          <div className="flex gap-4">
            <img
              src={
                user?.currentUser?.photo?.url ??
                (user?.currentUser?.type === 'business'
                  ? '/images/fallback/business.jpg'
                  : '/images/fallback/user.png')
              }
              alt=""
              className={`w-12 h-12 border-[1px] border-neutral-200 object-cover ${
                user?.currentUser?.type === 'business'
                  ? 'rounded'
                  : 'rounded-full'
              }`}
            />
            <TextArea
              ref={textAreaRef}
              placeholder="Write something..."
              className="border-none p-0 h-fit -mt-2"
              {...methods?.register('text', formValidations?.text)}
            />
          </div>
          {/* attached media */}
          <div className="flex flex-wrap gap-4">
            {attachments?.map((attachment, idx) => (
              <div
                key={idx}
                className="sm:w-[200px] w-48 sm:h-[200px] h-48 relative bg-transparent flex flex-row justify-center items-center"
              >
                {/* x button to delete item */}
                <button
                  aria-label="Delete"
                  type="button"
                  onClick={() => removeAttachment(idx)}
                  className="absolute z-10 -top-1 -right-1 bg-danger-300 text-text-white h-4 w-4 rounded-full flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faTimes} className="w-2 h-2" />
                </button>
                <img
                  alt={attachment?.name}
                  src={attachment?.src as string}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
          </div>
          <div className="flex md:flex-row flex-col md:justify-between">
            {/* tagged users */}
            {mentionedUsers?.length > 0 ? (
              <PostMentions
                mentions={mentionedUsers?.map((u) => u?.profile)}
                isCreate
              />
            ) : null}
            {/* location */}
            <PostLocation location={(getAddress() as Address) ?? null} />
          </div>
          <hr className="w-full bg-neutral-200" />
          <div className="flex justify-between md:text-base text-sm">
            <div className="flex md:gap-4 items-center">
              {/* <Button
                className="btn-secondary flex gap-3 px-12 items-center w-fit"
                onClick={() => console.log('attachments')}
                size="md"
                type="button"
              >
                <FontAwesomeIcon icon={faPaperclip} className="w-4 h-4" />
                <span>{t('post.addAttachments')}</span>
              </Button> */}
              <DocumentForm
                dropZoneClassName="md:btn-secondary h-fit py-2 px-4"
                documents={attachments}
                updateURLs={updateURLs}
                dropZoneText={screenSize > 768 ? t('post.addAttachments') : ''}
                dropZoneTextClassName="text-primary-500"
                horizontal
                customIcon={
                  <FontAwesomeIcon icon={faPaperclip} className="w-4 h-4" />
                }
                accept={{
                  'image/*': ['.png', '.jpeg', '.jpg'],
                  'video/*': ['.mp4', '.mov', '.m4a', '.avi'],
                  'audio/*': ['.mp3', '.wav', '.aac', '.ogg'],
                }}
                maximumAmount={1}
              />
              <div className="flex gap-0">
                <Button
                  tooltip={t('post.addLocation')}
                  className="flex gap-3 px-12 items-center w-fit text-neutral-500"
                  onClick={() => setOpenLocationModal(true)}
                  size="md"
                  type="button"
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="w-4 h-4" />
                  <span className="md:block hidden">
                    {t('post.addLocation')}
                  </span>
                </Button>
                <Button
                  tooltip={t('post.tagPeople')}
                  className="flex gap-3 px-12 items-center w-fit text-neutral-500"
                  onClick={() => setOpenMentionsModal(true)}
                  size="md"
                  type="button"
                >
                  <FontAwesomeIcon icon={faUser} className="w-4 h-4" />
                  <span className="md:block hidden">{t('post.tagPeople')}</span>
                </Button>
              </div>
            </div>
            <Button
              tooltip={t('post.postBtn')}
              className="btn-primary px-12 w-fit"
              size="md"
              type="submit"
            >
              <span>{t('post.postBtn')}</span>
            </Button>
          </div>
        </form>
        <PostLocationModal
          isOpen={openLocationModal}
          setOpen={setOpenLocationModal}
          currentAddress={user?.profile?.address}
        />
        <PostAddMentionsModal
          isOpen={openMentionsModal}
          setOpen={setOpenMentionsModal}
          mentions={mentionedUsers}
          setMentions={setMentionedUsers}
        />
      </FormProvider>
    </Card>
  );
}
