import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { PennantIcon } from '@dinbog/ui';
import useOutsideClick from '../../../hooks/useOutsideClick';

interface MediaOptionsDropdownProps {
  onEdit?: () => void;
  onDelete?: () => void;
  onReport?: () => void;
  setOpenMediaOptionsDropdown?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MediaOptionsDropdown({
  onEdit,
  onDelete,
  onReport,
  setOpenMediaOptionsDropdown,
}: MediaOptionsDropdownProps) {
  const { t } = useTranslation();
  const dropdownRef = React.useRef(null);

  useOutsideClick(dropdownRef, () =>
    setOpenMediaOptionsDropdown ? setOpenMediaOptionsDropdown(false) : {}
  );

  return (
    <div
      ref={dropdownRef}
      className="absolute top-4 right-0 bg-white shadow border flex flex-col w-36 z-10 rounded"
    >
      {onEdit ? (
        <button
          type="button"
          className="flex flex-row items-center gap-x-2 px-4 py-2 hover:bg-neutral-200 active:opacity-80"
          onClick={onEdit}
        >
          <FontAwesomeIcon
            icon={faPencilAlt}
            className="h-4 w-4 text-text-black"
          />
          <span className="">{t('edit')}</span>
        </button>
      ) : null}
      {onDelete ? (
        <button
          type="button"
          className="flex flex-row items-center gap-x-2 px-4 py-2 hover:bg-neutral-200 active:opacity-80"
          onClick={onDelete}
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="h-4 w-4 text-text-black"
          />
          <span className="">{t('delete')}</span>
        </button>
      ) : null}
      {onReport ? (
        <button
          type="button"
          className="flex flex-row items-center gap-x-2 px-4 py-2 hover:bg-neutral-200 active:opacity-80 text-danger-300"
          onClick={onReport}
        >
          <PennantIcon className="h-4 w-4" />
          <span className="">{t('profile.otherActions.report')}</span>
        </button>
      ) : null}
    </div>
  );
}
