import React from 'react';
import { Video } from '@dinbog/models';
import { useFormContext } from 'react-hook-form';
import { TrashIcon } from '@dinbog/ui';

function UploadedVideoInfo() {
  const { setValue, getValues } = useFormContext<Video>();

  return (
    <div className="flex md:gap-12 gap-6 justify-center items-center">
      <video
        className="w-full md:max-w-md rounded-xl h-[200px]"
        controls
        poster={getValues('cover')?.url}
        src={getValues('multimedia')?.url}
      >
        <track
          src="captions_en.vtt"
          kind="captions"
          srcLang="en"
          label="english_captions"
        />
        <track
          src="captions_es.vtt"
          kind="captions"
          srcLang="es"
          label="spanish_captions"
        />
      </video>
      <button
        type="button"
        className=" w-fit h-fit"
        onClick={(e) => {
          e.preventDefault();
          setValue('multimedia', null);
        }}
        aria-label="Delete"
      >
        <TrashIcon className="h-6 w-6 text-danger-300 hover:opacity-80 " />
      </button>
    </div>
  );
}

export default UploadedVideoInfo;
