import React from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import { getFlag } from '../../../lib/getFlag';
import Card from '../card';

interface ProfileInfoCardPreviewProps {
  user: any;
}

function ProfileInfoCardPreview({ user }: ProfileInfoCardPreviewProps) {
  const { t, i18n } = useTranslation();
  const [showAllTalents, setShowAllTalents] = React.useState(false);

  const showSeparationDot = (idx: number) =>
    (idx !== 3 &&
      idx < user.currentUser.categories.length - 1 &&
      !showAllTalents) ||
    (idx !== user.currentUser.categories.length - 1 && showAllTalents);
  return (
    <Card className="relative min-w-[225px]">
      {user?.currentUser?.cover ? (
        <img
          src={user?.currentUser?.cover?.url}
          alt=""
          className="h-[80px] w-full rounded-t object-cover"
        />
      ) : (
        <div className="w-full h-[80px] rounded-t bg-linearGradient" />
      )}
      <div className="flex relative flex-col items-center w-full bottom-[2rem] space-y-2 px-2">
        <div className="relative">
          <img
            src={
              user?.currentUser?.photo?.url ??
              (user?.currentUser?.type === 'business'
                ? '/images/fallback/business.jpg'
                : '/images/fallback/user.png')
            }
            alt="user"
            className={`h-20 w-20 ${
              user?.currentUser?.type === 'person'
                ? 'rounded-full'
                : 'rounded-lg'
            } object-cover object-center border-2 shadow border-background-50`}
          />
        </div>
        <span className="md:text-xl text-lg font-semibold text-text-black">
          {user?.currentUser?.firstName} {user?.currentUser?.lastName}
        </span>
        {/* show badge if user is verified */}
        <div className="relative flex w-full flex-wrap justify-center text-text-gray text-center">
          {user?.currentUser?.categories
            ?.slice(
              0,
              // eslint-disable-next-line no-nested-ternary
              !showAllTalents
                ? user?.currentUser?.categories?.length < 4
                  ? user?.currentUser?.categories?.length
                  : 4
                : user?.currentUser?.categories?.length
            )
            .map((category, idx) => (
              <span
                key={category._id}
                className="text-text-gray flex items-center truncate"
              >
                {category.name?.[i18n.language ?? 'en']}
                {showSeparationDot(idx) ? (
                  <span className="p-1"> | </span>
                ) : null}
              </span>
            ))}
          {user?.currentUser?.categories?.length > 4 ? (
            <button
              type="button"
              className="rounded ml-2 font-semibold text-primary-500 text-sm"
              onClick={() => setShowAllTalents(!showAllTalents)}
            >
              {showAllTalents ? t('less') : `... ${t('all')}`}
            </button>
          ) : null}
        </div>
        <div className="gap-1 flex text-text-gray md:text-base text-sm  text-center">
          <span className="">
            {user?.currentUser?.address?.country
              ? getFlag(user?.currentUser?.address?.country)
              : null}
          </span>
          <span className="">
            {Country.getCountryByCode(user?.currentUser?.address?.country)
              ?.name ??
              Country.getCountryByCode(user?.currentUser?.business?.headquarter)
                ?.name}
            ,{' '}
            {user?.currentUser?.address?.city ??
              user?.currentUser?.business?.city}
          </span>
        </div>
      </div>
    </Card>
  );
}

export default ProfileInfoCardPreview;
