import React from 'react';
import { Vacant, Event as EventModel, Application } from '@dinbog/models';
import EventCard from './EventCard';

export default function EventList({
  items,
  isVacancy = false,
}: {
  items: EventModel[] | (Vacant & { application?: Application })[];
  isVacancy?: boolean;
}) {
  return (
    <div className="flex flex-wrap gap-16 justify-center md:justify-normal">
      {items?.map((item) => (
        <EventCard
          key={item?._id}
          item={item?.event ?? item}
          vacancy={isVacancy ? item : null}
        />
      ))}
    </div>
  );
}
