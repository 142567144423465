import React from 'react';
import { useRouter } from 'next/router';
import TabItem from './TabItem';
import { TTabItem } from '../../../models';

interface TabProps {
  items: TTabItem[];
  currentItem: string;
  setCurrentItem: React.Dispatch<React.SetStateAction<string>>;
  isQueryParam?: boolean;
  zIndex?: string;
}

function Tab({
  items,
  currentItem,
  setCurrentItem,
  isQueryParam = false,
  zIndex,
}: TabProps) {
  const onClick = (key: string) => {
    setCurrentItem(key);
    if (isQueryParam) {
      pushQueryParam(key);
    }
  };
  const router = useRouter();
  const pushQueryParam = (tab: string) => {
    if (router?.pathname === '/[slug]') {
      router.push(
        {
          pathname: `${router.pathname}`,
          query: {
            slug: router?.query?.slug,
            tab,
          },
        },
        undefined,
        { shallow: true }
      );
      return;
    }
    if (router?.pathname === '/briefcase/castings/[slug]') {
      router.push(
        {
          pathname: `${router.pathname}`,
          query: {
            slug: router?.query?.slug,
            ...router.query,
            tab,
          },
        },
        undefined,
        { shallow: true }
      );
      return;
    }
    if (router?.pathname === '/search') {
      router.push(
        {
          pathname: `${router.pathname}`,
          query: {
            q: router?.query?.q,
            tab,
          },
        },
        undefined,
        { shallow: true }
      );
      return;
    }
    router.push(
      {
        pathname: `${router.pathname}`,
        query: {
          tab,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  React.useEffect(() => {
    if (isQueryParam) {
      if (!router?.query?.tab && router.isReady) {
        pushQueryParam(items[0].itemKey);
      } else {
        setCurrentItem(router.query.tab as string);
      }
    }
  }, [router?.query?.tab, router.isReady]);

  return (
    <div
      className={`w-full h-15 flex-row md:gap-2 flex border-b border-neutral-200 bg-background-50 overflow-x-auto scrollbar-hide sticky top-[80px] ${
        zIndex || 'z-50'
      } `}
    >
      {items.map((item, idx) => (
        <TabItem
          key={item.itemKey}
          itemKey={item.itemKey}
          title={item.title}
          number={item.number}
          onClick={onClick}
          active={currentItem === item.itemKey}
        />
      ))}
    </div>
  );
}

export default Tab;
