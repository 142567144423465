import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { validateEmail } from 'avilatek-utils';
import { User } from '@dinbog/models';
import { Button, Input } from '@dinbog/ui';
import { useNotify, useUser } from '../../../hooks';
import { useChangeCurrentEmail } from '../../../hooks/api/auth';
import DisableAccountModal from './DisableAccountModal';

export default function AccountForm() {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const methods = useForm<any>({
    defaultValues: {
      email: (user?.currentUser?.user as User)?.email ?? '',
    },
  });

  const formValidations = {
    email: {
      required: t('required'),
      validate: (value) => validateEmail(value),
    },
    password: {
      required: t('required'),
    },
  };

  const changeEmailMutation = useChangeCurrentEmail(user?.token);

  const onSubmit = async (e) => {
    if (disabled) return;
    setDisabled(true);
    try {
      await changeEmailMutation.mutateAsync(
        {
          user: (user?.currentUser?.user as User)?._id as string,
          newEmail: e.email,
          password: e.password,
        },
        {
          onSuccess: () => {
            notify(t('settings.notifs.success'), 'success');
          },
        }
      );
    } catch (err) {
      notify(
        err?.response?.data?.message ?? t('settings.notifs.error'),
        'error'
      );
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      <DisableAccountModal isOpen={isOpen} setOpen={setOpen} />
      <FormProvider {...methods}>
        <form method="post" onSubmit={methods?.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-6">
            <h3 className="font-semibold text-lg">{t('settings.account')}</h3>
            <hr className="w-full bg-neutral-200" />
            <div className="w-full md:w-1/2">
              <Input
                type="email"
                label={t('settings.newEmail')}
                {...methods.register('email', formValidations.email)}
                error={methods.formState.errors.email?.message as string}
                placeholder={t('settings.newEmailPlaceholder') ?? ''}
              />
            </div>
            <div className="w-full md:w-1/2">
              <Input
                label={t('settings.password')}
                type="password"
                {...methods.register('password', formValidations.password)}
                error={methods.formState.errors.password?.message as string}
                placeholder={t('settings.passwordPlaceholder') ?? ''}
                autoComplete="off" // Disable browser autocomplete
                onPaste={(e) => e.preventDefault()} // Disable pasting
              />
            </div>
            <div className="w-full ">
              <Button
                tooltip={t('settings.changeEmail')}
                type="submit"
                className="btn-primary"
              >
                {t('settings.changeEmail')}
              </Button>
            </div>
            <h3 className="font-semibold text-lg">
              {t('settings.deactivateAccount')}
            </h3>
            <hr className="w-full bg-neutral-200" />
            <p>{t('settings.deactivateAccountDescription')}</p>
            <div className="w-full ">
              <Button
                tooltip={t('settings.deactivateAccount')}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(true);
                }}
                className="border border-danger-300 text-danger-300 font-normal"
              >
                {t('settings.deactivateAccount')}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
