import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Profile, Specification } from '@dinbog/models';
import SpecsColumn from '../../../../shared/specs/SpecsColumn';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

interface BusinessProfileSpecsProps {
  profile: Profile;
  userId: string;
}

function BusinessProfileSpecs({ profile, userId }: BusinessProfileSpecsProps) {
  const [specs, setSpecs] = React.useState<Specification[]>([]);
  const [distributedSpecs, setDistributedSpecs] = React.useState<
    Specification[][]
  >([]);

  const distributeSpecs = () => [
    specs.slice(0, Math.floor(specs.length / 2) + 1),
    specs.slice(Math.floor(specs.length / 2) + 1, specs.length),
  ];

  const getProfileInfo = () => [
    {
      name: 'Branches', // i18n
      value: profile?.business?.branches?.join(' | ') ?? '-',
    },
    {
      name: 'Website', // i18n
      value: profile?.business?.websiteUrl,
    },
  ];

  React.useEffect(() => {
    setDistributedSpecs(distributeSpecs());
  }, [profile, userId, specs]);

  return (
    <div className="w-full flex flex-wrap ">
      <SpecsColumn key="spec-col-0" idx={0} info={getProfileInfo()} />
    </div>
  );
}

export default BusinessProfileSpecs;
