import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Business,
  BusinessUserRole,
  Profile,
  StatusEnum,
  User,
} from '@dinbog/models';
import SelectMembers from './SelectMembers';

interface MembersFormContentProps {
  members: (User & {
    profile?: Profile;
    business?: Business;
    role?: BusinessUserRole;
    isNew?: boolean;
    status?: StatusEnum;
  })[];
  setMembers: React.Dispatch<
    React.SetStateAction<
      (User & {
        profile?: Profile;
        business?: Business;
        role?: BusinessUserRole;
        isNew?: boolean;
        status?: StatusEnum;
      })[]
    >
  >;
}

/**
 * MEMBERS FORM CONTENT TO CREATE OR UPDATE A BUSINESS MEMBERS
 */
function MembersFormContent({ members, setMembers }: MembersFormContentProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-[30px]">
      {/* select admins  */}
      <div>
        <h4 className=" text-base font-semibold">
          {t(`auth.signUp.wizard.selectAdmin.addAdmin`)}
        </h4>
        <p className="mb-5">
          {t(`auth.signUp.wizard.selectAdmin.addAdminDescription`)}
        </p>
        <SelectMembers
          selectedUsers={members}
          setSelectedUsers={setMembers}
          type="admin"
        />
      </div>
      {/* select editors */}
      <div>
        <h4 className=" text-base font-semibold">
          {t(`auth.signUp.wizard.selectAdmin.addEditor`)}
        </h4>
        <p className="mb-5">
          {t(`auth.signUp.wizard.selectAdmin.addEditorDescription`)}
        </p>
        <SelectMembers
          selectedUsers={members}
          setSelectedUsers={setMembers}
          type="editor"
        />
      </div>
    </div>
  );
}

export default MembersFormContent;
