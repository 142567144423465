import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@dinbog/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import { Tab } from '../../../shared';
import ProfileCover from './ProfileCover';
import ProfilePicture from './ProfilePicture';
import ProfileSummaryInfo from './ProfileSummaryInfo';
import ProfileSpecs from './profileSpecs/ProfileSpecs';
import ProfileCompletionCard from './profileCompletion/ProfileCompletionCard';
import RelationsButtons from './RelationsButtons';
import ProfileRelationsCount from './ProfileRelationsCount';
import BusinessProfileSpecs from './profileSpecs/BusinessProfileSpecs';

interface ProfileInfoCardProps {
  user: any;
  hideDescription?: boolean;
  block?: { to: boolean; from: boolean };
  findUserBySlug: () => void;
}

function ProfileInfoCard({
  user,
  hideDescription = false,
  block,
  findUserBySlug,
}: ProfileInfoCardProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = React.useState('about');
  const [cover, setCover] = React.useState(user?.profile?.cover ?? null);
  React.useEffect(() => {
    setCover(user?.profile?.cover ?? null);
  }, [user?.profile?.cover]);
  const items = React.useMemo(
    () =>
      user?.profile?.categories?.length > 0
        ? [
            {
              title:
                user?.profile?.type === 'person'
                  ? t('profile.infoTabs.about')
                  : t('profile.infoTabs.aboutUs'),
              itemKey: 'about',
              component: <div className="">{user?.profile?.description}</div>,
            },
            {
              title: t('profile.infoTabs.specifications'),
              itemKey: 'specs',
              component:
                user?.profile?.type === 'person' ? (
                  <ProfileSpecs profile={user?.profile} userId={user?._id} />
                ) : (
                  <BusinessProfileSpecs
                    profile={user?.profile}
                    userId={user?._id}
                  />
                ),
            },
            /* {
        title: t('profile.infoTabs.recommendations'),
        itemKey: 'about',
        component: <div className="">recommendations</div>,
      }, */
          ]
        : [
            {
              title: t('profile.infoTabs.about'),
              itemKey: 'about',
              component: <div className="">{user?.profile?.description}</div>,
            },
          ],
    [user]
  );

  const profileCompleted = React.useMemo(
    () =>
      user?.profile?.stats?.books > 0 &&
      user?.profile?.stats?.videos > 0 &&
      user?.profile?.stats?.audios > 0 &&
      user?.profile?.stats?.workReferences > 0,
    [user]
  );

  return (
    <div className="relative lg:mt-20">
      <ProfileCover cover={cover} setCover={setCover} />
      <div className="flex md:px-12 px-6 gap-6 md:flex-row flex-col">
        <div className="w-full">
          <div className="flex relative flex-row items-end w-full bottom-[2rem] gap-y-2">
            <ProfilePicture
              profilePicture={user?.profile?.photo ?? null}
              type={user?.profile?.type}
            />
            <div className="flex justify-between md:w-auto w-full xl:mt-0 sm:mt-10 mt-0">
              <ProfileSummaryInfo user={user} />
              <div className="block md:hidden">
                {router?.pathname === '/profile' ? (
                  <div className="flex flex-col items-end gap-4 w-fit">
                    <Button
                      className="text-primary-500 gap-3 px-12 items-center w-fit"
                      onClick={() => router?.push('/settings?tab=profile')}
                      size="md"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="w-4 h-4" />
                    </Button>
                  </div>
                ) : (
                  <RelationsButtons
                    user={user}
                    block={block}
                    refreshUser={findUserBySlug}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="block sm:hidden h-fit sm:m-0 mb-8 ml-4">
            <ProfileRelationsCount user={user} />
          </div>
          {/* about and specs tabs. hide if current user doesn't have permission to see this profile's account */}
          {!hideDescription ? (
            <div className="flex flex-col gap-6">
              <Tab
                items={items}
                currentItem={tabSelected}
                setCurrentItem={setTabSelected}
              />
              <div className="w-full">
                {
                  items?.find((item) => item?.itemKey === tabSelected)
                    ?.component
                }
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col items-end mt-6">
          <div className="md:block hidden h-fit">
            {router?.pathname === '/profile' ? (
              <div className="flex flex-col items-end gap-4 w-fit h-fit">
                <Button
                  tooltip={t('profile.editBtn')}
                  className="btn-secondary flex gap-3 px-12 items-center w-fit"
                  onClick={() => router?.push('/settings?tab=profile')}
                  size="md"
                >
                  <FontAwesomeIcon icon={faPencilAlt} className="w-4 h-4" />
                  <span className="whitespace-nowrap">
                    {t('profile.editBtn')}
                  </span>
                </Button>
                {user?.profile?.type === 'person' &&
                user?.profile?.categories?.length > 0 &&
                !profileCompleted ? (
                  <div className="lg:block hidden">
                    <ProfileCompletionCard
                      placement="profile"
                      registrationStep={user?.profile?.registrationStep}
                      stats={user?.profile?.stats}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <RelationsButtons
                user={user}
                block={block}
                refreshUser={findUserBySlug}
              />
            )}
          </div>
          {/* aqui tengo que traerme también la otra info que me da el view */}
        </div>
      </div>
    </div>
  );
}

export default ProfileInfoCard;
