import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dropdown } from '../../../../../shared';

export interface DropdownItem {
  customKey: string;
  type: 'link' | 'button';
  label: string;
  icon: React.ReactNode;
  onClick?: any /* () => void */;
  href?: string;
}

export default function UserDropdownItem({
  customKey,
  type,
  label,
  icon,
  href,
  onClick = () => {},
}: DropdownItem) {
  const router = useRouter();
  if (type === 'link') {
    return (
      <Link href={href} key={customKey}>
        <Dropdown.Item
          text={label}
          icon={icon}
          selected={href === router?.pathname}
        />
      </Link>
    );
  }
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      key={customKey}
      type="button"
      className=""
      onClick={typeof onClick !== 'undefined' ? onClick : () => {}}
    >
      <Dropdown.Item text={label} icon={icon} />
    </button>
  );
}

// TODO: considerar lo de selected
