import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Input } from '@dinbog/ui';
import { ItemCollectionEnum } from '@dinbog/models';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNotify, useUser } from '../../hooks';
import { useCreateComment } from '../../hooks/api/comments';

function CommentForm({
  itemCollection,
  item,
  ms,
  onSuccessPostComment = () => {}, // refetch queries as needed
}: {
  itemCollection: ItemCollectionEnum;
  item: string;
  ms: 'portfolios' | 'posts';
  onSuccessPostComment?: () => void;
}) {
  const { t } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);

  const methods = useForm<{ comment: string }>({
    defaultValues: {
      comment: '',
    },
  });

  const formValidations = {
    comment: {
      required: t('required'),
      pattern: {
        value: /\S/,
        message: t('comment.error.whitespace'),
      },
    },
  };

  const queryClient = useQueryClient();
  const comment = useCreateComment(ms);

  const onSubmit: SubmitHandler<{ comment: string }> = async (_data: {
    comment: string;
  }) => {
    try {
      if (!_data.comment.trim())
        return notify(t('comment.whitespace'), 'error');
      if (disabled) return;
      setDisabled(true);
      const success = await comment.mutateAsync(
        {
          owner: user?._id as string,
          text: _data.comment,
          itemCollection,
          item,
        },
        {
          onSuccess: onSuccessPostComment,
        }
      );
      if (!success) return notify(t('comment.error'), 'error');
      // notify(t('comment.success'), 'success');
      methods?.reset();
      queryClient.refetchQueries(['comments', { item, itemCollection }]);
    } catch (err) {
      notify(err.response.data.message, 'error');
      // return notify(t('comment.error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-row gap-4 items-center"
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit(methods.getValues());
        }}
      >
        <img
          className="rounded-full md:h-[48px] md:w-[48px] h-8 w-8 object-cover border"
          src={
            user?.profile?.photo?.url ??
            (user?.profile?.type === 'business'
              ? '/images/fallback/business.jpg'
              : '/images/fallback/user.png')
          }
          alt="profilePic"
        />
        <div className="flex gap-2 items-center w-full">
          <Input
            className="mt-0"
            {...methods?.register('comment', formValidations.comment)}
            error={methods?.formState?.errors.comment?.message as string}
            placeholder={t('comment.placeholder')}
            isRequired
          />
          <Button
            type="submit"
            tooltip={t('comment.send')}
            className="md:py-2 h-fit md:px-3  px-0 md:bg-primary-500 md:text-white md:rounded py-0 bg-transparent text-neutral-300 rounded-none "
            disabled={!!methods?.formState?.errors.comment || disabled}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              className="h-[20px] md:hidden"
            />
            <p className="md:block hidden">{t('comment.send')}</p>
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default CommentForm;
