import React from 'react';
import { InfiniteScroll, SpinnerIcon } from '@dinbog/ui';
import { Pagination, Post as PostModel, Profile, User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { Post } from '../shared';
import { useUser } from '../../hooks';
import { FeedLeftSide } from '../shared/feed';
import CreatePostCard from '../post/CreatePostCard';
import SuggestedUsersCard from './SuggestedUsersCard';

interface FeedProps {
  setPagination: React.Dispatch<
    React.SetStateAction<{
      page: number;
      perPage: number;
    }>
  >;
  data: Pagination<PostModel>;
  loading: boolean;
  pagination;
  recommendations: {
    talents: Array<User & { profile: Profile }>;
    businesses: Array<User & { profile: Profile }>;
    refetchTalents;
    refetchBusinesses;
  };
  loadingRecommendations: boolean;
}
function Feed({
  setPagination,
  data,
  loading,
  pagination,
  recommendations,
  loadingRecommendations,
}: FeedProps) {
  const { currentUser: user } = useUser();
  const [loadedData, setLoadedData] = React.useState([]);
  const queryClient = useQueryClient();

  return (
    <InfiniteScroll
      setPagination={setPagination}
      data={data}
      loading={loading}
      setLoadedData={setLoadedData}
      fixedHeight={false}
    >
      <div className="w-full flex gap-6 ">
        {/* right side user card/wizard progress/castings */}
        <FeedLeftSide sticky />
        {/* center feed */}
        <div className="w-full flex flex-col gap-6 items-center">
          <CreatePostCard />
          {/* posts */}
          {loading && loadedData?.length === 0 ? (
            <SpinnerIcon className=" w-10 h-10 text-gray-200 animate-spin  fill-primary-500" />
          ) : (
            loadedData.map((post) => (
              <Post
                key={post._id}
                content={post}
                owner={post?.profile}
                onSuccessLike={() => {
                  queryClient.refetchQueries([
                    'feed',
                    {
                      user: (user?.currentUser?.user as User)?._id as string,
                      ...pagination,
                    },
                  ]);
                }}
              />
            ))
          )}
        </div>
        {/* left side suggestions */}
        <div className=" lg:block hidden ">
          <SuggestedUsersCard
            users={recommendations.talents}
            loadingSuggestedUsers={loadingRecommendations}
            type="talents"
            refetch={recommendations.refetchTalents}
          />
          <SuggestedUsersCard
            users={recommendations.businesses}
            loadingSuggestedUsers={loadingRecommendations}
            type="businesses"
            refetch={recommendations.refetchBusinesses}
          />
        </div>
      </div>
    </InfiniteScroll>
  );
}

export default Feed;
