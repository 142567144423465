import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { ClientOnlyPortal, Toast } from '@dinbog/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { v4 as uuid } from 'uuid';

export type ToastType = 'success' | 'error' | 'warning' | 'info';

interface IToastContext {
  alerts?: Array<TAlert>;
  notify?: (
    content: string,
    type: 'success' | 'error' | 'warning' | 'info',
    err?: Error
  ) => void;
}

type TAlert = {
  content: React.ReactNode;
  type: 'success' | 'error' | 'warning' | 'info';
  id: string;
  timeOut: NodeJS.Timeout;
};

interface ToastContextProvider {
  children?: React.ReactNode;
}

export const ToastContext = React.createContext<IToastContext>({
  alerts: [],
  notify: (content, type, err) => {
    console.log('');
  },
});

export function ToastContextProvider({ children }: ToastContextProvider) {
  const [alerts, setAlerts] = React.useState<TAlert[]>([]);
  const notify = React.useCallback((content, type: ToastType, err?: Error) => {
    if (err) Sentry.captureException(err);
    const id = uuid();
    const newNotification = {
      content,
      type,
      id,
      timeOut: setTimeout(() => {
        setAlerts((__alerts) => __alerts.filter((alert) => alert.id !== id));
      }, 6000),
    };

    setAlerts((_alerts) => [..._alerts, newNotification].slice(-3));
  }, []);

  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const { id } = e.currentTarget.dataset;
    setAlerts((_alerts) => _alerts.filter((alert) => alert.id !== id));
  };
  const context = React.useMemo(() => ({ alerts, notify }), [alerts, notify]);
  return (
    <ToastContext.Provider value={context}>
      <ClientOnlyPortal selector="#toast">
        {alerts?.length > 0 ? (
          <div
            className="fixed m-6 right-0 bottom-0"
            style={{
              zIndex: 200,
            }}
          >
            <AnimatePresence>
              {alerts.map((alert) => (
                <motion.div
                  key={alert.id}
                  initial={{ translateX: 200 }}
                  animate={{ translateX: 0 }}
                  exit={{ translateX: 1000 }}
                >
                  <Toast
                    key={alert.id}
                    type={alert.type}
                    id={alert.id}
                    onDelete={onDelete}
                  >
                    {alert.content}
                  </Toast>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        ) : null}
      </ClientOnlyPortal>
      {children}
    </ToastContext.Provider>
  );
}
