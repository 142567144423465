import React from 'react';

interface LargeContentProps {
  children: React.ReactNode | React.ReactNode[];
  position?: 'right' | 'left' | 'center';
  // 2: one small content and one large content
  // 3: two small content and one large content
  contentDistributionItems?: 2 | 3;
  className?: string;
}

function LargeContent({
  children,
  position = 'left',
  contentDistributionItems = 2,
  className = '',
}: LargeContentProps) {
  const _position = {
    left: 'order-2 lg:order-1 lg:pr-2',
    center: 'order-2 lg:order-2 lg:pl-2 lg:pr-2',
    right: 'order-2 lg:order-3 lg:pl-2',
  };

  const contentWidth = {
    2: 'w-full mt-5 lg:mt-0 lg:w-4/5 xl:w-5/6',
    3: 'w-full mt-5 lg:mt-0 lg:w-3/5 xl:w-4/6 ',
  };

  return (
    <div
      className={`${_position[position]} ${contentWidth[contentDistributionItems]} ${className} `}
    >
      {children}
    </div>
  );
}

export default LargeContent;
