import React from 'react';
import { Input, MultipleSelect, Select, TextArea } from '@dinbog/ui';
import {
  Category,
  DocumentModel,
  IOption,
  Video,
  videoPrivacyValues,
} from '@dinbog/models';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MediaCoverFormContent from '../../MediaCoverFormContent';
import UploadVideoCard from './UploadVideoCard';
import UploadedVideoInfo from './UploadedVideoInfo';

interface VideoFormContentProps {
  disabled?: boolean;
  categories: Category[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  media: DocumentModel[];
  categoriesDefault?: IOption[];
}

/**
 * UPLOAD VIDEO FORM CONTENT TO CREATE OR UPDATE A VIDEO
 */
function VideoFormContent({
  disabled = false,
  categories,
  updateURLs,
  media,
  categoriesDefault = [],
}: VideoFormContentProps) {
  const { i18n, t } = useTranslation();

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<Video>();

  const formValidations = {
    categories: { required: t('required') },
    title: { required: t('required') },
    description: { required: t('required') },
    privacy: { required: t('required') },
    'multimedia.url': {
      required: t('required'),
    },
  };

  return (
    <div className="flex lg:flex-row flex-col lg:gap-x-8 gap-y-4 lg:items-start items-center">
      <div className="">
        <MediaCoverFormContent media={media} updateURLs={updateURLs} />
      </div>
      <div className="space-y-4 flex flex-col w-full">
        <div className="w-full">
          <MultipleSelect
            options={categories?.map((cat) => ({
              name: cat?.name[i18n?.language ?? 'en'],
              _id: cat?._id,
            }))}
            label={t('auth.signUp.wizard.video.category.title') ?? ''}
            onChangeSelect={(selected) => {
              setValue(
                'categories',
                selected.map((cat) => cat._id)
              );
            }}
            defaultSelect={categoriesDefault}
            {...register('categories', formValidations.categories)}
          />
          <span className="text-sm text-danger-300">
            {errors.categories && errors.categories.type === 'required'
              ? t('required')
              : ''}
          </span>
        </div>
        <div className="w-full">
          <Input
            disabled={disabled}
            isRequired
            {...register('title', formValidations.title)}
            className="w-full"
            error={errors.title?.message as string}
            label={t('auth.signUp.wizard.video.name.title') ?? ''}
            placeholder={t('auth.signUp.wizard.video.name.description') ?? ''}
          />
        </div>

        <div className="w-full">
          <TextArea
            disabled={disabled}
            isRequired
            {...register('description', formValidations.description)}
            error={errors.description?.message as string}
            className="h-24"
            label={t('auth.signUp.wizard.video.videoDescription.title') ?? ''}
            placeholder={
              t('auth.signUp.wizard.video.videoDescription.description') ?? ''
            }
          />
        </div>
        <div className="w-full">
          <Select
            disabled={disabled}
            isRequired
            {...register('privacy', formValidations.privacy)}
            error={errors.privacy?.message as string}
            className="w-full"
            label={t('portfolio.privacy.title') ?? ''}
          >
            <option key={-1} value="" disabled>
              {t('portfolio.privacy.values.default')}
            </option>
            {videoPrivacyValues?.map((privacy, i) => (
              <option key={`${i + 1}-${privacy}`} value={privacy ?? ''}>
                {t(`portfolio.privacy.values.${privacy}`)}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full">
          {watch('multimedia') ? <UploadedVideoInfo /> : <UploadVideoCard />}
        </div>
      </div>
    </div>
  );
}

export default VideoFormContent;
