import React from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { User } from '@dinbog/models';
import { useUser } from '../../../../../../../hooks';
import { useChangeAccount } from '../../../../../../../hooks/api/auth';
import { FadeAnimation } from '../../../../../../shared';
import AccountCard from './AccountCard';

interface SwitchAccountCardProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SwitchAccountCard({ setOpen }: SwitchAccountCardProps) {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const router = useRouter();
  const { t } = useTranslation();
  const session = useSession();
  const [selectedAccount, setSelectedAccount] = React.useState(
    user?.accounts?.find(
      (acc) => acc?._id === (user?.currentUser?.user as User)?._id
    )
  );

  // query to switch account
  const { data, isLoading, refetch, isRefetching } = useChangeAccount(
    {
      user: selectedAccount?._id,
      token: user?.token,
      type: selectedAccount?.profile?.type,
    },
    {
      enabled: false,
    }
  );

  // refetch switch acc query if user clicks on an account other than the current one
  React.useEffect(() => {
    if (selectedAccount?._id !== (user?.currentUser?.user as User)?._id) {
      refetch();
    }
  }, [selectedAccount]);

  const updateSession = async () => {
    await session.update({
      ...session.data,
      user: {
        ...session.data.user,
        token: data?.token,
      },
    });
    router?.push('/');
  };

  // set new user info when the query is done fetching
  React.useEffect(() => {
    if (!isRefetching && !isLoading) {
      setUser({
        _id: data?.currentUser?._id ?? user?._id,
        profile: data?.currentUser?.profile ?? user?.profile,
        accounts: data?.currentUser?.accounts ?? user?.accounts,
        currentUser: data?.currentUser?.currentUser ?? user?.currentUser,
        token: data?.token ?? user?.token,
      });
      updateSession();
    }
  }, [data, isRefetching, isLoading]);

  return (
    <FadeAnimation duration={0.5}>
      <div className="w-[348px] h-fit py-4 flex-col justify-start items-start gap-6 text-text-black inline-flex">
        <div className="self-stretch justify-start items-center inline-flex">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="text-xl font-semibold leading-[30px] flex gap-2 items-center"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" />
            <span>{t('navbar.logged.switchAccount.title')}</span>
          </button>
        </div>
        <div className="self-stretch flex-col justify-start items-start flex overflow-y-auto max-h-[50vh] pr-2">
          {user?.accounts?.map((account, idx) => (
            <AccountCard
              key={idx}
              account={account}
              current={
                account?._id ===
                ((user?.currentUser?.user as User)?._id ?? user?._id)
              }
              onClick={setSelectedAccount}
            />
          ))}
        </div>
        <button
          type="button"
          onClick={() => router.push('/sign-up/new-business')}
          className="self-stretch px-4 py-1 rounded-[5px] justify-start items-center gap-3 inline-flex"
        >
          <FontAwesomeIcon icon={faPlus} className="h-5 w-5" />
          <span className="text-base font-normal leading-normal">
            {t('navbar.logged.switchAccount.create')}
          </span>
        </button>
      </div>
    </FadeAnimation>
  );
}
