/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import {
  CastingResult,
  PortfolioResults,
  PostResults,
  UserResults,
  VacancyResult,
} from './resultTypes';

type FiltersData = {
  name: string;
  type: string;
  label: string;
  iterable: Array<any>;
}[];

interface SearchResultsProps {
  filtersData: FiltersData;
}

export default function SearchResults({ filtersData }: SearchResultsProps) {
  const router = useRouter();
  if (router?.query?.tab === 'posts') return <PostResults />;

  if (router?.query?.tab === 'portfolios') return <PortfolioResults />;
  if (router?.query?.tab === 'events' && !(filtersData as any).categories)
    return <CastingResult />;
  if (router?.query?.tab === 'events' && (filtersData as any).categories)
    return <VacancyResult />;
  return <UserResults />;
}
