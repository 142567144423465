import React from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { useRouter } from 'next/router';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { User } from '@dinbog/models';
import { ACTIONS_TYPE } from '../reducer';
import { useNotify, useUser } from '../../../../hooks';
import { useUserRecommendations } from '../../../../hooks/api/users';
import { useFollowUser, useUnfollowUser } from '../../../../hooks/api/follows';
import PeopleCarousel from './peopleCarousel/PeopleCarousel';
import FormButtons from '../../shared/FormButtons';
import { SkeletonWrapper } from '../../../shared';
import { useUpdateProfile } from '../../../../hooks/api/profiles';

const FINAL_REGISTRATION_STEP = '2';

interface DiscoverPeopleProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

function DiscoverPeople({ currentStep, setCurrentStep }: DiscoverPeopleProps) {
  const { currentUser: user, setCurrentUser } = useUser();
  const notify = useNotify();
  const router = useRouter();
  const { t } = useTranslation();
  const {
    data: recommendations,
    isLoading,
    refetch,
  } = useUserRecommendations({
    user: (user?.currentUser?.user as User)?._id as string,
    page: 1,
    perPage: 9,
  });

  const [followLoading, setFollowLoading] = React.useState(false);

  const [talentRecommendation, setTalentRecommendations] = React.useState(
    recommendations?.items?.map((u) => ({
      ...u,
      following: false,
      requestedFollow: false,
      requestedConnection: false,
    }))
  );

  React.useEffect(() => {
    if (recommendations)
      setTalentRecommendations(
        recommendations?.items?.map((u) => ({
          ...u,
          following: false,
          requestedFollow: false,
          requestedConnection: false,
        }))
      );
  }, [recommendations]);

  const followMutation = useFollowUser();
  const updateMutation = useUpdateProfile();

  const onFollow = async (e) => {
    setFollowLoading(true);
    try {
      const { id } = e.currentTarget.dataset;
      const _data = await followMutation.mutateAsync(
        {
          from: (user?.currentUser?.user as User)?._id as string,
          to: id,
        },
        {
          onSuccess: (data) => {
            if (setTalentRecommendations)
              setTalentRecommendations((prev) =>
                prev.map((u) => ({
                  ...u,
                  following:
                    data.to === u._id && data.accepted ? true : u.following,
                  requestedFollow:
                    data.to === u._id && !data.accepted
                      ? true
                      : u.requestedFollow,
                }))
              );
            if (refetch) refetch();
          },
        }
      );

      if (!_data) {
        return notify(t('follow.error'), 'error');
      }
      setFollowLoading(false);
    } catch (err) {
      console.log('error');
      setFollowLoading(false);
    }
  };

  const onSubmit: SubmitHandler<any> = async () => {
    const _data = await updateMutation.mutateAsync(
      {
        user: user?._id,
        registrationStep: FINAL_REGISTRATION_STEP,
      },
      {
        onSuccess: (data) => {
          setCurrentUser((prevState) => ({
            ...prevState,
            profile: data,
            currentUser: {
              ...prevState.currentUser,
              registrationStep: FINAL_REGISTRATION_STEP,
            },
          }));
        },
      }
    );
    if (!_data) {
      return notify(t('auth.signUp.notifs.failure'), 'error');
    }
    router.push('/');
  };

  return (
    <SkeletonWrapper isLoading={isLoading}>
      <div className="w-full flex flex-col  space-y-10 relative mt-10">
        <div className="flex w-full justify-center items-center">
          <PeopleCarousel
            followLoading={followLoading}
            onFollow={onFollow}
            people={talentRecommendation}
          />
        </div>
        <FormButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={2}
          onComplete={onSubmit}
        />
      </div>
    </SkeletonWrapper>
  );
}

export default DiscoverPeople;
