import axios from 'axios';
import NProgress from 'nprogress';

// Cropped image to blob
function base64ToBlob(base64: string, type: string): Blob {
  const binaryString = window.atob(base64.split(',')[1]);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type });
}

export const uploadFile = async (
  img,
  setUploading,
  updateURLs,
  createMutation,
  notify,
  t
) => {
  if (img?.src && !img?.src.startsWith('http')) {
    try {
      setUploading(true);
      NProgress.start();
      const data = await createMutation.mutateAsync({
        filename: img?.file?.name,
        filetype: img?.file?.type,
      });
      const options = {
        headers: {
          'Content-Type': img?.file?.type,
          Origin: process.env.NEXTAUTH_URL,
        },
      };
      const blob = base64ToBlob(img?.src, img?.file?.type);

      const response = await axios.put(data?.signedRequest, blob, options);
      // const response = await axios.put(data?.signedRequest, img?.file, options);
      if (response) {
        updateURLs((urls) => {
          const _urls = urls?.filter((_url) => _url?.id !== img?.id);
          return [
            ..._urls,
            { src: data?.url, name: img?.name, id: img?.id, file: img?.file },
          ];
        });
        // notify(t('successImageUpload'), 'success');
        return data;
      }
      notify(`${t('failedImageUpload')} ${img?.name}`, 'error');
    } catch (err) {
      notify(`${t('failedImageUpload')} ${img?.name}`, 'error');
    } finally {
      setUploading(false);
      NProgress.done();
    }
  } else if (img?.src && img?.src.startsWith('http')) {
    return img;
  }
};
