import React from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronLeft, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoWithIcon } from '../../../../shared';
import TalentList from './TalentList';

export default function PastTalents({
  talents = {},
  setViewing,
}: {
  talents;
  setViewing: React.Dispatch<
    React.SetStateAction<'current' | 'past' | 'requests'>
  >;
}) {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <button
        type="button"
        className="flex gap-2 items-center"
        onClick={() => setViewing('current')}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="h-3 w-3 text-text-black"
        />
        <span className="font-semibold">
          {t('profile.tabs.returnBtn')}{' '}
          {t('profile.tabs.talents.title').toLowerCase()}
        </span>
      </button>
      {talents?.items?.length > 0 ? (
        <TalentList talents={talents?.items} viewing="past" />
      ) : (
        <InfoWithIcon
          title={t('emptyStates.noPastTalents.title')}
          text={t('emptyStates.noPastTalents.text')}
          icon={faUsers}
        />
      )}
    </div>
  );
}
