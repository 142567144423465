import React from 'react';
import { Address } from '@dinbog/models';
import { Button, Modal, Select } from '@dinbog/ui';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from '../../hooks';

interface PostLocationModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentAddress: Address;
}

export default function PostLocationModal({
  isOpen = false,
  setOpen,
  currentAddress,
}: PostLocationModalProps) {
  const { t } = useTranslation();

  // form context
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const formValidations = {
    country: { required: t('required') },
    state: { required: t('required') },
    city: { required: t('required') },
  };

  const { countries, states, cities } = useLocation(
    watch('country') ?? '',
    watch('state') ?? ''
  );

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      className="bg-background-50 p-6 rounded"
    >
      <Modal.Header exitBtn />
      <div className="space-y-4">
        <p className="font-semibold text-xl text-center -mt-6">
          {t('location.setLocation')}
        </p>
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <Select
              isRequired
              name="country"
              error={errors?.country?.message as string}
              className="w-full"
              label={t('location.country') ?? ''}
              {...register('country', formValidations?.country)}
            >
              <option key={-1} value="">
                {t('select')}
              </option>
              {countries?.map((country, i) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              isRequired={states?.length > 0}
              name="state"
              error={errors?.state?.message as string}
              className="w-full"
              label={t('location.state') ?? ''}
              {...register('state', formValidations?.state)}
            >
              <option key={-1} value="">
                {t('select')}
              </option>
              {states?.map((s, i) => (
                <option key={s.isoCode} value={s.isoCode}>
                  {s.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              isRequired
              className="w-full"
              error={errors?.city?.message as string}
              label={t('location.city') ?? ''}
              {...register('city', formValidations?.city)}
            >
              <option key={-1} value="">
                {t('select')}
              </option>
              {cities?.map((city, i) => (
                <option key={i} value={city.name ?? ''}>
                  {city.name}
                </option>
              ))}
            </Select>
          </div>
          <Button
            tooltip={t('save')}
            className="btn-primary"
            onClick={() => setOpen(false)}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
