import React from 'react';
import { useRouter } from 'next/router';
import { Profile, User } from '@dinbog/models';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PostMentions({
  mentions,
  isCreate = false,
}: {
  mentions: Profile[];
  isCreate?: boolean;
}) {
  const router = useRouter();
  return (
    <div className="text-neutral-400 flex items-center gap-2 text-sm flex-wrap mb-6">
      <FontAwesomeIcon icon={faUser} className="w-3 h-3 text-neutral-400" />
      {mentions?.map((mention, idx) => (
        <div key={idx} className="flex gap-3">
          {isCreate ? (
            <>
              {mention?.firstName}
              {mention?.lastName ? ` ${mention?.lastName}` : null}
              {idx + 1 < mentions?.length ? ', ' : null}
            </>
          ) : (
            <button
              type="button"
              onClick={() =>
                router.push({
                  pathname: `/[slug]`,
                  query: {
                    slug: (mention?.user as User)?.slug,
                  },
                })
              }
              className="font-semibold text-sm text-neutral-400"
            >
              {mention?.firstName}
              {mention?.lastName ? ` ${mention?.lastName}` : null}
              {idx + 1 < mentions?.length ? ', ' : null}
            </button>
          )}
        </div>
      ))}
    </div>
  );
}
