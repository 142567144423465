import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Video, Category, DocumentModel, Track, User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@dinbog/ui';
import { useNotify, useUser } from '../../../../../hooks';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../../utils/formatDocumentModel';
import {
  useCreateVideo,
  useUpdateVideo,
} from '../../../../../hooks/api/videos';
import { VideoFormContent } from '../../../../shared';
import { uploadFile } from '../../../../../lib/uploadFile';
import { useSignS3 } from '../../../../../hooks/api/s3';

interface VideoFormProps {
  data: Video;
  categories: Category[];
  setIsInForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function VideoForm({ data, categories, setIsInForm }: VideoFormProps) {
  const { t, i18n } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [disabled, setDisabled] = React.useState(false);
  const [media, setMedia] = React.useState<DocumentModel[]>(
    data?.cover ? multimediaToDocument([data?.cover]) : []
  );
  const updateURLs = React.useCallback(setMedia, [setMedia]);
  const methods = useForm<Video>({
    defaultValues: {
      title: data?.title ?? '',
      description: data?.description ?? '',
      privacy: data?.privacy ?? null,
      multimedia: data?.multimedia ?? null,
      categories: data?.categories ?? [],
    },
  });

  const createVideoMutation = useCreateVideo();
  const updateVideoMutation = useUpdateVideo();
  const signS3 = useSignS3();
  const onSubmit: SubmitHandler<Partial<Video>> = async (
    _data: Partial<Video>
  ) => {
    try {
      const creating = !data?._id;
      if (disabled) return;
      setDisabled(true);
      let coverImg = { url: '' };
      if (media?.length > 0 && media[0]?.src !== data?.cover?.url) {
        coverImg = await uploadFile(
          media[0],
          setDisabled,
          updateURLs,
          signS3,
          notify,
          t
        );
      } else {
        coverImg.url = data?.cover?.url;
      }
      const image = media?.length > 0 ? documentToMedia(media) : null;

      const videoDataPromise = (
        !creating ? updateVideoMutation : createVideoMutation
      ).mutateAsync({
        _id: data?._id ?? undefined,
        title: _data.title,
        user: (user?.currentUser.user as User)._id,
        description: _data.description,
        privacy: _data.privacy,
        categories: _data?.categories,
        ...(media?.length > 0
          ? { cover: { ...image[0], type: 'image', url: coverImg.url } }
          : { cover: undefined }),
        multimedia: {
          ...(_data?.multimedia ?? {}),
          fileName: _data?.title?.replaceAll(' ', '_'),
          type: 'video',
        },
      });

      await Promise.all([videoDataPromise]).then(() => {
        queryClient.refetchQueries(['video', { _id: data?._id }]);
        queryClient.refetchQueries(['videos', { user: user?._id }]);
      });
      notify(t('profile.tabs.video.update.success'), 'success');
      setIsInForm(false);
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const actionButtonLabel = t(data ? 'update' : 'create');

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <VideoFormContent
          media={media}
          disabled={disabled}
          updateURLs={updateURLs}
          categoriesDefault={categories
            ?.map((cat) => ({
              name: cat?.name[i18n?.language ?? 'en'],
              _id: cat?._id,
            }))
            .filter((cat) =>
              (data?.categories as string[])?.includes(cat?._id)
            )}
          categories={categories}
        />
        <div className="w-full flex gap-5 justify-end mt-5">
          <Button
            tooltip={t('cancel')}
            className=" bg-neutral-300 rounded"
            onClick={(e) => {
              e.preventDefault();
              methods.reset();
              setIsInForm(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            tooltip={actionButtonLabel}
            type="submit"
            className="bg-primary-500 text-white font-normal"
          >
            {actionButtonLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default VideoForm;
