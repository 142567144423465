import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { axios } from '../../api';

type TUseSignS3Input = {
  filename: string;
  filetype: string;
};

interface TUseSignS3Output {
  signedRequest: string;
  url: string;
}

export function useSignS3() {
  const mutation = useMutation<TUseSignS3Output, AxiosError, TUseSignS3Input>(
    async (newSign) => {
      const { data } = await axios.post('/api/users/media-upload/v1', newSign);
      return data;
    }
  );
  return mutation;
}
