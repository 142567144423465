import React from 'react';
import { Modal } from '@dinbog/ui';
import { User } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useBlockUser, useUnblockUser } from '../../../../hooks/api/block';
import { useNotify, useUser } from '../../../../hooks';
import BlockConfirmation from './BlockConfirmation';
import BlockNotif from './BlockNotif';

interface BlockModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user?: any;
  unblock?: boolean;
  onSuccess?: () => void;
}

export default function BlockModal({
  isOpen = false,
  setOpen,
  user,
  unblock = false,
  onSuccess,
}: BlockModalProps) {
  const notify = useNotify();
  const { currentUser } = useUser();
  const [current, setCurrent] = React.useState<number>(0);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const blockUserMutation = useBlockUser();
  const unblockUserMutation = useUnblockUser();

  const onSubmit = async () => {
    let next = false;
    try {
      if (disabled) return;
      setDisabled(true);

      await (unblock ? unblockUserMutation : blockUserMutation).mutateAsync(
        {
          to: user?._id as string,
          from: (currentUser?.currentUser?.user as User)?._id as string,
        },
        {
          onSuccess: () => {
            if (onSuccess) onSuccess();
          },
        }
      );
      next = true;
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
      next = false;
    } finally {
      setDisabled(false);
      if (next) {
        setCurrent(current + 1);
      }
    }
  };

  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 p-8"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      {current === 0 ? (
        <BlockConfirmation
          setOpen={setOpen}
          onSubmit={onSubmit}
          disabled={disabled}
          userName={`${user?.profile?.firstName}${
            user?.profile?.lastName ? ` ${user?.profile?.lastName}` : ''
          }`}
          unblock={unblock}
        />
      ) : (
        <>
          <Modal.Header exitBtn />
          <BlockNotif blockedUser={user?.profile} />
        </>
      )}
    </Modal>
  );
}
