import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { FiltersInput, Pagination, Video } from '@dinbog/models';
import { axios } from '../../api';

export function useVideos(
  filters: Partial<Video> & FiltersInput & { filter?: any },
  options: Omit<
    UseQueryOptions<
      Pagination<Video>,
      AxiosError,
      Pagination<Video>,
      ['videos']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<Video>, AxiosError>(
    ['videos', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<Pagination<Video>>(
          '/api/portfolios/videos/talents/videos/v1',
          {
            params: { ...filters },
          }
        );
        return data;
      }
    },
    options
  );
  return query;
}

export function useVideo(
  filters: Partial<Video> & FiltersInput & { currentUser?: string },
  options: Omit<
    UseQueryOptions<Video, AxiosError, Video, ['video']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Video, AxiosError>(
    ['video', filters],
    async () => {
      const { data } = await axios.get<Video>('/api/portfolios/video/v1', {
        params: { ...filters },
      });
      return data;
    },
    options
  );
  return query;
}
export function useCreateVideo() {
  const mutation = useMutation<Video, AxiosError, Partial<Video>>(
    async (newVideo) => {
      const { data } = await axios.post('/api/portfolios/videos/v1', newVideo);
      return data;
    }
  );
  return mutation;
}

export function useUpdateVideo() {
  const mutation = useMutation<Video, AxiosError, Partial<Video>>(
    async (video) => {
      const { data } = await axios.patch(`/api/portfolios/videos/v1`, video);
      return data;
    }
  );
  return mutation;
}
