import React from 'react';
import { Modal, PennantIcon } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ReportItemCollectionEnum, ServicesEnum } from '@dinbog/models';
import ReportModal from '../reportModal/ReportModal';

export default function OtherActionsCommentModal({
  isOpen = false,
  setOpen,
  onDelete,
  isOwner,
  commentId,
  service,
  itemCollection,
  itemOwner,
}: {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete: () => void;
  isOwner?: boolean;
  commentId: string;
  service?: ServicesEnum;
  itemCollection: ReportItemCollectionEnum;
  itemOwner: string;
}) {
  const { t } = useTranslation();
  const [openReportModal, setOpenReportModal] = React.useState<boolean>(false);
  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      className="w-1/6 rounded-lg text-neutral-600 font-semibold"
    >
      <Modal.Body padding="small">
        <div className="flex flex-col gap-2">
          {isOwner ? (
            <button
              type="button"
              className="flex items-center gap-3 hover:text-danger-300 p-4 rounded"
              onClick={() => onDelete()}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="w-4 h-4" />
              <span>{t('delete')}</span>
            </button>
          ) : (
            <button
              type="button"
              className="flex items-center gap-3 hover:text-danger-300 p-4 rounded"
              onClick={() => {
                setOpenReportModal(true);
              }}
            >
              <PennantIcon className="h-4 w-4" />
              <span>{t('profile.otherActions.report')}</span>
            </button>
          )}
        </div>
      </Modal.Body>
      <ReportModal
        isOpen={openReportModal}
        setOpen={setOpenReportModal}
        item={itemCollection}
        itemIdToBeReported={commentId}
        service={service}
        itemOwner={itemOwner}
      />
    </Modal>
  );
}
