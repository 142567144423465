import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { validateString } from 'avilatek-utils';
import { Pagination, WorkReference } from '@dinbog/models';
import MediaProfileLayout from '../../../../layout/MediaProfileLayout';
import WorkItemList from './WorkItemList';
import WorkReferenceForm from './WorkForm';
import { useWorkReference } from '../../../../../hooks/api/workReferences';
import { InfoWithImage, SkeletonWrapper } from '../../../../shared';
import { useGetUserInfo } from '../../../../../hooks/api/users';

interface WorkReferenceTabProps {
  workReferenceData: Pagination<WorkReference>;
  user;
  fetchNextPage;
}

export default function WorkReferenceTab({
  workReferenceData,
  user,
  fetchNextPage,
}: WorkReferenceTabProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const [create, setCreate] = React.useState(false);

  const { data, isLoading } = useWorkReference(
    {
      _id: router.query.work as string,
    },
    { enabled: !!router.query.work }
  );

  const { data: business, isLoading: businessLoading } = useGetUserInfo(
    {
      _id: (data?.company as string) ?? '',
    },
    {
      enabled: validateString(data?.company),
    }
  );

  if (router.query.work)
    return (
      <SkeletonWrapper
        isLoading={
          isLoading || (businessLoading && validateString(data?.company))
        }
      >
        <MediaProfileLayout
          isOwner={router?.pathname === '/profile'}
          handleGoBack={() => {
            router.push({
              pathname: `${router.pathname}`,
              query: {
                ...router.query,
                work: undefined,
              },
            });
          }}
          type={t('profile.tabs.workReference.title')}
        >
          <WorkReferenceForm
            data={{
              ...data,
              company:
                business && !businessLoading
                  ? { ...business?.user, profile: business?.profile }
                  : data?.company,
            }}
            setIsInForm={setCreate}
          />
        </MediaProfileLayout>
      </SkeletonWrapper>
    );

  if (create)
    return (
      <MediaProfileLayout
        type={t('profile.tabs.workReference.title')}
        handleGoBack={() => {
          setCreate(false);
        }}
      >
        <WorkReferenceForm data={null} setIsInForm={setCreate} />
      </MediaProfileLayout>
    );

  return (
    <div className="">
      <div className="flex flex-col gap-y-16 w-full">
        {router?.asPath.includes('/profile') ? (
          <div className="ml-auto">
            <button
              type="button"
              className="btn-primary px-12 py-3 font-normal rounded text-white "
              onClick={() => setCreate(true)}
            >
              {t('profile.tabs.workReference.uploadButton')}
            </button>
          </div>
        ) : null}

        {/* list of workReferences */}
        {workReferenceData?.count > 0 ? (
          <WorkItemList
            workData={workReferenceData}
            user={user}
            fetchNextPage={fetchNextPage}
          />
        ) : (
          <InfoWithImage
            image="/images/fallback/no-cv.png"
            title={
              router?.pathname === '/profile'
                ? t('emptyStates.noWorks.self.title')
                : t('emptyStates.noWorks.other.title')
            }
            text={
              router?.pathname === '/profile'
                ? t('emptyStates.noWorks.self.text')
                : ''
            }
          />
        )}
      </div>
    </div>
  );
}
