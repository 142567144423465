import React from 'react';
import { Input, Modal, Button } from '@dinbog/ui';
import { validateEmail, validateString } from 'avilatek-utils';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNotify, useUser } from '../../../hooks';
import { useInviteUser } from '../../../hooks/api/users';

interface InviteUserModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  email?: string;
}

export default function InviteUserModal({
  isOpen = false,
  setOpen,
  email = '',
}: InviteUserModalProps) {
  const { t } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);

  const methods = useForm<{ email: string }>({
    defaultValues: {
      email,
    },
  });

  const formValidations = {
    email: {
      required: t('required'),
      validate: (value) => validateEmail(value) || t('invalidEmail'),
    },
  };

  const inviteUser = useInviteUser();

  const onSubmit: SubmitHandler<{ email: string }> = async (_data: {
    email: string;
  }) => {
    try {
      if (disabled) return;
      setDisabled(true);
      const success = await inviteUser.mutateAsync({
        emails: [_data?.email],
        invitedBy: user?._id,
      });
      if (!success) return notify(t('inviteUserModal.error'), 'error');
      notify(t('inviteUserModal.success'), 'success');
      setOpen(false);
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
      // return notify(t('inviteUserModal.error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  React.useEffect(() => {
    if (validateString(email)) methods.setValue('email', email);
  }, [email]);

  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 px-4 py-4 flex flex-col"
      setOpen={setOpen}
      isOpen={isOpen}
    >
      <Modal.Header title={t('inviteUserModal.title')} exitBtn />
      <Modal.Body padding="small">
        <p className="">{t('inviteUserModal.description')}</p>
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-8"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSubmit(methods.getValues());
            }}
          >
            <Input
              type="email"
              {...methods?.register('email', formValidations.email)}
              error={methods?.formState?.errors.email?.message as string}
              placeholder={'Email' ?? ''}
              isRequired
            />
            <Button
              tooltip={t('inviteUserModal.invite')}
              type="submit"
              className="btn-primary"
            >
              {t('inviteUserModal.invite')}
            </Button>
          </form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
}
