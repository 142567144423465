import React from 'react';
import { User } from '@dinbog/models';
import { useRouter } from 'next/router';

export default function LatestComments({ comments }) {
  const router = useRouter();

  return (
    <div className="">
      {comments?.map((comment, idx) => (
        <div key={idx} className="flex gap-2">
          <button
            type="button"
            onClick={() =>
              router?.push({
                pathname: `/[slug]`,
                query: { slug: (comment?.profile?.user as User)?.slug },
              })
            }
            className="font-semibold break-keep whitespace-nowrap text-left"
          >
            {comment?.profile?.firstName} {comment?.profile?.lastName}
          </button>
          <span className=" w-full break-all text-justify ">
            {comment?.text}
          </span>
        </div>
      ))}
    </div>
  );
}
