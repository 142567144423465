import React from 'react';
import { Pagination, WorkReference } from '@dinbog/models';
import WorkItem from './WorkItem';
import InfiniteList from '../../../../shared/infinite-list/InfiniteList';

interface WorkItemListProps {
  workData: Pagination<WorkReference>;
  user;
  fetchNextPage;
}

export default function WorkItemList({
  workData,
  user,
  fetchNextPage,
}: WorkItemListProps) {
  return (
    <InfiniteList items={workData.items} fetchNextPage={fetchNextPage}>
      {(workItem, index) => (
        <WorkItem
          item={workItem}
          key={workItem._id}
          ii={index}
          user={user}
          count={Number(workData.count) ?? 0}
        />
      )}
    </InfiniteList>
  );
}
