import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Country } from 'country-state-city';
import { getFlag } from '../../../lib/getFlag';
import LocationCategory from './LoactionCategory';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

interface PersonItemBodyProps {
  user;
  withLocation?: boolean;
  children?: React.ReactNode;
  textSize?: string;
}

function PersonItemBody({
  user,
  withLocation = false,
  children,
  textSize = 'text-sm',
}: PersonItemBodyProps) {
  if (user?.position)
    return (
      <div className={`${textSize} gap-1`}>
        <span className="">{user?.position}</span>
        <div className="gap-1 flex">
          <span className="">
            {user?.profile?.address?.country
              ? getFlag(user?.profile?.address?.country)
              : null}
          </span>
          {withLocation ? (
            <span className="">
              {user?.profile?.address?.city},{' '}
              {Country.getCountryByCode(user?.profile?.address?.country)?.name}
            </span>
          ) : (
            <span className="">
              {dayjs(user?.startDate).format('ll')} -{' '}
              {user?.endDate ? dayjs(user?.endDate).format('ll') : 'Present'}
            </span>
          )}
        </div>
        {children}
      </div>
    );
  return (
    <div className="">
      <LocationCategory
        user={user}
        withLocation={withLocation}
        textSize={textSize}
        business={user?.business}
      />
      {children}
    </div>
  );
}

export default PersonItemBody;
