import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Profile, User, WorkReference } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Button } from '@dinbog/ui';
import { validateString } from 'avilatek-utils';
import { useNotify, useUser } from '../../../../../hooks';
import {
  useCreateWorkReference,
  useUpdateWorkReference,
} from '../../../../../hooks/api/workReferences';
import { WorkReferenceFormContent } from '../../../../shared';

interface WorkReferenceFormProps {
  data: WorkReference;
  setIsInForm: React.Dispatch<React.SetStateAction<boolean>>;
}

interface WorkReferenceData {
  _id: string | undefined;
  user: string | undefined;
  position: string;
  startDate: string | Date;
  company: string | (User & { profile: Profile }) | undefined;
  companyName: string;
  description: string;
  privacy: string;
  endDate?: string | Date;
}

function WorkReferenceForm({ data, setIsInForm }: WorkReferenceFormProps) {
  const { t, i18n } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const router = useRouter();

  const [disabled, setDisabled] = React.useState(false);

  const methods = useForm<WorkReference>({
    defaultValues: {
      position: data?.position ?? '',
      startDate: data?.startDate
        ? dayjs(data?.startDate).format('YYYY-MM-DD')
        : null,
      endDate: data?.endDate ? dayjs(data?.endDate).format('YYYY-MM-DD') : null, // TODO: FORMATEAR BIEN FECHA
      company: data?.company ?? '',
      description: data?.description ?? '',
      companyName: data?.companyName ?? '',
      manager: data?.manager ?? '',
      privacy: data?.privacy ?? null,
      status: data?.status ?? 'pending',
      experience: data?.experience ?? undefined,
    },
  });

  const createWorkReferenceMutation = useCreateWorkReference();
  const updateWorkReferenceMutation = useUpdateWorkReference();
  const userType = user?.currentUser?.type;

  const onSubmit: SubmitHandler<Partial<WorkReference>> = async (
    _data: Partial<WorkReference>
  ) => {
    try {
      const creating = !data?._id;
      if (disabled) return;
      setDisabled(true);

      let company = _data.company !== '' ? _data.company : undefined;
      let companyName = _data.companyName ?? '';

      if (userType === 'business') {
        companyName = user?.currentUser?.firstName;
        company = (user?.currentUser?.user as User)?._id;
      }

      const workReferenceData: WorkReferenceData = {
        _id: data?._id ?? undefined,
        user: (user?.currentUser?.user as User)?._id,
        position: _data.position,
        startDate: _data.startDate,
        company,
        companyName,
        description: _data.description ?? '',
        privacy: _data.privacy ?? '',
      };

      if (_data.endDate) {
        workReferenceData.endDate = _data.endDate;
      }

      const workReferenceDataPromise = (
        !creating ? updateWorkReferenceMutation : createWorkReferenceMutation
      ).mutateAsync(workReferenceData);

      await Promise.all([workReferenceDataPromise])
        .then(() => {
          notify(t('profile.tabs.workReference.update.success'), 'success');
          queryClient.refetchQueries(['workReferences']);
        })
        .finally(() => {
          if (!creating) {
            router.push('/profile?tab=works');
          } else {
            setIsInForm(false);
          }
        });
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const actionButtonLabel = t(data ? 'update' : 'create');

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <WorkReferenceFormContent
          disabled={disabled}
          isUpdate={validateString(
            (data?.company as User & { profile: Profile })?._id ?? data?.company
          )}
          userType={userType}
        />
        <div className="w-full flex gap-5 justify-end mt-5">
          <Button
            tooltip={t('cancel')}
            className="btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              methods.reset();
              setIsInForm(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            tooltip={actionButtonLabel}
            type="submit"
            className="btn-primary"
          >
            {actionButtonLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default WorkReferenceForm;
