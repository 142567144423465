/* eslint-disable no-nested-ternary */
import React from 'react';
import { Category, Subcategory } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNotify } from '../../../../hooks';

interface SubcategoryDropdownProps {
  category: Category;
  subcategories;
  append;
  remove;
  selectedSubcategories?: Subcategory[];
}

export default function SubcategoryDropdown({
  category,
  subcategories,
  append,
  remove,
  selectedSubcategories,
}: SubcategoryDropdownProps) {
  const { t, i18n } = useTranslation();
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);

  const manageSubcategories = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget.dataset;
    const subcategory = subcategories?.filter((sc) => sc._id === id)[0];
    if (e.target.checked) {
      // add to setSelectedSubcategories
      if (selectedSubcategories?.length < 10) {
        // ver cómo hacer esta validación. el límite es por categoría, no en general
        append(subcategory);
      } else {
        notify(t('auth.signUp.wizard.talentCategory.subcategoryLimit'), 'info'); //  info o warning?
      }
    } else {
      // remove from setSelectedSubcategories
      const idx = (selectedSubcategories as Subcategory[])?.indexOf(
        (selectedSubcategories as Subcategory[])?.find((sc) => sc?._id === id)
      );
      remove(idx);
    }
  };

  return (
    <div className="flex flex-col md:w-5/12 w-full">
      {/* category */}
      <button
        type="button"
        onClick={(e) => setOpen(!open)}
        className="rounded bg-background-100 flex flex-row justify-between items-center px-6 py-3"
      >
        <span className="font-semibold">
          {category?.name[i18n?.language ?? 'en']}
        </span>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`text-neutral-300 transition-all duration-500 ${
            open ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {/* category's subcategories */}
      <div
        className={`${
          subcategories?.length > 0 ? 'max-h-64 overflow-y-scroll' : 'h-fit'
        }`}
      >
        {open ? (
          subcategories?.length > 0 ? (
            subcategories?.map((sc) => (
              <div className="w-full py-2 px-4" key={sc._id}>
                <label className="text-sm space-x-2 py-4 flex flex-row justify-between">
                  <span className="">{sc?.name[i18n?.language ?? 'en']}</span>
                  <input
                    defaultChecked={
                      !!selectedSubcategories.find(
                        (subC) => (subC as any)._id === sc._id
                      )
                    }
                    disabled={
                      !selectedSubcategories.find(
                        (subC) => (subC as any)._id === sc._id
                      ) && selectedSubcategories.length === 10
                    }
                    type="checkbox"
                    className="rounded disabled:opacity-50"
                    onChange={(e) => manageSubcategories(e)}
                    data-id={sc._id}
                  />
                </label>
              </div>
            ))
          ) : (
            <div className="p-4 text-neutral-500">
              {t('auth.signUp.wizard.talentCategory.noSubcategories')}
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}
