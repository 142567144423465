import React from 'react';

interface CardBody {
  children: React.ReactNode;
  className?: string;
}

export default function CardBody({ children, className = '' }: CardBody) {
  return (
    <div className={`p-6 flex flex-col flex-auto gap-6 ${className}`}>
      {children}
    </div>
  );
}
