import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { User } from '@dinbog/models';
import { Button, SpinnerIcon } from '@dinbog/ui';
import {
  useFollowUser,
  useUnfollowUser,
} from '../../../../../hooks/api/follows';
import { useNotify, useUser } from '../../../../../hooks';

interface FollowButtonProps {
  user: any;
  refreshUser?: () => void;
}

export default function FollowButton({ user, refreshUser }: FollowButtonProps) {
  const notify = useNotify();
  const { t } = useTranslation();
  const { currentUser } = useUser();
  const queryClient = useQueryClient();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  // Mutations
  const followMutation = useFollowUser();
  const unfollowMutation = useUnfollowUser();

  const buttonLabel = React.useMemo(() => {
    const follow = user?.relations?.follow;
    if (follow === 'approved') {
      return t('profile.relations.actions.unfollow');
    }
    if (follow === 'pending') {
      return t('profile.relations.requested');
    }
    return t('profile.relations.actions.follow');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    setDisabled(false);
  }, [buttonLabel]);

  const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disabled) return;
    setDisabled(true);
    try {
      const follow = ['approved', 'pending'].includes(user?.relations?.follow);
      const mutation = follow ? unfollowMutation : followMutation;

      const _data = await mutation.mutateAsync({
        from: (currentUser?.currentUser?.user as User)?._id as string,
        to: user?._id,
      });
      if (_data) {
        queryClient.refetchQueries(['followers', { user: user?._id }]);
        queryClient.refetchQueries(['following', { user: user?._id }]);
        // refrescar current user para actualizar stats
        refreshUser();
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  return (
    <Button
      tooltip={buttonLabel}
      className="btn-secondary h-fit whitespace-nowrap"
      onClick={handleOnClick}
      disabled={disabled}
    >
      <div className="flex gap-1 items-center">{buttonLabel}</div>
    </Button>
  );
}
