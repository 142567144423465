import React from 'react';
import { User } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import RelationsModal from '../relations/RelationsModal';
import { useUser } from '../../../../hooks';

export default function ProfileRelationsCount({ user }: { user }) {
  const { t } = useTranslation();
  const [relation, setRelation] = React.useState<
    'following' | 'followers' | 'connections'
  >('following');
  const [openRelationsModal, setOpenRelationsModal] = React.useState(false);
  const { currentUser: storedUser, updateCurrentUser, loading } = useUser();
  const [profileStats, setProfileStats] = React.useState(null);
  React.useEffect(() => {
    updateCurrentUser();
  }, []);

  React.useEffect(() => {
    if (!loading) {
      setProfileStats(user._id !== storedUser?._id ? user : storedUser);
    }
  }, [loading, user, storedUser]);

  return (
    <>
      <div className="flex flex-row gap-4 mt-4 md:text-base text-sm">
        <button
          type="button"
          onClick={() => {
            setRelation('followers');
            setOpenRelationsModal(true);
          }}
          disabled={!profileStats?.profile?.stats?.followers}
          className="disabled:opacity-70"
        >
          <span className="font-semibold">
            {profileStats?.profile?.stats?.followers ?? 0}
          </span>{' '}
          {t('profile.relations.followers').toLowerCase()}
        </button>
        <button
          type="button"
          onClick={() => {
            setRelation('following');
            setOpenRelationsModal(true);
          }}
          disabled={!profileStats?.profile?.stats?.following}
          className="disabled:opacity-70"
        >
          <span className="font-semibold">
            {profileStats?.profile?.stats?.following ?? 0}
          </span>{' '}
          {t('profile.relations.following').toLowerCase()}
        </button>
        <button
          type="button"
          onClick={() => {
            setRelation('connections');
            setOpenRelationsModal(true);
          }}
          disabled={!profileStats?.profile?.stats?.connections}
          className="disabled:opacity-70"
        >
          <span className="font-semibold">
            {profileStats?.profile?.stats?.connections ?? 0}
          </span>{' '}
          {t('profile.relations.connections').toLowerCase()}
        </button>
      </div>
      <RelationsModal
        userId={
          typeof user?.profile?.user === 'string'
            ? (user?.profile?.user as string)
            : (user?.profile?.user as User)?._id
        }
        relation={relation}
        isOpen={openRelationsModal}
        setOpen={setOpenRelationsModal}
      />
    </>
  );
}
