import React from 'react';

interface InfoWithImage {
  title: string;
  text: string;
  complimentaryText?: string;
  image: string;
  size?: 'sm' | 'lg';
  type?: 'info' | 'danger';
}

export default function InfoWithImage({
  title,
  text,
  complimentaryText,
  image,
  size = 'sm',
  type = 'info',
}: InfoWithImage) {
  return (
    <div className="flex flex-col items-center gap-y-9">
      <img className="w-80" src={image} alt="placeholder" />
      <div className="space-y-4">
        <h2
          className={`text-center font-semibold ${
            size === 'lg'
              ? 'md:text-3xl sm:text-2xl text-xl'
              : 'md:text-xl sm:text-lg text-base'
          }`}
        >
          {title}
        </h2>
        <p
          className={`text-center ${
            size === 'lg' ? 'md:text-xl text-base' : 'md:text-base text-xs'
          }`}
        >
          {text}
        </p>
        {complimentaryText ? (
          <p className="md:text-lg text-sm text-center">{complimentaryText}</p>
        ) : null}
      </div>
    </div>
  );
}
