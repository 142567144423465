import React from 'react';
import Cropper from 'react-easy-crop';
import { Area, Crop, DocumentModel, Point } from '../../../models';

interface CropAreaProps {
  onCropChange: (crop: Point) => void;
  onZoomChange: (zoom: number) => void;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
  completeCropImage: () => void;
  croppedImage: string;
  crop: Crop;
  img?: DocumentModel;
  className?: string;
  cropShape?: 'round' | 'rect';
}

function CropArea({
  onCropChange,
  onZoomChange,
  onCropComplete,
  completeCropImage,
  croppedImage,
  crop,
  img = null,
  className = '',
  cropShape = 'rect',
}: CropAreaProps) {
  return (
    <>
      <div className={`relative w-full flex ${className}`}>
        <Cropper
          image={(img?.src as string) ?? ''}
          crop={crop.crop}
          zoom={crop.zoom}
          aspect={crop.aspect}
          cropSize={crop.size}
          cropShape={cropShape}
          showGrid={false}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="p-5">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Zoom
        </label>
        <input
          type="range"
          value={crop.zoom * 10}
          onChange={(e) => {
            onZoomChange(parseFloat(e.target.value) / 10);
          }}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        />
      </div>
    </>
  );
}

export default CropArea;
