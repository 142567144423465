import { AxiosError } from 'axios';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import {
  Business,
  BusinessUser,
  BusinessUserRole,
  FiltersInput,
  Pagination,
  Profile,
  StatusEnum,
  User,
} from '@dinbog/models';
import { axios } from '../../api';

export function useBusinessMembers(
  filters: Pick<BusinessUser, 'user' & 'role'> | FiltersInput,
  options: Omit<
    UseQueryOptions<
      Pagination<{
        _id: string;
        profile: Profile;
        role: BusinessUserRole;
        status?: StatusEnum;
      }>,
      AxiosError,
      Pagination<{
        _id: string;
        profile: Profile;
        role: BusinessUserRole;
        status?: StatusEnum;
      }>,
      ['businessMembers']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<
    Pagination<{
      _id: string;
      profile: Profile;
      role: BusinessUserRole;
      status?: StatusEnum;
    }>,
    AxiosError
  >(
    ['businessMembers', filters],
    async () => {
      const { data } = await axios.get<
        Pagination<{
          _id: string;
          profile: Profile;
          user: User;
          role: BusinessUserRole;
          status?: StatusEnum;
        }>
      >('/api/users/business/admins/v1', {
        params: { ...filters },
      });
      return data;
    },
    options
  );
  return query;
}

export function useUpdateMembers() {
  const mutation = useMutation<
    {
      business: string;
      members: Array<{
        user: string;
        email: string;
        role: string;
        action: string;
      }>;
    },
    AxiosError,
    Partial<{
      business: string;
      members: Array<{
        user?: string;
        email?: string;
        role: string;
        action: string;
      }>;
      token: string;
    }>
  >(async (newMembers) => {
    const { data } = await axios.patch(
      `/api/users/business/members/v1`,
      newMembers,
      {
        headers: {
          Authorization: `Bearer ${newMembers.token}`,
        },
      }
    );
    return data;
  });
  return mutation;
}

export function useManageMembers() {
  const mutation = useMutation<
    {
      user: string;
      action: 'add' | 'remove' | 'update';
      admin: Partial<BusinessUser>;
    },
    AxiosError,
    Partial<{
      user: string;
      action: 'add' | 'remove' | 'update';
      admin: Partial<BusinessUser>;
    }>
  >(async (member) => {
    const { data } = await axios.patch(`/api/users/business/admins/v1`, member);
    return data;
  });
  return mutation;
}

export function useBusinesses(
  filters?: FiltersInput,
  options: Omit<
    UseQueryOptions<
      Pagination<Business>,
      AxiosError,
      Pagination<Business>,
      ['businesses']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<Business>, AxiosError>(
    ['business', filters],
    async () => {
      const { data } = await axios.get<Pagination<Business>>(
        '/api/users/businesses/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useBusinessDetails(
  filters?: Partial<Business>,
  options: Omit<
    UseQueryOptions<Business, AxiosError, Business, ['business']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Business, AxiosError>(
    ['business', filters?._id],
    async () => {
      const { data } = await axios.get<Business>(
        '/api/users/business/details/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useUpdateBusiness(
  options: Omit<
    UseMutationOptions<Partial<Profile>, AxiosError, Partial<Profile>, unknown>,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<Partial<Profile>, AxiosError, Partial<Profile>>(
    async (updatedBusiness) => {
      const { data } = await axios.patch(
        '/api/users/business/v1',
        updatedBusiness
      );
      return data;
    },
    options
  );
  return mutation;
}
