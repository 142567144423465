import React from 'react';
import { useRouter } from 'next/router';
import { User } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import {
  useBookWithImages,
  useUpdateBook,
} from '../../../../../hooks/api/books';
import { MediaProfileLayout } from '../../../../layout';
import BookForm from './BookForm';
import {
  DeleteMediaConfirmationModal,
  Post,
  ReportModal,
  SkeletonWrapper,
} from '../../../../shared';
import { useNotify, useUser } from '../../../../../hooks';

function BookProfile({ categories }) {
  const router = useRouter();
  const { currentUser: user } = useUser();
  const { slug: userSlug } = router.query;
  const notify = useNotify();
  const [update, setUpdate] = React.useState(false);
  const [openDeleteForm, setOpenDeleteForm] = React.useState(false);
  const [openReportForm, setOpenReportForm] = React.useState(false);
  const { t } = useTranslation();

  const { data, isLoading, refetch } = useBookWithImages({
    currentUser: user?._id ?? '',
    _id: router.query.book as string,
  });

  const profileInfo = {
    user: {
      slug: userSlug as string,
    },
  };

  const dataWithSlugEqualstoId = {
    ...data,
    slug: router.query.book as string,
  };

  const updateBookMutation = useUpdateBook();
  const queryClient = useQueryClient();
  const onDelete = async () => {
    try {
      await updateBookMutation.mutateAsync(
        {
          _id: data?._id ?? undefined,
          active: false,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries([
              'bookWithImages',
              { user: user?._id ?? '', _id: router.query.book as string },
            ]);
            queryClient.refetchQueries(['books', { _id: user?._id }]);
          },
        }
      );

      notify(t('profile.tabs.book.update.success'), 'success');
      setOpenDeleteForm(false);
      router.push({
        pathname: `${router.pathname}`,
        query: {
          ...router.query,
          book: undefined,
        },
      });
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  return (
    <SkeletonWrapper isLoading={isLoading}>
      <MediaProfileLayout
        isOwner={router?.pathname === '/profile'}
        handleGoBack={() => {
          if (update) {
            return setUpdate(false);
          }

          router.push({
            pathname: `${router.pathname}`,
            query: {
              ...router.query,
              book: undefined,
            },
          });
        }}
        type={t('profile.tabs.book.title')}
        setOpenEditForm={setUpdate}
        setOpenDeleteForm={setOpenDeleteForm}
        setOpenReportForm={setOpenReportForm}
      >
        {update ? (
          <BookForm
            data={data}
            categories={categories}
            setIsInForm={setUpdate}
          />
        ) : (
          <Post
            content={dataWithSlugEqualstoId}
            owner={profileInfo}
            contentType="book"
            onSuccessLike={() => refetch()}
          />
        )}
        <DeleteMediaConfirmationModal
          handleDelete={() => {
            onDelete();
          }}
          isOpen={openDeleteForm}
          setOpen={setOpenDeleteForm}
        />
        <ReportModal
          isOpen={openReportForm}
          setOpen={setOpenReportForm}
          item="book"
          itemIdToBeReported={router.query.book as string}
          service="portfolio"
          itemOwner={(data?.user as User)?._id}
        />
      </MediaProfileLayout>
    </SkeletonWrapper>
  );
}

export default BookProfile;
