/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useRouter } from 'next/router';
import {
  Category,
  User,
  Vacant,
  Event as EventModel,
  Profile,
  Event,
} from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useNotify, useUser } from '../../../../../../hooks';
import { useUpdateVacant } from '../../../../../../hooks/api/vacancies';
import { DeleteVacancyConfirmationModal } from '../../../../../shared';
import { useSharePost } from '../../../../../../hooks/api/posts';

export default function VacancyCard({ vacancy }: { vacancy: Vacant }) {
  const { currentUser: user } = useUser();
  const router = useRouter();
  const notify = useNotify();
  const { t, i18n } = useTranslation();
  const [openDeleteForm, setOpenDeleteForm] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const updateVacantMutation = useUpdateVacant();
  const queryClient = useQueryClient();
  const sharePost = useSharePost();

  const onDelete = async () => {
    setDisabled(true);
    try {
      const result = await updateVacantMutation.mutateAsync(
        { _id: vacancy?._id, active: false },
        {
          onSuccess: () => {
            queryClient.refetchQueries([`vacancies`]);
          },
        }
      );
      if (result) {
        notify(t('events.casting.vacancies.delete.success'), 'success');
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onShare = async () => {
    setDisabled(true);
    try {
      const shareUrl = await sharePost.mutateAsync({
        collection: 'vacant',
        slugCollection: vacancy?.slug,
        slugEvent: (vacancy?.event as Event)?.slug,
      });
      navigator.clipboard.writeText(shareUrl);
      notify(t('copyToClipboard'), 'success');
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
    setDisabled(false);
  };

  return (
    <div className="flex justify-between items-center px-6 py-4 border-b">
      {/* info */}
      <button
        key={vacancy?._id}
        className="w-full text-left"
        type="button"
        onClick={() => {
          if (
            (user?.currentUser?.user as User)?._id ===
            (((vacancy?.event as EventModel)?.user as Profile)?.user as User)
              ?._id
          ) {
            router.push({
              pathname: '/briefcase/castings/[slug]',
              query: {
                slug: router.query.slug,
                tab: 'vacancies',
                vacancy: vacancy?.slug,
              },
            });
          } else {
            router.push({
              pathname: '/briefcase/castings/[slug]',
              query: { ...router.query, tab: 'info', vacancy: vacancy?.slug },
            });
          }
        }}
      >
        <div className="flex flex-col">
          <div className="flex gap-4 items-center">
            {/* vacancy name */}
            <span className="font-semibold">{vacancy?.title}</span>
            {vacancy?.status === 'draft' ? (
              <span className="bg-neutral-300/80 text-text-white py-1 px-3 text-xs rounded h-fit">
                {t('events.casting.vacancies.draft')}
              </span>
            ) : null}
            {/* vacancy?.status === 'draft' ? (
              <span className="text-neutral-300 text-sm rounded h-fit">
                Draft
              </span>
            ) : null */}
          </div>
          {/* categories */}
          <div className="flex gap-2">
            {vacancy?.categories?.map((category, idx) => (
              <div className="flex gap-2" key={idx}>
                <span className="">
                  {(category as Category)?.name?.[i18n?.language ?? 'en']}
                </span>
                <span className="">
                  {vacancy?.categories?.length > idx + 1 ? '|' : null}
                </span>
              </div>
            ))}
          </div>
        </div>
      </button>
      {/* actions */}
      <div className="flex gap-4">
        {(user?.currentUser?.user as User)?._id ===
        (((vacancy?.event as EventModel)?.user as Profile)?.user as User)
          ?._id ? (
          <>
            {/* delete */}
            <button type="button" onClick={() => setOpenDeleteForm(true)}>
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="w-5 h-5 text-neutral-400"
              />
            </button>
            {/* edit */}
            <button
              type="button"
              onClick={() =>
                router.push({
                  pathname: `/briefcase/castings/[slug]`,
                  query: {
                    slug: router.query.slug,
                    tab: 'vacancies',
                    vacancy: vacancy?.slug,
                    edit: true,
                  },
                })
              }
            >
              <FontAwesomeIcon
                icon={faPencilAlt}
                className="w-5 h-5 text-neutral-400"
              />
            </button>
          </>
        ) : null}
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onShare();
          }}
          disabled={disabled}
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="w-5 h-5 text-neutral-400"
          />
        </button>
      </div>
      <DeleteVacancyConfirmationModal
        handleDelete={() => {
          onDelete();
        }}
        isOpen={openDeleteForm}
        setOpen={setOpenDeleteForm}
        disabled={disabled}
      />
    </div>
  );
}
