import React from 'react';
import { ItemCollectionEnum, LikeItem, Pagination } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { InfiniteScroll, Modal, SpinnerIcon } from '@dinbog/ui';
import LikeDetail from './LikeDetail';

interface LikeModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: { _id: string; owner: any; type: ItemCollectionEnum };
  likesData: any;
  setPagination: React.Dispatch<
    React.SetStateAction<{ page: number; perPage: number }>
  >;
  likesLoading: boolean;
}

function LikeModal({
  isOpen = false,
  setOpen,
  item,
  likesData,
  setPagination,
  likesLoading,
}: LikeModalProps) {
  const { t } = useTranslation();
  const [likesList, setCommentsList] = React.useState([
    ...(likesData?.items ?? []),
  ]);
  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 md:p-2 flex flex-col gap-4 items-center"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <Modal.Header title={t('stats.likes')} exitBtn />
      <Modal.Body padding="small">
        {likesLoading ? (
          <>
            <SpinnerIcon className="m-auto w-20 h-20 text-gray-200 animate-spin  fill-primary-500" />
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          <InfiniteScroll
            containerClassName="w-full flex flex-col-reverse gap-4 px-4"
            loading={likesLoading}
            data={likesData}
            setPagination={setPagination}
            setLoadedData={setCommentsList}
            maxHeight={350}
            triggerFunctionOnHit="top"
          >
            {likesList.map((like, idx) => (
              <LikeDetail
                key={`${like.firstName}${like.lastName}-${idx}`}
                like={like}
              />
            ))}
          </InfiniteScroll>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default LikeModal;
