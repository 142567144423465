import React from 'react';
import { User } from '@dinbog/models';
import { Button, Modal, Select, UserSelectSearch } from '@dinbog/ui';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../hooks';
import { useGetConnections } from '../../../hooks/api/connections';
import EditableMentionsList from './EditableMentionsList';
import UserConnectionList from '../../shared/UserConnectionList';

interface PostAddMentionsModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mentions;
  setMentions: React.Dispatch<React.SetStateAction<any>>;
}

export default function PostAddMentionsModal({
  isOpen = false,
  setOpen,
  mentions,
  setMentions,
}: PostAddMentionsModalProps) {
  const { t } = useTranslation();

  // form context
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  // select users data
  const { currentUser: user } = useUser();
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 30 });
  const [searchText, setSearchText] = React.useState('');
  const [mentionedUsers, setMentionedUsers] = React.useState(mentions);

  // users data
  const { data, isLoading } = useGetConnections({
    page: pagination.page,
    perPage: pagination.perPage,
    searchString: searchText,
    from: (user?.currentUser?.user as User)?._id,
    status: 'approved',
    /* filter: JSON.stringify({
      status: {
        $ne: 'ghost',
      },
    }), */
  });

  // delete mention from mentions list
  const handleDeleteMention = (mentionIdx) => {
    const updatedMentions = mentionedUsers?.filter(
      (u, idx) => idx !== mentionIdx
    );
    setMentionedUsers(updatedMentions);
  };

  const onSubmit = () => {
    setMentions(mentionedUsers);
    setMentionedUsers([]);
    setOpen(false);
  };

  const handleSelectUser = (selectedUser_) => {
    if (mentionedUsers?.length < 20) {
      setMentionedUsers((prev) => [
        ...prev.filter((_user) => _user?._id !== selectedUser_?._id),
        selectedUser_,
      ]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setOpen={setOpen}
      className="bg-background-50 p-6 rounded"
    >
      <Modal.Header exitBtn />
      <div className="space-y-4">
        <p className="font-semibold text-xl text-center -mt-6">
          {t('post.tagPeople')}
        </p>
        <div className="flex flex-col gap-4">
          <EditableMentionsList
            mentions={mentionedUsers}
            handleDeleteMention={handleDeleteMention}
          />
          <UserSelectSearch
            setPagination={setPagination}
            setSearchText={setSearchText}
            searchText={searchText}
            usersData={data}
            usersLoading={isLoading}
            onSelectUser={(selectedUser_) => {
              handleSelectUser(selectedUser_);
            }}
            inputPlaceholder={t(
              'auth.signUp.wizard.selectAdmin.searchUsersPlaceholder'
            )}
            loadingText={t('auth.signUp.wizard.selectAdmin.searchUsersLoading')}
            noResultsText={t(
              'auth.signUp.wizard.selectAdmin.searchUsersNoResults'
            )}
          />

          <UserConnectionList
            usersData={data}
            usersLoading={isLoading}
            onSelectUser={handleSelectUser}
          />
          <Button className="btn-primary" onClick={() => onSubmit()}>
            {t('tag')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
