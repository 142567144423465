import React from 'react';
import { useTranslation } from 'react-i18next';
import OptionBox from './OptionBox';

type DropdownOption = {
  value: string;
  label: string;
};
interface InfiniteDropdownProps {
  options: DropdownOption[];
  selectedOption: string;
  onChange: (value: any, label: string) => void;
  fetchNextPage: any;
}
export default function InfiniteDropdown({
  options,
  selectedOption,
  onChange,
  fetchNextPage,
}: InfiniteDropdownProps) {
  const { t } = useTranslation();

  const observer = React.useRef<IntersectionObserver>();
  const lastOptionRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries.length > 0 && entries[0].isIntersecting) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [options]
  );
  return (
    <div className="flex flex-col justify-center items-center w-full">
      <h1 className="text-text-black text-sm font-semibold mb-2 text-left w-full">
        {t('searchPage.categorySearch')}
      </h1>
      <div className="w-full bg-white border rounded-lg max-h-[100px] overflow-y-auto px-1">
        <ul>
          {options?.map((option, index) => (
            <div
              key={option.value}
              ref={index === options.length - 1 ? lastOptionRef : null}
            >
              <OptionBox
                label={option.label}
                value={option.value}
                selected={option.value === selectedOption}
                onSelectOption={onChange}
              />
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}
