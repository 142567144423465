import React from 'react';

import RequestContainer from './RequestContainer';

export default function Requests() {
  return (
    <RequestContainer>
      <RequestContainer.RequestListSection />
      <RequestContainer.NotificationListSection />
      <RequestContainer.SuggestionSection />
    </RequestContainer>
  );
}
