/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { EyeIcon, Input, PasswordEyeIcon } from '@dinbog/ui';
import { useFormContext } from 'react-hook-form';
import { validateEmail } from 'avilatek-utils';
import { useTranslation } from 'react-i18next';

interface SignUpPersonContentProps {
  setOpenTermsAndConditionsModal?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setTermType?: React.Dispatch<React.SetStateAction<'Adults' | 'Children'>>;
}

function SignUpPersonContent({
  setOpenTermsAndConditionsModal,
  setTermType,
}: SignUpPersonContentProps) {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConf, setShowPasswordConf] = React.useState(false);

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const formValidations = {
    ...(watch('type') === 'person'
      ? {
          firstName: { required: t('required') },
          lastName: { required: t('required') },
          birthDate: {
            required: t('required'),
            validate: {
              isOldEnough: (value) => {
                const date = new Date(value);
                const today = new Date();
                const age = today.getFullYear() - date.getFullYear();
                return age >= 14 || t('invalidAge');
              },
            },
          },
          email: {
            required: t('required'),
            validate: (value, formValues) => validateEmail(value),
          },
          password: {
            required: t('required'),
            pattern:
              /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.\\/:;<=>?\\@[\]^_`{|}~]).{8,}$/,
          },
          passwordConf: {
            required: t('required'),
            validate: (value, formValues) => value === formValues.password, // TODO: revisar
          },
        }
      : {}),
  };

  const getMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 14);
    return date.toISOString().split('T')[0];
  };

  const isTeen = () => {
    const today = new Date();
    const birthDate = new Date(watch('birthDate'));
    const age = today.getFullYear() - birthDate.getFullYear();
    return age >= 14 && age <= 17;
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="">
        <Input
          error={errors.firstName?.message as string}
          type="text"
          {...register('firstName', formValidations.firstName)}
          placeholder={t('auth.signUp.firstName') ?? ''}
        />
      </div>
      <div className="">
        <Input
          type="text"
          {...register('lastName', formValidations.lastName)}
          placeholder={t('auth.signUp.lastName') ?? ''}
          error={errors.lastName?.message as string}
        />
      </div>
      <div className="">
        <Input
          type="email"
          {...register('email', formValidations.email)}
          error={errors.email?.message as string}
          placeholder={t('auth.signUp.email') ?? ''}
        />
      </div>
      <div className="">
        <Input
          isRequired
          type="text"
          onFocus={(e) => (e.target.type = 'date')}
          error={errors?.birthDate?.message as string}
          placeholder={t('auth.signUp.wizard.personal.birthDay.title') ?? ''}
          max={getMaxDate()}
          {...register('birthDate', formValidations.birthDate)}
        />
        {/* puede que tenga que agregar el icono del calendario */}
      </div>
      <div className="">
        <Input
          type={showPassword ? 'text' : 'password'}
          {...register('password', formValidations.password)}
          placeholder={t('auth.signUp.password') ?? ''}
          rightIcon={
            !showPassword ? (
              <button
                className=""
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                <PasswordEyeIcon className="w-5 h-5" />
              </button>
            ) : (
              <button
                className=""
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                <EyeIcon className="w-5 h-5" />
              </button>
            )
          }
        />
        {errors.password && (
          <span className="text-sm text-danger-300">
            {t('auth.signUp.notifs.passwordRequirements')}
          </span>
        )}
      </div>
      <div className="">
        <Input
          type={showPasswordConf ? 'text' : 'password'}
          {...register('passwordConfirmation', formValidations.passwordConf)}
          placeholder={t('auth.signUp.confirmPassword') ?? ''}
          rightIcon={
            !showPasswordConf ? (
              <button
                className=""
                type="button"
                onClick={() => setShowPasswordConf(!showPasswordConf)}
              >
                <PasswordEyeIcon className="w-5 h-5" />
              </button>
            ) : (
              <button
                className=""
                type="button"
                onClick={() => setShowPasswordConf(!showPasswordConf)}
              >
                <EyeIcon className="w-5 h-5" />
              </button>
            )
          }
        />
        {errors.passwordConfirmation &&
          (errors.passwordConfirmation.type === 'required' ? (
            <span className="text-sm text-danger-300">
              {t('auth.signUp.notifs.confirmPassword')}
            </span>
          ) : (
            <span className="text-sm text-danger-300">
              {t('auth.signUp.notifs.differentPasswordValues')}
            </span>
          ))}
      </div>
      {isTeen() ? (
        <div className="flex flex-col">
          <label className="text-sm space-x-2 py-1">
            <input
              type="checkbox"
              {...register('termsCheckbox', {
                required: true,
              })}
              className={`rounded ${
                errors.termsCheckbox && errors.termsCheckbox.type === 'required'
                  ? 'border-danger-300'
                  : ''
              }`}
            />
            <span className="">
              {t('auth.signUp.terms.text')}{' '}
              <a
                href="#"
                className="text-primary-500 hover:text-primary-500/80"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTermsAndConditionsModal(true);
                  setTermType('Children');
                }}
              >
                {t('auth.signUp.terms.childrenLink')}
              </a>
            </span>
          </label>
          {errors.termsCheckbox && errors.termsCheckbox.type === 'required' ? (
            <span className="text-sm text-danger-300">
              {t('auth.signUp.notifs.terms')}
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default SignUpPersonContent;
