import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FiltersInput, Subcategory } from '@dinbog/models';
import { axios } from '../../api';

export function useSubcategories(
  filters: Partial<Subcategory> & FiltersInput,
  options: Omit<
    UseQueryOptions<
      Subcategory[],
      AxiosError,
      Subcategory[],
      ['subcategories']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Subcategory[], AxiosError>(
    ['subcategories', filters?.category],
    async () => {
      const { data } = await axios.get<Subcategory[]>(
        '/api/users/subcategories/v1',
        {
          params: { ...filters },
        }
      );

      return data;
    },
    options
  );
  return query;
}
