import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Book,
  Category,
  DocumentModel,
  Multimedia,
  User,
} from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@dinbog/ui';
import { useNotify, useUser } from '../../../../../hooks';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../../utils/formatDocumentModel';
import { useCreateBook, useUpdateBook } from '../../../../../hooks/api/books';
import { BookFormContent } from '../../../../shared';
import { useSignS3 } from '../../../../../hooks/api/s3';
import { uploadFile } from '../../../../../lib/uploadFile';

interface BookFormProps {
  data?: Book & { images: { multimedia: Multimedia }[] };
  categories: Category[];
  setIsInForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function BookForm({ data = null, categories, setIsInForm }: BookFormProps) {
  const { t, i18n } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [images, setImages] = React.useState<DocumentModel[]>(
    data !== null
      ? multimediaToDocument([
          data?.cover,
          ...(data?.images?.map((img) => img?.multimedia) ?? []),
        ])
      : []
  );
  const updateURLs = React.useCallback(setImages, [setImages]);
  const methods = useForm<Book>({
    defaultValues: {
      categories: data?.categories ?? [],
      title: data?.title ?? '',
      description: data?.description ?? '',
      privacy: data?.privacy ?? 'public',
      cover: data ? (multimediaToDocument([data?.cover]) as any) : null,
    },
  });

  // queries/mutations
  const queryClient = useQueryClient();
  const createBookMutation = useCreateBook();
  const updateBookMutation = useUpdateBook();
  const uploadToS3Mutation = useSignS3();

  const handlePhotoUpload = () => {
    // upload images to s3
    const newImages = images?.map((image, idx) => {
      let newImg = (
        idx === 0 ? data?.cover : data?.images[idx - 1]?.multimedia
      ) as any;
      // if the image is didn't change, don't upload it
      if (
        image?.src !== data?.images[idx - 1]?.multimedia?.url &&
        image?.src !== data?.cover?.url // we know that the cover is always the first image of images
      )
        newImg = uploadFile(
          image,
          setUploading,
          updateURLs,
          uploadToS3Mutation,
          notify,
          t
        );
      return newImg;
    });
    return newImages;
  };

  const onSubmit: SubmitHandler<Partial<Book>> = async (
    _data: Partial<Book>
  ) => {
    try {
      if (disabled) return;
      setDisabled(true);

      if (images?.length < 3 || images?.length > 10) {
        return notify(t('auth.signUp.wizard.notifs.bookLength'), 'error');
      }

      const returnedImages = handlePhotoUpload();

      const fulfilledPromises = await Promise.all(returnedImages);

      const imagesAsDocs = images?.map((img, idx) => ({
        src: fulfilledPromises[idx]?.url ?? img?.src,
        name: img?.name,
        id: img?.id,
        file: img?.file,
      }));

      const bookImages =
        imagesAsDocs?.length > 0 ? documentToMedia(imagesAsDocs) : null;

      const promises = [
        (data ? updateBookMutation : createBookMutation).mutateAsync({
          _id: data?._id ?? undefined,
          title: _data?.title,
          user: (user?.currentUser.user as User)._id,
          description: _data?.description,
          privacy: _data?.privacy,
          categories: _data?.categories,
          ...(images?.length > 0
            ? {
                cover: { ...bookImages[0], type: 'image' },
                photos: bookImages
                  ?.slice(1)
                  ?.map((img) => ({ ...img, type: 'image' })),
              }
            : { cover: undefined, photos: undefined }),
        }),
      ];

      await Promise.all(promises).then(() => {
        queryClient.refetchQueries(['bookWithImages', { _id: data?._id }]);
        queryClient.refetchQueries(['books', { user: user?._id }]);
      });
      setIsInForm(false);
    } catch (err) {
      notify(
        err?.response?.data?.message || t('auth.signUp.notifs.failure'),
        'error'
      );
    } finally {
      setDisabled(false);
    }
  };

  const actionButtonLabel = t(data ? 'update' : 'create');

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <BookFormContent
          disabled={disabled}
          updateURLs={updateURLs}
          categories={categories}
          images={images}
          setImages={setImages}
          categoriesDefault={(data?.categories as Category[])?.map((cat) => ({
            name: cat?.name[i18n?.language ?? 'en'],
            _id: cat?._id,
          }))}
        />
        <div className="w-full flex gap-5 justify-end mt-5">
          <Button
            tooltip={t('cancel')}
            className=" bg-neutral-300 rounded"
            onClick={(e) => {
              e.preventDefault();
              methods.reset();
              setIsInForm(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            tooltip={actionButtonLabel}
            type="submit"
            className="bg-primary-500 text-white font-normal"
          >
            {actionButtonLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default BookForm;
