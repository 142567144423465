import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useUser } from '../../../hooks';
import { reducer } from './reducer';
import { ProgressBar } from '../../shared';
import PersonalInformation from './personalInformation/PersonalInformation';
import DiscoverPeople from './discoverPeople/DiscoverPeople';

function FanSignUp({
  currentStep = 1,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentUser: user } = useUser();
  const [userState, userDispatch] = React.useReducer(reducer, {});

  const forms = React.useMemo(
    () => [
      <PersonalInformation
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        state={userState}
        dispatch={userDispatch}
      />,
      <DiscoverPeople
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, userState]
  );
  React.useEffect(() => {
    setCurrentStep(parseInt(user?.profile?.registrationStep) ?? 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile?.registrationStep]);

  React.useEffect(() => {
    if (currentStep >= 3) router?.push('/profile');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <>
      <h2 className="font-semibold text-lg">{t('auth.signUp.wizard.title')}</h2>
      <div className="flex flex-row justify-center">
        <ProgressBar steps={2} currentStep={currentStep} />
      </div>
      {forms[currentStep - 1]}
    </>
  );
}

export default FanSignUp;
