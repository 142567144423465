import React from 'react';
import Skeleton from './Skeleton';

interface SkeletonWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  className?: string;
}

function SkeletonWrapper({
  children,
  isLoading,
  className,
}: SkeletonWrapperProps) {
  if (isLoading) return <Skeleton className={className ?? 'vh-[60vh]'} />;

  return <div>{children}</div>;
}

export default SkeletonWrapper;
