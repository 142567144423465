import React from 'react';
import { Button } from '@dinbog/ui';
import { Profile } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNotify, useUser } from '../../../../../hooks';
import { useAcceptOrRejectWorkReference } from '../../../../../hooks/api/workReferences';
import { PersonItem } from '../../../../shared';

export default function TalentList({
  talents,
  viewing = 'current',
}: {
  talents /* : Profile[] */;
  viewing?: 'current' | 'past' | 'requests';
}) {
  const notify = useNotify();
  const { currentUser: user } = useUser();
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const acceptOrRejectWRMutation = useAcceptOrRejectWorkReference();
  const queryClient = useQueryClient();

  const manageAcceptOrRejectWR = async (
    workReferenceId: string,
    status: 'approved' | 'rejected'
  ) => {
    // mutation
    setDisabled(true);
    try {
      const _data = await acceptOrRejectWRMutation.mutateAsync({
        companyAdminId: user?._id as string,
        workReferenceId,
        status,
      });
      if (_data) {
        queryClient.refetchQueries(['workReferences', { company: user?._id }]);
        // refrescar current user para actualizar stats
        // setUser();
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div className="flex flex-wrap w-full gap-4">
      {talents?.map((talent) => (
        <div className="w-fit" key={talent?.user?._id}>
          <PersonItem
            user={{ profile: talent?.user, position: talent?.position }}
            isHorizontal
          >
            <div className="">
              <PersonItem.Header
                user={{ profile: talent?.user, position: talent?.position }}
              />
              <PersonItem.Body
                user={{
                  profile: talent?.user,
                  position: talent?.position,
                  startDate: talent?.startDate,
                  endDate: talent?.endDate,
                }}
              />
            </div>
            {viewing === 'requests' ? (
              <div className="flex gap-1 w-full">
                <Button
                  tooltip={t('decline')}
                  className="btn-secondary w-full"
                  size="sm"
                  onClick={() =>
                    manageAcceptOrRejectWR(talent?._id, 'rejected')
                  }
                >
                  {t('decline')}
                </Button>
                <Button
                  tooltip={t('accept')}
                  className="btn-primary w-full"
                  size="sm"
                  onClick={() =>
                    manageAcceptOrRejectWR(talent?._id, 'approved')
                  }
                >
                  {t('accept')}
                </Button>
              </div>
            ) : null}
          </PersonItem>
        </div>
      ))}
    </div>
  );
}
