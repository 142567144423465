import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Album, Category, DocumentModel, Track, User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@dinbog/ui';
import { useNotify, useUser } from '../../../../../hooks';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../../utils/formatDocumentModel';
import {
  useCreateTrack,
  useUpdateTrack,
} from '../../../../../hooks/api/tracks';
import {
  useCreateAlbum,
  useUpdateAlbum,
} from '../../../../../hooks/api/albums';
import { AudioFormContent } from '../../../../shared';
import { useSignS3 } from '../../../../../hooks/api/s3';
import { uploadFile } from '../../../../../lib/uploadFile';

interface AudioFormProps {
  data: Album & { tracks: Track[] };
  categories: Category[];
  setIsInForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function AudioForm({ data, categories, setIsInForm }: AudioFormProps) {
  const { t, i18n } = useTranslation();
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [disabled, setDisabled] = React.useState(false);
  const [media, setMedia] = React.useState<DocumentModel[]>(
    data?.cover ? multimediaToDocument([data?.cover]) : []
  );
  const updateURLs = React.useCallback(setMedia, [setMedia]);
  const methods = useForm<Album>({
    defaultValues: {
      title: data?.title ?? '',
      description: data?.description ?? '',
      privacy: data?.privacy ?? null,
      tracks: data?.tracks ?? [],
      categories:
        data?.categories.map((category: Category) => category._id) ?? [],
    },
  });

  const createAlbumMutation = useCreateAlbum();
  const createTrackMutation = useCreateTrack();
  const updateAlbumMutation = useUpdateAlbum();
  const updateTrackMutation = useUpdateTrack();
  const signS3 = useSignS3();

  const onSubmit: SubmitHandler<Partial<Album>> = async (
    _data: Partial<Album>
  ) => {
    try {
      const creating = !data?._id;
      if (disabled) return;
      setDisabled(true);
      let coverImg = { url: data?.cover?.url };
      if (media?.length > 0 && media[0]?.src !== data?.cover?.url) {
        coverImg = await uploadFile(
          media[0],
          setDisabled,
          updateURLs,
          signS3,
          notify,
          t
        );
      }
      const image = media?.length > 0 ? documentToMedia(media) : null;

      const albumDataPromise = (
        !creating ? updateAlbumMutation : createAlbumMutation
      ).mutateAsync({
        _id: data?._id ?? undefined,
        title: _data.title,
        user: (user?.currentUser.user as User)._id,
        description: _data.description,
        tracks: !creating
          ? undefined
          : _data.tracks.map((track: Track) => ({
              title: track.title,
              audio: {
                fileName: track.title.replaceAll(' ', '-'),
                type: 'audio',
                url: track.audio.url,
              },
            })) ?? [],
        privacy: _data.privacy,
        categories: _data?.categories,
        ...(media?.length > 0
          ? {
              cover: {
                ...image[0],
                type: 'image',
                url: coverImg?.url ?? data?.cover?.url,
              },
            }
          : { cover: undefined }),
      });

      const tracksPromises = [];
      if (!creating) {
        _data?.tracks?.forEach((track: Track) => {
          // if track is new, its id will a number
          if (Number.isNaN(+track._id)) {
            tracksPromises.push(
              updateTrackMutation.mutateAsync({
                _id: track._id,
                album: data?._id,
                title: track.title,
                audio: {
                  fileName: track.title.replaceAll(' ', '-'),
                  type: 'audio',
                  url: track.audio.url,
                },
              })
            );
          } else {
            tracksPromises.push(
              createTrackMutation.mutateAsync({
                title: track.title,
                album: data?._id,
                audio: {
                  fileName: track.title.replaceAll(' ', '-'),
                  type: 'audio',
                  url: track.audio.url,
                },
              })
            );
          }
        });
      }

      await Promise.all([albumDataPromise, ...tracksPromises]).then(() => {
        queryClient.refetchQueries(['album', { _id: data?._id }]);
        queryClient.refetchQueries(['albums', { user: user?._id }]);
      });
      notify(t('profile.tabs.audio.update.success'), 'success');
      setIsInForm(false);
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleDeleteTrack = async (trackId: string) => {
    if (disabled) return;
    setDisabled(true);
    try {
      if (!Number.isNaN(+trackId)) return; // if track is new, its id will a number and we don't need to delete it
      await updateTrackMutation.mutateAsync({
        _id: trackId,
        active: false,
      });
      queryClient.refetchQueries(['allTracks', { album: data?._id }]);
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const actionButtonLabel = t(data ? 'update' : 'create');

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <AudioFormContent
          media={media}
          disabled={disabled}
          updateURLs={updateURLs}
          categoriesDefault={categories
            ?.map((cat) => ({
              name: cat?.name[i18n?.language ?? 'en'],
              _id: cat?._id,
            }))
            .filter((cat) =>
              (data?.categories as Array<{ _id: string }>)?.some(
                (category) => category._id === cat?._id
              )
            )}
          categories={categories}
          handleDeleteTrack={handleDeleteTrack}
        />
        <div className="w-full flex gap-5 justify-end mt-5">
          <Button
            tooltip={t('cancel')}
            className=" bg-neutral-300 rounded"
            onClick={(e) => {
              e.preventDefault();
              methods.reset();
              setIsInForm(false);
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            tooltip={actionButtonLabel}
            type="submit"
            className="bg-primary-500 text-white font-normal"
          >
            {actionButtonLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default AudioForm;
