import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import {
  User,
  Profile,
  Pagination,
  FiltersInput,
  Business,
} from '@dinbog/models';
import { axios } from '../../api';

export function useBlockUser() {
  const mutation = useMutation<
    { to: string; from: string },
    AxiosError,
    Partial<{ to: string; from: string }>
  >(async (params) => {
    const { data } = await axios.post(`/api/users/block/v1`, params);
    return data;
  });
  return mutation;
}

export function useUnblockUser() {
  const mutation = useMutation<
    { to: string; from: string },
    AxiosError,
    Partial<{ to: string; from: string }>
  >(async (params) => {
    const { data } = await axios.patch(`/api/users/unblock/v1`, params);
    return data;
  });
  return mutation;
}

export interface useGetBlackListProps {
  _id: string;
  userType: string;
  profile?: Profile;
  business?: Business;
}
export function useGetBlackList(
  filters,
  options: Omit<
    UseQueryOptions<
      Pagination<useGetBlackListProps>,
      AxiosError,
      Pagination<useGetBlackListProps>,
      ['userBlackList']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<useGetBlackListProps>, AxiosError>(
    ['userBlackList', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<Pagination<useGetBlackListProps>>(
          `/api/users/user-blocks/paginate/v1`,
          {
            params: {
              ...filters,
            },
          }
        );

        return data;
      }
    },
    options
  );
  return query;
}
