/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { validateEmail } from 'avilatek-utils';
import { User } from '@dinbog/models';
import { Button, EyeIcon, Input, PasswordEyeIcon } from '@dinbog/ui';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { useChangeOldPassword } from '../../../hooks/api/auth';

export default function SecurityForm() {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const { t } = useTranslation();
  const updateMutation = useChangeOldPassword(user?.token);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConf, setShowPasswordConf] = React.useState(false);

  const methods = useForm<any>({
    defaultValues: {},
  });

  const formValidations = {
    oldPassword: {
      required: t('required'),
    },
    password: {
      required: t('required'),
      pattern:
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-.\\/:;<=>?\\@[\]^_`{|}~]).{8,}$/,
    },
    passwordConf: {
      required: t('required'),
      validate: (value, formValues) => value === formValues.password,
    },
  };

  const onSubmit = async (e) => {
    if (disabled) return;
    setDisabled(true);
    try {
      await updateMutation.mutateAsync(
        {
          user: (user?.currentUser?.user as User)?._id as string,
          previousPassword: e.oldPassword,
          newPassword: e.password,
        },
        {
          onSuccess: () => {
            notify(t('settings.notifs.success'), 'success');
          },
        }
      );
    } catch (err) {
      notify(
        err?.response?.data?.message || t('settings.notifs.error'),
        'error'
      );
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods?.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-6">
          <h3 className="font-semibold text-lg">{t('settings.security')}</h3>
          <hr className="w-full bg-neutral-200" />
          <div className="w-full md:w-1/2">
            <Input
              type="password"
              label={t('settings.oldPassword')}
              {...methods.register('oldPassword', formValidations.oldPassword)}
              error={methods.formState.errors.oldPassword?.message as string}
              placeholder={t('settings.oldPasswordPlaceholder') ?? ''}
              autoComplete="off" // Disable browser autocomplete
              onPaste={(e) => e.preventDefault()} // Disable pasting
            />
          </div>
          <div className="w-full md:w-1/2">
            <Input
              type={showPassword ? 'text' : 'password'}
              {...methods.register('password', formValidations.password)}
              label={t('auth.signUp.password') ?? ''}
              placeholder={t('auth.signUp.password') ?? ''}
              autoComplete="off" // Disable browser autocomplete
              onPaste={(e) => e.preventDefault()} // Disable pasting
              rightIcon={
                !showPassword ? (
                  <button
                    className=""
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <PasswordEyeIcon className="w-5 h-5" />
                  </button>
                ) : (
                  <button
                    className=""
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <EyeIcon className="w-5 h-5" />
                  </button>
                )
              }
            />
            {methods.formState.errors.password && (
              <span className="text-sm text-danger-300">
                {t('auth.signUp.notifs.passwordRequirements')}
              </span>
            )}
          </div>
          <div className="w-full md:w-1/2">
            <Input
              type={showPasswordConf ? 'text' : 'password'}
              {...methods.register(
                'passwordConfirmation',
                formValidations.passwordConf
              )}
              label={t('auth.signUp.confirmPassword') ?? ''}
              placeholder={t('auth.signUp.confirmPassword') ?? ''}
              autoComplete="off" // Disable browser autocomplete
              onPaste={(e) => e.preventDefault()} // Disable pasting
              rightIcon={
                !showPasswordConf ? (
                  <button
                    className=""
                    type="button"
                    onClick={() => setShowPasswordConf(!showPasswordConf)}
                  >
                    <PasswordEyeIcon className="w-5 h-5" />
                  </button>
                ) : (
                  <button
                    className=""
                    type="button"
                    onClick={() => setShowPasswordConf(!showPasswordConf)}
                  >
                    <EyeIcon className="w-5 h-5" />
                  </button>
                )
              }
            />
            {methods.formState.errors.passwordConfirmation &&
              (methods.formState.errors.passwordConfirmation.type ===
              'required' ? (
                <span className="text-sm text-danger-300">
                  {t('auth.signUp.notifs.confirmPassword')}
                </span>
              ) : (
                <span className="text-sm text-danger-300">
                  {t('auth.signUp.notifs.differentPasswordValues')}
                </span>
              ))}
          </div>
          <div className="w-full ">
            <Button
              tooltip={t('settings.changePassword')}
              type="submit"
              className="btn-primary"
            >
              {t('settings.changePassword')}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
