import React from 'react';
import { Category, Pagination } from '@dinbog/models';
import { SpinnerIcon } from '@dinbog/ui';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { axios } from '../../../api';
import InfiniteDropdown from '../infinite-dropdown/InfiniteDropDown';
import { parseCategoryToOptions } from '../../../lib/parseCategoryToOptions';
import { useCategories } from '../../../hooks/api/categories';
import { useCategorySelect } from '../../../context/CategorySelectContext';

interface CategorySelectProps {
  field: { name: string; label: string; type: string; iterable: Array<any> };
  type?: string;
}

function CategorySelect({ field, type = 'talent' }: CategorySelectProps) {
  const { t, i18n } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const selectedOption = watch(field?.name);
  const { setSelectedOption, setSelectedLabel } = useCategorySelect();
  async function fetchPaginatedCategories({ pageParam = 1 }) {
    const response = await axios.get('/api/users/categories/pagination/v1', {
      params: {
        page: pageParam,
        perPage: 10,
        filter: JSON.stringify({ type, active: true }),
      },
    });
    return response.data as any;
  }

  const { data, fetchNextPage, isLoading, isError, error, isFetching } =
    useInfiniteQuery<Pagination<Category>, unknown, Pagination<Category>>({
      queryKey: ['categoriesPaginated'],
      queryFn: fetchPaginatedCategories,
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
      enabled: type !== 'portfolios',
      cacheTime: 0,
    });

  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategories(
      {
        type: null,
      },
      'portfolios',
      {
        refetchOnWindowFocus: false,
        enabled: type === 'portfolios',
        cacheTime: 0,
      }
    );

  const hanldeChange = (value, label) => {
    setValue(field?.name, value);
    setSelectedOption(value);
    setSelectedLabel(label);
  };

  const options = React.useMemo(
    () =>
      data?.pages?.reduce(
        (acc, page) => [...acc, ...page.items],
        [] as Category[]
      ),
    [data]
  );

  const parsedOptions = parseCategoryToOptions(
    type !== 'portfolios' ? options : dataCategories,
    i18n?.language
  );

  if (isLoading || (type === 'portfolios' && isLoadingCategories)) {
    return (
      <>
        <SpinnerIcon className="m-auto w-20 h-20 text-gray-200 animate-spin  fill-primary-500" />
        <span className="sr-only">Loading...</span>
      </>
    );
  }

  if (isError) {
    return <div>Error: {(error as Error).message}</div>;
  }

  return (
    <>
      <input {...register(field?.name)} className="hidden" />
      <InfiniteDropdown
        options={parsedOptions}
        selectedOption={selectedOption}
        onChange={hanldeChange}
        fetchNextPage={fetchNextPage}
      />
    </>
  );
}

export default CategorySelect;
