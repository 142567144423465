import { AxiosError } from 'axios';
import { validateString } from 'avilatek-utils';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Track } from '@dinbog/models';
import { axios } from '../../api';

export function useGetTracks(
  filters?: Partial<Track>,
  options: Omit<
    UseQueryOptions<Array<Track>, AxiosError, Array<Track>, ['allTracks']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Array<Track>, AxiosError>(
    ['allTracks', filters],
    async () => {
      if (filters) {
        if (!validateString(filters?.album)) return [];
        const { data } = await axios.get<Array<Track>>(
          '/api/portfolios/tracks/v1',
          {
            params: { ...filters, active: true },
          }
        );

        return data;
      }
    },
    options
  );
  return query;
}

export function useCreateTrack() {
  const mutation = useMutation<Track, AxiosError, any>(async (newTrack) => {
    const { data } = await axios.post('/api/portfolios/tracks/v1', newTrack);
    return data;
  });
  return mutation;
}

export function useUpdateTrack() {
  const mutation = useMutation<Track, AxiosError, Partial<Track>>(
    async (album) => {
      const { data } = await axios.patch(`/api/portfolios/tracks/v1`, album);
      return data;
    }
  );
  return mutation;
}
