import React from 'react';
import { Input, Select, TextArea } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { genderOptions } from '@dinbog/models';
import { useLocation } from '../../../../hooks';
import { ACTIONS } from '../reducer';
import { CustomPhoneInput } from '../../../shared';
import ProfilePicture from './ProfilePicture';

interface PersonalInformationContentProps {
  profileImg;
  updateProfileURLs;
}

function PersonalInformationContent({
  profileImg,
  updateProfileURLs,
}: PersonalInformationContentProps) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext();

  const {
    countries: birthCountries,
    states: birthStates,
    cities: birthCities,
  } = useLocation(
    watch(ACTIONS.BIRTH_COUNTRY) ?? '',
    watch(ACTIONS.BIRTH_STATE) ?? ''
  );

  const {
    countries: currentCountries,
    states: currentStates,
    cities: currentCities,
  } = useLocation(
    watch(ACTIONS.CURRENT_COUNTRY) ?? '',
    watch(ACTIONS.CURRENT_STATE) ?? ''
  );

  const formValidations = {
    [ACTIONS.GENDER]: {
      required: t('required'),
    },
    [ACTIONS.BIRTH_COUNTRY]: {
      required: t('required'),
    },
    [ACTIONS.CURRENT_CITY]: {
      required: t('required'),
    },
    [ACTIONS.CURRENT_STATE]: {
      required: t('required'),
    },
    [ACTIONS.CURRENT_COUNTRY]: {
      required: t('required'),
    },
    [ACTIONS.DESCRIPTION]: {
      required: t('required'),
    },
  };

  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex justify-between gap-9">
        <ProfilePicture
          profileImage={profileImg}
          updateProfileURLs={updateProfileURLs}
        />
        <div className="flex flex-col gap-y-6 w-full">
          <div className="inputs-container">
            <div className="w-full">
              <Select
                isRequired
                name={ACTIONS.GENDER}
                className="w-full"
                defaultValue=""
                label={t('auth.signUp.wizard.fan.gender.title')}
                error={errors[ACTIONS.GENDER]?.message as string}
                {...register(ACTIONS.GENDER, formValidations[ACTIONS.GENDER])}
              >
                <option key={-1} value="">
                  {t('auth.signUp.wizard.fan.gender.options.default')}
                </option>
                {genderOptions?.map((gender, i) => (
                  <option key={i + 1} value={gender}>
                    {t(`auth.signUp.wizard.fan.gender.options.${gender}`)}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-full md:pr-4">
              <CustomPhoneInput
                name={ACTIONS.PHONE}
                label={t('auth.signUp.wizard.fan.phone')}
                error={errors[ACTIONS.PHONE]?.message as string}
                validations={formValidations[ACTIONS.PHONE]}
                control={control}
                controlled
              />
            </div>
          </div>
          <div className="inputs-container">
            <TextArea
              isRequired
              name={ACTIONS.DESCRIPTION}
              className="h-36"
              label={t('auth.signUp.wizard.fan.description.title')}
              placeholder={t('auth.signUp.wizard.fan.description.description')}
              error={errors[ACTIONS.DESCRIPTION]?.message as string}
              {...register(
                ACTIONS.DESCRIPTION,
                formValidations[ACTIONS.DESCRIPTION]
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        <p className="font-semibold">
          {t('auth.signUp.wizard.fan.birthLocation.title')}
        </p>
        <div className="inputs-container">
          <div className="w-full">
            <Select
              isRequired
              name={ACTIONS.BIRTH_COUNTRY}
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.wizard.personal.birthLocation.country')}
              error={errors[ACTIONS.BIRTH_COUNTRY]?.message as string}
              {...register(
                ACTIONS.BIRTH_COUNTRY,
                formValidations[ACTIONS.BIRTH_COUNTRY]
              )}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {birthCountries?.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              name={ACTIONS.BIRTH_STATE}
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.wizard.personal.birthLocation.state')}
              error={errors[ACTIONS.BIRTH_STATE]?.message as string}
              {...register(
                ACTIONS.BIRTH_STATE,
                formValidations[ACTIONS.BIRTH_STATE]
              )}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {birthStates?.map((state) => (
                <option
                  key={state.isoCode}
                  value={state.isoCode}
                  selected={watch(ACTIONS.BIRTH_STATE) === state.isoCode}
                >
                  {state.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              name={ACTIONS.BIRTH_CITY}
              defaultValue=""
              label={t('auth.signUp.wizard.personal.birthLocation.city')}
              error={errors[ACTIONS.BIRTH_CITY]?.message as string}
              disabled={birthCities.length <= 0}
              {...register(
                ACTIONS.BIRTH_CITY,
                formValidations[ACTIONS.BIRTH_CITY]
              )}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {birthCities?.map((city) => (
                <option
                  key={city.name}
                  value={city.name}
                  selected={watch(ACTIONS.BIRTH_CITY) === city.name}
                >
                  {city.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4">
        <p className="font-semibold">
          {t('auth.signUp.wizard.fan.currentLocation.title')}
        </p>
        <div className="inputs-container">
          <div className="w-full">
            <Select
              isRequired
              name={ACTIONS.CURRENT_COUNTRY}
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.wizard.fan.currentLocation.country')}
              error={errors[ACTIONS.CURRENT_COUNTRY]?.message as string}
              {...register(
                ACTIONS.CURRENT_COUNTRY,
                formValidations[ACTIONS.CURRENT_COUNTRY]
              )}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {currentCountries?.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              isRequired={currentStates?.length > 0}
              name={ACTIONS.CURRENT_STATE}
              className="w-full"
              defaultValue=""
              label={t('auth.signUp.wizard.fan.currentLocation.state')}
              error={errors[ACTIONS.CURRENT_STATE]?.message as string}
              {...register(
                ACTIONS.CURRENT_STATE,
                formValidations[ACTIONS.CURRENT_STATE]
              )}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {currentStates?.map((state) => (
                <option
                  key={state.isoCode}
                  value={state.isoCode}
                  selected={watch(ACTIONS.CURRENT_STATE) === state.isoCode}
                >
                  {state.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <Select
              isRequired
              name={ACTIONS.CURRENT_CITY}
              defaultValue=""
              label={t('auth.signUp.wizard.fan.currentLocation.city')}
              error={errors[ACTIONS.CURRENT_CITY]?.message as string}
              disabled={currentCities.length <= 0}
              {...register(
                ACTIONS.CURRENT_CITY,
                formValidations[ACTIONS.CURRENT_CITY]
              )}
            >
              <option key={-1} value="" disabled>
                {t('select')}
              </option>
              {currentCities?.map((city) => (
                <option
                  key={city.name}
                  value={city.name}
                  selected={watch(ACTIONS.CURRENT_CITY) === city.name}
                >
                  {city.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformationContent;
