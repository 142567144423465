import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

interface FadeAnimationProps {
  children: React.ReactNode | React.ReactNode[];
  duration?: number;
  className?: string;
}

function FadeAnimation({
  children,
  duration = 1,
  className = '',
}: FadeAnimationProps) {
  const transition = { duration, ease: 'easeInOut' };

  const fadeVariant = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition },
    exit: { opacity: 0, transition },
  };

  return (
    <AnimatePresence>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={fadeVariant}
        className={className}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export default FadeAnimation;
