/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useRouter } from 'next/router';
import { MenuIcon } from '@dinbog/ui';
import {
  MusicalNoteIcon,
  BriefcaseIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faClapperboard } from '@fortawesome/free-solid-svg-icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { NotificationsDropdown, RequestDropdown } from './optionDropdowns';
import { useScreenSize } from '../../../../../hooks/useScreenSize';
import { FadeAnimation } from '../../../../shared';
import UserDropdown from './userDropdown/UserDropdown';
import ChatDropdown from './optionDropdowns/chatDropdown/ChatDropdown';
import BriefcaseDropdown from './optionDropdowns/briefcaseDropdown/BriefcaseDropdown';
import SearchInput from '../../../../shared/SearchInput';
import { RequestsProvider } from '../../../../../context/RequestContext';

// framer motion animation to translate the options to the left
const variants = {
  open: {
    x: 0,
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  closed: {
    x: '-100%',
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

function LoggedOptions() {
  const iconClass = 'text-text-gray w-6 h-6';
  const { t } = useTranslation();
  const router = useRouter();
  const { screenSize } = useScreenSize();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState<string>('');

  React.useEffect(() => {
    if (screenSize > 768) {
      setIsOpen(false);
      setIsSearchOpen(false);
    }
  }, [screenSize]);

  React.useEffect(() => {
    if (searchValue !== '') {
      router.push(
        {
          pathname: '/search',
          query: {
            q: searchValue,
            tab: router?.query?.tab ?? 'talents',
          },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [searchValue]);

  return (
    <RequestsProvider>
      {screenSize > 768 || isSearchOpen ? (
        <div className="w-44 md:w-56 lg:w-64 flex h-full items-center mx-auto md:mx-5 lg:mx-10  ">
          <FadeAnimation duration={0.5}>
            <SearchInput
              value={searchValue}
              onChange={(value) => setSearchValue(value)}
              hasSearchIcon
              placeholder={t('search')}
            />
          </FadeAnimation>
        </div>
      ) : null}
      <div className="ml-auto flex items-center space-x-5">
        <AnimatePresence>
          {screenSize > 768 || isOpen ? (
            <motion.div
              variants={screenSize > 768 ? {} : variants}
              animate={isOpen ? 'open' : 'closed'}
              initial="closed"
              exit="closed"
              className={`space-x-5 items-center flex md:relative ${
                isOpen
                  ? 'absolute top-20 z-50 left-0 w-full bg-white h-10 justify-center     '
                  : 'relative hidden md:flex left-[-200px] md:left-0'
              }`}
            >
              <button
                type="button"
                className="flex w-full text-left border-none"
                onClick={() => router.push('/briefcase/castings')}
              >
                <FontAwesomeIcon icon={faBriefcase} className={iconClass} />
              </button>

              <RequestDropdown />

              {/* <ChatDropdown /> */}

              {/* <NotificationsDropdown /> */}
            </motion.div>
          ) : null}
        </AnimatePresence>
        <button
          type="button"
          onClick={() => setIsSearchOpen(!isSearchOpen)}
          className="
        md:hidden"
        >
          {!isSearchOpen ? (
            <FadeAnimation duration={0.5} className="flex space-x-3">
              <MagnifyingGlassIcon className="w-5 h-5 text-text-gray" />
            </FadeAnimation>
          ) : (
            <FadeAnimation duration={0.5} className="flex space-x-3">
              <XMarkIcon className="w-5 h-5 text-danger-200 mt-2" />
            </FadeAnimation>
          )}
        </button>
        {!isSearchOpen ? (
          <FadeAnimation duration={0.5} className="flex space-x-3">
            <button
              type="button"
              className={`md:hidden  ${
                isOpen ? 'text-primary-500' : 'text-text-gray'
              }`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <MenuIcon />
            </button>
            <UserDropdown />
          </FadeAnimation>
        ) : null}
      </div>
    </RequestsProvider>
  );
}

export default LoggedOptions;
