import React from 'react';
import { Address } from '@dinbog/models';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PostLocation({ location }: { location: Address }) {
  const existLocation =
    location && location?.city && location.city.trim() !== '';

  if (existLocation)
    return (
      <div className="text-neutral-400 flex items-center gap-2 text-sm flex-wrap mb-3">
        {existLocation ? (
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="w-3 h-3 text-neutral-400"
          />
        ) : null}
        <span>
          {existLocation ? `${location.city}, ` : ''}
          {location?.country}
        </span>
      </div>
    );
  return null;
}
