import React from 'react';
import { TTabItem } from '../../../models';

interface TabItemProps extends TTabItem {
  active?: boolean;
}

function TabItem({
  itemKey,
  title,
  number,
  onClick = () => {},
  active,
}: TabItemProps) {
  return (
    <button
      type="button"
      onClick={() =>
        typeof onClick !== 'undefined' ? onClick(itemKey) : () => {}
      }
      className={`w-fit flex flex-col justify-center items-center pt-1 pb-3 px-1 mx-5 md:m-0  ${
        active && 'border-b-[3px]  border-primary-500'
      }`}
    >
      <div
        className={`${
          active ? 'text-primary-500' : 'text-text-gray'
        } flex flex-row gap-1 whitespace-nowrap`}
      >
        <span className="">{number}</span>
        <span>{title}</span>
      </div>
      {/* !active ? <hr className="w-full md:hidden px-5" /> : null */}
    </button>
  );
}

export default TabItem;
