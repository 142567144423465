import React from 'react';
import { Category } from '@dinbog/models';
import { useTranslation } from 'react-i18next';

export default function VacancyCategory({
  category,
  selected = '',
  setSelected,
}: {
  category: Category;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { i18n } = useTranslation();
  return (
    <div className="">
      <button
        onClick={() =>
          selected === category?._id
            ? setSelected('')
            : setSelected(category?._id)
        }
        type="button"
        className={`rounded py-1 px-3 ${
          selected === category?._id
            ? 'bg-primary-500 text-text-white'
            : 'bg-background-300 text-primary-500'
        }`}
      >
        {category?.name?.[i18n?.language ?? 'en']}
      </button>
    </div>
  );
}
