import React from 'react';
import { useTranslation } from 'react-i18next';

interface AccountCardProps {
  account;
  current: boolean;
  onClick: (any) => void;
}

export default function AccountCard({
  account,
  current,
  onClick,
}: AccountCardProps) {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={() => {
        onClick(account);
      }}
      className={`self-stretch px-4 py-2 rounded-[5px] justify-between items-center gap-4 inline-flex ${
        current ? 'bg-background-300' : ''
      }`}
    >
      <div className="w-[148px] self-stretch justify-start items-center gap-4 flex">
        <img
          className={`w-9 h-9 object-cover ${
            account?.profile?.type === 'business' ? 'rounded' : 'rounded-full'
          }`}
          src={
            account?.profile?.photo?.url ??
            (account?.profile?.type === 'business'
              ? '/images/fallback/business.jpg'
              : '/images/fallback/user.png')
          }
          alt=""
        />
        <div className="self-stretch flex-col justify-between items-start inline-flex">
          <div className="flex-col justify-start items-start flex">
            <div className="text-left text-text-black text-sm font-semibold leading-tight">
              {`${account?.profile?.firstName ?? ''} ${
                account?.profile?.lastName ?? ''
              }`}
            </div>
            <div className="text-left text-text-black text-xs font-normal leading-[18px]">
              {account?.email}
            </div>
          </div>
        </div>
      </div>
      {current ? (
        <div className="text-center text-sky-600 text-[10px] font-normal leading-[18px]">
          {t('navbar.logged.switchAccount.current')}
        </div>
      ) : null}
    </button>
  );
}
