import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { Album, Category, Track, User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { DocumentModel } from '../../../models';
import { useCreateAlbum, useUpdateAlbum } from '../../../hooks/api/albums';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../utils/formatDocumentModel';
import { useCreateTrack, useUpdateTrack } from '../../../hooks/api/tracks';
import { AudioFormContent } from '../../shared';
import FormButtons from '../shared/FormButtons';
import { uploadFile } from '../../../lib/uploadFile';
import { useSignS3 } from '../../../hooks/api/s3';

interface UploadAudioProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  data?: Partial<Album>;
  formsLength: number;
  categories?: Category[];
}

export default function UploadAudio({
  currentStep = 5,
  setCurrentStep,
  data = null,
  formsLength,
  categories,
}: UploadAudioProps) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { t, i18n } = useTranslation();
  const [disabled, setDisabled] = React.useState(false);
  const [media, setMedia] = React.useState<DocumentModel[]>(
    data?.cover ? multimediaToDocument([data?.cover]) : []
  );
  const updateURLs = React.useCallback(setMedia, [setMedia]);
  const methods = useForm<Album>({
    defaultValues: {
      title: data?.title ?? '',
      description: data?.description ?? '',
      privacy: data?.privacy ?? null,
      tracks: data?.tracks ?? [],
      categories: data?.categories ?? [],
    },
  });

  const createAlbumMutation = useCreateAlbum();
  const createTrackMutation = useCreateTrack();
  const updateAlbumMutation = useUpdateAlbum();
  const updateTrackMutation = useUpdateTrack();
  const updateProfileMutation = useUpdateProfile();
  const signS3 = useSignS3();

  const onSubmit: SubmitHandler<Partial<Album>> = async (
    _data: Partial<Album>
  ) => {
    try {
      const creating = !data?._id;
      if (disabled) return;
      setDisabled(true);
      let coverImg = { url: '', src: '' };
      if (media?.length > 0 && media[0]?.src !== data?.cover?.url) {
        coverImg = await uploadFile(
          media[0],
          setDisabled,
          updateURLs,
          signS3,
          notify,
          t
        );
      } else {
        coverImg.url = data?.cover?.url;
      }

      const image = media?.length > 0 ? documentToMedia(media) : null;

      const albumDataPromise = (
        !creating ? updateAlbumMutation : createAlbumMutation
      ).mutateAsync({
        _id: data?._id ?? undefined,
        title: _data.title,
        user: (user?.currentUser?.user as User)?._id,
        description: _data.description,
        tracks: !creating
          ? undefined
          : _data.tracks.map((track: Track) => ({
              title: track.title,
              audio: {
                fileName: track.title.replaceAll(' ', '-'),
                type: 'audio',
                url: track.audio.url,
              },
            })) ?? [],
        privacy: _data.privacy,
        categories: _data?.categories,
        ...(media?.length > 0
          ? {
              cover: {
                ...image[0],
                type: 'image',
                url: coverImg.src || coverImg.url,
              },
            }
          : { cover: undefined }),
      });

      const tracksPromises = [];
      if (!creating) {
        _data?.tracks?.forEach((track: Track) => {
          // if track is new, its id will a number
          if (Number.isNaN(+track._id)) {
            tracksPromises.push(
              updateTrackMutation.mutateAsync({
                _id: track._id,
                album: data?._id,
                title: track.title,
                audio: {
                  fileName: track.title.replaceAll(' ', '-'),
                  type: 'audio',
                  url: track.audio.url,
                },
              })
            );
          } else {
            tracksPromises.push(
              createTrackMutation.mutateAsync({
                title: track.title,
                album: data?._id,
                audio: {
                  fileName: track.title.replaceAll(' ', '-'),
                  type: 'audio',
                  url: track.audio.url,
                },
              })
            );
          }
        });
      }
      // update registration step
      const userDataPromise = updateProfileMutation.mutateAsync({
        user: user?._id,
        registrationStep: '6',
      });
      await Promise.all([
        albumDataPromise,
        userDataPromise,
        ...tracksPromises,
      ]).then(() => {
        queryClient.refetchQueries(['album', { user: user?._id }]);
        queryClient.refetchQueries(['allTracks', { album: data?._id }]);
        setCurrentStep(currentStep + 1);
      });
    } catch (err) {
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleDeleteTrack = async (trackId: string) => {
    if (disabled) return;
    setDisabled(true);
    try {
      if (!Number.isNaN(+trackId)) return; // if track is new, its id will a number and we don't need to delete it
      await updateTrackMutation.mutateAsync({
        _id: trackId,
        active: false,
      });
      queryClient.refetchQueries(['allTracks', { album: data?._id }]);
    } catch (err) {
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onSkip = async () => {
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);

      await updateProfileMutation.mutateAsync({
        user: user?._id as string,
        // update registration step
        registrationStep: '6',
      });
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <div className="space-y-3 mb-2">
            <h3 className="font-semibold text-lg">
              {t('auth.signUp.wizard.audio.title')}
            </h3>
            <p>{t('auth.signUp.wizard.audio.subtitle')}</p>
          </div>
          <AudioFormContent
            media={media}
            disabled={disabled}
            updateURLs={updateURLs}
            categoriesDefault={categories
              ?.map((cat) => ({
                name: cat?.name[i18n?.language ?? 'en'],
                _id: cat?._id,
              }))
              .filter((cat) =>
                (data?.categories as Array<{ _id: string }>)?.some(
                  (category) => category._id === cat?._id
                )
              )}
            categories={categories}
            handleDeleteTrack={handleDeleteTrack}
          />
        </div>
        <FormButtons
          disabled={disabled}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={formsLength}
          onSkip={onSkip}
        />
      </form>
    </FormProvider>
  );
}
