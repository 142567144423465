import React from 'react';
import { useTranslation } from 'react-i18next';
import { CategorySelection } from '../../../shared';
import { ACTIONS, ACTIONS_TYPE } from '../reducer';
import { useNotify } from '../../../../hooks';
import { useUpdateBusiness } from '../../../../hooks/api/businesses';
import { useUpdateProfile } from '../../../../hooks/api/profiles';

interface SelectCategoryProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  state?: Partial<any>; // FIXME: add type
  dispatch?: React.Dispatch<ACTIONS_TYPE>;
  formsLength?: number;
}

function SelectCategory({
  currentStep,
  setCurrentStep,
  state,
  dispatch,
  formsLength,
}: SelectCategoryProps) {
  const notify = useNotify();
  const updateMutation = useUpdateProfile();
  const [categoriesSelected, setCategoriesSelected] = React.useState(
    state?.categories || []
  );

  const onSubmit = () => {
    if (categoriesSelected.length === 0) {
      return notify('Please select at least one category', 'error');
    }
    // set categories in reducer state
    dispatch({
      type: ACTIONS.DEFAULT,
      payload: { ...state, categories: categoriesSelected },
    });
    // set categories in local storage
    // localStorage.setItem(
    //   'business',
    //   JSON.stringify({
    //     ...state,
    //     categories: categoriesSelected,
    //   })
    // );
    // Go to next step
    setCurrentStep(currentStep + 1);
  };
  const { t } = useTranslation();

  return (
    <div>
      <div className="gap-y-3 flex flex-col">
        <h3 className="font-semibold text-lg">
          {t('auth.signUp.wizard.business.category.title')}
        </h3>
        <p>{t('auth.signUp.wizard.business.category.description')}</p>
      </div>
      <CategorySelection
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        state={state}
        dispatch={dispatch}
        type="business"
        formsLength={formsLength}
        updateMutation={updateMutation}
      />
    </div>
  );
}

export default SelectCategory;
