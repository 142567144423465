import React from 'react';
import {
  faBan,
  faLock,
  faSliders,
  faTags,
  faUser,
  faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { useRouter } from 'next/router';

function SettingsSidebar({ type }: { type: 'talent' | 'business' | 'fan' }) {
  const router = useRouter();
  const { t } = useTranslation();

  const settingsItems = [
    {
      name: t('settings.profile'),
      icon: faUser,
      tab: 'profile',
    },
    {
      name: t('settings.account'),
      icon: faUserGear,
      tab: 'account',
    },
    {
      name: t('settings.categories'),
      icon: faTags,
      tab: 'categories',
    },
    ...(type === 'talent' || type === 'business'
      ? [
          {
            name: t(
              `settings.${
                type === 'business' ? 'administrators' : 'specifications'
              }`
            ),
            icon: faSliders,
            tab: type === 'business' ? 'administrators' : 'specifications',
          },
        ]
      : []),
    {
      name: t('settings.security'),
      icon: faLock,
      tab: 'security',
    },
    // { TODO: Uncomment when saved is ready
    //   name: t('settings.saved'),
    //   icon: faBookmark,
    //   tab: 'saved',
    // },
    {
      name: t('settings.blockedProfiles'),
      icon: faBan,
      tab: 'blocked-profiles',
    },
  ];

  React.useEffect(() => {
    if (router.query.tab === undefined) {
      router.push(
        {
          pathname: router.pathname,
          query: {
            tab: 'profile',
          },
        },
        undefined,
        { shallow: true }
      );
    }
  }, []);

  return (
    <div className="fixed flex flex-col gap-4 md:min-w-[220px]">
      {settingsItems.map((item, index) => (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            router.push(
              {
                pathname: `${router.pathname}`,
                query: {
                  tab: item.tab,
                },
              },
              undefined,
              { shallow: true }
            );
          }}
          className={`px-4 py-2 flex sm:gap-3 justify-center sm:justify-start text-xl font-normal items-center rounded-md ${
            router.query.tab === item.tab
              ? 'bg-background-300 text-primary-500'
              : ' text-text-black'
          }`}
          key={index}
        >
          <FontAwesomeIcon icon={item.icon} />
          <h3 className="hidden sm:block whitespace-nowrap">{item.name}</h3>
        </button>
      ))}
    </div>
  );
}

export default SettingsSidebar;
