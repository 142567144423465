import React from 'react';
import { Input, MultipleSelect, Select, ToggleInput } from '@dinbog/ui';
import { Specification, SpecificationTemplate } from '@dinbog/models';
import { validateString } from 'avilatek-utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SpecificationsFormContentProps {
  specifications: Specification[];
  disabled?: boolean;
  containerClassName?: string;
}
/**
 * SPECIFICATIONS FORM CONTENT TO CREATE OR UPDATE A TALENT SPECIFICATIONS
 */
function SpecificationsFormContent({
  specifications,
  disabled = false,
  containerClassName = 'grid md:grid-cols-2 gap-7',
}: SpecificationsFormContentProps) {
  const { i18n, t } = useTranslation();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const formValidations = {};
  specifications.forEach(
    ({
      specificationTemplate,
    }: {
      specificationTemplate: SpecificationTemplate;
    }) => {
      formValidations[specificationTemplate?._id] = { required: t('required') };
    }
  );

  return (
    <div className={containerClassName}>
      {specifications.map(
        ({
          specificationTemplate,
          multipleValues,
        }: {
          specificationTemplate: SpecificationTemplate;
          multipleValues: string[];
        }) => {
          const _id = specificationTemplate?._id;
          if (!_id) return null;
          switch (specificationTemplate?.type) {
            case 'text':
              return (
                <Input
                  disabled={disabled}
                  key={_id}
                  label={`${specificationTemplate.name[i18n.language]} ${
                    validateString(specificationTemplate.measure)
                      ? `(${specificationTemplate.measure})`
                      : ''
                  }`}
                  type={specificationTemplate.type}
                  name={_id}
                  error={errors[_id] ? (errors[_id]?.message as string) : ''}
                  {...register(_id, formValidations[_id])}
                />
              );
            case 'boolean':
              return (
                <div
                  className="w-full pl-0.5 flex items-center justify-start"
                  key={_id}
                >
                  <ToggleInput
                    disabled={disabled}
                    label={`${specificationTemplate.name[i18n.language]} ${
                      validateString(specificationTemplate.measure)
                        ? `(${specificationTemplate.measure})`
                        : ''
                    }`}
                    name={_id}
                    error={errors[_id] ? (errors[_id]?.message as string) : ''}
                    {...register(_id)}
                  />
                </div>
              );
            case 'number':
              return (
                <Input
                  disabled={disabled}
                  key={_id}
                  label={`${specificationTemplate.name[i18n.language]} ${
                    validateString(specificationTemplate.measure)
                      ? `(${specificationTemplate.measure})`
                      : ''
                  }`}
                  type={specificationTemplate.type}
                  step="0.01"
                  min="0"
                  name={_id}
                  error={errors[_id] ? (errors[_id]?.message as string) : ''}
                  {...register(_id, formValidations[_id])}
                />
              );

            case 'select':
              return (
                <Select
                  disabled={disabled}
                  key={_id}
                  label={`${specificationTemplate.name[i18n.language]} ${
                    validateString(specificationTemplate.measure)
                      ? `(${specificationTemplate.measure})`
                      : ''
                  }`}
                  name={_id}
                  error={errors[_id] ? (errors[_id]?.message as string) : ''}
                  {...register(_id, formValidations[_id])}
                >
                  <option key="default" value={null} disabled>
                    {specificationTemplate.name[i18n.language]}
                  </option>
                  {specificationTemplate.options.map((option) => (
                    <option key={(option as any)._id} value={option.value}>
                      {option.name[i18n.language]}
                    </option>
                  ))}
                </Select>
              );

            case 'multiselect':
              return (
                <div className="w-full" key={_id}>
                  <MultipleSelect
                    name={_id}
                    defaultSelect={
                      multipleValues.map((value) => ({
                        _id: (
                          specificationTemplate?.options.find(
                            (op) => op?.value === value
                          ) as any
                        )?._id,
                        value,
                        name: specificationTemplate?.options.find(
                          (op) => op?.value === value
                        )?.name[i18n.language],
                      })) ?? []
                    }
                    label={`${specificationTemplate.name[i18n.language]} ${
                      validateString(specificationTemplate.measure)
                        ? `(${specificationTemplate.measure})`
                        : ''
                    }`}
                    options={
                      specificationTemplate.options?.map((option) => ({
                        _id: (option as any)._id,
                        value: option.value,
                        name: option.name[i18n.language],
                      })) ?? []
                    }
                    {...register(_id, formValidations[_id])}
                    onChangeSelect={(value) => {
                      setValue(
                        _id,
                        [...value]?.map((val) => val?.value)
                      );
                    }}
                  />
                  <span className="text-sm text-danger-300">
                    {errors[_id] && errors[_id].type === 'required'
                      ? t('required')
                      : ''}
                  </span>
                </div>
              );

            default:
              return null;
          }
        }
      )}
    </div>
  );
}

export default SpecificationsFormContent;
