import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import { Address, Event as EventModel } from '@dinbog/models';
import Card from '../../../../../shared/card';

export default function SummaryCard({ item }: { item: EventModel }) {
  const { t } = useTranslation();
  return (
    <Card className="px-12 py-6 flex flex-col gap-2 min-w-fit">
      <div className="">
        <span className="font-semibold">{t('events.startDate')}:</span>{' '}
        <span className="">{dayjs(item?.startDate).utc().format('lll')}</span>
      </div>
      <div className="">
        <span className="font-semibold">{t('events.endDate')}:</span>{' '}
        <span className="">{dayjs(item?.endDate).utc().format('lll')}</span>
      </div>
      {/* <div className="">
        <span className="font-semibold">Casting for:</span>{' '}
        <span className="">categoria</span>
      </div> */}
      <div className="">
        <span className="font-semibold">{t('events.location')}:</span>{' '}
        <span className="">
          {(item?.address as Address)?.city},{' '}
          {Country.getCountryByCode((item?.address as Address)?.country)?.name}
        </span>
      </div>
    </Card>
  );
}
