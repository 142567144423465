import { AxiosError } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Like, ItemCollectionEnum } from '@dinbog/models';
import { axios } from '../../api';

type UseGetLikesParams = {
  item: string;
  itemCollection: ItemCollectionEnum;
  page: number;
  perPage: number;
  sort: string;
  sortOrder: number;
  token: string;
  filter: Record<string, unknown>;
};

export function useLike(ms: 'portfolios' | 'posts') {
  const mutation = useMutation<
    Like,
    AxiosError,
    {
      user: string;
      item: string;
      itemCollection: ItemCollectionEnum;
    }
  >(async (params) => {
    const { data } = await axios.post(`/api/${ms}/likes/v1`, params);
    return data;
  });
  return mutation;
}

export function useDislike(ms: 'portfolios' | 'posts') {
  const mutation = useMutation<
    Like,
    AxiosError,
    {
      user: string;
      item: string;
      itemCollection: ItemCollectionEnum;
    }
  >(async (params) => {
    const { data } = await axios.patch(`/api/${ms}/likes/v1`, params);
    return data;
  });
  return mutation;
}

export function useGetLikes(
  params: UseGetLikesParams,
  options?: UseQueryOptions
) {
  return useQuery(
    ['likes', params],
    async () => {
      const { data } = await axios.get(
        `api/posts/likes/pagination/v1`,
        {
          params: {
            ...params,
            itemCollection: 'post',
            filter: JSON.stringify(params.filter),
          },
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      );
      return data;
    },
    options
  );
}

export function useGetPortfolioLikes(
  params: UseGetLikesParams,
  options?: UseQueryOptions
) {
  return useQuery(
    ['likes', params],
    async () => {
      const { data } = await axios.get(
        `api/portfolios/likes/pagination/v1`,
        {
          params: {
            ...params,
            filter: JSON.stringify(params.filter),
          },
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      );
      return data;
    },
    options
  );
}
