import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TFunction } from 'i18next';

dayjs.extend(relativeTime);

export const getTimeAgo = (date: Date, i18n: TFunction) => {
  const createdAt = dayjs(date);
  const now = dayjs();
  const differenceInHours = now.diff(createdAt, 'hour');
  let timeAgo;
  if (differenceInHours <= 23) {
    timeAgo = i18n('timeAgo.hours', {
      hours: differenceInHours,
    });
  } else {
    timeAgo = createdAt.fromNow();
  }
  return timeAgo;
};
