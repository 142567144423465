import React from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../../../hooks';
import { useGetTalentPosts } from '../../../hooks/api/posts';
import { Tab } from '../../shared';
import { PostTab } from '../shared/tabs';
import ProfileInfoCard from '../shared/profileInfoCard/ProfileInfoCard';

interface FanProfileProps {
  user: any;
  findUserBySlug?: () => void;
}

export default function FanProfile({
  user,
  findUserBySlug = null,
}: FanProfileProps) {
  const { currentUser } = useUser();
  const router = useRouter();
  const [tabSelected, setTabSelected] = React.useState('posts');
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 10,
  });

  const [categoryFilter, setCategoryFilter] = React.useState(''); // id of the category to filter media

  const {
    data: dataPosts,
    isLoading: isLoadingPosts,
    error: errorPosts,
    refetch: refetchPosts,
  } = useGetTalentPosts({
    ...pagination,
    user: user?._id,
    active: true,
    sort: 'createdAt',
    viewer: currentUser?._id,
  });

  const items = React.useMemo(
    () => ({
      posts: {
        title: 'Posts',
        number: dataPosts?.count ?? 0,
        itemKey: 'posts',
        component: (
          <PostTab
            postsData={dataPosts}
            loading={isLoadingPosts}
            setPagination={setPagination}
            onSuccessLike={() => {
              refetchPosts();
            }}
          />
        ),
      },
    }),
    [dataPosts, tabSelected]
  );

  React.useEffect(() => {
    setCategoryFilter(''); // reset filter if tab changes
    // reset pagination
    if (tabSelected !== router?.query?.tab) {
      setPagination((prev: { page: number; perPage: number }) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [tabSelected]);

  return (
    <div className="space-y-12">
      <ProfileInfoCard user={user} findUserBySlug={findUserBySlug} />
      {/* <div className="md:px-12 px-6">
        <Tab
          items={Object.values(items)}
          currentItem={tabSelected}
          setCurrentItem={setTabSelected}
          isQueryParam
        />
        <div className="sm:px-16 sm:py-12 py-6 px-8 w-full">
          {router.isReady ? items[tabSelected]?.component : null}
        </div>{' '}
      </div> */}
    </div>
  );
}
