/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useRouter } from 'next/router';
import { Comment, ItemCollectionEnum, User } from '@dinbog/models';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import {
  faEllipsis,
  faTrashAlt,
  faThumbsUp as likedIcon,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNotify, useTruncatedText, useUser } from '../../../../hooks';
import { useLike, useDislike } from '../../../../hooks/api/likes';
import { useUpdateComment } from '../../../../hooks/api/comments';
import OtherActionsCommentModal from './OtherActionsCommentModal';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

function CommentDetail({
  comment,
  item,
  ms,
  liked,
  onSuccess = () => {},
  refetchComments,
}: {
  comment;
  liked: boolean;
  item: { _id: string; owner: any; type: ItemCollectionEnum };
  ms: 'portfolios' | 'posts';
  onSuccess?: () => void;
  refetchComments?: () => void;
}) {
  const { currentUser: user } = useUser();
  const router = useRouter();
  const notify = useNotify();
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState(false);
  const [commentLiked, setCommentLiked] = React.useState<boolean>(liked);
  const [openOtherActions, setOpenOtherActions] =
    React.useState<boolean>(false);
  const currentUserID = (user?.currentUser?.user as User)?._id;
  const isOwner =
    (comment?.profile?.user as User)?._id === currentUserID ||
    currentUserID === item?.owner;

  const { truncatedContent, toggle, showFullText, maxChars } = useTruncatedText(
    comment?.text ?? ''
  );

  // queries/mutations
  const like = useLike(ms); // micro service
  const dislike = useDislike(ms); // micro service
  const updateMutation = useUpdateComment(ms); // micro service

  const onHitLikeButton = async () => {
    setDisabled(true);
    try {
      await (commentLiked ? dislike : like).mutateAsync(
        {
          item: comment?._id,
          itemCollection: 'comment',
          user: (user?.currentUser?.user as User)?._id,
        },
        {
          onSuccess: () => {
            onSuccess();
            refetchComments();
            setCommentLiked(!commentLiked);
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onDelete = async () => {
    setDisabled(true);
    try {
      await updateMutation.mutateAsync(
        {
          _id: comment?._id,
          active: false,
          user: (user?.currentUser?.user as User)?._id,
        },
        {
          onSuccess: () => {
            refetchComments();
            onSuccess();
            setOpenOtherActions(false);
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const getMicroservice = () => {
    if (ms === 'portfolios') {
      return 'portfolio';
    }
    return 'post';
  };

  return (
    <div className="pr-4 w-full flex justify-between gap-2 items-center ">
      <div className="flex gap-4 items-center w-full ">
        <button
          type="button"
          onClick={() =>
            router?.push({
              pathname: `/[slug]`,
              query: { slug: (comment?.profile?.user as User)?.slug },
            })
          }
        >
          <img
            className="rounded-full h-[36px] w-[36px] object-cover border"
            src={
              comment?.profile?.photo?.url ??
              (comment?.profile?.type === 'business'
                ? '/images/fallback/business.jpg'
                : '/images/fallback/user.png')
            }
            alt="profilePic"
          />
        </button>
        <div className="w-full">
          <div className="flex gap-2 items-center">
            <button
              type="button"
              onClick={() =>
                router?.push({
                  pathname: `/[slug]`,
                  query: { slug: (comment?.profile?.user as User)?.slug },
                })
              }
              className=" font-semibold"
            >
              {comment?.profile?.firstName} {comment?.profile?.lastName}
            </button>
            <p className=" font-light text-xs text-neutral-500 ">
              {dayjs(comment?.createdAt).format('DD/MM/YYYY')}
            </p>
          </div>
          <div className="w-full text-neutral-600 text-justify break-all ">
            {truncatedContent}
            {comment?.text?.length > maxChars && (
              <>
                {!showFullText ? '... ' : ' '}
                <button
                  type="button"
                  className="text-blue-500 cursor-pointer text-sm"
                  onClick={toggle}
                >
                  {showFullText ? t('readLess') : t('readMore')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        {/* like comment counter and button */}
        <div className="flex items-center">
          {comment?.stats?.likes > 0 && (
            <p className="text-neutral-500 text-xs font-medium mr-2 mt-1.5">
              {comment?.stats?.likes ?? 0}
            </p>
          )}
          <button
            type="button"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              onHitLikeButton();
            }}
            className={`flex flex-start ${
              commentLiked ? 'text-primary-500' : ' text-neutral-500'
            }`}
          >
            <FontAwesomeIcon
              icon={commentLiked ? likedIcon : faThumbsUp}
              className="h-5 w-5"
            />
          </button>
        </div>
        {/* delete comment button */}
        {(comment?.profile?.user as User)?._id ===
          (user?.currentUser?.user as User)?._id ||
        (user?.currentUser?.user as User)?._id === item?.owner ? (
          <button
            type="button"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              setOpenOtherActions(true);
            }}
            className="text-neutral-500"
          >
            <FontAwesomeIcon
              icon={faEllipsis}
              className="h-5 w-5 transform rotate-90"
            />
          </button>
        ) : (
          <button
            type="button"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              setOpenOtherActions(true);
            }}
            className="text-neutral-500"
          >
            <FontAwesomeIcon
              icon={faEllipsis}
              className="h-5 w-5 transform rotate-90"
            />
          </button>
        )}
      </div>
      <OtherActionsCommentModal
        isOpen={openOtherActions}
        setOpen={setOpenOtherActions}
        onDelete={onDelete}
        isOwner={isOwner}
        commentId={comment?._id}
        service={getMicroservice()}
        itemCollection={item?.type}
        itemOwner={comment?.profile?.user?._id}
      />
    </div>
  );
}

export default CommentDetail;
