import { Event, FiltersInput, Pagination, Profile } from '@dinbog/models';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axios } from '../../api';

export function useEvents(
  filters?: FiltersInput & Partial<Event>,
  options: Omit<
    UseQueryOptions<
      Pagination<Event>,
      AxiosError,
      Pagination<Event>,
      ['events']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<Event>, AxiosError>(
    ['events', filters],
    async () => {
      const { data } = await axios.get<Pagination<Event>>('/api/events/v1', {
        params: { ...filters },
      });
      return data;
    },
    options
  );
  return query;
}

export function useInfiniteEvents(
  filters: Partial<Event> & FiltersInput,
  options: Omit<
    UseInfiniteQueryOptions<
      Pagination<Event>,
      AxiosError,
      Pagination<Event>,
      Pagination<Event>,
      ['events']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  async function fetchEvents({ pageParam = 1 }) {
    const { data } = await axios.get<Pagination<Event>>(
      '/api/events/v1',
      {
        params: { page: pageParam, ...filters },
      }
    );
    return data;
  }

  const {
    data,
    fetchNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery<Pagination<Event>, AxiosError>(
    ['events', filters],
    fetchEvents,
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
      ...options,
    }
  );

  return {
    data,
    fetchNextPage,
    isLoading,
    isError,
  };
}

export function useEvent(
  filters?: FiltersInput & Partial<Event>,
  options: Omit<
    UseQueryOptions<
      Event & { user: Profile },
      AxiosError,
      Event & { user: Profile },
      ['event']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Event & { user: Profile }, AxiosError>(
    ['event', filters],
    async () => {
      const { data } = await axios.get<Event & { user: Profile }>(
        '/api/events/event/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useUpdateEvent(
  options: Omit<
    UseMutationOptions<Event, AxiosError, Partial<Event>, unknown>,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<Event, AxiosError, Partial<Event>>(
    async (updatedData) => {
      const { data } = await axios.patch('/api/events/v1', updatedData);
      return data;
    },
    options
  );
  return mutation;
}

export function useCreateEvent() {
  const mutation = useMutation<Event, AxiosError, Partial<Event>>(
    async (newEvent) => {
      const { data } = await axios.post('/api/events/v1', newEvent);
      return data;
    }
  );
  return mutation;
}
