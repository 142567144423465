import React from 'react';
import {
  Input,
  MultipleSelect,
  Select,
  TextArea,
  ThumbTackIcon,
} from '@dinbog/ui';
import {
  Category,
  DocumentModel,
  Book,
  bookPrivacyValues,
  IOption,
} from '@dinbog/models';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DocumentForm } from '../../document';

interface BookFormContentProps {
  disabled?: boolean;
  categories: Category[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  images: DocumentModel[];
  setImages: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  categoriesDefault?: IOption[];
}

/**
 * UPLOAD BOOK FORM CONTENT TO CREATE OR UPDATE A BOOK
 */
function BookFormContent({
  disabled = false,
  categories,
  updateURLs,
  images,
  setImages,
  categoriesDefault = [],
}: BookFormContentProps) {
  const { i18n, t } = useTranslation();

  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<Book>();

  const formValidations = {
    categories: { required: t('required') },
    title: { required: t('required') },
    description: { required: t('required') },
    privacy: { required: t('required') },
  };
  const setBookCover = (idx: number) => {
    const cover = images.find((image, i) => i === idx);
    setImages([cover, ...images.filter((image, i) => i !== idx)]);
  };

  // remove selected photo from array
  const removePhoto = (idx: number) => {
    const newImages = images.filter((image, i) => i !== idx);
    setImages(newImages);
  };
  return (
    <div className="flex flex-col gap-8">
      <div className="space-y-4">
        <div className="flex flex-wrap lg:gap-x-8 gap-y-4 lg:items-start items-center">
          <DocumentForm
            icon
            customIcon={
              <FontAwesomeIcon icon={faCameraAlt} className="w-5 h-5" />
            }
            dropZoneTextClassName="text-neutral-400"
            dropZoneText={t(`uploadImage`) ?? ''}
            dropZoneClassName="sm:w-[200px] w-48 sm:h-[200px] h-48 rounded-lg bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2"
            updateURLs={updateURLs}
            documents={images}
            maximumAmount={10}
          />
          {images?.map((image, idx) => (
            <div className="sm:w-[200px] w-48 sm:h-[200px] h-48 relative bg-transparent flex flex-row justify-center items-center">
              {/* x button to delete photo */}
              <button
                aria-label="Delete"
                type="button"
                onClick={() => removePhoto(idx)}
                className="absolute z-10 -top-1 -right-1 bg-danger-300 text-text-white h-4 w-4 rounded-full flex items-center justify-center"
              >
                <FontAwesomeIcon icon={faTimes} className="w-2 h-2" />
              </button>
              {idx === 0 ? (
                <div className="absolute w-full left-0 bg-black/50 text-text-white px-3 flex items-center rounded-b-lg h-1/6 py-1 bottom-0">
                  <p className="text-xs">Cover</p>
                </div>
              ) : (
                <div className="absolute w-full left-0 top-0 bg-black/50 text-text-white p-3 flex gap-2 rounded-lg h-full hover:opacity-100 opacity-0">
                  <button
                    type="button"
                    onClick={() => setBookCover(idx)}
                    className="flex gap-2 items-center h-fit"
                  >
                    <ThumbTackIcon
                      stroke={idx !== 0 ? '#FEFEFE' : ''}
                      className={`h-4 w-4 ${
                        idx === 0 ? 'text-text-white' : 'text-transparent'
                      }`}
                    />
                    <p className="text-xs">
                      {idx === 0 ? 'Remove cover' : 'Set as cover'}
                    </p>
                  </button>
                </div>
              )}
              <img
                alt={image?.name}
                src={image?.src as string}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-full">
        <MultipleSelect
          options={categories?.map((cat) => ({
            name: cat?.name[i18n?.language ?? 'en'],
            _id: cat?._id,
          }))}
          label={t('auth.signUp.wizard.book.category.title') ?? ''}
          onChangeSelect={(selected) => {
            setValue(
              'categories',
              selected.map((cat) => cat._id)
            );
          }}
          defaultSelect={categoriesDefault}
          {...register('categories', formValidations.categories)}
        />
        <span className="text-sm text-danger-300">
          {errors.categories && errors.categories.type === 'required'
            ? t('required')
            : ''}
        </span>
      </div>
      <div className="w-full">
        <Input
          disabled={disabled}
          isRequired
          error={errors?.title?.message as string}
          className="w-full"
          label={t('auth.signUp.wizard.book.name.title') ?? ''}
          placeholder={t('auth.signUp.wizard.book.name.description') ?? ''}
          {...register('title', formValidations?.title)}
        />
      </div>
      <div className="w-full">
        <TextArea
          disabled={disabled}
          isRequired
          error={errors.description?.message as string}
          className="h-24"
          label={t('auth.signUp.wizard.book.bookDescription.title') ?? ''}
          placeholder={
            t('auth.signUp.wizard.book.bookDescription.description') ?? ''
          }
          {...register('description', formValidations.description)}
        />
      </div>
      <div className="w-full">
        <Select
          disabled={disabled}
          isRequired
          error={errors.privacy?.message as string}
          className="w-full"
          label={t('portfolio.privacy.title') ?? ''}
          {...register('privacy', formValidations.privacy)}
        >
          <option key={-1} value="" disabled>
            {t('portfolio.privacy.values.default')}
          </option>
          {bookPrivacyValues?.map((privacy, i) => (
            <option key={`${i + 1}-${privacy}`} value={privacy ?? ''}>
              {t(`portfolio.privacy.values.${privacy}`)}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default BookFormContent;
