import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@dinbog/ui';
import { User } from '@dinbog/models';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import {
  LanguagePreferencesFormContent,
  PersonalInformationFormContent,
} from './personalInformationFormContent';

export default function PersonalInfoForm({
  type,
}: {
  type: 'talent' | 'business' | 'fan';
}) {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const notify = useNotify();
  const { t, i18n } = useTranslation();
  const updateMutation = useUpdateProfile();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const methods = useForm<any>({
    defaultValues: {
      firstName: user?.currentUser?.firstName ?? '',
      lastName: user?.currentUser?.lastName ?? '',
      private: user?.currentUser?.private ? 'true' : 'false',
      birthDate: user?.currentUser?.birthDate ?? '',
      gender: user?.currentUser?.gender ?? '',
      description: user?.currentUser?.description ?? '',
      birthCountry: user?.currentUser?.birthPlace?.country ?? '',
      birthState: user?.currentUser?.birthPlace?.state ?? '',
      birthCity: user?.currentUser?.birthPlace?.city ?? '',
      currentCountry:
        user?.currentUser?.business?.headquarter ??
        user?.currentUser?.address?.country ??
        '',
      currentState:
        user?.currentUser?.business?.state ??
        user?.currentUser?.address?.state ??
        '',
      currentCity:
        user?.currentUser?.business?.city ??
        user?.currentUser?.address?.city ??
        '',
      phone:
        `${user?.currentUser?.phone?.prefix}${user?.currentUser?.phone?.number}` ??
        '',
      photo: user?.currentUser?.photo ?? null,
      cover: user?.currentUser?.cover ?? null,
      languages: user?.currentUser?.languages ?? [],
      websiteUrl: user?.currentUser?.business?.websiteUrl ?? '',
      branches: user?.currentUser?.business?.branches ?? [],
      appLanguage: user?.currentUser?.appLanguage ?? '',
    },
  });

  const onSubmit = async (e) => {
    if (disabled) return;
    setDisabled(true);
    try {
      if (
        e.phone &&
        e.phone?.split('-')[1]?.length < 7 &&
        e.phone?.split('-')[1]?.length > 0
      ) {
        return notify(t('auth.signUp.wizard.notifs.shortPhone'), 'error');
      }
      const data = await updateMutation.mutateAsync({
        firstName: e.firstName,
        lastName: e.lastName,
        private: e.private.toString() === 'true',
        user: (user?.currentUser?.user as User)?._id,
        ...(['talent', 'fan'].includes(type) && {
          birthPlace: {
            country: e.birthCountry,
            city: e.birthCity ?? null,
            state: e?.birthState ?? null,
          },
          address: {
            country: e.currentCountry,
            city: e.currentCity ?? null,
            state: e?.currentState ?? null,
          },
          gender: e.gender,
        }),
        ...(type === 'business' && {
          business: {
            headquarter: e?.currentCountry,
            city: e?.currentCity,
            state: e?.currentState,
            websiteUrl: e?.websiteUrl,
            branches: e?.branches?.map((e_) => e_?._id),
          },
        }),
        description: e?.description,
        phone: e?.phone
          ? { prefix: e.phone.split('-')[0], number: e.phone.split('-')[1] }
          : null,
        appLanguage: e.appLanguage ?? i18n?.language,
        languages: e?.languages,
      });
      if (!data) {
        return notify(t('settings.notifs.error'), 'error');
      }

      setUser({
        ...user,
        _id: user?._id,
        profile: { ...user?.profile, ...data, user: user?.profile?.user },
        token: user?.token,
        currentUser: {
          ...user?.currentUser,
          ...data,
          user: user?.currentUser?.user as User,
        },
        accounts: user?.accounts,
      });
      notify(t('settings.notifs.success'), 'success');
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods?.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-6">
          <h3 className="font-semibold text-lg">
            {t('auth.signUp.wizard.personal.title')}
          </h3>
          <hr className="w-full bg-neutral-200" />

          <PersonalInformationFormContent type={type} disabled={disabled} />
          <h3 className="font-semibold text-lg">{t('settings.preferences')}</h3>
          <hr className="w-full bg-neutral-200" />
          <LanguagePreferencesFormContent disabled={disabled} />
        </div>
        <div className="w-full flex gap-5 justify-end mt-5">
          <Button
            tooltip={t('save')}
            type="submit"
            className="bg-primary-500 text-white font-normal"
          >
            {t('save')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
