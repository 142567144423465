import React from 'react';
import { useRouter } from 'next/router';
import { Select } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { Category, Pagination, Video } from '@dinbog/models';
import { ProfileMedia } from '../../media';
import MediaProfileLayout from '../../../../layout/MediaProfileLayout';
import VideoForm from './VideoForm';
import VideoProfile from './VideoProfile';
import { InfoWithImage } from '../../../../shared';

interface VideoTabProps {
  videoData: Pagination<Video>;
  setPagination: React.Dispatch<
    React.SetStateAction<{ page: number; perPage: number }>
  >;
  loading: boolean;
  categories: Category[];
  setCategoryFilter;
  categoryFilter;
}

export default function VideoTab({
  videoData,
  setPagination,
  loading,
  categories = [],
  setCategoryFilter,
  categoryFilter,
}: VideoTabProps) {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const [create, setCreate] = React.useState(false);

  if (router.query.video) return <VideoProfile categories={categories} />;

  if (create)
    return (
      <MediaProfileLayout
        type={t('profile.tabs.video.title')}
        handleGoBack={() => {
          setCreate(false);
        }}
        isOwner={router?.pathname === '/profile'}
      >
        <VideoForm
          data={null}
          categories={categories}
          setIsInForm={setCreate}
        />
      </MediaProfileLayout>
    );

  return (
    <div className="">
      <div className="flex flex-col gap-y-16 w-full">
        {router?.asPath.includes('/profile') ? (
          <div className="ml-auto">
            <button
              type="button"
              className="btn-primary px-12 py-3 font-normal rounded text-white "
              onClick={() => setCreate(true)}
            >
              {t('profile.tabs.video.uploadButton')}
            </button>
          </div>
        ) : null}
        <div className="md:w-1/3 w-full">
          <Select
            name="category"
            className="w-full"
            value={categoryFilter}
            placeholder={t('profile.tabs.category.placeholder')}
            onChange={(e) => {
              setCategoryFilter(e.target.value);
            }}
          >
            <option value="">{t('profile.tabs.category.placeholder')}</option>
            {categories?.map((category) => (
              <option
                className="capitalize"
                key={category._id}
                value={category._id}
              >
                {category.name[i18n.language]}
              </option>
            ))}
          </Select>
        </div>
        {/* list of videos */}
        {!loading && videoData?.count === 0 ? (
          <InfoWithImage
            image="/images/fallback/no-videos.png"
            title={
              router?.pathname === '/profile' && categoryFilter === ''
                ? t('emptyStates.noVideos.self.title')
                : t('emptyStates.noVideos.other.title')
            }
            text={
              router?.pathname === '/profile' && categoryFilter === ''
                ? t('emptyStates.noVideos.self.text')
                : ''
            }
          />
        ) : (
          <ProfileMedia
            mediaData={videoData}
            type="video"
            handleSelectItem={(item: Video) => {
              router.push(
                {
                  pathname: `${router.pathname}`,
                  query: {
                    ...router.query,
                    video: item._id,
                  },
                },
                undefined,
                { shallow: true }
              );
            }}
            setPagination={setPagination}
            mediaLoading={loading}
          />
        )}
      </div>
    </div>
  );
}
