import React from 'react';
import { useTranslation } from 'react-i18next';

function RequestDropdownHeader() {
  const { t } = useTranslation();

  return (
    <div className="dropdown-header">
      <span>{t('navbar.logged.notificationsDropdown.notifications')}</span>
    </div>
  );
}

export default RequestDropdownHeader;
