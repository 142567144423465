import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '@dinbog/models';
import { Button } from '@dinbog/ui';
import {
  useConnect,
  useDisconnect,
} from '../../../../../hooks/api/connections';
import { useNotify, useUser } from '../../../../../hooks';

interface ConnectionButtonProps {
  user: any;
  refreshUser?: () => void;
}

export default function ConnectionButton({
  user,
  refreshUser,
}: ConnectionButtonProps) {
  const notify = useNotify();
  const { t } = useTranslation();
  const { currentUser } = useUser();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  // Mutations
  const connectMutation = useConnect();
  const disconnectMutation = useDisconnect();

  const buttonLabel = React.useMemo(() => {
    const connection = user?.relations?.connection;
    if (connection === 'approved') {
      return t('profile.relations.actions.disconnect');
    }
    if (connection === 'on_hold') {
      return t('profile.relations.requested');
    }
    return t('profile.relations.actions.connect');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    setDisabled(false);
  }, [buttonLabel]);

  const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (currentUser?.profile?.categories?.length === 0) {
      return notify(t('profile.relations.actions.fanRestriction'), 'error');
    }

    if (disabled) return;
    setDisabled(true);

    try {
      const connected = ['approved', 'pending'].includes(
        user?.relations?.connection
      );
      const mutation = connected ? disconnectMutation : connectMutation;
      const input = {
        from: (currentUser?.currentUser?.user as User)?._id as string,
        to: user?._id,
        ...(!connected ? { type: 'friend' } : {}),
      };

      const _data = await mutation.mutateAsync(input);
      if (_data) refreshUser();
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  return (
    <Button
      tooltip={buttonLabel}
      className="btn-primary h-fit"
      onClick={handleOnClick}
      disabled={disabled}
    >
      {buttonLabel}
    </Button>
  );
}
