import React from 'react';
import { Album, Book, Pagination, Video } from '@dinbog/models';
import { InfiniteScroll, SpinnerIcon } from '@dinbog/ui';
import MediaCardPreview from './MediaCardPreview';

interface ProfileMediaProps {
  mediaData: Pagination<Video | Book | Album>;
  setPagination: React.Dispatch<
    React.SetStateAction<{ page: number; perPage: number }>
  >;
  type: string;
  handleSelectItem: (item: Video | Book | Album) => void;
  mediaLoading: boolean;
}
/**
 *  Component that renders the media of a profile en small cards with the image, category, date and title
 */
export default function ProfileMedia({
  mediaData,
  type,
  handleSelectItem,
  setPagination,
  mediaLoading,
}: ProfileMediaProps) {
  const [mediaList, setMediaList] = React.useState([
    ...(mediaData?.items || []),
  ]);

  return (
    <InfiniteScroll
      containerClassName="w-full flex flex-wrap gap-7 scrollbar-hide"
      loading={mediaLoading}
      data={mediaData}
      setPagination={setPagination}
      setLoadedData={setMediaList}
      loadingComponentClassName="my-7"
      fixedHeight={false}
    >
      {mediaList.map((item: Video | Book | Album) => (
        <MediaCardPreview
          key={item?._id}
          item={item}
          type={type}
          handleSelectItem={handleSelectItem}
        />
      ))}
    </InfiniteScroll>
  );
}
