import React from 'react';
import { InfiniteScroll, Modal } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { Comment, ItemCollectionEnum, Pagination } from '@dinbog/models';
import CommentDetail from './CommentDetail';
import CommentForm from '../../CommentForm';

interface CommentsModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: { _id: string; owner: any; type: ItemCollectionEnum };
  commentsData: Pagination<Comment>;
  setPagination: React.Dispatch<
    React.SetStateAction<{ page: number; perPage: number }>
  >;
  commentsLoading: boolean;
  ms: 'posts' | 'portfolios';
  onSuccessPostComment?: () => void;
  refetchComments?: () => void;
}

export default function CommentsModal({
  isOpen = false,
  setOpen,
  item,
  commentsData,
  setPagination,
  commentsLoading,
  ms,
  onSuccessPostComment,
  refetchComments,
}: CommentsModalProps) {
  const { t } = useTranslation();

  const [commentsList, setCommentsList] = React.useState(
    [...(commentsData?.items ?? [])].reverse()
  );

  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 md:p-2 flex flex-col gap-4 items-center"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <Modal.Header title={t('comment.plural')} exitBtn />
      <Modal.Body padding="small">
        <InfiniteScroll
          containerClassName="w-full flex flex-col-reverse gap-4 px-4"
          loading={commentsLoading}
          data={commentsData}
          setPagination={setPagination}
          setLoadedData={(newComments) => {
            if (Array.isArray(newComments)) {
              setCommentsList([...newComments].reverse());
            }
          }}
          maxHeight={350}
          triggerFunctionOnHit="top"
        >
          {commentsList.map((comment, idx) => (
            <CommentDetail
              key={`${comment?._id}-${idx}`}
              comment={comment}
              item={item}
              ms={ms}
              liked={(comment as any)?.liked}
              onSuccess={onSuccessPostComment}
              refetchComments={refetchComments}
            />
          ))}
        </InfiniteScroll>
        <CommentForm
          item={item?._id}
          itemCollection={item?.type}
          ms={ms}
          onSuccessPostComment={onSuccessPostComment}
        />
      </Modal.Body>
    </Modal>
  );
}
