import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { Album, FiltersInput, Pagination, Track } from '@dinbog/models';
import { axios } from '../../api';

export function useAlbums(
  filters: Partial<Album> & FiltersInput,
  options: Omit<
    UseQueryOptions<
      Pagination<Album>,
      AxiosError,
      Pagination<Album>,
      ['albums']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<Album>, AxiosError>(
    ['albums', filters],
    async () => {
      const { data } = await axios.get<Pagination<Album>>(
        '/api/portfolios/albums/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}
export function useAlbum(
  filters: Partial<
    Album & { user: string } & FiltersInput & { currentUser?: string }
  >,
  options: Omit<
    UseQueryOptions<
      Album & { tracks: Track[] },
      AxiosError,
      Album & { tracks: Track[] },
      ['album']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Album & { tracks: Track[] }, AxiosError>(
    ['album', filters],
    async () => {
      const { data } = await axios.get<Album & { tracks: Track[] }>(
        '/api/portfolios/albums/details/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useCreateAlbum() {
  const mutation = useMutation<Album, AxiosError, any>(async (newAlbum) => {
    const { data } = await axios.post('/api/portfolios/albums/v1', newAlbum);
    return data;
  });
  return mutation;
}

export function useUpdateAlbum() {
  const mutation = useMutation<Album, AxiosError, Partial<Album>>(
    async (album) => {
      const { data } = await axios.patch(`/api/portfolios/albums/v1`, album);
      return data;
    }
  );
  return mutation;
}
