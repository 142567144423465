import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CameraIcon } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { DocumentModel } from '@dinbog/models';
import { DocumentForm } from '../document';

function MediaCoverFormContent({
  media,
  updateURLs,
}: {
  media: DocumentModel[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
}) {
  const { t } = useTranslation();
  // remove selected photo from array
  const removePhoto = (idx: number) => {
    updateURLs((urls) => urls?.slice().filter((url) => url !== urls[idx]));
  };

  return (
    <div>
      {media?.length > 0 ? (
        <div className=" relative ">
          {/* x button to delete photo */}
          <button
            type="button"
            onClick={() => removePhoto(0)}
            className="absolute z-10 -top-1 -right-1 bg-danger-300 text-text-white h-4 w-4 rounded-full flex items-center justify-center"
            aria-label="Remove photo"
          >
            <FontAwesomeIcon icon={faTimes} className="w-2 h-2" />
          </button>
          <img
            alt={media[0]?.name}
            src={media[0]?.src as string}
            className="w-[250px] h-[200px] object-cover rounded-lg"
          />
        </div>
      ) : (
        <DocumentForm
          icon={false}
          dropZoneText={t(`auth.signUp.wizard.audio.coverPhoto.dragText`) ?? ''}
          dropZoneClassName=" w-[200px] h-[200px]  rounded-lg bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2"
          dropZoneTextClassName=" text-sm"
          updateURLs={updateURLs}
          documents={media}
          maximumAmount={1}
          customIcon={
            <CameraIcon className="w-[20px] h-[20px] text-neutral-400  mx-auto" />
          }
          showDocumentList
        />
      )}
    </div>
  );
}

export default MediaCoverFormContent;
