import React from 'react';
import { useRouter } from 'next/router';
import { useFormContext } from 'react-hook-form';
import { useLocation } from '../../../hooks';
import SearchFilters from './SearchFilters';

export default function LocationFilters({
  filters,
  setFilters,
}: {
  filters: {
    name: string;
    type: string;
    label: string;
    iterable: Array<any>;
  }[];
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        type: string;
        label: string;
        iterable: Array<any>;
      }[]
    >
  >;
}) {
  const router = useRouter();
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const { countries, states } = useLocation(
    watch('country') ?? '',
    watch('countryState') ?? ''
  );

  React.useEffect(() => {
    setFilters([
      {
        name: 'country',
        type: 'select:location',
        label: 'Country',
        iterable: countries,
      },
      {
        name: 'countryState',
        type: 'select:location',
        label: 'State',
        iterable: states,
      },
    ]);
  }, [countries, states]);

  return (
    <SearchFilters
      title="Search filters"
      filters={filters?.filter(
        (f) => f?.name === 'country' || f?.name === 'countryState'
      )}
    />
  );
}
