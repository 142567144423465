import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Book, Category, bookPrivacyValues, Multimedia } from '@dinbog/models';
import { Input, Select, TextArea, ThumbTackIcon } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { DocumentForm } from '../../document';
import { DocumentModel } from '../../../models';
import { ACTIONS, ACTIONS_TYPE } from './reducer';
import { FormButtons } from '../shared';
import { useNotify, useUser } from '../../../hooks';
import { useCreateBook, useUpdateBook } from '../../../hooks/api/books';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../utils/formatDocumentModel';
import { uploadFile } from '../../../lib/uploadFile';
import { useSignS3 } from '../../../hooks/api/s3';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { BookFormContent } from '../../shared';

interface UploadBookProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formsLength: number;
  data?: Book & { images: any[] };
  categories?: Category[];
}

export default function UploadBook({
  currentStep = 3,
  setCurrentStep,
  formsLength,
  data = null,
  categories = [],
}: UploadBookProps) {
  const { t, i18n } = useTranslation();
  const notify = useNotify();
  const { currentUser: user } = useUser();
  const [disabled, setDisabled] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [images, setImages] = React.useState<DocumentModel[]>(
    data !== null
      ? multimediaToDocument([
          data?.cover,
          ...(data?.images?.map((img) => img?.multimedia) ?? []),
        ])
      : []
  );
  const updateURLs = React.useCallback(setImages, [setImages]);
  const methods = useForm<Book>({
    defaultValues: {
      categories: data?.categories ?? [],
      title: data?.title ?? '',
      description: data?.description ?? '',
      privacy: data?.privacy ?? 'public',
      cover: data ? (multimediaToDocument([data?.cover]) as any) : null,
    },
  });

  // queries/mutations
  const queryClient = useQueryClient();
  const createBookMutation = useCreateBook();
  const updateBookMutation = useUpdateBook();
  const uploadToS3Mutation = useSignS3();
  const updateProfileMutation = useUpdateProfile();

  const handlePhotoUpload = async () => {
    // upload images to s3
    const newImages = images?.map(async (image) => {
      const newImg = await uploadFile(
        image,
        setUploading,
        updateURLs,
        uploadToS3Mutation,
        notify,
        t
      );
      return newImg;
    });
    return newImages;
  };

  const onSubmit: SubmitHandler<Partial<Book>> = async (
    _data: Partial<Book>
  ) => {
    try {
      if (disabled) return;
      setDisabled(true);

      if (images?.length < 3 || images?.length > 10) {
        return notify(t('auth.signUp.wizard.notifs.bookLength'), 'error');
      }

      const returnedImages = await handlePhotoUpload();

      const fulfilledPromises = await Promise.all(returnedImages);

      const imagesAsDocs = images?.map((img, idx) => ({
        src: fulfilledPromises?.[idx]?.url ?? img?.src,
        name: img?.name,
        id: img?.id,
        file: img?.file,
      }));

      const bookImages =
        imagesAsDocs?.length > 0 ? documentToMedia(imagesAsDocs) : null;

      const promises = [
        updateProfileMutation.mutateAsync({
          user: user?._id as string,
          // update registration step
          registrationStep: '4',
        }),
        (data ? updateBookMutation : createBookMutation).mutateAsync({
          _id: data?._id ?? undefined,
          title: _data?.title,
          user: user?._id,
          description: _data?.description,
          privacy: _data?.privacy,
          categories: _data?.categories,
          ...(images?.length > 0
            ? {
                cover: { ...bookImages[0], type: 'image' },
                photos: bookImages
                  ?.slice(1)
                  ?.map((img) => ({ ...img, type: 'image' })),
              }
            : { cover: undefined, photos: undefined }),
        }),
      ];

      await Promise.all(promises).then(() => {
        queryClient.refetchQueries(['book', { user: user?._id }]);
        setCurrentStep(currentStep + 1);
      });
    } catch (err) {
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onSkip = async () => {
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);

      await updateProfileMutation.mutateAsync({
        user: user?._id as string,
        // update registration step
        registrationStep: '4',
      });
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods?.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-lg">
              {t('auth.signUp.wizard.book.title')}
            </h3>
            <p>{t('auth.signUp.wizard.book.subtitle')}</p>
          </div>
          <BookFormContent
            categories={categories}
            images={images}
            setImages={setImages}
            disabled={disabled}
            updateURLs={updateURLs}
            categoriesDefault={(data?.categories as Category[])?.map((cat) => ({
              name: cat?.name[i18n?.language ?? 'en'],
              _id: cat?._id,
            }))}
          />
        </div>
        <FormButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={formsLength}
          onSkip={onSkip}
        />
      </form>
    </FormProvider>
  );
}
