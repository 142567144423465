import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface StepProps {
  text: string;
  completed: boolean;
}

export default function Step({ text, completed }: StepProps) {
  return (
    <div className="flex gap-2 items-center">
      <div
        className={`rounded-full border border-primary-500 flex justify-center items-center h-5 w-5 ${
          completed ? 'bg-primary-500' : ''
        }`}
      >
        <FontAwesomeIcon
          icon={faCheck}
          className="w-3 h-3 p-1 text-text-white"
        />
      </div>
      <span className={completed ? 'line-through text-neutral-500' : ''}>
        {text}
      </span>
    </div>
  );
}
