/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Category, PageInfo } from '@dinbog/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CategoryCard from './CategoryCard';

interface CategoryGridProps {
  selectedCategories;
  categories: Category[];
  pagination: Partial<PageInfo>;
  setPagination: React.Dispatch<React.SetStateAction<Partial<PageInfo>>>;
  manageCategories;
}

export default function CategoryGrid({
  selectedCategories,
  categories,
  pagination,
  setPagination,
  manageCategories,
}: CategoryGridProps) {
  const changePage = (page) => {
    if (page === 'prev' && pagination?.hasPreviousPage) {
      handleGoToPage((Number(pagination?.page) ?? 0) - 1);
    }
    if (page === 'next' && pagination?.hasNextPage) {
      handleGoToPage((Number(pagination?.page) ?? 0) + 1);
    }
  };

  const handleGoToPage = (value: number) => {
    setPagination({
      ...pagination,
      page: Number(value) > 0 ? Number(value) : 1,
    });
  };

  return (
    <div className="flex flex-row gap-6 items-center justify-between mx-9">
      <button
        type="button"
        onClick={() => changePage('prev')}
        disabled={!pagination?.hasPreviousPage}
        className="disabled:opacity-50"
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-text-black w-6 h-6"
        />
      </button>
      <div className="flex flex-wrap gap-4 justify-center">
        {categories?.map((category, idx) => (
          <CategoryCard
            isSelected={
              !!(selectedCategories as Category[])?.find(
                (c) => c?._id === category?._id
              )
            }
            category={category}
            key={idx}
            manageCategories={manageCategories}
          />
        ))}
      </div>
      <button
        type="button"
        onClick={() => changePage('next')}
        disabled={!pagination?.hasNextPage}
        className="disabled:opacity-50"
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-text-black w-6 h-6 transform rotate-180"
        />
      </button>
    </div>
  );
}
