import React from 'react';

interface CategorySelectContextProps {
  selectedOption: any;
  setSelectedOption: (value: any) => void;
  selectedLabel: string;
  setSelectedLabel: (label: string) => void;
}

export const CategorySelectContext = React.createContext<
  CategorySelectContextProps | undefined
>(undefined);

export const CategorySelectProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [selectedLabel, setSelectedLabel] = React.useState(null);

  const contextValue = React.useMemo(
    () => ({
      selectedOption,
      setSelectedOption,
      selectedLabel,
      setSelectedLabel,
    }),
    [selectedOption, selectedLabel]
  );

  return (
    <CategorySelectContext.Provider value={contextValue}>
      {children}
    </CategorySelectContext.Provider>
  );
};

export const useCategorySelect = () => {
    const context = React.useContext(CategorySelectContext);
    if (context === undefined) {
      throw new Error('useRequests must be used within a RequestsProvider');
    }
    return context;
  };
  
