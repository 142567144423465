import React from 'react';
import { PersonItem } from '../../../../shared';

export default function FollowingTab() {
  const followed = [
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
  ];
  return (
    <div className="w-full flex flex-row flex-wrap gap-16">
      {followed?.map((person) => (
        <PersonItem key={person?._id} user={person}>
          <div className="">
            {/* <PersonItem.Header user={person} />
            <PersonItem.Body user={person} /> */}
          </div>
        </PersonItem>
      ))}
    </div>
  );
}
