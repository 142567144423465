import React from 'react';
import { DebouncedInput } from '@dinbog/ui';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

interface SearchInputProps {
  placeholder?: string;
  className?: string;
  value: string;
  onChange: (_value: string) => void;
  hasSearchIcon: boolean;
}

function SearchInput({
  className,
  value,
  onChange,
  hasSearchIcon,
  placeholder,
}: SearchInputProps) {
  return (
    <label className="block w-full">
      <DebouncedInput
        value={value}
        onChange={onChange}
        placeholder={placeholder ?? ''}
        className="w-full py-2 border-0"
      />
    </label>
  );
}

export default SearchInput;
