import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from '../../../../../../shared';
import { useScreenSize } from '../../../../../../../hooks';
import ChatDropdownItem from './ChatDropdownItem';

function ChatDropdown() {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();

  const [isOpen, setIsOpen] = React.useState(false);

  const notifications = ['', '', ''];

  return (
    <div>
      {/* in small screen size, its only a link */}
      {screenSize >= 768 ? (
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className="h-full flex items-center"
        >
          <Dropdown.Button>
            <FontAwesomeIcon
              icon={faComment}
              className={`w-6 h-6 ${
                isOpen ? 'text-primary-500' : 'text-text-gray'
              }`}
            />
          </Dropdown.Button>
          <Dropdown.ItemsContainer className="rounded-none border border-gray-300 mt-12 p-0">
            <div className="dropdown-header">
              <button type="button">
                {t('navbar.logged.notificationsDropdown.markAll')}
              </button>
            </div>
            {notifications.map((notification) => (
              <ChatDropdownItem />
            ))}
            <Link
              href="/notifications"
              className="flex w-full justify-center text-left border-none text-primary-500 font-semibold py-2"
            >
              {t('all')}
            </Link>
          </Dropdown.ItemsContainer>
        </Dropdown>
      ) : (
        <Link href="/notifications">
          <ChatBubbleLeftIcon className="text-text-gray w-6 h-6" />
        </Link>
      )}
    </div>
  );
}

export default ChatDropdown;
