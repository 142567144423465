import React from 'react';
import { faGlobe, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LinkPreview({ url }: { url: string }) {
  return (
    <a
      className="flex rounded bg-background-200 shadow max-h-48 w-full"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex justify-between items-center w-full p-5">
        <FontAwesomeIcon
          icon={faGlobe}
          className="w-12 h-12 object-cover rounded-l text-neutral-500"
        />
        <div className="w-full p-4 flex flex-col justify-between">
          <div className="flex gap-2 items-center text-neutral-500">
            <FontAwesomeIcon icon={faLink} className="h-5 w-5" />
            <span className="">{url}</span>
          </div>
        </div>
      </div>
    </a>
  );
}
