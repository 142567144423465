/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button } from '@dinbog/ui';
import {
  Category,
  SpecificationTemplate,
  Subcategory,
  Vacant,
} from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useNotify, useUser } from '../../../../../hooks';
import {
  useCreateVacant,
  useUpdateVacant,
} from '../../../../../hooks/api/vacancies';
import { useSubcategories } from '../../../../../hooks/api/subcategories';
import VacancyFormFields from './VacancyFormFields';

interface VacancyFormProps {
  data?: Vacant;
  isLoading?: boolean;
  isCreate?: boolean;
  categories: Category[];
  specs: SpecificationTemplate[];
  previousRoute?: string;
  event?: string;
}

export default function VacancyForm({
  data = null,
  isLoading = false,
  isCreate = false,
  categories,
  specs,
  previousRoute = null,
  event,
}: VacancyFormProps) {
  const router = useRouter();
  const notify = useNotify();
  const { t } = useTranslation();
  const [selectedSpecs, setSelectedSpecs] = React.useState(
    data?.specifications?.map((s) => ({
      ...s,
      _id: (s.specificationTemplate as SpecificationTemplate)._id,
    })) ?? []
  );

  const [categoriesSelected, setCategoriesSelected] = React.useState(
    data?.categories?.map((cc) => ({
      _id: (cc as Category)._id,
      name: (cc as Category).name.en,
    })) || []
  );

  const [subcategoriesSelected, setSubcategoriesSelected] = React.useState(
    data?.subcategories?.map((sc) => ({
      _id: (sc as Subcategory)._id,
      name: (sc as Subcategory).name.en,
    })) || []
  );

  const queryClient = useQueryClient();
  const updateVacantMutation = useUpdateVacant();
  const createVacantMutation = useCreateVacant();

  const [disabled, setDisabled] = React.useState(false);

  const defaultValues = {};
  data?.specifications?.forEach((spec) => {
    const specificationTemplate =
      spec.specificationTemplate as SpecificationTemplate;
    if (spec.type === 'boolean') {
      defaultValues[specificationTemplate._id] = spec.value === 'true';
    } else {
      defaultValues[specificationTemplate._id] = spec.value;
    }
    if (spec.type === 'range') {
      defaultValues[`min-${specificationTemplate._id}`] =
        spec.multipleValues?.[0];
      defaultValues[`max-${specificationTemplate._id}`] =
        spec.multipleValues?.[1];
    }
  });

  const methods = useForm<Vacant>({
    defaultValues: {
      title: data?.title ?? '',
      description: data?.description ?? '',
      status: data?.status ?? 'active',
      ...defaultValues,
    },
  });

  const { data: dataSubcategories, isLoading: isLoadingSubcategories } =
    useSubcategories(
      {
        // category: categoriesSelected,
        filter: JSON.stringify({
          category: { $in: categoriesSelected?.map((cat) => cat?._id) },
        }),
      },
      { enabled: categoriesSelected?.length > 0 }
    );

  const onSubmit: SubmitHandler<Partial<Vacant>> = async (
    _data: Partial<Vacant>
  ) => {
    try {
      if (disabled) return;
      setDisabled(true);
      const mutationData = {
        ...(!isCreate ? { _id: data?._id } : {}),
        title: _data?.title,
        description: _data?.description,
        status: _data?.status,
        event: event as string,
        categories: categoriesSelected.map((cat) => cat._id),
        subcategories: subcategoriesSelected.map((subc) => subc._id),
        specifications: selectedSpecs.map((spec) => ({
          required: spec.required,
          type: spec.type,
          multipleValues:
            (spec?.type === 'range'
              ? [
                  _data[`min-${spec._id}`]?.replace(',', '.'),
                  _data[`max-${spec._id}`]?.replace(',', '.'),
                ]
              : spec.multipleValues) ?? [],
          value: String(_data[spec._id]),
          specificationTemplate: (
            spec?.specificationTemplate as SpecificationTemplate
          )?._id,
        })),
      };

      const mutationResult = await (!isCreate
        ? updateVacantMutation
        : createVacantMutation
      ).mutateAsync(mutationData, {
        onSuccess: () => {
          queryClient.refetchQueries([`vacancies`, { event }]);
        },
      });

      if (mutationResult) {
        notify(
          isCreate
            ? t('events.casting.vacancies.notifs.successfulCreation')
            : t('events.casting.vacancies.notifs.successfulUpdate'),
          'success'
        );
        router.push({
          pathname: `/briefcase/castings/[slug]`,
          query: { slug: router.query.slug, tab: 'vacancies' },
        });
      } else {
        return notify('Error', 'error');
      }
    } catch (err) {
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const disableVacant = React.useCallback(async () => {
    try {
      if (disabled) return;
      setDisabled(true);

      const respVacant = updateVacantMutation.mutateAsync(
        {
          _id: data?._id,
          active: !data?.active,
        },
        {
          onSuccess: () => {
            // queryClient.refetchQueries(['vacant', router.query.vacantId]);
            queryClient.refetchQueries([`vacancies`, { event }]);
          },
        }
      );

      if (respVacant) {
        notify(
          t('events.casting.vacancies.notifs.successfulUpdate'),
          'success'
        );
        /* router.push(
          previousRoute ??
            `/app/briefcase/castings/${event}?tab=vacancies`
        ); */
      } else {
        return notify('Error', 'error');
      }
    } catch (err) {
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  }, [data]);

  const actionButtonLabel = isCreate ? t('create') : t('update');

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-10 mt-6"
        method="post"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {/* section title */}
        <div className="flex flex-col gap-6">
          <h2 className="font-semibold text-xl">
            {isCreate ? t('create') : t('update')}{' '}
            {t('events.casting.vacancies.vacancy')}
          </h2>
          <hr className="w-full" />
        </div>
        {/* inputs */}
        <VacancyFormFields
          selectedSpecs={selectedSpecs}
          setSelectedSpecs={setSelectedSpecs}
          specs={specs}
          categories={categories}
          subcategories={dataSubcategories}
          categoriesSelected={categoriesSelected}
          subcategoriesSelected={subcategoriesSelected}
          setCategoriesSelected={setCategoriesSelected}
          setSubcategoriesSelected={setSubcategoriesSelected}
          vacantSpecs={data?.specifications}
        />
        {/* next btn */}
        <Button
          tooltip={actionButtonLabel}
          className="btn-primary w-fit px-8 place-self-end"
          type="submit"
        >
          {actionButtonLabel}
        </Button>
      </form>
    </FormProvider>
  );
}
