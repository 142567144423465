import React from 'react';

interface SmallContentProps {
  children: React.ReactNode | React.ReactNode[];
  position: 'right' | 'left';
  // 2: one small content and one large content
  // 3: two small content and one large content
  contentDistributionItems?: 2 | 3;
  className?: string;
}

function SmallContent({
  children,
  position,
  contentDistributionItems,
  className = '',
}: SmallContentProps) {
  const _position = {
    left: '  sm:order-1 lg:order-1 lg:pr-2',
    right: 'sm:order-1 lg:order-2 lg:pl-2 lg:pr-2',
    center: 'sm:order-1 lg:order-3  lg:pl-2',
  };

  const contentWidth = {
    2: 'w-full lg:w-1/5 xl:w-1/6',
    3: 'w-1/2  lg:w-1/5 xl:w-1/6',
  };

  return (
    <div
      className={`${_position[position]} ${contentWidth[contentDistributionItems]} ${className} `}
    >
      {children}
    </div>
  );
}

export default SmallContent;
