import React from 'react';
import { Input, Select } from '@dinbog/ui';
import { useFormContext } from 'react-hook-form';
import CategorySelect from '../../shared/category/CategorySelect';
import SpecificationFilterContainer from './specification/SpecificationFilterContainer';

interface SearchFiltersProps {
  filters;
  title?: string;
  type?: string;
}

export default function SearchFilters({
  filters,
  title,
  type = 'talent',
}: SearchFiltersProps) {
  const { register } = useFormContext();
  function getInput(field) {
    switch (field?.type) {
      case 'text':
        return (
          <Input
            type="text"
            label={field?.label}
            className="w-full"
            name={field?.name}
            key={field?.name}
            {...register(field?.name)}
          />
        );
      case 'date':
        return (
          <Input
            type="date"
            label={field?.label}
            className="w-full"
            name={field?.name}
            key={field?.name}
            {...register(field?.name)}
          />
        );
      case 'select':
        return <CategorySelect field={field} type={type} />;

      case 'select:subcategory':
        return (
          <Select
            name={field?.name}
            className={`w-full `}
            label={field?.label}
            defaultValue=""
            disabled={field?.disabled}
            key={field?.name}
            {...register(field?.name)}
          >
            <option key={-1} value="" defaultValue="">
              Select...
            </option>
            {field?.iterable?.map((item, i) => (
              <option key={i} value={item.value}>
                {item.text}
              </option>
            ))}
          </Select>
        );
      case 'select:location':
        return (
          <Select
            name={field?.name}
            className={`w-full `}
            label={field?.label}
            key={field?.name}
            {...register(field?.name)}
            defaultValue=""
          >
            <option key={-1} value="">
              Select...
            </option>
            {field?.iterable?.map((place, i) => (
              <option key={place.isoCode} value={place.isoCode}>
                {place.name}
              </option>
            ))}
          </Select>
        );
      case 'select:specification':
        return <SpecificationFilterContainer />;

      default:
        return null;
    }
  }
  return (
    <div className="flex flex-col gap-6">
      {title ? (
        <h2 className="text-primary-500 text-lg font-semibold">{title}</h2>
      ) : null}
      <div className="overflow-x-auto hide-scrollbar">
        <div className="flex md:flex-col gap-6 md:w-full w-fit">
          {filters?.map((field, id) => (
            <div key={id} className="md:w-full w-56">
              {getInput(field)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
