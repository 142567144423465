import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useUser, useNotify } from '../../../../hooks';
import { useUpdateProfile } from '../../../../hooks/api/profiles';
import { ACTIONS, ACTIONS_TYPE } from '../reducer';
import { DocumentModel } from '../../../../models';
import { FormButtons } from '../../shared';
import PersonalInformationContent from './PersonalInformationContent';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../utils/formatDocumentModel';

interface PersonalInformationProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  state: Partial<any>; // FIXME: add type
  dispatch: React.Dispatch<ACTIONS_TYPE>;
}

function PersonalInformation({
  currentStep,
  setCurrentStep,
  state,
  dispatch,
}: PersonalInformationProps) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const { t } = useTranslation();
  const updateMutation = useUpdateProfile();
  const methods = useForm({
    defaultValues: {
      [ACTIONS.DESCRIPTION]: state?.description || '',
      [ACTIONS.PHONE]: state?.phone || '',
      [ACTIONS.BIRTH_COUNTRY]: state?.birthCountry || '',
      [ACTIONS.BIRTH_STATE]: state?.birthState || '',
      [ACTIONS.BIRTH_CITY]: state?.birthCity || '',
      [ACTIONS.CURRENT_COUNTRY]: state?.currentCountry || '',
      [ACTIONS.CURRENT_STATE]: state?.currentState || '',
      [ACTIONS.CURRENT_CITY]: state?.currentCity || '',
      photo: user?.profile?.photo ?? null,
    },
  }); // FIXME: ADD TYPE

  const [profileImg, setProfileImg] = React.useState<DocumentModel[]>(
    user?.profile?.photo ? multimediaToDocument([user?.profile?.photo]) : []
  );
  const updateProfileURLs = React.useCallback(setProfileImg, [setProfileImg]);

  // FIXME: ADD TYPE
  const onSubmit: SubmitHandler<any> = async (data: any) => {
    // Set data in reducer state
    dispatch({ type: ACTIONS.DEFAULT, payload: data });
    // set data in local storage
    // localStorage.setItem(
    //   'fan',
    //   JSON.stringify({
    //     ...state,
    //     ...data,
    //   })
    // );

    const profilePhoto =
      profileImg?.length > 0 ? documentToMedia(profileImg) : null;

    if (!profilePhoto) {
      return notify('auth.signUp.wizard.notifs.profilePicture', 'error');
    }

    const _data = await updateMutation.mutateAsync({
      user: user?._id,
      birthPlace: {
        country: data?.birthCountry,
        city: data?.birthCity ?? null,
        state: data?.birthState ?? null,
      },
      address: {
        country: data?.currentCountry,
        city: data?.currentCity ?? null,
        state: data?.currentState ?? null,
      },
      gender: data?.gender,
      ...(profileImg?.length > 0
        ? { photo: { ...profilePhoto[0], type: 'image' } }
        : { photo: undefined }),
      phone: data?.phone
        ? {
            prefix: data?.phone?.split('-')[0],
            number: data?.phone?.split('-')[1],
          }
        : null,
      registrationStep: '1',
    });
    if (!_data) {
      return notify(t('auth.signUp.notifs.failure'), 'error');
    }

    // Go to next step
    setCurrentStep((cs) => cs + 1);
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-10">
          <h3 className="font-semibold text-lg ">
            {t('auth.signUp.wizard.fan.title')}
          </h3>
          <PersonalInformationContent
            profileImg={profileImg}
            updateProfileURLs={updateProfileURLs}
          />
        </div>
        <FormButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={2}
          onSubmit={methods.handleSubmit(onSubmit)}
          canSkip={false}
        />
      </form>
    </FormProvider>
  );
}

export default PersonalInformation;
