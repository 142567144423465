import React from 'react';
import { ChevronLeftIcon } from '@dinbog/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { MediaOptionsDropdown } from '../shared';

interface MediaProfileLayoutProps {
  children: React.ReactNode;
  handleGoBack: () => void;
  type: string;
  isOwner?: boolean;
  setOpenEditForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenReportForm?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MediaProfileLayout({
  children,
  handleGoBack,
  type,
  isOwner = false,
  setOpenEditForm = null,
  setOpenDeleteForm = null,
  setOpenReportForm = null,
}: MediaProfileLayoutProps) {
  const { t } = useTranslation();
  const [openOptionsDropdown, setOpenOptionsDropdown] = React.useState(false);
  return (
    <div className="w-full space-y-6">
      <div className="w-full flex flex-row justify-between">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleGoBack();
          }}
          className="flex flex-row gap-x-4 items-center hover:opacity-80 active:opacity-50"
        >
          <ChevronLeftIcon className="w-8 h-8" />
          <p className="font-semibold text-lg">
            {t('profile.tabs.returnBtn')}
            {type}
          </p>
        </button>
        {(setOpenEditForm || setOpenDeleteForm) && (
          <div className="justify-end mt-1 relative">
            <button
              aria-label="Options"
              className="flex flex-row"
              type="button"
              onClick={() => setOpenOptionsDropdown(!openOptionsDropdown)}
            >
              <FontAwesomeIcon icon={faEllipsis} className="h-5" />
            </button>
            {openOptionsDropdown ? (
              <MediaOptionsDropdown
                onEdit={
                  isOwner
                    ? () => {
                        setOpenEditForm(true);
                        setOpenOptionsDropdown(false);
                      }
                    : null
                }
                onDelete={
                  isOwner
                    ? () => {
                        setOpenDeleteForm(true);
                        setOpenOptionsDropdown(false);
                      }
                    : null
                }
                onReport={
                  !isOwner
                    ? () => {
                        setOpenReportForm(true);
                        setOpenOptionsDropdown(false);
                      }
                    : null
                }
              />
            ) : null}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
