import React from 'react';
import { useTranslation } from 'react-i18next';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@dinbog/ui';
import InfoWithIcon from '../../InfoWithIcon';

interface BlockConfirmationProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
  disabled: boolean;
  userName?: string;
  unblock?: boolean;
}

export default function BlockConfirmation({
  setOpen,
  onSubmit,
  disabled = false,
  userName = '',
  unblock = false,
}: BlockConfirmationProps) {
  const { t } = useTranslation();
  function getButtonLabels(unblockValue) {
    return {
      noButton: unblockValue ? t('unblock.noBtn') : t('block.noBtn'),
      yesButton: unblockValue ? t('unblock.yesBtn') : t('block.yesBtn'),
    };
  }
  const { noButton, yesButton } = getButtonLabels(unblock);

  return (
    <div className="flex flex-col gap-8 items-center">
      <InfoWithIcon
        title={`${
          unblock ? t('unblock.title') : t('block.title')
        } ${userName}?`}
        text={unblock ? t('unblock.text') : t('block.text')}
        icon={faBan}
        type={unblock ? 'info' : 'danger'}
      />
      <div className="flex flex-row w-full justify-evenly gap-4">
        <Button
          tooltip={noButton}
          disabled={disabled}
          className={`${
            unblock ? ' text-primary-500' : 'text-danger-300'
          } w-full active:opacity-70`}
          onClick={() => setOpen(false)}
        >
          {noButton}
        </Button>
        <Button
          tooltip={yesButton}
          disabled={disabled}
          className={`text-white ${
            unblock ? 'bg-primary-500' : 'bg-danger-300'
          } active:opacity-70 w-full`}
          type="submit"
          onClick={onSubmit}
        >
          {yesButton}
        </Button>
      </div>
    </div>
  );
}
