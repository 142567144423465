import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequests } from '../../context/RequestContext';
import NotificationList from './NotificationList';
import { InfoWithImage } from '../shared';

function NotificationListSection() {
  const {
    notificationsData,
    fetchNotificationsNextPage,
    isErrorInfiniteNotification,
    loadingNotifications,
    tabSelected,
  } = useRequests();
  const { t } = useTranslation();

  const hasNotifications =
    !loadingNotifications &&
    notificationsData.count > 0 &&
    tabSelected === 'notifications';

  const isErrorNotification =
    isErrorInfiniteNotification && tabSelected === 'notifications';

  if (isErrorNotification) {
    return <span className="text-black">{t('notifications.isError')}</span>;
  }

  const isNotificationEmpty =
    !loadingNotifications &&
    notificationsData.count === 0 &&
    tabSelected === 'notifications';

  if (isNotificationEmpty) {
    return (
      <div className="w-full lg:w-[548px] xl:w-[600px] pt-16">
        <InfoWithImage
          image="/images/fallback/no-requests.png"
          title={t('emptyStates.noRequests.title')}
          text={t('emptyStates.noRequests.text')}
        />
      </div>
    );
  }

  return hasNotifications ? (
    <NotificationList
      notificationData={notificationsData}
      fetchNextPage={fetchNotificationsNextPage}
    />
  ) : null;
}

export default NotificationListSection;
