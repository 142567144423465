import { Application, FiltersInput, Pagination } from '@dinbog/models';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axios } from '../../api';

export function useApplications(
  filters?: FiltersInput & Partial<Application>,
  options: Omit<
    UseQueryOptions<
      Pagination<Application>,
      AxiosError,
      Pagination<Application>,
      ['applications']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<Application>, AxiosError>(
    ['applications', filters],
    async () => {
      const { data } = await axios.get<Pagination<Application>>(
        '/api/events/applications/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useInfiniteApplications(
  filters: Partial<Application> & FiltersInput,
  options: Omit<
    UseInfiniteQueryOptions<
      Pagination<Application>,
      AxiosError,
      Pagination<Application>,
      Pagination<Application>,
      ['applications']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  async function fetchApplications({ pageParam = 1 }) {
    const { data } = await axios.get<Pagination<Application>>(
      '/api/events/applications/v1',
      {
        params: { ...filters, page: pageParam },
      }
    );
    return data;
  }

  const {
    data,
    fetchNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery<Pagination<Application>, AxiosError>(
    ['applications', filters],
    fetchApplications,
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
      ...options,
    }
  );

  return {
    data,
    fetchNextPage,
    isLoading,
    isError,
  };
}

export function useApplication(
  filters?: FiltersInput & Partial<Application>,
  options: Omit<
    UseQueryOptions<Application, AxiosError, Application, ['application']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Application, AxiosError>(
    ['application', filters?._id],
    async () => {
      const { data } = await axios.get<Application>(
        '/api/events/application/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useUpdateApplication(
  options: Omit<
    UseMutationOptions<Application, AxiosError, Partial<Application>, unknown>,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<Application, AxiosError, Partial<Application>>(
    async (updatedData) => {
      const { data } = await axios.patch(
        '/api/events/applications/v1',
        updatedData
      );
      return data;
    },
    options
  );
  return mutation;
}

export function useCreateApplication() {
  const mutation = useMutation<Application, AxiosError, Partial<Application>>(
    async (newApplication) => {
      const { data } = await axios.post(
        '/api/events/applications/v1',
        newApplication
      );
      return data;
    }
  );
  return mutation;
}
