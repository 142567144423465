import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonItem from '../../personCard';

interface DeleteNotifProps {
  deletedUser;
}

export default function DeleteNotif({ deletedUser }: DeleteNotifProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 items-center pb-8">
      <div className="w-fit">
        <PersonItem user={{ profile: deletedUser }} isHorizontal>
          <div className="">
            <PersonItem.Header user={{ profile: deletedUser }} />
            <PersonItem.Body user={{ profile: deletedUser }} />
          </div>
        </PersonItem>
      </div>
      <p className="text-center font-semibold text-xl">
        {t('deleteMember.notif')}
      </p>
    </div>
  );
}
