import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt } from '@fortawesome/free-solid-svg-icons';
import { DocumentModel } from '../../../../models';
import { uploadFile } from '../../../../lib/uploadFile';
import { useNotify } from '../../../../hooks';
import { useSignS3 } from '../../../../hooks/api/s3';
import { CropImageModal } from '../../../shared';

interface CompanyInformationPicturesProps {
  logo: DocumentModel[];
  updateLogoURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  coverImage: DocumentModel[];
  updateCoverURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
}

export default function CompanyInformationPictures({
  logo,
  updateLogoURLs,
  coverImage,
  updateCoverURLs,
}: CompanyInformationPicturesProps) {
  const { t } = useTranslation();
  const notify = useNotify();
  const [uploading, setUploading] = React.useState(false);
  const createMutation = useSignS3();
  const [currentImage, setCurrentImage] = React.useState<'logo' | 'cover'>(
    'logo'
  );
  const [photoModalIsOpen, setPhotoModalIsOpen] = React.useState(false);
  const [cropProps, setCropProps] = React.useState({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    size: { width: currentImage === 'logo' ? 100 : 300, height: 100 },
  });
  const handleSetImage = (type) => {
    setCurrentImage(type);
    setCropProps((prev) => ({
      ...prev,
      size: { width: type === 'logo' ? 100 : 300, height: 100 },
    }));
    setPhotoModalIsOpen(true);
  };

  const onSubmit = () => {
    uploadFile(
      currentImage === 'logo' ? logo[0] : coverImage[0],
      setUploading,
      currentImage === 'logo' ? updateLogoURLs : updateCoverURLs,
      createMutation,
      notify,
      t
    );
    setPhotoModalIsOpen(false);
  };
  return (
    <div className="flex lg:flex-row flex-col lg:gap-x-8 gap-y-4 lg:items-start items-center">
      <div className="space-y-4">
        <p className="font-semibold text-sm">
          {t('auth.signUp.wizard.personal.picture.profilePictureTitle')} *
        </p>
        {/* <div className="sm:w-72 w-48 sm:h-72 h-48 bg-slate-300">
            {t(`auth.signUp.wizard.personal.picture.dragText`)}
          </div> */}
        <div className="">
          <div className="relative sm:w-[200px] w-48 sm:h-[200px] h-48">
            <button
              type="button"
              className="sm:w-[200px] w-48 sm:h-[200px] h-48"
              onClick={() => handleSetImage('logo')}
            >
              {/* show logo if chosen already */}
              {logo?.length > 0 ? (
                <div className="w-full h-full relative">
                  <div className="absolute w-full h-full rounded opacity-0 hover:opacity-100 bg-black/40 flex justify-center items-center">
                    <FontAwesomeIcon
                      icon={faCameraAlt}
                      className="h-6 w-6 text-background-300"
                    />
                  </div>
                  <img
                    alt={logo[0]?.name}
                    src={logo[0]?.src as string}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ) : (
                <div className="h-full w-full rounded-lg bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2">
                  <FontAwesomeIcon icon={faCameraAlt} className="w-5 h-5" />
                  <p className="font-semibold text-sm">
                    {t(
                      `auth.signUp.wizard.personal.picture.uploadProfilePicture`
                    )}
                  </p>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="space-y-4 w-full">
        <p className="font-semibold text-sm">
          {t('auth.signUp.wizard.personal.picture.coverTitle')}
        </p>
        <div className="relative w-full sm:h-[200px] h-24">
          <button
            type="button"
            className="w-full sm:h-[200px] h-24"
            onClick={() => handleSetImage('cover')}
          >
            {/* show cover picture if chosen already */}
            {coverImage?.length > 0 ? (
              <div className="w-full h-full relative">
                <div className="absolute w-full h-full rounded-lg opacity-0 hover:opacity-100 bg-black/40 flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faCameraAlt}
                    className="h-6 w-6 text-background-300"
                  />
                </div>
                <img
                  alt={coverImage[0]?.name}
                  src={coverImage[0]?.src as string}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ) : (
              <div className="h-full w-full rounded-lg bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2">
                <FontAwesomeIcon icon={faCameraAlt} className="w-5 h-5" />
                <p className="font-semibold text-sm">{t(`uploadImage`)}</p>
              </div>
            )}
          </button>
        </div>
      </div>
      <CropImageModal
        title={
          currentImage === 'logo'
            ? t('auth.signUp.wizard.personal.picture.uploadProfilePicture')
            : t(`uploadImage`)
        }
        isOpen={photoModalIsOpen}
        setIsOpen={setPhotoModalIsOpen}
        image={currentImage === 'logo' ? logo : coverImage}
        updateURLs={currentImage === 'logo' ? updateLogoURLs : updateCoverURLs}
        defaultCrop={cropProps}
        onSubmit={onSubmit}
        cropShape="rect"
      />
    </div>
  );
}
