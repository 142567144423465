/* eslint-disable no-nested-ternary */
import React from 'react';
import z from 'zod';
import { Album, DocumentModel, Track } from '@dinbog/models';
import { AddIcon, Input, SpinnerIcon } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArrayWithId, UseFieldArrayAppend } from 'react-hook-form';
import { TrashIcon } from '@heroicons/react/24/solid';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { useNotify } from '../../../../hooks';
import { DocumentForm } from '../../../document';
import { useSignS3 } from '../../../../hooks/api/s3';
import { uploadFile } from '../../../../lib/uploadFile';

interface NewTrackCardProps {
  tracks: FieldArrayWithId<Album, 'tracks', 'id'>[];
  append: UseFieldArrayAppend<Album, 'tracks'>;
}

export default function NewTrackCard({ tracks, append }: NewTrackCardProps) {
  const { t } = useTranslation();
  const notify = useNotify();
  const [title, setTitle] = React.useState('');
  const [attachments, setAttachments] = React.useState<DocumentModel[]>([]);
  const updateURLs = React.useCallback(setAttachments, [setAttachments]);
  const [uploading, setUploading] = React.useState(false);
  const signS3 = useSignS3();
  const [disabled, setDisabled] = React.useState(false);

  const addTrack = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const response = await uploadFile(
      attachments[0],
      setUploading,
      updateURLs,
      signS3,
      notify,
      t
    );

    // add track to list

    append({
      _id: String(tracks.length + 1),
      title,
      audio: {
        fileName: title,
        url: response.url,
        type: attachments[0].file?.type,
      },
    } as Track);
    // reset form values
    setTitle('');
    updateURLs([]);
    setDisabled(false);
  };

  return (
    <div className="shadow rounded p-8 flex md:flex-row md:items-center flex-col gap-4 bg-white">
      <div className="flex md:flex-row flex-col gap-4 w-11/12">
        <div className="w-full">
          <Input
            isRequired
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full"
            label={t('auth.signUp.wizard.audio.tracks.trackName.title') ?? ''}
            placeholder={
              t('auth.signUp.wizard.audio.tracks.trackName.description') ?? ''
            }
          />
        </div>
        <div className="w-full flex flex-col justify-end">
          {attachments.length > 0 ? (
            <div className="flex flex-col md:flex-row items-center gap-2 md:mb-3">
              <p>{attachments[0].name}</p>
              <button
                type="button"
                className=" w-fit h-fit"
                onClick={(e) => {
                  e.preventDefault();
                  updateURLs([]);
                }}
                aria-label="Delete"
              >
                <TrashIcon className="h-6 w-6 text-danger-300 hover:opacity-80 " />
              </button>
            </div>
          ) : (
            <DocumentForm
              dropZoneClassName="btn-secondary h-fit py-2 px-4"
              documents={attachments}
              updateURLs={updateURLs}
              dropZoneText={t('auth.signUp.wizard.audio.tracks.uploadTrack')}
              dropZoneTextClassName="text-primary-500"
              horizontal
              customIcon={
                <FontAwesomeIcon icon={faPaperclip} className="w-4 h-4" />
              }
              accept={{
                'audio/*': [
                  '.mp3',
                  '.wav',
                  '.ogg',
                  '.flac',
                  '.aac',
                  '.m4a',
                  '.wma',
                ],
              }}
              maximumAmount={1}
            />
          )}
        </div>
      </div>
      {uploading ? (
        <SpinnerIcon className=" w-10 h-10 text-gray-200 animate-spin  fill-primary-500" />
      ) : attachments.length > 0 ? (
        <button
          aria-label="Add"
          type="button"
          onClick={addTrack}
          disabled={disabled}
          className="h-10 w-10 p-2 rounded-full bg-primary-500 flex justify-center items-center md:self-center self-end"
        >
          <AddIcon className="h-6 w-6 text-white" />
        </button>
      ) : null}
    </div>
  );
}
