import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { User } from '@dinbog/models';
import { Button } from '@dinbog/ui';
import { PersonItem } from '../../shared';
import { useNotify, useUser } from '../../../hooks';
import { useFollowUser, useUnfollowUser } from '../../../hooks/api/follows';
import { useConnect, useDisconnect } from '../../../hooks/api/connections';
import { useUnblockUser } from '../../../hooks/api/block';
import { useSearchContext } from '../../../context';

interface UserResultItemProps {
  userItem: any;
}

function UserResultItem({ userItem }: UserResultItemProps) {
  const { currentUser } = useUser();
  const { refetch } = useSearchContext();
  const router = useRouter();
  const notify = useNotify();
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  // mutations
  const followMutation = useFollowUser();
  const unfollowMutation = useUnfollowUser();
  const connectMutation = useConnect();
  const disconnectMutation = useDisconnect();
  const unblockUserMutation = useUnblockUser();

  // interactions handlers
  const handleFollow = async (user) => {
    setDisabled(true);
    try {
      const _data = await followMutation.mutateAsync({
        from: (currentUser?.currentUser?.user as User)?._id as string,
        to: user._id,
      });
      if (_data) {
        refetch();
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleUnfollow = async (user) => {
    setDisabled(true);
    try {
      const _data = await unfollowMutation.mutateAsync({
        from: (currentUser?.currentUser?.user as User)?._id as string,
        to: user?._id,
      });
      if (_data) {
        refetch();
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleConnect = async (user) => {
    setDisabled(true);
    try {
      const _data = await connectMutation.mutateAsync({
        from: (currentUser?.currentUser?.user as User)?._id as string,
        to: user._id,
        type: 'friend',
      });
      if (_data) {
        refetch();
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleDisconnect = async (user) => {
    setDisabled(true);
    try {
      const _data = await disconnectMutation.mutateAsync({
        from: (currentUser?.currentUser?.user as User)?._id as string,
        to: user?._id,
      });
      if (_data) {
        refetch();
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleUnblock = async (user) => {
    setDisabled(true);
    try {
      const _data = await unblockUserMutation.mutateAsync({
        to: user?._id as string,
        from: (currentUser?.currentUser?.user as User)?._id as string,
      });
      if (_data) {
        refetch();
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  const getFollowStatusLabel = (item) => {
    if (item?.profile?.follow === 'followed') {
      return t('profile.relations.actions.unfollow');
    }
    return t('profile.relations.actions.follow');
  };

  const getConnectStatusLabel = (item) => {
    if (item?.profile?.connection === 'connected') {
      return t('profile.relations.actions.disconnect');
    }
    if (item?.profile?.connection === 'requested') {
      return t('profile.relations.actions.requested');
    }
    return t('profile.relations.actions.connect');
  };
  return (
    <div className="w-fit" key={userItem?._id}>
      <PersonItem
        onClick={() => router?.push(`/${userItem?.user?.slug}`)}
        user={{
          profile: {
            ...userItem,
          },
        }}
        key={userItem?._id}
        isHorizontal
        hasBackground
        cardPadding="p-5"
      >
        <>
          <button
            type="button"
            onClick={() => router?.push(`/${userItem?.user?.slug}`)}
            className="text-left"
            aria-label="view"
          >
            <PersonItem.Header
              user={{
                profile: {
                  ...userItem,
                },
              }}
            />
            <PersonItem.Body
              user={{
                profile: {
                  ...userItem,
                },
              }}
            />
          </button>

          {!userItem?.profile?.blocked ? (
            <div className="flex gap-2 w-full">
              <Button
                tooltip={getFollowStatusLabel(userItem)}
                className={`btn-${
                  currentUser?.currentUser?.categories?.length === 0
                    ? 'primary'
                    : 'secondary'
                } text-sm w-full px-2`}
                size="sm"
                data-id={userItem?._id}
                disabled={disabled}
                onClick={() =>
                  userItem?.profile?.follow === 'followed'
                    ? handleUnfollow(userItem?.user)
                    : handleFollow(userItem?.user)
                }
              >
                {getFollowStatusLabel(userItem)}
              </Button>
              {currentUser?.currentUser?.categories?.length > 0 ? (
                <Button
                  tooltip={getConnectStatusLabel(userItem)}
                  className="btn-primary text-sm w-full px-2"
                  size="sm"
                  data-id={userItem?._id}
                  disabled={
                    userItem?.profile?.connection === 'requested' && disabled
                  }
                  onClick={() =>
                    userItem?.profile?.connection === 'connected'
                      ? handleDisconnect(userItem?.user)
                      : handleConnect(userItem?.user)
                  }
                >
                  {getConnectStatusLabel(userItem)}
                </Button>
              ) : null}
            </div>
          ) : (
            <Button
              tooltip={t('profile.relations.actions.unblock')}
              className="btn-primary text-sm w-full px-2"
              size="sm"
              data-id={userItem?._id}
              disabled={disabled}
              onClick={() => handleUnblock(userItem?.user)}
            >
              {t('profile.relations.actions.unblock')}
            </Button>
          )}
        </>
      </PersonItem>
    </div>
  );
}

export default UserResultItem;
