import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Specification, SpecificationsFormProps } from '@dinbog/models';
import { Alert } from '@dinbog/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { FormButtons } from '../shared';
import {
  generateTalentSpecificationInputDefaultValues,
  talentSpecificationsFormatToUpdate,
} from '../../../utils/talentSpecificationsFormat';
import SpecificationsFormContent from '../../shared/formContents/SpecificationsFormContent';

interface TalentSpecsFormProps {
  specifications: Array<Specification>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formsLength: number;
}

export default function TalentSpecsForm({
  specifications,
  currentStep = 2,
  setCurrentStep,
  formsLength,
}: TalentSpecsFormProps) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const { t } = useTranslation();

  const [disabled, setDisabled] = React.useState(false);

  const queryClient = useQueryClient();
  const updateProfileMutation = useUpdateProfile();

  const methods = useForm<SpecificationsFormProps>({
    defaultValues:
      generateTalentSpecificationInputDefaultValues(specifications),
  });

  const onSkip = async () => {
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);

      await updateProfileMutation.mutateAsync({
        user: user?._id as string,
        // update registration step
        registrationStep: '3',
      });
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const onSubmit: SubmitHandler<SpecificationsFormProps> = async (
    _data: SpecificationsFormProps
  ) => {
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);

      await updateProfileMutation.mutateAsync(
        {
          user: user?._id as string,
          // construct specifications array with values entered by user
          specifications: talentSpecificationsFormatToUpdate(
            specifications,
            _data
          ),
          // update registration step
          registrationStep: '3',
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(['talentSpecs', { user: user?._id }]);
          },
        }
      );
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-10"
        method="post"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="space-y-3">
          <div>
            <h3 className="font-semibold text-lg">
              {t('auth.signUp.wizard.specifications.title')}
            </h3>
            <p>{t('auth.signUp.wizard.specifications.description')}</p>
          </div>
          {specifications?.length > 0 ? (
            <SpecificationsFormContent
              specifications={specifications ?? []}
              disabled={disabled}
            />
          ) : (
            <Alert
              title={t('auth.signUp.wizard.specifications.noSpecsFound')}
              text={t(
                'auth.signUp.wizard.specifications.noSpecsFoundDescription'
              )}
              show
            />
          )}
        </div>
        <FormButtons
          disabled={disabled}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={formsLength}
          onSkip={onSkip}
        />
      </form>
    </FormProvider>
  );
}
