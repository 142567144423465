import Dropdown from './Dropdown';
import DropdownButton from './DropdownButton';
import DropdownItem from './DropdownItem';
import DropdownItemsContainer from './DropdownItemsContainer';

export default Object.assign(Dropdown, {
  Button: DropdownButton,
  ItemsContainer: DropdownItemsContainer,
  Item: DropdownItem,
});
