import React from 'react';
import NProgress from 'nprogress';
import { NextComponentType } from 'next';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { Lato } from 'next/font/google';
import dayjs from 'dayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ThemeContextProvider, ToastContextProvider } from '../context';
import '../i18n';
import '../style.css';
import { Auth } from '../components';
import 'dayjs/locale/es';
import { UserContextProvider } from '../context/UserContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const lato = Lato({
  subsets: ['latin'],
  variable: '--font-lato',
  weight: ['100', '300', '400', '700', '900'],
});

type CustomAppProps = AppProps & {
  Component: NextComponentType & { auth?: boolean }; // add auth type to protect routes
};

// @ts-expect-error err is not defined
function MyApp({ Component, pageProps, err }: CustomAppProps<any>) {
  const router = useRouter();
  const { i18n } = useTranslation();
  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, []);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // On page load or when changing themes, best to add inline in `head` to avoid FOUC
      if (
        localStorage.getItem('theme') === 'dark' ||
        (!('theme' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, []);

  React.useEffect(() => {
    if (window !== undefined && localStorage?.getItem('dinbog.lang')) {
      i18n.changeLanguage(localStorage?.getItem('dinbog.lang'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dayjs.locale(i18n?.language ?? 'en');
  }, [i18n?.language]);

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider session={pageProps.session} refetchInterval={0}>
        <UserContextProvider>
          <>
            <Head>
              <title>Dinbog</title>
            </Head>
            <ThemeContextProvider>
              <ToastContextProvider>
                {Component?.auth ? (
                  <Auth>
                    {/* <UserContextProvider> */}

                    <main className={`${lato.variable} font-text`}>
                      <Component {...pageProps} err={err} />
                    </main>

                    {/* </UserContextProvider> */}
                  </Auth>
                ) : (
                  <Component {...pageProps} />
                )}
              </ToastContextProvider>
            </ThemeContextProvider>
          </>
        </UserContextProvider>
      </SessionProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
