import React from 'react';
import { TriangleIcon } from '@dinbog/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useDropdown } from './Dropdown';

interface DropdownButtonProps {
  children?: React.ReactNode;
  className?: string;
  hasTriangleIcon?: boolean;
  triangleColor?: string;
  style?: React.CSSProperties;
}

function DropdownButton({
  children,
  className,
  hasTriangleIcon = false,
  triangleColor = 'primary-500',
  style,
}: DropdownButtonProps) {
  const [isOpen, setIsOpen] = useDropdown();

  const toggleUserDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <button
      type="button"
      onClick={toggleUserDropdown}
      className={`w-auto flex items-center lg:justify-between cursor-pointer outline-none focus:outline-none ${className}`}
    >
      {children}
      {hasTriangleIcon ? (
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`w-3 h-3 ml-2 transition-all duration-500 text-${triangleColor}  ${
            isOpen ? 'rotate-180' : 'rotate-0'
          }`}
          style={style}
        />
      ) : null}
    </button>
  );
}

export default DropdownButton;
