import { Vacant, FiltersInput, Pagination } from '@dinbog/models';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { axios } from '../../api';

export function useVacancies(
  filters?: FiltersInput &
    Partial<Vacant & { applicants: number }> & { token: string },
  options: Omit<
    UseQueryOptions<
      Pagination<Vacant & { applicants: number }>,
      AxiosError,
      Pagination<Vacant & { applicants: number }>,
      ['vacancies']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<
    Pagination<Vacant & { applicants: number }>,
    AxiosError
  >(
    ['vacancies', filters.token],
    async () => {
      const { data } = await axios.get<
        Pagination<Vacant & { applicants: number }>
      >('/api/events/vacancies/v1', {
        params: { ...filters },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      });
      return data;
    },
    options
  );
  return query;
}

export function useInfiniteOtherVacancies(
  filters: Partial<Vacant & { applicants: number }> & FiltersInput & { token: string },
  options: Omit<
    UseInfiniteQueryOptions<
      Pagination<Vacant & { applicants: number }>,
      AxiosError,
      Pagination<Vacant & { applicants: number }>,
      Pagination<Vacant & { applicants: number }>,
      ['vacancies']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  async function fetchVacancies({ pageParam = 1 }) {
    const { data } = await axios.get<Pagination<Vacant & { applicants: number }>>(
      '/api/events/vacancies/v1',
      {
        params: { ...filters, page: pageParam },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      }
    );
    return data;
  }

  const {
    data,
    fetchNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery<Pagination<Vacant & { applicants: number }>, AxiosError>(
    ['vacancies', filters],
    fetchVacancies,
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
      ...options,
    }
  );

  return {
    data,
    fetchNextPage,
    isLoading,
    isError,
  };
}

export function useInfiniteVacancies(
  filters: Partial<Vacant & { applicants: number }> & FiltersInput & { token: string },
  options: Omit<
    UseInfiniteQueryOptions<
      Pagination<Vacant & { applicants: number }>,
      AxiosError,
      Pagination<Vacant & { applicants: number }>,
      Pagination<Vacant & { applicants: number }>,
      ['vacancies']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  async function fetchVacancies({ pageParam = 1 }) {
    const { data } = await axios.get<Pagination<Vacant & { applicants: number }>>(
      '/api/events/vacancies/discover/v1',
      {
        params: { ...filters, page: pageParam },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      }
    );
    return data;
  }

  const {
    data,
    fetchNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery<Pagination<Vacant & { applicants: number }>, AxiosError>(
    ['vacancies', filters],
    fetchVacancies,
    {
      getNextPageParam: (lastPage) =>
        lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
      ...options,
    }
  );

  return {
    data,
    fetchNextPage,
    isLoading,
    isError,
  };
}

export function useVacant(
  filters?: any,
  options: Omit<
    UseQueryOptions<
      Vacant & {
        applications: {
          pending: number;
          preselected: number;
          selected: number;
          rejected: number;
        };
      },
      AxiosError,
      Vacant & {
        applications: {
          pending: number;
          preselected: number;
          selected: number;
          rejected: number;
        };
      },
      ['vacant']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<
    Vacant & {
      applications: {
        pending: number;
        preselected: number;
        selected: number;
        rejected: number;
      };
    },
    AxiosError
  >(
    ['vacant', filters.token],
    async () => {
      const { data } = await axios.get<any>('/api/events/vacant/v1', {
        params: { ...filters },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      });
      return data;
    },
    options
  );
  return query;
}

export function useUpdateVacant(
  options: Omit<
    UseMutationOptions<Vacant, AxiosError, Partial<Vacant>, unknown>,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<Vacant, AxiosError, Partial<Vacant>>(
    async (updatedData) => {
      const { data } = await axios.patch(
        '/api/events/vacancies/v1',
        updatedData
      );
      return data;
    },
    options
  );
  return mutation;
}

export function useCreateVacant() {
  const mutation = useMutation<Vacant, AxiosError, Partial<Vacant>>(
    async (newVacant) => {
      const { data } = await axios.post('/api/events/vacancies/v1', newVacant);
      return data;
    }
  );
  return mutation;
}
