import React from 'react';
import { Modal, Button } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface DeleteMediaConfirmationModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: () => void;
}

export default function DeleteMediaConfirmationModal({
  isOpen = false,
  setOpen,
  handleDelete,
}: DeleteMediaConfirmationModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 p-8 flex flex-col gap-8 items-center"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div className="bg-danger-100 rounded-full shadow-lg w-28 h-28 flex items-center justify-center">
        <FontAwesomeIcon
          icon={faTrashAlt}
          className="w-12 h-12 text-danger-300"
        />
      </div>
      <div className="space-y-2 mb-8">
        <h2 className="text-xl font-semibold text-center">
          {t('profile.deleteMedia.title')}
        </h2>
        <p className="">{t('profile.deleteMedia.description')}</p>
      </div>
      <div className="flex flex-row w-full justify-evenly gap-4">
        <Button
          tooltip={t('profile.deleteMedia.keep')}
          className="text-danger-300 w-full active:opacity-70"
          onClick={() => setOpen(false)}
        >
          {t('profile.deleteMedia.keep')}
        </Button>
        <Button
          tooltip={t('profile.deleteMedia.delete')}
          className="text-white bg-danger-300 active:opacity-70 w-full"
          onClick={(e) => {
            e.preventDefault();
            handleDelete();
            setOpen(false);
          }}
        >
          {t('profile.deleteMedia.delete')}
        </Button>
      </div>
    </Modal>
  );
}
