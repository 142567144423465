import React from 'react';
import { useTranslation } from 'react-i18next';

export default function FaceRecInfo({
  isModal = false,
}: {
  isModal?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div className={`space-y-8 w-full ${isModal ? '' : 'xl:w-2/3 lg:w-1/2'}`}>
      <ul className="list-disc pl-4 text-sm">
        {new Array(6).fill(0).map((_, idx) => (
          <li className="" key={idx}>
            {t(`auth.signUp.wizard.personal.picture.description.${idx}`)}
          </li>
        ))}
      </ul>
    </div>
  );
}
