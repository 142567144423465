import React from 'react';
import { ProfileStats } from '@dinbog/models';
import { Card } from '../../../../shared';
import CompletionGraph from './CompletionGraph';
import StepsList from './steps/StepsList';

interface ProfileCompletionCardProps {
  placement?: 'profile' | 'feed';
  stats: ProfileStats;
  registrationStep: number;
  padding?: string;
}

export default function ProfileCompletionCard({
  placement = 'feed',
  stats,
  registrationStep = 0,
  padding = 'px-20 py-6',
}: ProfileCompletionCardProps) {
  return (
    <Card
      className={`${
        placement === 'profile' ? 'w-[432px]' : 'feed'
      } ${padding} flex flex-col gap-6`}
    >
      <CompletionGraph stats={stats} />
      <StepsList registrationStep={registrationStep} stats={stats} />
    </Card>
  );
}
