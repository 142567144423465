import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { Book, FiltersInput, Multimedia, Pagination } from '@dinbog/models';
import { axios } from '../../api';

export function useBooks(
  filters?: Partial<Book> & FiltersInput,
  options: Omit<
    UseQueryOptions<Pagination<Book>, AxiosError, Pagination<Book>, ['books']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<Book>, AxiosError>(
    ['books', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<Pagination<Book>>(
          '/api/portfolios/books/v1',
          {
            params: { ...filters },
          }
        );

        return data;
      }
    },
    options
  );
  return query;
}

export function useBook(
  filters: Partial<Book>,
  options: Omit<
    UseQueryOptions<Book, AxiosError, Book, ['book']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Book, AxiosError>(
    ['book', filters],
    async () => {
      const { data } = await axios.get<Book>('/api/portfolios/book/v1', {
        params: { ...filters },
      });
      return data;
    },
    options
  );
  return query;
}

export function useBookWithImages(
  filters: Partial<Book> & FiltersInput & { currentUser?: string },
  options: Omit<
    UseQueryOptions<
      Book & { images: any[] },
      AxiosError,
      Book & { images: any[] },
      ['bookWithImages']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Book & { images: any[] }, AxiosError>(
    ['bookWithImages', filters],
    async () => {
      const { data } = await axios.get<Book & { images: any[] }>(
        '/api/portfolios/book/images/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useCreateBook() {
  const mutation = useMutation<Book, AxiosError, Partial<Book>>(
    async (newBook) => {
      const { data } = await axios.post('/api/portfolios/books/v1', newBook);
      return data;
    }
  );
  return mutation;
}

export function useUpdateBook() {
  const mutation = useMutation<Book, AxiosError, Partial<Book>>(
    async (book) => {
      const { data } = await axios.patch(`/api/portfolios/books/v1`, book);
      return data;
    }
  );
  return mutation;
}
