import React from 'react';
import { useTranslation } from 'react-i18next';

interface SpecRowProps {
  spec;
  fromTemplate: boolean;
}

function SpecRow({ spec, fromTemplate }: SpecRowProps) {
  const { t, i18n } = useTranslation();

  const specToUpperCase = (value: any) => {
    if (typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
    return value;
  };

  const formatSpecValues = () => {
    switch (spec.type) {
      case 'range':
        return (
          <div className="">
            {specToUpperCase(spec?.multipleValues[0])} -{' '}
            {specToUpperCase(spec?.multipleValues[1])}{' '}
            {specToUpperCase(spec?.specificationTemplate?.measure)}
          </div>
        );
      case 'multiselect':
        return spec?.multipleValues?.map((value, idx) => (
          <div className="space-x-1" key={idx}>
            <span className="">
              {specToUpperCase(
                spec?.specificationTemplate?.options?.find(
                  (option) => option?.value === value
                )?.name?.[i18n?.language ?? 'en'] ?? ''
              )}
            </span>
            <span>{spec?.multipleValues?.length > idx + 1 ? '|' : null}</span>
          </div>
        ));
      case 'boolean':
        return (
          <div className="">
            {specToUpperCase(spec?.value === 'true' ? t('yes') : t('no'))}
          </div>
        );

      default:
        return null;
    }
  };

  const isLanguageSpec = spec?.name === t('profile.generalInfo.languages');

  const getValue = () => {
    if (
      spec?.value !== 'undefined' &&
      spec?.type !== 'boolean' &&
      spec?.type !== 'multiselect'
    ) {
      if (isLanguageSpec) {
        return spec?.value.toUpperCase();
      }
      return specToUpperCase(spec?.value ?? '');
    }
    return formatSpecValues();
  };

  return (
    <div className="flex w-full font-medium">
      <span className="text-text-black">
        {fromTemplate
          ? specToUpperCase(
              spec?.specificationTemplate?.name[i18n?.language ?? 'en'] ?? ''
            )
          : specToUpperCase(spec?.name ?? '')}
      </span>
      <p className="text-neutral-500 ml-auto text-right">{getValue()}</p>
    </div>
  );
}

export default SpecRow;
