import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { CategoryTypes } from '@dinbog/models';
import { useUser } from '../../../hooks';
import { useBookWithImages } from '../../../hooks/api/books';
import { useVideo } from '../../../hooks/api/videos';
import { useAlbum } from '../../../hooks/api/albums';
import { useWorkReference } from '../../../hooks/api/workReferences';
import { reducer } from './reducer';
import { useGetTalentSpecs } from '../../../hooks/api/specifications';
import { SkeletonWrapper, ProgressBar } from '../../shared';
import { useGetTracks } from '../../../hooks/api/tracks';
import { useCategories } from '../../../hooks/api/categories';
import PersonalInfo from './personalInfo/PersonalInfo';
import TalentSpecsForm from './SpecificationsForm';
import UploadBook from './UploadBook';
import UploadVideo from './UploadVideo';
import UploadAudio from './UploadAudio';
import WorkReferenceInfo from './WorkReferenceInfo';

// FORM STEPS AMOUNT
const formsLength = 6;

// since many steps have a categories selection,
// this function returns the category type to filter them in the query based on the current step
const getCategoryTypeByStep = (step: number): CategoryTypes => {
  switch (step) {
    case 3:
      return 'book';
    case 4:
      return 'video';
    case 5:
      return 'audio';

    default:
      return undefined;
  }
};

export default function TalentSignUp({
  currentStep = 1,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { currentUser: user } = useUser();
  const router = useRouter();
  const { t } = useTranslation();
  const [userState, userDispatch] = React.useReducer(
    reducer,
    /* JSON.parse(localStorage?.getItem('talent') ?? '') || */ {}
  );

  // to get the book created by the talent if they turn back to this step
  const { data: dataBook, isLoading: loadingBook } = useBookWithImages(
    {
      user: user?._id ?? '',
    },
    { refetchOnWindowFocus: false, enabled: currentStep === 3 }
  );

  // to get the album created by the talent if they turn back to this step
  const { data: dataAlbum, isLoading: loadingAlbum } = useAlbum(
    {
      user: user?._id ?? '',
    },
    { refetchOnWindowFocus: false, enabled: currentStep === 5 }
  );
  const { data: dataTracks, isLoading: loadingTracks } = useGetTracks(
    {
      album: dataAlbum?._id ?? '',
    },
    { refetchOnWindowFocus: false, enabled: currentStep === 5 }
  );

  // talent specs for SpecificationForm
  const { data: dataSpecs, isLoading: isLoadingSpecs } = useGetTalentSpecs(
    {
      user: user?._id ?? '',
    },
    { refetchOnWindowFocus: false, enabled: currentStep === 2 }
  );

  // to get the video created by the talent if they turn back to this step
  const { data: dataVideo, isLoading: loadingVideo } = useVideo(
    {
      user: user?._id ?? '',
    },
    { refetchOnWindowFocus: false, enabled: currentStep === 4 }
  );

  // to get the workReference created by the talent if they turn back to this step
  const { data: dataWorkReference, isLoading: loadingWorkReference } =
    useWorkReference(
      {
        user: user?._id ?? '',
      },
      { refetchOnWindowFocus: false, enabled: currentStep === 6 }
    );

  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategories(
      {
        type: getCategoryTypeByStep(currentStep),
      },
      'portfolios', // To get categories from ms-portfolios
      {
        refetchOnWindowFocus: false,
        enabled: currentStep === 3 || currentStep === 4 || currentStep === 5,
      }
    );

  React.useEffect(() => {
    if (currentStep === 7) {
      router?.push('/profile');
    }
    if (currentStep >= 1 && user?.profile?.categories?.length === 0) {
      setCurrentStep(0);
    }
  }, [currentStep]);

  const forms = [
    <PersonalInfo
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      formsLength={formsLength}
    />,
    <SkeletonWrapper isLoading={isLoadingSpecs}>
      <TalentSpecsForm
        specifications={dataSpecs}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formsLength={formsLength}
      />
    </SkeletonWrapper>,
    <SkeletonWrapper isLoading={loadingBook || isLoadingCategories}>
      <UploadBook
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formsLength={formsLength}
        data={dataBook}
        categories={dataCategories}
      />
    </SkeletonWrapper>,
    <SkeletonWrapper isLoading={loadingVideo || isLoadingCategories}>
      <UploadVideo
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formsLength={formsLength}
        data={dataVideo}
        categories={dataCategories}
      />
    </SkeletonWrapper>,
    <SkeletonWrapper
      isLoading={loadingAlbum || loadingTracks || isLoadingCategories}
    >
      <UploadAudio
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        data={{ ...dataAlbum, tracks: dataTracks }}
        formsLength={formsLength}
        categories={dataCategories}
      />
    </SkeletonWrapper>,
    <SkeletonWrapper isLoading={loadingWorkReference}>
      <WorkReferenceInfo
        data={dataWorkReference}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formsLength={formsLength}
      />
    </SkeletonWrapper>,
  ];

  return (
    <>
      <h2 className="font-semibold text-lg">{t('auth.signUp.wizard.title')}</h2>
      <div className="flex flex-row justify-center">
        <ProgressBar steps={formsLength} currentStep={currentStep} />
      </div>
      {forms[currentStep - 1]}
    </>
  );
}
