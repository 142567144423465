import React from 'react';
import { ArrowUpTrayIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt } from '@fortawesome/free-solid-svg-icons';
import { DocumentModel } from '../../../../models';
import CropDocumentForm from '../../../document/cropDocument/CropDocumentForm';

interface UploadImageProps {
  image: DocumentModel[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

function UploadImage({ image, updateURLs, setStep }: UploadImageProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full h-auto ">
      <div className="p-6">
        <CropDocumentForm
          dropZoneText={t(`uploadImage`)}
          dropZoneTextClassName="h-auto font-semibold text-sm"
          dropZoneClassName="w-full h-56 rounded bg-[#EAEAEA]/50 border border-neutral-400 border-dashed text-neutral-400 flex flex-col justify-center items-center gap-y-2"
          updateURLs={updateURLs}
          documents={image}
          maximumAmount={1}
          setStep={setStep}
          customIcon={
            <FontAwesomeIcon icon={faCameraAlt} className="w-5 h-5" />
          }
        />
      </div>
    </div>
  );
}

export default UploadImage;
