import React from 'react';
import {
  Business,
  BusinessUserRole,
  Profile,
  StatusEnum,
  User,
} from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { getFlag } from '../../lib/getFlag';

interface UserItemProps {
  onClick?: () => void;
  className?: string;
  icon: React.ReactNode;
  user: User & { profile?: Profile; business?: Business } & {
    role?: BusinessUserRole;
    status?: StatusEnum;
  };
  showButton?: boolean;
  size?: 'small' | 'large';
  isMember?: boolean;
  includeFlag?: boolean;
}

function UserItem({
  onClick,
  icon,
  className,
  user,
  showButton = true,
  size = 'small',
  includeFlag = false,
  isMember = false,
}: UserItemProps) {
  const { t } = useTranslation('');

  const getStatusTextColor = (status: StatusEnum) => {
    switch (status) {
      case 'approved':
        return 'text-green-300';
      case 'rejected':
        return 'text-danger-300';
      default:
        return 'text-neutral-300';
    }
  };
  return (
    <div
      className={`dropdown-item w-full py-3 md:max-w-[500px] justify-between ${
        size === 'small' ? 'gap-2' : 'gap-4'
      } ${className} `}
    >
      <div className="flex gap-4 items-center">
        <img
          className={`rounded-full ${
            size === 'small'
              ? 'w-8 h-8'
              : 'md:w-20 md:h-20 w-8 h-8 sm:w-14 sm:h-14'
          } object-cover`}
          src={
            user?.profile?.photo?.url ??
            (user?.profile?.type === 'business'
              ? '/images/fallback/business.jpg'
              : '/images/fallback/user.png')
          }
          alt="user"
        />
        <div className="flex flex-col">
          <div className="flex gap-2">
            <span className="text-text-black whitespace-nowrap">
              {user?.profile?.firstName ?? ''} {user?.profile?.lastName ?? ''}
            </span>
            {isMember && (
              <p
                className={`whitespace-nowrap ${getStatusTextColor(
                  user?.status
                )}`}
              >
                {t(`profile.tabs.workReference.status.${user?.status}`)}
              </p>
            )}
          </div>

          <div className="flex">
            {includeFlag && (
              <span className="">
                {getFlag(user?.profile?.address?.country)}
              </span>
            )}
            <span className="text-text-gray text-sm">{user?.email}</span>
          </div>
        </div>
      </div>
      {showButton && (
        <button
          type="button"
          onClick={typeof onClick !== 'undefined' ? onClick : () => {}}
          className=" flex flex-col"
        >
          {icon}
        </button>
      )}
    </div>
  );
}

export default UserItem;
