import React from 'react';
import { Video } from '@dinbog/models';
import { useTranslation } from 'react-i18next';

function VideoContent({ video }: { video: Video }) {
  const { t } = useTranslation();
  const maxChars = 300; // max characters to show in the description
  const [showFullDescription, setShowFullDescription] = React.useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncatedContent = showFullDescription
    ? video?.description
    : video?.description?.slice(0, maxChars);

  return (
    <div className="w-full py-6 mb-6 flex flex-col gap-6 b border-b-[1px] border-neutral-200 ">
      <video
        className="w-full rounded-xl xl:h-[700px] md:h-[400px] h-[200px]"
        controls
        src={video?.multimedia?.url}
        poster={video?.cover?.url}
      >
        <track
          src="captions_en.vtt"
          kind="captions"
          srcLang="en"
          label="english_captions"
        />
        <track
          src="captions_es.vtt"
          kind="captions"
          srcLang="es"
          label="spanish_captions"
        />
      </video>
      {/* video description */}
      <div className="description w-full text-neutral-500  text-justify ">
        {truncatedContent}
        {video?.description?.length > maxChars && (
          <>
            {!showFullDescription ? '... ' : ' '}
            <button
              type="button"
              className="text-blue-500 cursor-pointer"
              onClick={toggleDescription}
            >
              {showFullDescription ? t('readLess') : t('readMore')}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default VideoContent;
