import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Category, Subcategory } from '@dinbog/models';
import { SkeletonWrapper } from '../../../../../../shared';
import Preferences from './Preferences';
import VacancyCategories from './vacancyCategories/VacancyCategories';

export default function VacancyInfo({
  data,
  isLoading,
}: {
  data;
  isLoading: boolean;
}) {
  const { t } = useTranslation();

  return (
    <SkeletonWrapper isLoading={isLoading}>
      <div className="flex flex-col gap-6">
        {/* description */}
        <div className="space-y-4">
          <p className="text-lg font-semibold">
            {t('events.casting.vacancies.about')}
          </p>
          <p className="">{data?.description}</p>
        </div>
        {/* categories and subcategories */}
        <div className="space-y-4">
          <p className="text-lg font-semibold">
            {t('events.casting.vacancies.categoriesAndSubcategories')}
          </p>
          <VacancyCategories
            categories={data?.categories as Category[]}
            subcategories={data?.subcategories as Subcategory[]}
          />
        </div>
        {/* preferences */}
        <Preferences specs={data?.specifications} />
      </div>
    </SkeletonWrapper>
  );
}
