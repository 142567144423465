import React from 'react';
import {
  AlbumStats,
  BookStats,
  ItemCollectionEnum,
  PageInfo,
  PostStats,
  TrackStats,
  VideoStats,
  User,
  Pagination,
  LikeItem,
} from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { validateNumber } from 'avilatek-utils';
import { useTranslation } from 'react-i18next';
import { useComments } from '../../hooks/api/comments';
import LikeCommentShareButtons from '../shared/LikeCommentShareButtons';
import { CommentsModal } from '../shared/modal';
import CommentForm from '../shared/CommentForm';
import LatestComments from './LatestComments';
import { useUser } from '../../hooks';
import { useGetLikes, useGetPortfolioLikes } from '../../hooks/api/likes';
import LikeModal from '../shared/modal/likeModal/LikeModal';

interface CommentSectionProps {
  item: {
    _id: string;
    owner: any;
    type: ItemCollectionEnum;
    slug?: string;
    ownerSlug?: string;
  };
  stats: PostStats | BookStats | VideoStats | AlbumStats | TrackStats;
  liked: boolean;
  onSuccessLike?: () => void;
}

export default function CommentSection({
  item,
  liked,
  stats,
  onSuccessLike,
}: CommentSectionProps) {
  const { currentUser: user } = useUser();
  const ms = item?.type === 'post' ? 'posts' : 'portfolios';
  const { t } = useTranslation();
  const [openCommentsModal, setOpenCommentsModal] = React.useState(false);

  const [commentsPreview, setCommentsPreview] = React.useState([]); // 3 last comments to show in the preview
  const [itemStats, setItemStats] = React.useState({
    comments: 0,
    likes: 0,
  });
  const [commentsPagination, setCommentsPagination] = React.useState<
    Partial<PageInfo>
  >({
    page: 1,
    perPage: 30,
  });

  const [openLikesModal, setOpenLikesModal] = React.useState(false);
  const [likesPagination, setLikesPagination] = React.useState<
    Partial<PageInfo>
  >({
    page: 1,
    perPage: 30,
  });

  const {
    data,
    isLoading,
    refetch: refetchComments,
  } = useComments(ms, {
    item: item?._id,
    itemCollection: item?.type,
    user: user?._id,
    ...commentsPagination,
    sort: 'createdAt',
    sortOrder: -1,
  });

  const { data: likesData, isLoading: isLikesLoading } = useGetLikes(
    {
      item: item?._id,
      page: likesPagination.page,
      itemCollection: item?.type,
      perPage: likesPagination.perPage,
      sort: 'createdAt',
      sortOrder: -1,
      token: user?.token,
      filter: { active: true },
    },
    {
      enabled: !!user?.token && openLikesModal && item?.type === 'post',
    }
  );

  const { data: portfolioLikesData, isLoading: isPortfolioLikesLoading } =
    useGetPortfolioLikes(
      {
        item: item?._id,
        page: likesPagination.page,
        itemCollection: item?.type,
        perPage: likesPagination.perPage,
        sort: 'createdAt',
        sortOrder: -1,
        token: user?.token,
        filter: { active: true },
      },
      {
        enabled: !!user?.token && openLikesModal && item?.type !== 'post',
      }
    );

  React.useEffect(() => {
    if (data?.items !== undefined && Number(data?.pageInfo?.page) === 1)
      setCommentsPreview(data?.items?.slice(0, 3) ?? null);
    if (validateNumber(data?.count) && data?.count !== itemStats?.comments)
      setItemStats({ ...itemStats, comments: data.count });
    if (validateNumber(stats?.likes) && stats?.likes !== itemStats?.likes)
      setItemStats({ ...itemStats, likes: stats.likes });
  }, [data, stats]);

  const queryClient = useQueryClient();

  return (
    <div className="flex flex-col gap-y-4 justify-start">
      {/* show amount of likes and comments */}
      <div className="flex mb-2 gap-4 font-semibold lowercase">
        <button
          type="button"
          onClick={() => setOpenLikesModal(true)}
          disabled={itemStats?.likes === 0}
        >
          <span className="text-primary-500">
            {itemStats?.likes ?? 0} {t('stats.likes')}
          </span>
        </button>
        <button type="button" onClick={() => setOpenCommentsModal(true)}>
          <span className="text-primary-500">
            {itemStats?.comments ?? 0} {t('stats.comments')}
          </span>
        </button>
      </div>
      {/* like, comment, save and share actions */}
      <LikeCommentShareButtons
        item={item?._id}
        slug={item?.slug}
        itemCollection={item?.type}
        ownerSlug={item?.ownerSlug}
        onHitCommentButton={() => setOpenCommentsModal(true)}
        ms={ms}
        buttonsSize="w-7 h-7 text-neutral-700"
        liked={liked}
        onSuccessLike={onSuccessLike}
      />
      {/* <div className="flex">
        <button className="" type="button">
          <FontAwesomeIcon
            icon={faBookmark}
            className="w-6 h-6 justify-self-end"
          />
        </button>
      </div> */}
      {/* open comments modal */}
      <button
        className="w-fit text-neutral-400 font-medium hover:opacity-80"
        type="button"
        onClick={() => setOpenCommentsModal(true)}
      >
        {itemStats?.comments > 0 ? (
          <>
            {`${t('comment.viewAll')} ${itemStats?.comments} `}
            <span className="lowercase">{t('comment.plural')}</span>
          </>
        ) : (
          t('comment.noComments')
        )}
      </button>
      {/* show latest comments */}
      <LatestComments comments={commentsPreview.reverse() ?? null} />
      {/* new comment form */}
      <CommentForm
        itemCollection={item?.type}
        item={item?._id}
        ms={ms}
        onSuccessPostComment={() => {
          queryClient.refetchQueries([
            'comments',
            ms,
            {
              user: (user?.currentUser?.user as User)?._id,
              item: item?._id,
              itemCollection: item?.type,
              ...commentsPagination,
              page: 1,
              sort: 'createdAt',
              sortOrder: -1,
            },
          ]);
        }}
      />
      {/* comments modal */}
      <CommentsModal
        isOpen={openCommentsModal}
        setOpen={setOpenCommentsModal}
        item={item}
        commentsData={data}
        commentsLoading={isLoading}
        ms={ms}
        setPagination={setCommentsPagination}
        onSuccessPostComment={() => {
          queryClient.refetchQueries([
            'comments',
            ms,
            {
              user: (user?.currentUser?.user as User)?._id,
              item: item?._id,
              itemCollection: item?.type,
              ...commentsPagination,
              page: 1,
              sort: 'createdAt',
              sortOrder: -1,
            },
          ]);
        }}
        refetchComments={refetchComments}
      />
      <LikeModal
        isOpen={openLikesModal}
        setOpen={setOpenLikesModal}
        setPagination={setLikesPagination}
        item={item}
        likesData={item?.type === 'post' ? likesData : portfolioLikesData}
        likesLoading={
          item?.type === 'post' ? isLikesLoading : isPortfolioLikesLoading
        }
      />
    </div>
  );
}
