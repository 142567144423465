import React from 'react';
import { ContentSkeleton } from '@dinbog/ui';
import Card from '../card';
import FadeAnimation from '../FadeAnimation';

interface SkeletonProps {
  className?: string;
}

function Skeleton({ className }: SkeletonProps) {
  return (
    <FadeAnimation>
      <Card className={className}>
        <Card.Body>
          <ContentSkeleton />
        </Card.Body>
      </Card>
    </FadeAnimation>
  );
}

export default Skeleton;
