import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface CustomPhoneInputProps {
  value?: string;
  label?: string;
  name: string;
  onChange?: (phone: string, country: string) => void;
  controlled?: boolean;
  control?: Control<any, any>;
  error?: string;
  validations?: Omit<
    RegisterOptions<any, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

function CustomPhoneInput({
  value = '',
  label,
  onChange = () => {},
  controlled = false,
  control,
  name = '',
  error = '',
  validations = {},
}: CustomPhoneInputProps) {
  const inputStyle = {
    appearance: 'none',
    height: '42px',
    background: 'transparent',
    width: '100%',
    border: 'solid',
    borderWidth: '0.5px',
    borderColor: error ? '#E5483D' : '#A6A6A6',
    borderRadius: '4px',
    minHeight: '40px',
    fontSize: '16px',
    color: '#606060',
    fontWeight: '300',
  };

  return (
    <label className="block w-full">
      {label !== '' ? (
        <span className="text-sm font-semibold mb-5 ">{label}</span>
      ) : null}

      <div className="mt-2 ">
        {controlled ? (
          <Controller
            name={name}
            control={control}
            rules={validations}
            render={({ field: { onChange: _onChange, value: _value } }) => (
              <PhoneInput
                inputStyle={inputStyle as React.CSSProperties}
                value={_value}
                onChange={(phone: string, country: any) => {
                  // TODO: necesito formatear esto apenas carga el componente
                  const reducedPhone = phone.replace(country.dialCode, '');
                  _onChange(
                    phone?.length > country.dialCode
                      ? `${country.dialCode}-${reducedPhone}`
                      : ''
                  );
                }}
              />
            )}
          />
        ) : (
          <PhoneInput
            inputStyle={inputStyle as React.CSSProperties}
            countryCodeEditable={false}
            value={value}
            onChange={onChange}
          />
        )}
      </div>

      {error ? (
        <span className="text-sm text-danger-300 capitalize">{error}</span>
      ) : null}
    </label>
  );
}

export default CustomPhoneInput;
