import { AxiosError } from 'axios';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { FiltersInput, Pagination, Profile, Report } from '@dinbog/models';
import { axios } from '../../api';

export function useCreateReport(
  options: Omit<
    UseMutationOptions<
      Report,
      AxiosError,
      Partial<Report> & { service: string },
      unknown
    >,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<
    Report,
    AxiosError,
    Partial<Report> & { service: string }
  >(async (newReport) => {
    const { data } = await axios.post('/api/reports/v1', newReport);
    return data;
  }, options);
  return mutation;
}
