import React from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronLeft, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoWithIcon, Tab } from '../../../../shared';
import TalentList from './TalentList';

export default function TalentRequests({
  talents = {},
  setViewing,
}: {
  talents;
  setViewing: React.Dispatch<
    React.SetStateAction<'current' | 'past' | 'requests'>
  >;
}) {
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = React.useState<'received' | 'sent'>(
    'received'
  );
  const items = React.useMemo(
    () => [
      {
        title: t('profile.tabs.talents.requests.received'),
        itemKey: 'received',
        component: <TalentList talents={talents?.items} viewing="requests" />,
      },
      /* {
        title: t('profile.tabs.talents.requests.sent'),
        itemKey: 'sent',
        component: <div className="">sent requests</div>,
      }, */
    ],
    [talents]
  );

  return (
    <div className="space-y-6">
      <button
        type="button"
        className="flex gap-2 items-center"
        onClick={() => setViewing('current')}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="h-3 w-3 text-text-black"
        />
        <span className="font-semibold">
          {t('profile.tabs.returnBtn')}{' '}
          {t('profile.tabs.talents.title').toLowerCase()}
        </span>
      </button>
      {talents?.items?.length > 0 ? (
        <div className="flex flex-col gap-6">
          <Tab
            zIndex="z-40"
            items={items}
            currentItem={tabSelected}
            setCurrentItem={setTabSelected}
          />
          <div className="w-full">
            {items?.find((item) => item?.itemKey === tabSelected)?.component}
          </div>
        </div>
      ) : (
        <InfoWithIcon
          title={t('emptyStates.noCVRequests.title')}
          text={t('emptyStates.noCVRequests.text')}
          icon={faUsers}
        />
      )}
    </div>
  );
}
