import React from 'react';
import { PersonItem } from '../../../../shared';

export default function FollowersTab() {
  const followers = [
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '1',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '2',
      name: 'Jane Doe',
      category: 'Dancer',
    },
    {
      _id: '3',
      name: 'Jane Doe',
      category: 'Dancer',
    },
  ];
  return (
    <div className="w-full flex flex-row flex-wrap gap-16">
      {/* ver si para los no seguidos se muestra o no el botón de seguir */}
      {/* agregarle "following" según lo que venga de la db */}
      {followers?.map((person) => (
        <PersonItem key={person?._id} user={person} />
      ))}
    </div>
  );
}
