import React from 'react';
import { UserContext, UserContextProps } from '../context/UserContext';

export const useUser = (): UserContextProps | undefined => {
  const context = React.useContext(UserContext);
  // if (!context) {
  //   throw new Error(
  //     'useCurrentUserContext must be used within a CurrentUserProvider'
  //   );
  // }
  return context;
};

// import React from 'react';
// import { CurrentUserContext } from '../context/CurrentUserContext';

// export function useUser() {
//   const context = React.useContext(CurrentUserContext);
//   return context;
// }
