import { AxiosError } from 'axios';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import {
  FiltersInput,
  ItemCollectionEnum,
  Pagination,
  Comment,
} from '@dinbog/models';
import { axios } from '../../api';

export function useUpdateComment(
  ms: 'portfolios' | 'posts',
  options: Omit<
    UseMutationOptions<
      Comment,
      AxiosError,
      Partial<Comment> & { user: string },
      unknown
    >,
    'mutationFn'
  > = {}
) {
  const mutation = useMutation<
    Comment,
    AxiosError,
    Partial<Comment> & { user: string }
  >(async (updatedComment) => {
    const { data } = await axios.patch(
      `/api/${ms}/comments/v1`,
      updatedComment
    );
    return data;
  }, options);
  return mutation;
}

export function useGetComment(
  filters: Partial<Comment> | any,
  options: Omit<
    UseQueryOptions<Comment, AxiosError, Comment, ['comment']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Comment, AxiosError>(
    ['comment', filters],
    async () => {
      const { data } = await axios.get<Comment>(
        `/api/posts/comment-details/v1`,
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useCreateComment(ms: 'portfolios' | 'posts') {
  // ms = microservice
  const mutation = useMutation<
    {
      owner: string;
      text: string;
      item: string;
      itemCollection: ItemCollectionEnum;
    }, // owner and item are ids
    AxiosError,
    Partial<{
      owner: string;
      text: string;
      item: string;
      itemCollection: ItemCollectionEnum;
    }>
  >(async (params) => {
    const { data } = await axios.post(`/api/${ms}/comments/v1`, params);
    return data;
  });
  return mutation;
}

export function useComments(
  ms: 'portfolios' | 'posts', // ms = microservice
  filters: FiltersInput & Partial<Comment> & { user: string },
  options: Omit<
    UseQueryOptions<
      Pagination<Comment>,
      AxiosError,
      Pagination<Comment>,
      ['comments']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<Comment>, AxiosError>(
    ['comments', filters],
    async () => {
      const { data } = await axios.get<Pagination<Comment>>(
        ms === 'portfolios'
          ? `/api/${ms}/comments/pagination/v1`
          : `api/${ms}/post-comments/v1`,
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useGetCommentLikes(
  filters,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['commentLikes']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['commentLikes', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get(`/api/posts/comment-likes/v1`, {
          params: { ...filters },
        });
        return data;
      }
    },
    options
  );
  return query;
}
