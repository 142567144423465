import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Button, CheckIcon } from '@dinbog/ui';
import { CircledIcon } from '../../shared';
import { useNotify } from '../../../hooks';
import { useConfirmEmail } from '../../../hooks/api/auth';

export default function ConfirmEmail() {
  const { t } = useTranslation();
  const notify = useNotify();
  const router = useRouter();
  const confirmEmailMutation = useConfirmEmail();

  // query
  const onSubmit = async () => {
    try {
      const data = await confirmEmailMutation.mutateAsync({
        token: router?.query?.token as string,
      });
      if (!data) {
        return notify(t('auth.signUp.notifs.failure'), 'error');
      }
    } catch (err) {
      // console.log(err);
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  React.useEffect(() => {
    if (router?.query?.token) {
      onSubmit();
    }
  }, [router]);

  return (
    <div className="w-full h-full py-8 row-start-1 row-end-4 col-start-1 col-end-4">
      <div className="bg-white p-16 h-full flex flex-col justify-between rounded-lg shadow-sm">
        <div className="mb-8 flex flex-col items-center space-y-6">
          <CircledIcon>
            <CheckIcon className="h-12 w-12 text-primary-500" />
          </CircledIcon>
          <h1 className=" text-3xl ">
            {t('auth.signUp.activate.success.title')}
          </h1>
          <p className="text-center">
            {t('auth.signUp.activate.success.subtitle')}
          </p>
          <Button
            onClick={(e) => router.push('sign-in')}
            className="btn-primary w-auto"
            tooltip={t('auth.signUp.activate.success.btn')}
          >
            <p className=" text-base font-normal">
              {t('auth.signUp.activate.success.btn')}
            </p>
          </Button>
        </div>
      </div>
    </div>
  );
}
