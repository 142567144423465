import React from 'react';
import { Button, Modal } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { Crop, DocumentModel } from '../../../models';
import UploadImage from './steps/UploadImage';
import CropImage from './steps/CropImage';
import ShowCroppedImage from './steps/ShowCroppedImage';
import FaceRecInfo from '../FaceRecInfo';

interface CropImageModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  image: DocumentModel[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  title: string;
  onSubmit: () => void;
  defaultCrop: Crop;
  cropShape: 'round' | 'rect';
  isPfp?: boolean;
}

function CropImageModal({
  title,
  isOpen,
  setIsOpen,
  image,
  defaultCrop,
  updateURLs,
  cropShape = 'rect',
  onSubmit = () => {},
  isPfp = false,
}: CropImageModalProps) {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = React.useState(0);

  const cropProps = React.useMemo(
    () => ({
      defaultCrop,
      img: image?.length > 0 ? image[0] : null,
    }),
    [image]
  );

  const stepComponents = React.useMemo(
    () => [
      <UploadImage
        image={image}
        updateURLs={updateURLs}
        setStep={setCurrentStep}
      />,
      <CropImage
        setStep={setCurrentStep}
        image={image}
        updateURLs={updateURLs}
        cropProps={cropProps}
        cropShape={cropShape}
      />,
      <ShowCroppedImage
        image={image}
        updateURLs={updateURLs}
        setStep={setCurrentStep}
        cropShape={cropShape}
      />,
    ],
    [currentStep, image, updateURLs]
  );

  return (
    <Modal isOpen={isOpen} setOpen={setIsOpen}>
      <Modal.Header title={title ?? ''} exitBtn />
      <Modal.Body padding="none" className="space-y-0">
        {stepComponents[currentStep]}
        {isPfp ? (
          <div className="px-8 space-y-2">
            <p className="font-semibold">
              {t('auth.signUp.wizard.personal.picture.requirements')}
            </p>
            <FaceRecInfo isModal />
          </div>
        ) : null}
        <div className="w-full flex justify-end p-6 pt-0">
          {currentStep === 0 ? (
            <Button
              tooltip={t('cancel')}
              className="text-primary-500"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {t('cancel')}
            </Button>
          ) : null}

          {currentStep === 2 ? (
            <div className="flex space-x-3">
              {/* <Button
                className="text-primary-500"
                onClick={() => {
                  setCurrentStep(1);
                }}
              >
                {t('return')}
              </Button> */}
              <Button
                tooltip={t('apply')}
                className="btn-primary"
                onClick={() => {
                  setCurrentStep(0);
                  onSubmit();
                }}
              >
                {t('apply')}
              </Button>
            </div>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CropImageModal;
