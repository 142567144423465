import React from 'react';
import { TrashIcon } from '@dinbog/ui';
import { UseFieldArrayRemove } from 'react-hook-form';
import { Track } from '@dinbog/models';

interface TrackCardProps {
  track: Track;
  idx: number;
  remove: UseFieldArrayRemove;
  handleDeleteTrack: (trackId: string) => Promise<void>;
}

export default function TrackCard({
  track,
  idx,
  remove,
  handleDeleteTrack,
}: TrackCardProps) {
  const removeTrack = (e) => {
    e.preventDefault();
    handleDeleteTrack(track._id);
    remove(idx - 1);
  };
  return (
    <div className="p-8 border-b-[1px] py-2 px-4 flex md:flex-row md:items-center flex-col-reverse items-end md:gap-8">
      <div className="w-full flex md:flex-row flex-col md:gap-8 md:items-center">
        <p className="font-semibold md:block hidden">{idx}</p>
        <div className="w-full" key={track._id ?? idx}>
          <p className="font-semibold break-words">{track.title ?? ''}</p>
          {/* <p className="text-sm break-words">{track.audio?.url ?? ''}</p> */}
        </div>
      </div>
      <button
        type="button"
        onClick={(e) => removeTrack(e)}
        className="h-fit w-fit"
        aria-label="Delete"
      >
        <TrashIcon className="h-6 w-6 text-neutral-600 hover:opacity-80" />
      </button>
    </div>
  );
}
