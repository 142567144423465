import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { Button } from '@dinbog/ui';
import { Category, Pagination, Subcategory, User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { useNotify, useUser } from '../../../hooks';
import {
  CategoryGrid,
  ConfirmationModal,
  FadeAnimation,
  SkeletonWrapper,
  SubcategoryList,
} from '../../shared';

const getChangeRoleMessage = (type: 'talent' | 'fan' | 'business') => {
  switch (type) {
    case 'talent':
      return {
        title: 'settings.changeRoleToFan.title',
        description: 'settings.changeRoleToFan.description',
      };
    case 'fan':
      return {
        title: 'settings.changeRoleToTalent.title',
        description: 'settings.changeRoleToTalent.description',
      };
    default:
      return {};
  }
};

interface CategoriesFormProps {
  type: 'talent' | 'business' | 'fan';
  data: Pagination<Category>;
  pagination: { page: number; perPage: number };
  refetch: () => void;
  setPagination: React.Dispatch<
    React.SetStateAction<{
      page: number;
      perPage: number;
    }>
  >;
  isLoading?: boolean;
  refetchSpecs;
}

export default function CategoriesForm({
  type = 'talent',
  data,
  pagination,
  refetch,
  setPagination,
  isLoading,
  refetchSpecs,
}: CategoriesFormProps) {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const [isOpen, setIsOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const router = useRouter();
  const notify = useNotify();
  const { t } = useTranslation();

  const [categories, setCategories] = React.useState([]);
  const methods = useForm({
    defaultValues: {
      userCategories: user?.currentUser?.categories || [],
      userSubcategories: user?.currentUser?.subcategories || [],
    },
  });
  const {
    fields: selectedCategories,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: 'userCategories',
    rules: { maxLength: 10 },
  });

  const {
    fields: selectedSubcategories,
    append: appendSubcategory,
    remove: removeSubcategory,
  } = useFieldArray({
    control: methods.control,
    name: 'userSubcategories',
    rules: { maxLength: 100 },
  });

  const manageCategories = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget.dataset;
    const category = categories?.filter((cat) => cat._id === id)[0];

    if (!(selectedCategories as Category[])?.find((c) => c?._id === id)) {
      // add to selectedCategories only if the 10 categories limit has not been reached
      if (selectedCategories?.length < 10) {
        append(category);
      } else {
        notify(t('auth.signUp.wizard.talentCategory.categoryLimit'), 'info'); //  info o warning?
      }
    } else {
      // remove from selectedCategories
      const idx = (selectedCategories as Category[])?.indexOf(
        (selectedCategories as Category[])?.find((c) => c?._id === id)
      );
      remove(idx);
    }
  };

  React.useEffect(() => {
    if (data) {
      setCategories(data?.items);
    }
  }, [data]);

  React.useEffect(() => {
    refetch();
  }, [pagination, refetch]);

  const queryClient = useQueryClient();
  const updateMutation = useUpdateProfile();

  const onSubmit = async (e) => {
    if (disabled) return;
    setDisabled(true);
    try {
      const categoriesIds = e?.userCategories?.map((c) => c._id);
      const subcategoriesIds = e?.userSubcategories?.map((sc) => sc._id);

      const _data = await updateMutation.mutateAsync({
        user: (user?.currentUser?.user as User)?._id,
        categories: categoriesIds,
        subcategories: subcategoriesIds,
      });

      if (!_data) {
        return notify(t('auth.signUp.notifs.failure'), 'error');
      }

      setUser({
        ...user,
        currentUser: {
          ...user?.currentUser,
          categories: _data?.categories,
          subcategories: _data?.subcategories,
        },
        ...(user?.currentUser?.type === 'person' ? { profile: _data } : {}),
      });
      refetchSpecs();

      notify(t('settings.notifs.success'), 'success');
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
    } finally {
      setIsOpen(false);
      setDisabled(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
        <ConfirmationModal
          isOpen={isOpen}
          setOpen={setIsOpen}
          title={t(getChangeRoleMessage(type)?.title)}
          text={t(getChangeRoleMessage(type)?.description)}
          onSubmit={methods.handleSubmit(onSubmit)}
          disabled={disabled}
          confirmButtonText="settings.changeRoleConfirm"
        />

        <div className="flex flex-col gap-y-12">
          <div className="flex flex-col gap-6">
            <h4 className="font-semibold text-lg">
              {t('auth.signUp.wizard.talentCategory.categories')}
            </h4>
            <hr className="w-full bg-neutral-200" />
            <p>{t('auth.signUp.wizard.business.category.description')}</p>
            <SkeletonWrapper isLoading={isLoading}>
              <CategoryGrid
                selectedCategories={selectedCategories}
                categories={categories}
                pagination={data?.pageInfo}
                setPagination={setPagination}
                manageCategories={manageCategories}
              />
            </SkeletonWrapper>
          </div>
          {selectedCategories?.length > 0 ? (
            <FadeAnimation>
              <div className="flex flex-col gap-y-4">
                <h4 className="font-semibold">
                  {t('auth.signUp.wizard.talentCategory.subcategories')}
                </h4>
                <SubcategoryList
                  selectedCategories={selectedCategories as Category[]}
                  selectedSubcategories={selectedSubcategories as Subcategory[]}
                  append={appendSubcategory}
                  remove={removeSubcategory}
                />
              </div>
            </FadeAnimation>
          ) : null}
        </div>

        <div className="w-full flex gap-5 justify-end mt-5">
          <Button
            tooltip={t('save')}
            type="button"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              if (
                (selectedCategories.length === 0 && type === 'talent') ||
                (selectedCategories.length > 0 && type === 'fan')
              ) {
                return setIsOpen(true);
              }
              if (selectedCategories.length === 0 && type === 'business') {
                return notify(
                  t('settings.categoriesNotifs.noCategoriesBusiness'),
                  'error'
                );
              }
              methods.handleSubmit(onSubmit)();
            }}
            className="bg-primary-500 text-white font-normal"
          >
            {t('save')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
