/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Link from 'next/link';
import { GraphicEqIcon, PlayCircleIcon } from '@dinbog/ui';

interface PostResultItemProps {
  postItem: any;
}

function PostResultItem({ postItem }: PostResultItemProps) {
  switch (postItem?.multimedia?.[0]?.type) {
    case 'image':
      return (
        <Link href={`${postItem?.profile.user.slug}/post/${postItem?.slug}`}>
          <img
            className="rounded h-44 w-44 object-cover"
            alt={postItem?.multimedia?.[0]?.filename}
            src={postItem?.multimedia?.[0]?.url}
          />
        </Link>
      );
    case 'video':
      return (
        <Link
          className="h-44 w-44 relative"
          href={`${postItem?.profile.user.slug}/post/${postItem?.slug}`}
        >
          <div className="w-full h-full absolute flex items-center justify-center">
            <PlayCircleIcon className="w-12 h-12 group-hover:hidden  text-background-300 z-10 " />
          </div>
          <video
            className="rounded h-44 w-44 object-cover"
            src={postItem?.multimedia?.[0]?.url}
          />
        </Link>
      );
    case 'audio':
      return (
        <Link
          href={`${postItem?.profile.user.slug}/post/${postItem?.slug}`}
          className="h-44 w-44 relative overflow-hidden"
        >
          <div className="w-full h-full absolute flex items-center justify-center">
            <GraphicEqIcon className="w-12 h-12 group-hover:hidden  text-background-300 z-10 " />
          </div>
          <img
            className="rounded object-cover w-full h-full blur"
            alt={postItem?.multimedia?.[0]?.filename}
            src={postItem?.profile?.photo?.url}
          />
        </Link>
      );
    default:
      return null;
  }
}

export default PostResultItem;
