import React from 'react';
import { useTranslation } from 'react-i18next';
import { Category } from '@dinbog/models';

interface CategoryCardProps {
  category: Category;
  isSelected?: boolean;
  manageCategories?;
}

export default function CategoryCard({
  category,
  isSelected = false,
  manageCategories,
}: CategoryCardProps) {
  const { i18n } = useTranslation();
  return (
    <button
      data-id={category?._id}
      onClick={(e) => manageCategories(e)}
      type="button"
      key={category?._id}
      className={`p-2 rounded-lg flex-col items-center gap-2 inline-flex max-w-[108px] ${
        isSelected ? 'bg-background-300' : ''
      }`}
    >
      <img
        alt={category?.image?.fileName}
        className={`w-24 h-24 object-cover ${
          category?.type === 'talent' ? 'rounded-full' : 'rounded-md'
        }`}
        src={category?.image?.url ?? '/images/fallback/category-example.jpg'}
      />
      <div className="flex-col justify-start items-center gap-2 flex">
        <div className="flex-col justify-start items-center flex">
          <div
            className={`text-center ${
              isSelected ? 'text-primary-500' : 'text-text-black'
            } font-semibold leading-normal`}
          >
            {category?.name[i18n?.language ?? 'en']}
          </div>
        </div>
      </div>
    </button>
  );
}
