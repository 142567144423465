import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import Card from '../card';

function QuickActions() {
  const { t } = useTranslation();
  return (
    <Card className="w-full px-4 py-2">
      <Link href="/briefcase/castings" className="">
        <span className="">{t('castings')}</span>
      </Link>
    </Card>
  );
}

export default QuickActions;
