import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { Notification } from '@dinbog/models';
import { PersonItem } from '../shared';
import { useUser } from '../../hooks';
import { getTimeAgo } from '../../lib/getTimeAgo';

dayjs.extend(relativeTime);

interface NotificationCardProps {
  notification: Notification;
}

function NotificationCard({ notification }: NotificationCardProps) {
  const { currentUser: user } = useUser();

  const { i18n } = useTranslation();
  React.useEffect(() => {
    dayjs.locale(i18n?.language ?? 'en');
  }, [i18n?.language]);

  const timeAgo = getTimeAgo(notification?.createdAt, i18n.t);

  return (
    <div className="flex justify-stretch gap-2 items-center">
      {!notification?.seen ? (
        <div className="w-2 h-2 bg-primary-500 rounded-full" />
      ) : null}
      <PersonItem isHorizontal size="sm" user={{ profile: user.profile }}>
        <Link
          href={notification?.url || '/'}
          className="flex flex-col h-fit w-full space-y-1"
        >
          <span className="font-semibold overflow-ellipsis">
            {notification?.title}
          </span>
          <span className="text-sm text-gray-500">{notification?.body}</span>
          <span className="text-xs text-gray-400">{timeAgo}</span>
        </Link>
      </PersonItem>
    </div>
  );
}

export default NotificationCard;
