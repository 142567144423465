import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileStats } from '@dinbog/models';
import Step from './Step';

interface StepsListProps {
  registrationStep: number;
  stats: ProfileStats;
}

export default function StepsList({ registrationStep, stats }: StepsListProps) {
  const { t } = useTranslation();
  const checkStepCompletion = (idx) => registrationStep > idx;
  return (
    <div className="flex flex-col gap-2">
      <Step
        text={t('profile.profileCompletion.steps.personalInfo')}
        completed={checkStepCompletion(1)}
      />
      <Step
        text={t('profile.profileCompletion.steps.specs')}
        completed={checkStepCompletion(2)}
      />
      <Step
        text={t('profile.profileCompletion.steps.book')}
        completed={stats?.books > 0}
      />
      <Step
        text={t('profile.profileCompletion.steps.video')}
        completed={stats?.videos > 0}
      />
      <Step
        text={t('profile.profileCompletion.steps.album')}
        completed={stats?.audios > 0}
      />
      <Step
        text={t('profile.profileCompletion.steps.workReference')}
        completed={stats?.workReferences > 0}
      />
    </div>
  );
}
