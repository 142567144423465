import React from 'react';
import { Input, MultipleSelect, Select, TextArea } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useLocation } from '../../../../hooks';
import { CustomPhoneInput } from '../../../shared';

function BusinessContent({ formValidations, disabled }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
    getValues,
  } = useFormContext();

  const { countries, states, cities } = useLocation(
    watch('currentCountry') ?? '',
    watch('currentState') ?? ''
  );

  return (
    <div className="space-y-8">
      <div className="inputs-container">
        <div className="w-full">
          <Input
            disabled={disabled}
            isRequired
            name="firstName"
            className="w-full"
            defaultValue=""
            label={t('auth.signUp.name')}
            error={errors.firstName?.message as string}
            {...register('firstName', formValidations.firstName)}
          />
        </div>
        <div className="w-full">
          <Input
            disabled={disabled}
            label={t('auth.signUp.wizard.business.url')}
            {...register('websiteUrl', formValidations.websiteUrl)}
            className="w-full"
            error={errors.websiteUrl?.message as string}
            placeholder="https://dinbog.com"
          />
        </div>
      </div>
      <div className="inputs-container">
        <div className="w-full">
          <CustomPhoneInput
            name="phone"
            label={t('auth.signUp.wizard.business.phone')}
            error={errors.phone?.message as string}
            validations={formValidations.phone}
            control={control}
            controlled
          />
        </div>
        <div className="w-full" />
      </div>
      <div className="inputs-container">
        <div className="w-full">
          <Select
            disabled={disabled}
            name="currentCountry"
            className="w-full"
            defaultValue=""
            label={t('auth.signUp.wizard.business.country')}
            error={errors.currentCountry?.message as string}
            {...register('currentCountry', formValidations.currentCountry)}
          >
            <option key={-1} value="" disabled>
              {t('select')}
            </option>
            {countries?.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <Select
            disabled={disabled}
            name="currentState"
            className="w-full"
            defaultValue=""
            label={t('auth.signUp.wizard.business.state')}
            error={errors.currentState?.message as string}
            {...register('currentState', formValidations.currentState)}
          >
            <option key={-1} value="" disabled>
              {t('select')}
            </option>
            {states?.map((hqState) => (
              <option
                key={hqState.isoCode}
                value={hqState.isoCode}
                selected={watch('currentState') === hqState.isoCode}
              >
                {hqState.name}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div className="inputs-container">
        <div className="w-full">
          <Select
            name="currentCity"
            defaultValue=""
            label={t('auth.signUp.wizard.business.city')}
            error={errors.currentCity?.message as string}
            disabled={cities.length <= 0 || disabled}
            {...register('currentCity', formValidations.currentCity)}
          >
            <option key={-1} value="" disabled>
              {t('select')}
            </option>
            {cities?.map((city) => (
              <option
                key={city.name}
                value={city.name}
                selected={watch('currentCity') === city.name}
              >
                {city.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="w-full">
          <MultipleSelect
            className="w-full"
            name="branches"
            defaultSelect={
              getValues('branches')?.map((branchOffice_) => {
                const branchOffice = countries.find(
                  (country) => country.isoCode === branchOffice_
                );
                if (!branchOffice) return null;
                return {
                  _id: branchOffice.isoCode,
                  value: branchOffice.isoCode,
                  name: branchOffice.name,
                };
              }) ?? []
            }
            label={t('auth.signUp.wizard.business.branchOffice')}
            options={
              countries.map((country) => ({
                _id: country.isoCode,
                value: country.isoCode,
                name: country.name,
              })) ?? []
            }
            onChangeSelect={(e) => {
              setValue('branches', e);
            }}
          />
        </div>
      </div>
      <div className="inputs-container">
        <TextArea
          disabled={disabled}
          name="description"
          className="h-36"
          label={t('auth.signUp.wizard.business.description.title')}
          placeholder={t('auth.signUp.wizard.business.description.placeholder')}
          error={errors.description?.message as string}
          {...register('description', formValidations.description)}
        />
      </div>
    </div>
  );
}

export default BusinessContent;
