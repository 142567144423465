import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { Like, Post, Profile } from '@dinbog/models';
import { axios } from '../../api';

export function useCreatePost() {
  const mutation = useMutation<Post, AxiosError, Partial<Post>>(
    async (newPost) => {
      const { data } = await axios.post('/api/posts/v1', newPost);
      return data;
    }
  );
  return mutation;
}

export function useUpdatePost() {
  const mutation = useMutation<Post, AxiosError, Partial<Post>>(
    async (updatedPost) => {
      const { data } = await axios.patch('/api/posts/v1', updatedPost);
      return data;
    }
  );
  return mutation;
}

export function useGetPost(
  filters: { slug: string; token: string },
  options: Omit<
    UseQueryOptions<
      { post: Post; profileInfo: Partial<Profile>; liked: boolean },
      AxiosError,
      { post: Post; profileInfo: Partial<Profile>; liked: boolean },
      ['post']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<
    { post: Post; profileInfo: Partial<Profile>; liked: boolean },
    AxiosError
  >(
    ['post', filters?.slug],
    async () => {
      if (filters?.slug) {
        const { data } = await axios.get<{
          post: Post;
          profileInfo: Partial<Profile>;
          liked: boolean;
        }>(`/api/posts/post-details/v1`, {
          params: { slug: filters?.slug },
          headers: {
            Authorization: `Bearer ${filters.token}`,
          },
        });

        return data;
      }
    },
    options
  );
  return query;
}

export function usePosts(
  filters /* : Partial<Post> */,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['posts']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['posts', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<any>('/api/posts/pagination/v1', {
          params: { ...filters },
        });

        return data;
      }
    },
    options
  );
  return query;
}

export function useGetPostLikes(
  filters,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['postLikes']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['postLikes', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get(`/api/posts/post-likes/v1`, {
          params: { ...filters },
        });
        return data;
      }
    },
    options
  );
  return query;
}

export function useGetTalentPosts(
  filters,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['talentPosts']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['talentPosts', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get('/api/posts/talent/posts/v1', {
          params: { ...filters },
        });
        return data;
      }
    },
    options
  );
  return query;
}

export function useFeed(
  filters: { user: string; perPage: number; page: number },
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['feed']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['feed', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<any>('/api/posts/feed/v1', {
          params: { ...filters },
        });

        return data;
      }
    },
    options
  );
  return query;
}

export function useSharePost() {
  const mutation = useMutation<any, AxiosError, any>(
    async (shareInput: any) => {
      const { data } = await axios.get('/api/communications/share/v1', {
        params: shareInput,
      });
      return data;
    }
  );

  return mutation;
}
