import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ProfileStats } from '@dinbog/models';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

interface CompletionGraphProps {
  stats: ProfileStats;
}

export default function CompletionGraph({ stats }: CompletionGraphProps) {
  const { t } = useTranslation();
  const calculatePercentage = () => {
    /* if (registrationStep === 0) {
      return 0;
    } */
    let completedSteps = 2;
    if (stats?.books > 0) {
      completedSteps += 1;
    }
    if (stats?.audios > 0) {
      completedSteps += 1;
    }
    if (stats?.videos > 0) {
      completedSteps += 1;
    }
    if (stats?.workReferences > 0) {
      completedSteps += 1;
    }
    return Math.floor((completedSteps / 6) * 100);
  };

  // graph data
  const dataComplete = {
    datasets: [
      {
        data: [calculatePercentage(), 100 - calculatePercentage()],
        backgroundColor: ['#097DC1', '#EFF0F3'],
        display: true,
        border: '0px',
        borderColor: 'transparent',
        // circumference: 60 * Math.PI,
        // rotation: 85 * Math.PI,
        borderRadius: [
          { outerStart: 100, outerEnd: 0, innerStart: 100, innerEnd: 0 },
          { outerStart: 0, outerEnd: 100, innerStart: 0, innerEnd: 100 },
        ],
      },
    ],
  };
  return (
    <div className="flex flex-col justify-center font-semibold gap-2">
      <span className="text-xs text-neutral-600 text-center">
        {t('profile.profileCompletion.profileCompletion')}
      </span>
      {/* graph */}
      <div className="w-full flex justify-center">
        <div className="relative w-[88px] h-[88px]">
          <Doughnut
            data={dataComplete}
            options={{
              maintainAspectRatio: false,
              hover: { mode: null },
              radius: '100%',
              cutout: 28,
              plugins: {
                tooltip: {
                  enabled: false,
                },
              },
            }}
            // plugins={[roundedEnd]}
            // className="w-[10rem] h-[10rem]"
          />
          <div
            style={{
              position: 'absolute',
              bottom: '18%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 'semibold',
            }}
          >
            <div>{calculatePercentage()}%</div>
          </div>
        </div>
      </div>
      <span className="text-center">{t('profile.profileCompletion.cta')}</span>
    </div>
  );
}

/* 


const ProgressBarCard = (props: any) => {
  return (<div className="max-w-xs rounded overflow-hidden">
      <div className="px-6 py-4">
        {props.tittleLocation == 'top' && (
          <div className="font-light text-l mb-2  text-[#667085] text-center">
            Pedidos entregados
          </div>
        )}
        <div className="flex flex-col justify-center items-center">
          <div
            className={
              props.className ?? cn({ 'relative w-[200px] h-[200px]': true })
            }
          >
            <Doughnut
              data={dataComplete}
              options={{
                maintainAspectRatio: false,
                hover: { mode: null },
                radius: '100%',
                cutout: props.cutout ?? 75.5,
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                },
                onClick: (evt, element) => {
                  if (element.length > 0) {
                    const ind = element[0].index;
                  }
                },
              }}
              // plugins={[roundedEnd]}
              // className="w-[10rem] h-[10rem]"
            />
            <div
              style={{
                position: 'absolute',
                bottom: props.bottom ?? '2%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                fontSize: props.fontSize ?? '50px',
                fontWeight: 'bolder',
              }}
            >
              <div>75%</div>
            </div>
          </div>
        </div>
        {props.tittleLocation != 'top' && (
          <div className="font-light text-l mb-2  text-[#667085] text-center">
            Pedidos entregados
          </div>
        )}
      </div>
    </div>
  );
};
export default ProgressBarCard; */
