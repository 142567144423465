import React from 'react';
import { useTranslation } from 'react-i18next';
import { ACTIONS_TYPE } from '../talentSignUp/reducer';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { ACTIONS_TYPE as BUSINESS_ACTIONS_TYPE } from '../businessSignUp/reducer';
import { CategorySelection } from '../../shared';

interface SelectCategoryProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  state?: any;
  dispatch?: React.Dispatch<ACTIONS_TYPE | BUSINESS_ACTIONS_TYPE>;
  formsLength: number;
}

export default function SelectCategory({
  currentStep = 1,
  setCurrentStep,
  state,
  dispatch = () => {},
  formsLength,
}: SelectCategoryProps) {
  const { t } = useTranslation();
  const updateMutation = useUpdateProfile();

  return (
    <div>
      <h2 className="font-semibold text-2xl text-center my-8">
        {t('auth.signUp.wizard.title')}
      </h2>
      <div className="gap-y-3 flex flex-col">
        <h3 className="font-semibold text-lg">
          {t('auth.signUp.wizard.talentCategory.title')}
        </h3>
        <p>{t('auth.signUp.wizard.talentCategory.description')}</p>
      </div>
      <CategorySelection
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        state={state}
        dispatch={dispatch}
        type="talent"
        formsLength={formsLength}
        updateMutation={updateMutation}
      />
    </div>
  );
}
