import React from 'react';
import { useRouter } from 'next/router';
import { validateString } from 'avilatek-utils';
import { User } from '@dinbog/models';
import { useUser } from '../../hooks';
import { useGetTalentSpecs } from '../../hooks/api/specifications';
import { usePaginatedCategories } from '../../hooks/api/categories';
import { useGetBlackList } from '../../hooks/api/block';
import { getUserType } from '../../lib/getUserType';
import { SkeletonWrapper } from '../shared';
import {
  AccountForm,
  BlockedProfilesList,
  MembersForm,
  PersonalInfoForm,
  SecurityForm,
  SpecificationsForm,
} from './settingsForms';
import CategoriesForm from './settingsForms/CategoriesForm';
import SettingsSidebar from './SettingsSidebar';

function Settings() {
  const { currentUser: user } = useUser();
  const router = useRouter();
  const userType = getUserType(user?.currentUser);

  const [categoriesPagination, setCategoriesPagination] = React.useState({
    page: 1,
    perPage: 18,
  });

  const [blockedPagination, setBlockedPagination] = React.useState({
    page: 1,
    perPage: 18,
  });

  const {
    data: dataCategories,
    isLoading: isLoadingCategories,
    refetch,
  } = usePaginatedCategories(
    {
      ...categoriesPagination,
      filter: JSON.stringify({
        type: (userType ?? '').replace('fan', 'talent'),
        active: true,
      }),
    },
    {
      enabled: !!user?.currentUser?.user,
    }
  );

  React.useEffect(() => {
    refetch();
  }, [userType]);

  // talent specs for SpecificationForm
  const {
    data: dataSpecs,
    isLoading: isLoadingSpecs,
    refetch: refetchSpecs,
  } = useGetTalentSpecs(
    {
      user: (user?.currentUser?.user as User)?._id ?? '',
    },
    {
      enabled: !!user?.currentUser?.user,
    }
  );

  const {
    data: dataBlackList,
    isLoading: isLoadingBlackList,
    refetch: refetchBlackList,
  } = useGetBlackList(
    {
      user: (user?.currentUser?.user as User)?._id ?? '',
      ...blockedPagination,
    },
    { enabled: !!user?.currentUser?.user }
  );
  const items = React.useMemo(
    () => ({
      profile: <PersonalInfoForm type={userType} />,
      account: (
        <SkeletonWrapper isLoading={!userType}>
          <AccountForm />
        </SkeletonWrapper>
      ),
      categories: (
        <SkeletonWrapper isLoading={!userType}>
          <CategoriesForm
            type={userType}
            data={dataCategories}
            pagination={categoriesPagination}
            refetch={refetch}
            setPagination={setCategoriesPagination}
            isLoading={isLoadingCategories}
            refetchSpecs={refetchSpecs}
          />
        </SkeletonWrapper>
      ),
      ...(['talent'].includes(userType) && {
        specifications: (
          <SkeletonWrapper isLoading={isLoadingSpecs}>
            <SpecificationsForm specifications={dataSpecs} />
          </SkeletonWrapper>
        ),
      }),
      ...(userType === 'business' && {
        administrators: <MembersForm />,
      }),
      security: (
        <SkeletonWrapper isLoading={!userType}>
          <SecurityForm />
        </SkeletonWrapper>
      ),
      saved: <div />,
      'blocked-profiles': (
        <BlockedProfilesList
          data={dataBlackList}
          isLoading={isLoadingBlackList}
          setPagination={setBlockedPagination}
          refetch={refetchBlackList}
        />
      ),
    }),
    [userType, dataSpecs, dataCategories, dataBlackList]
  );

  return (
    <div className="flex sm:gap-16 gap-4 w-full ">
      <SettingsSidebar type={userType} />

      <div className="w-full sm:ml-[264px] ml-[73px]">
        {router.isReady && userType ? items[router.query.tab as string] : null}
      </div>
    </div>
  );
}

export default Settings;
