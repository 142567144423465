import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@dinbog/ui';
import { User } from '@dinbog/models';
import { useNotify, useUser } from '../../../../../hooks';
import { useUnblockUser } from '../../../../../hooks/api/block';

interface UnblockButtonProps {
  user: any;
  refreshUser?: () => void;
}

export default function UnblockButton({
  user,
  refreshUser,
}: UnblockButtonProps) {
  const notify = useNotify();
  const { t } = useTranslation();
  const { currentUser } = useUser();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  // Mutations
  const unblockMutation = useUnblockUser();

  const handleUnblock = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (disabled) return;
    setDisabled(true);
    try {
      const _data = await unblockMutation.mutateAsync({
        from: (currentUser?.currentUser?.user as User)?._id as string,
        to: user?._id,
      });
      if (_data) refreshUser();
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Button
      tooltip={t('profile.relations.actions.unblock')}
      className="btn-secondary"
      onClick={handleUnblock}
    >
      {t('profile.relations.actions.unblock')}
    </Button>
  );
}
