import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteImage from '../DeleteImage';
import { DocumentModel } from '../../../../models';

interface ShowCroppedImageProps {
  image: DocumentModel[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  cropShape: 'round' | 'rect';
}

function ShowCroppedImage({
  image,
  updateURLs,
  setStep,
  cropShape,
}: ShowCroppedImageProps) {
  return (
    <div className="w-full h-auto ">
      <div className="w-full h-36 flex justify-center relative">
        <button
          aria-label="Delete"
          type="button"
          onClick={() => {
            updateURLs([]);
            setStep(0);
          }}
          className={`absolute h-36 ${
            cropShape === 'round' ? 'w-36 rounded-full' : 'w-full rounded'
          } opacity-0 hover:opacity-100 bg-black/40 flex justify-center items-center`}
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="h-6 w-6 text-background-300"
          />
        </button>
        <img
          src={(image[0]?.src as string) ?? ''}
          className={`${
            cropShape === 'round' ? 'rounded-full' : 'rounded'
          } h-full object-cover`}
          alt="cropped"
        />
      </div>
    </div>
  );
}

export default ShowCroppedImage;
