import React from 'react';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CircledIcon } from '../../shared';

function BlockedProfileContent({ me = false }: { me?: boolean }) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full py-14 flex flex-col justify-center items-center gap-[36px]">
      <CircledIcon icon={faBan} />

      <div className="flex flex-col gap-4 justify-center items-center">
        <h1 className=" text-lg font-semibold ">
          {me ? t('profile.block.me') : t('profile.block.them')}
        </h1>
      </div>
    </div>
  );
}

export default BlockedProfileContent;
