import { Multimedia } from '@dinbog/models';

export const ACTIONS = {
  USER_TYPE: 'userType',
  BIRTH_DATE: 'birthDate',
  GENDER: 'gender',
  AGENCY_OR_MANAGER: 'agencyOrManager',
  DESCRIPTION: 'description',
  BIRTH_COUNTRY: 'birthCountry',
  BIRTH_STATE: 'birthState',
  BIRTH_CITY: 'birthCity',
  CURRENT_COUNTRY: 'currentCountry',
  CURRENT_STATE: 'currentState',
  CURRENT_CITY: 'currentCity',
  PHONE: 'phone',
  PROFILE_PICTURE: 'photo',
  COVER: 'cover',
  BOOK_CATEGORY: 'bookCategory',
  BOOK_NAME: 'bookName',
  BOOK_DESCRIPTION: 'bookDescription',
  BOOK_PRIVACY: 'bookPrivacy',
  VIDEO_CATEGORY: 'videoCategory',
  VIDEO_NAME: 'videoName',
  VIDEO_DESCRIPTION: 'videoDescription',
  VIDEO_URL: 'videoUrl',
  VIDEO_PRIVACY: 'videoPrivacy',
  AUDIO_CATEGORY: 'audioCategory',
  AUDIO_NAME: 'audioName',
  AUDIO_DESCRIPTION: 'audioDescription',
  AUDIO_TRACKS: 'audioTracks',
  AUDIO_PRIVACY: 'audioPrivacy',
  WORK_REFERENCE_ROLE: 'workReferenceRole',
  WORK_REFERENCE_FROM: 'workReferenceFrom',
  WORK_REFERENCE_UNTIL: 'workReferenceUntil',
  WORK_REFERENCE_COMPANY: 'workReferenceCompany',
  WORK_REFERENCE_DESCRIPTION: 'workReferenceDescription',
  WORK_REFERENCE_PRIVACY: 'workReferencePrivacy',
  DEFAULT: 'default',
} as const;

export type ACTIONS_TYPE =
  | { type: 'userType'; payload: string } // revisar
  | { type: 'birthDate'; payload: string }
  | { type: 'gender'; payload: string }
  | { type: 'agencyOrManager'; payload: string } // revisar
  | { type: 'description'; payload: string }
  | { type: 'birthCountry'; payload: string }
  | { type: 'birthState'; payload: string }
  | { type: 'birthCity'; payload: string }
  | { type: 'currentCountry'; payload: string } // revisar
  | { type: 'currentState'; payload: string }
  | { type: 'currentCity'; payload: string } // revisar
  | { type: 'phone'; payload: string }
  | { type: 'photo'; payload: Multimedia }
  | { type: 'cover'; payload: Multimedia }
  | { type: 'bookCategory'; payload: string } // revisar
  | { type: 'bookName'; payload: string }
  | { type: 'bookDescription'; payload: string }
  | { type: 'bookPrivacy'; payload: string }
  | { type: 'videoCategory'; payload: string } // revisar
  | { type: 'videoName'; payload: string }
  | { type: 'videoDescription'; payload: string }
  | { type: 'videoUrl'; payload: string }
  | { type: 'videoPrivacy'; payload: string }
  | { type: 'audioCategory'; payload: string } // revisar
  | { type: 'audioName'; payload: string }
  | { type: 'audioDescription'; payload: string }
  | { type: 'audioTracks'; payload: string } // ojo
  | { type: 'audioPrivacy'; payload: string }
  | { type: 'workReferenceRole'; payload: string }
  | { type: 'workReferenceFrom'; payload: string }
  | { type: 'workReferenceUntil'; payload: string }
  | { type: 'workReferenceCompany'; payload: string }
  | { type: 'workReferenceDescription'; payload: string }
  | { type: 'workReferencePrivacy'; payload: string }
  | { type: 'default'; payload: any };

export function reducer(
  state, // : Partial<string> ojo con esto. dentro de Partial debe ir User o algo así. El modelo que guarda la info de la persona
  action: ACTIONS_TYPE
) {
  // : Partial<string> . después del paréntesis del reducer va esto
  switch (action.type) {
    case ACTIONS.USER_TYPE:
      return { ...state, userType: action.payload };
    case ACTIONS.BIRTH_DATE:
      return { ...state, birthDate: action.payload };
    case ACTIONS.GENDER:
      return { ...state, gender: action.payload };
    case ACTIONS.AGENCY_OR_MANAGER:
      return { ...state, agencyOrManager: action.payload };
    case ACTIONS.DESCRIPTION:
      return { ...state, description: action.payload };
    case ACTIONS.BIRTH_COUNTRY:
      return { ...state, birthCountry: action.payload };
    case ACTIONS.BIRTH_STATE:
      return { ...state, birthState: action.payload };
    case ACTIONS.BIRTH_CITY:
      return { ...state, birthCity: action.payload };
    case ACTIONS.CURRENT_COUNTRY:
      return { ...state, currentCountry: action.payload };
    case ACTIONS.CURRENT_STATE:
      return { ...state, currentState: action.payload };
    case ACTIONS.CURRENT_CITY:
      return { ...state, currentCity: action.payload };
    case ACTIONS.PHONE:
      return { ...state, phone: action.payload };
    case ACTIONS.PROFILE_PICTURE:
      return { ...state, photo: action.payload };
    case ACTIONS.COVER:
      return { ...state, cover: action.payload };
    case ACTIONS.BOOK_CATEGORY:
      return { ...state, bookCategory: action.payload };
    case ACTIONS.BOOK_NAME:
      return { ...state, bookName: action.payload };
    case ACTIONS.BOOK_DESCRIPTION:
      return { ...state, bookDescription: action.payload };
    case ACTIONS.BOOK_PRIVACY:
      return { ...state, bookPrivacy: action.payload };
    case ACTIONS.VIDEO_CATEGORY:
      return { ...state, videoCategory: action.payload };
    case ACTIONS.VIDEO_NAME:
      return { ...state, videoName: action.payload };
    case ACTIONS.VIDEO_DESCRIPTION:
      return { ...state, videoDescription: action.payload };
    case ACTIONS.VIDEO_URL:
      return { ...state, videoUrl: action.payload };
    case ACTIONS.VIDEO_PRIVACY:
      return { ...state, videoPrivacy: action.payload };
    case ACTIONS.AUDIO_CATEGORY:
      return { ...state, audioCategory: action.payload };
    case ACTIONS.AUDIO_NAME:
      return { ...state, audioName: action.payload };
    case ACTIONS.AUDIO_DESCRIPTION:
      return { ...state, audioDescription: action.payload };
    case ACTIONS.AUDIO_TRACKS:
      return { ...state, audioTracks: action.payload };
    case ACTIONS.AUDIO_PRIVACY:
      return { ...state, audioPrivacy: action.payload };
    case ACTIONS.WORK_REFERENCE_ROLE:
      return { ...state, workReferenceRole: action.payload };
    case ACTIONS.WORK_REFERENCE_FROM:
      return { ...state, workReferenceFrom: action.payload };
    case ACTIONS.WORK_REFERENCE_UNTIL:
      return { ...state, workReferenceUntil: action.payload };
    case ACTIONS.WORK_REFERENCE_COMPANY:
      return { ...state, workReferenceCompany: action.payload };
    case ACTIONS.WORK_REFERENCE_DESCRIPTION:
      return { ...state, workReferenceDescription: action.payload };
    case ACTIONS.WORK_REFERENCE_PRIVACY:
      return { ...state, wokrReferencePrivacy: action.payload };
    case ACTIONS.DEFAULT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
