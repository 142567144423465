import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Category, Video } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { DocumentModel } from '../../../models';
import { useCreateVideo, useUpdateVideo } from '../../../hooks/api/videos';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../utils/formatDocumentModel';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { VideoFormContent } from '../../shared';
import FormButtons from '../shared/FormButtons';
import { uploadFile } from '../../../lib/uploadFile';
import { useSignS3 } from '../../../hooks/api/s3';

interface UploadVideoProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  data?: Video;
  formsLength: number;
  categories?: Category[];
}

export default function UploadVideo({
  currentStep = 4,
  setCurrentStep,
  data = null,
  formsLength,
  categories,
}: UploadVideoProps) {
  const { currentUser: user } = useUser();
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [media, setMedia] = React.useState<DocumentModel[]>(
    data?.cover ? multimediaToDocument([data?.cover]) : []
  );
  const updateURLs = React.useCallback(setMedia, [setMedia]);

  const methods = useForm<Video>({
    defaultValues: {
      title: data?.title ?? '',
      description: data?.description ?? '',
      privacy: data?.privacy ?? null,
      categories: data?.categories ?? [],
      multimedia: data?.multimedia ?? null,
    },
  });

  // queries/mutations
  const queryClient = useQueryClient();
  const createVideoMutation = useCreateVideo();
  const updateVideoMutation = useUpdateVideo();
  const updateProfileMutation = useUpdateProfile();
  const signS3 = useSignS3();

  const onSubmit: SubmitHandler<Partial<Video>> = async (
    _data: Partial<Video>
  ) => {
    try {
      if (disabled) return;
      setDisabled(true);
      let coverImg = { url: '', src: '' };

      if (media?.length > 0 && media[0]?.src !== data?.cover?.url) {
        coverImg = await uploadFile(
          media[0],
          setDisabled,
          updateURLs,
          signS3,
          notify,
          t
        );
      } else {
        coverImg.url = data?.cover?.url;
      }
      const image = media?.length > 0 ? documentToMedia(media) : null;
      const promises = [
        updateProfileMutation.mutateAsync({
          user: user?._id as string,
          // update registration step
          registrationStep: '5',
        }),
        (data ? updateVideoMutation : createVideoMutation).mutateAsync({
          _id: data?._id ?? undefined,
          title: _data.title,
          user: user?._id,
          description: _data.description,
          privacy: _data.privacy,
          categories: _data?.categories,
          ...(media?.length > 0
            ? {
                cover: {
                  ...image[0],
                  type: 'image',
                  url: coverImg.src || coverImg.url,
                },
              }
            : { cover: undefined }),
          multimedia: {
            url: _data.multimedia?.url,
            type: 'video',
            fileName: _data.title.replaceAll(' ', '_'),
          },
        }),
      ];

      await Promise.all(promises).then(() => {
        queryClient.refetchQueries(['video', { user: user?._id }]);
        setCurrentStep(currentStep + 1);
      });
    } catch (err) {
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onSkip = async () => {
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);

      await updateProfileMutation.mutateAsync({
        user: user?._id as string,
        // update registration step
        registrationStep: '5',
      });
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods?.handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <div className="space-y-3 mb-2">
            <h3 className="font-semibold text-lg">
              {t('auth.signUp.wizard.video.title')}
            </h3>
            <p>{t('auth.signUp.wizard.video.subtitle')}</p>
          </div>
          <VideoFormContent
            media={media}
            disabled={disabled}
            updateURLs={updateURLs}
            categoriesDefault={categories
              ?.map((cat) => ({
                name: cat?.name[i18n?.language ?? 'en'],
                _id: cat?._id,
              }))
              .filter((cat) =>
                (data?.categories as string[])?.includes(cat?._id)
              )}
            categories={categories}
          />
        </div>
        <FormButtons
          disabled={disabled}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={formsLength}
          onSkip={onSkip}
        />
      </form>
    </FormProvider>
  );
}
