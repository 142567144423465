/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { faCameraAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Multimedia, User } from '@dinbog/models';
import { DocumentModel } from '../../../../models';
import { useSignS3 } from '../../../../hooks/api/s3';
import { useUpdateProfile } from '../../../../hooks/api/profiles';
import {
  documentToMedia,
  multimediaToDocument,
} from '../../../../utils/formatDocumentModel';
import { CropImageModal, FullPhoto } from '../../../shared';
import { useNotify, useUser } from '../../../../hooks';
import { uploadFile } from '../../../../lib/uploadFile';
import { useVerifyPhoto } from '../../../../hooks/api/users';

interface ProfilePictureProps {
  profilePicture?: Multimedia;
  type?: 'person' | 'business';
}

function ProfilePicture({
  profilePicture,
  type = 'person',
}: ProfilePictureProps) {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const router = useRouter();
  const { slug } = router.query;
  const notify = useNotify();
  const { t } = useTranslation();
  const [photoModalIsOpen, setPhotoModalIsOpen] = React.useState(false);
  const [image, setImage] = React.useState<DocumentModel[]>(
    profilePicture ? multimediaToDocument([profilePicture]) : []
  );
  const [selectedPhoto, setSelectedPhoto] = React.useState<Multimedia | null>(
    null
  );
  const updateURLs = React.useCallback(setImage, [setImage]);
  const [uploading, setUploading] = React.useState(false);
  const createMutation = useSignS3();
  const updateMutation = useUpdateProfile();
  const queryClient = useQueryClient();

  const cropProps = {
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
    size: { width: 100, height: 100 },
  };

  const {
    data: dataVerifyPhoto,
    isLoading: loadingVerifyPhoto,
    error: errorVerifyPhoto,
    refetch: refetchVerifyPhoto,
  } = useVerifyPhoto(
    {
      key: image[0]?.src?.toString().split('.com/')[1],
    },
    {
      enabled:
        user?.currentUser?.type !== 'business' &&
        image?.length > 0 &&
        (image[0]?.src as string)?.startsWith('https://') &&
        image[0]?.src !== profilePicture?.url,
      retry: false,
    }
  );

  const verifyPhoto = async () => {
    try {
      const response = await refetchVerifyPhoto();
      if (response.status === 'error' && errorVerifyPhoto) {
        return notify((errorVerifyPhoto.response.data as any).message, 'error');
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  const updatePicture = async () => {
    try {
      const verifyRefectPhoto = await refetchVerifyPhoto();
      if (
        (!verifyRefectPhoto.error && verifyRefectPhoto) ||
        user?.currentUser?.type === 'business'
      ) {
        const photo = image?.length > 0 ? documentToMedia(image) : null;
        // update profile picture
        const data = await updateMutation.mutateAsync({
          user: (user?.currentUser?.user as User)?._id,
          ...(image?.length > 0
            ? { photo: { ...photo[0], type: 'image' } }
            : { photo: profilePicture }),
        });
        if (!data) {
          return notify(t('auth.signUp.notifs.failure'), 'error');
        }
        setUser({
          _id: user?._id,
          profile: user?.profile,
          token: user?.token,
          currentUser: data,
          accounts: user?.accounts,
        });
        queryClient.refetchQueries(['talentPosts']);
        setPhotoModalIsOpen(false);
      } else {
        setImage(multimediaToDocument([profilePicture]));
        return notify(
          (verifyRefectPhoto.error.response.data as any).message,
          'error'
        );
      }
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
      setImage(multimediaToDocument([profilePicture]));
    }
  };

  const onSubmit = async () => {
    // upload to s3
    await uploadFile(
      image[0],
      setUploading,
      updateURLs,
      createMutation,
      notify,
      t
    );
  };

  React.useEffect(() => {
    if (
      image?.length > 0 &&
      (image[0]?.src as string)?.startsWith('https://') &&
      image[0]?.src !== profilePicture?.url
    ) {
      try {
        if (user?.currentUser?.type !== 'business') verifyPhoto();
        else updatePicture();
      } catch (err) {
        notify(err?.response?.data?.message || t('error'), 'error');
      }
    }

    if (
      image[0]?.src?.toString().split('.com/')[1] && // if the image is from s3
      !slug && // if the user is in his own profile
      image[0]?.src !== profilePicture?.url // if the image is different from the current profile picture
    ) {
      updatePicture(); // then update the profile picture
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <>
      <div className="relative h-full shrink-0">
        <button
          type="button"
          className="w-full max-h-[500px] rounded"
          onClick={() => setSelectedPhoto(profilePicture)}
        >
          <img
            src={
              profilePicture?.url ??
              (user?.currentUser?.type === 'business'
                ? '/images/fallback/business.jpg'
                : '/images/fallback/user.png')
            }
            alt="user"
            className={`sm:h-36 lg:h-44 h-28 sm:w-36 lg:w-44 w-28 shrink-0 ${
              type === 'person' ? 'rounded-full' : 'rounded-lg'
            } object-cover object-center border-4 shadow border-background-50`}
          />
        </button>
        {selectedPhoto?.url === profilePicture?.url ? (
          <FullPhoto photo={profilePicture} setSelected={setSelectedPhoto} />
        ) : null}
        {router?.pathname === '/profile' ? (
          <button
            type="button"
            className="absolute right-0 bottom-0 z-50 bg-neutral-400 rounded-full opacity-75 h-9 w-9 flex justify-center items-center"
            onClick={() => setPhotoModalIsOpen(true)}
          >
            <FontAwesomeIcon
              icon={faCameraAlt}
              className="w-5 h-5 mx-auto text-text-white "
            />
          </button>
        ) : null}
      </div>
      <CropImageModal
        title={t('profile.changePictureModal.title')}
        isOpen={photoModalIsOpen}
        setIsOpen={setPhotoModalIsOpen}
        image={image}
        updateURLs={updateURLs}
        defaultCrop={cropProps}
        onSubmit={onSubmit}
        cropShape="round"
        isPfp={user?.currentUser?.type !== 'business'}
      />
      {/* revisar crop shape */}
    </>
  );
}

export default ProfilePicture;
