import React from 'react';
import { Button } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import ChangeLanguageSelect from '../changeLanguageDropdown/ChangeLanguageDropdown';

function OnRegisterOptions() {
  const { t } = useTranslation();

  return (
    <div className="ml-auto my-auto flex space-x-5 py-5">
      <Button tooltip={t('navbar.onRegister.button')} className="btn-secondary">
        {t('navbar.onRegister.button')}
      </Button>
      <ChangeLanguageSelect />
    </div>
  );
}

export default OnRegisterOptions;
