import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from '../shared';
import { useRequests } from '../../context/RequestContext';
import RequestListSection from './RequestListSection';
import NotificationListSection from '../notification/NotificationListSection';
import SuggestionSection from '../suggestions/SuggestionSection';

interface RequestContainerProps {
  children: React.ReactNode;
}

function RequestContainer({ children }: RequestContainerProps) {
  const { t } = useTranslation();
  const {
    notificationsData,
    tabSelected,
    setTabSelected,
    count,
    requestsData,
  } = useRequests();

  const items = React.useMemo(
    () => ({
      notification: {
        title: `${t('notifications.tab')}`,
        number: notificationsData?.count ?? 0,
        itemKey: 'notifications',
      },
      received: {
        title: `${t('requests.received')}`,
        number: requestsData?.count ?? 0,
        itemKey: 'received',
      },
      sent: {
        title: `${t('requests.sent')}`,
        number: count?.sent ?? 0,
        itemKey: 'sent',
      },
    }),
    [count, notificationsData?.count, requestsData?.count]
  );

  const suggestionSection = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === SuggestionSection) {
      return child;
    }
  });

  const otherSections = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type !== SuggestionSection) {
      return child;
    }
  });

  return (
    <div className="flex flex-row justify-center gap-16">
      <div className="flex flex-col gap-6">
        <h1 className="font-semibold text-xl">{t('notifications.title')}</h1>
        {/* tabs */}
        <Tab
          items={Object.values(items)}
          currentItem={tabSelected}
          setCurrentItem={setTabSelected}
          isQueryParam
        />
        {otherSections}
      </div>
      {suggestionSection}
    </div>
  );
}

export default Object.assign(RequestContainer, {
  RequestListSection,
  NotificationListSection,
  SuggestionSection,
});
