import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Category } from '@dinbog/models';
import { axios } from '../../api';

export function useCategories(
  filters: Partial<Category>,
  categoryFrom: 'users' | 'portfolios', // Categories from ms-users or ms-portfolio
  options: Omit<
    UseQueryOptions<Category[], AxiosError, Category[], ['categories']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Category[], AxiosError>(
    ['categories', filters.type],
    async () => {
      const { data } = await axios.get<Category[]>(
        `/api/${categoryFrom}/categories/v1`,
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function usePaginatedCategories(
  filters: any,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['categoriesPaginated']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['categoriesPaginated', filters.type],
    async () => {
      const { data } = await axios.get<any>(
        '/api/users/categories/pagination/v1',
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}
