import React from 'react';
import {
  Business,
  BusinessUser,
  BusinessUserRole,
  Pagination,
  Profile,
  StatusEnum,
  User,
} from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { FormButtons } from '../../shared';
import { useNotify, useUser } from '../../../../hooks';
import { useUpdateMembers } from '../../../../hooks/api/businesses';
import { MembersFormContent } from '../../../shared';
import { useUpdateProfile } from '../../../../hooks/api/profiles';

interface AddMembersFormProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleCompleteSignUp: () => void;
  formsLength: number;
}

function AddMembersForm({
  currentStep = 3,
  setCurrentStep,
  handleCompleteSignUp,
  formsLength,
}: AddMembersFormProps) {
  const { t } = useTranslation();
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);

  const queryClient = useQueryClient();
  const updateMembersMutation = useUpdateMembers();
  const updateMutation = useUpdateProfile();

  const [members, setMembers] = React.useState<
    (User & {
      profile?: Profile;
      business?: Business;
      role?: BusinessUserRole;
      isNew?: boolean;
      status?: StatusEnum;
    })[]
  >([]);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);
      const promises = [
        // add admins/editors
        updateMembersMutation.mutateAsync({
          token: user?.token,
          business: (user?.currentUser?.user as User)?._id as string,
          members: [
            ...members
              .filter((member) => member?.isNew) // filter to add only the ones that were not members before
              .map((user_) => ({
                user: user_?._id,
                role: user_?.role,
                email: user_?.email,
                action: 'add',
              })),
          ],
        }),
        updateMutation.mutateAsync({
          user: (user?.currentUser?.user as User)?._id,
          registrationStep: '3',
        }),
      ];

      await Promise.all(promises).then(() => {
        queryClient.refetchQueries([
          'businessMembers',
          { user: (user?.currentUser?.user as User)?._id },
        ]);
      });
      setUser({
        ...user,
        currentUser: { ...user?.currentUser, registrationStep: '3' },
      });
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        handleCompleteSignUp();
      }
    }
  };

  const onSkip = async () => {
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);

      await updateMutation.mutateAsync({
        user: (user?.currentUser?.user as User)?._id,
        registrationStep: '3',
      });
      setUser({
        ...user,
        currentUser: { ...user?.currentUser, registrationStep: '3' },
      });
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        handleCompleteSignUp();
      }
    }
  };

  return (
    <form className="flex flex-col gap-10" method="post" onSubmit={onSubmit}>
      <div className="space-y-10">
        <div>
          <h3 className="font-semibold text-lg ">
            {t('auth.signUp.wizard.selectAdmin.title')}
          </h3>
          <p>{t('auth.signUp.wizard.selectAdmin.titleDescription')}</p>
        </div>
        <MembersFormContent members={members} setMembers={setMembers} />
      </div>
      <FormButtons
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formsLength={formsLength}
        onSkip={onSkip}
      />
    </form>
  );
}

export default AddMembersForm;
