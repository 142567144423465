import React from 'react';
import Link from 'next/link';
import { useUser } from '../../../hooks';
import ProfileCompletionCard from '../../profile/shared/profileInfoCard/profileCompletion/ProfileCompletionCard';
import QuickActions from './QuickActions';
import ProfileInfoCardPreview from './ProfileInfoCardPreview';

function FeedLeftSide({ sticky = false }) {
  const { currentUser: user } = useUser();
  return (
    <div
      className={` min-w-[225px] lg:flex flex-col hidden gap-4 h-full ${
        sticky && ' sticky top-[112px]'
      }`}
    >
      <Link href="/profile">
        <ProfileInfoCardPreview user={user} />
      </Link>
      {user?.currentUser?.type === 'person' &&
      user?.currentUser?.categories?.length > 0 &&
      user?.currentUser?.registrationStep !== '7' ? (
        <ProfileCompletionCard
          placement="feed"
          padding="p-6"
          registrationStep={Number(user?.currentUser?.registrationStep) ?? 0}
          stats={user?.currentUser?.stats}
        />
      ) : null}
      {/* <CastingsCard /> */}
      <QuickActions />
    </div>
  );
}

export default FeedLeftSide;
