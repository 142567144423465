import React from 'react';

interface ContextLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

function ContentLayout({ children }: ContextLayoutProps) {
  return <div className="flex w-full flex-wrap lg:flex-nowrap">{children}</div>;
}

export default ContentLayout;
