import React from 'react';
import { MultipleSelect, Select } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { languages } from '../../../../lib/languages';

function LanguagePreferencesFormContent({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();

  const formValidations = {
    languages: {
      // required: t('required'),
    },
    appLanguage: {
      required: t('required'),
    },
  };

  React.useEffect(() => {
    if (watch('appLanguage')) {
      i18n.changeLanguage(watch('appLanguage'));
      localStorage?.setItem('dinbog.lang', watch('appLanguage'));
    }
  }, [watch('appLanguage')]);

  return (
    <div className="inputs-container">
      <div className="w-full">
        <MultipleSelect
          disabled={disabled}
          options={languages?.map((lang) => ({
            name: lang?.name,
            _id: lang?.lang,
          }))}
          defaultSelect={
            getValues('languages')?.map((language_) => {
              const language = languages.find(
                (_language) => _language.lang === language_
              );
              if (!language) return null;
              return {
                _id: language.lang,
                value: language.name,
                name: language.name,
              };
            }) ?? []
          }
          label={t('auth.signUp.wizard.personal.languages') ?? ''}
          onChangeSelect={(selected) => {
            setValue(
              'languages',
              selected.map((lang) => lang._id)
            );
          }}
          /* defaultSelect={categoriesDefault} */
          {...register('languages')}
        />
      </div>
      <div className="w-full">
        <Select
          disabled={disabled}
          isRequired
          {...register('appLanguage', formValidations.appLanguage)}
          error={errors.appLanguage?.message as string}
          className="w-full"
          label={t('settings.appLanguage.title') ?? ''}
        >
          <option key={-1} value="" disabled>
            {t('settings.appLanguage.default')}
          </option>
          {[
            { lang: 'en', name: 'English' },
            {
              lang: 'es',
              name: 'Español',
            },
          ]?.map((language, i) => (
            <option key={`${i + 1}-${language?.lang}`} value={language?.lang}>
              {language?.name}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default LanguagePreferencesFormContent;
