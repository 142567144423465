import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button } from '@dinbog/ui';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { InfoWithIcon, SkeletonWrapper, Tab } from '../shared';
import {
  BusinessFilters,
  PortfolioFilters,
  TalentFilters,
} from './searchFilters';
import LocationFilters from './searchFilters/LocationFilters';
import SearchResults from './SearchResults';
import CastingFilters from './searchFilters/CastingFilters';
import { useSearchContext } from '../../context';
import { useCategorySelect } from '../../context/CategorySelectContext';

export default function Search({
  setFiltersData,

  filtersData,
}: {
  setFiltersData;

  filtersData;
}) {
  const router = useRouter();
  const methods = useForm<any>();
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState([]);
  const { searchData, loadingSearch } = useSearchContext();
  const { setSelectedLabel } = useCategorySelect();

  const onSubmit = (e) => {
    const {
      country,
      countryState,
      subcategories,
      categories,
      state,
      ...otherProps
    } = methods.getValues();

    const filteredSpecs = Object.fromEntries(
      Object.entries(otherProps).filter(
        ([__, value]) =>
          value !== undefined &&
          (typeof value === 'boolean' || value) &&
          !(Array.isArray(value) && value.length === 0)
      )
    );

    const specificationArray = [].concat(
      ...Object.entries(filteredSpecs).map(([tag, value]) =>
        Array.isArray(value)
          ? value.map((item) => ({ tag, value: item }))
          : { tag, value }
      )
    );

    e.preventDefault();
    let values = {};
    filters?.forEach((field) => {
      const newValue = (
        document.getElementsByName(field?.name) as NodeListOf<HTMLInputElement>
      )?.[0]?.value;
      values = {
        ...values,
        [field?.name === 'countryState' ? 'state' : field?.name]:
          newValue !== '' ? newValue : undefined,
      };
    });

    if (specificationArray.length > 0) {
      values = {
        ...values,
        specifications: specificationArray,
      };
    }

    setFiltersData(values);
  };

  const resetFilters = () => {
    setFiltersData({});
    methods.reset();
    setSelectedLabel(null);
    filters?.forEach((field) => {
      const elements = document.getElementsByName(
        field?.name
      ) as NodeListOf<HTMLInputElement>;
      if (elements.length > 0) {
        elements[0].value = '';
      }
    });
  };

  // tabs
  const [tabSelected, setTabSelected] = React.useState('talents');

  React.useEffect(() => {
    setSelectedLabel(null);
  }, [tabSelected]);

  const items = React.useMemo(
    () => ({
      talents: {
        title: 'Talents',
        itemKey: 'talents',
        component: <TalentFilters filters={filters} setFilters={setFilters} />,
      },
      businesses: {
        title: 'Businesses',
        itemKey: 'businesses',
        component: (
          <BusinessFilters filters={filters} setFilters={setFilters} />
        ),
      },
      portfolios: {
        title: 'Portfolio',
        itemKey: 'portfolios',
        component: (
          <PortfolioFilters filters={filters} setFilters={setFilters} />
        ),
      },
      posts: {
        title: 'Posts',
        itemKey: 'posts',
        component: (
          <LocationFilters filters={filters} setFilters={setFilters} />
        ),
      },
      events: {
        title: 'Castings',
        itemKey: 'events',
        component: <CastingFilters filters={filters} setFilters={setFilters} />,
      },
    }),
    [filters]
  );

  // reset filters data when tab changes
  React.useEffect(() => {
    methods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query?.tab]);

  return (
    <div className="flex flex-col w-full gap-6">
      <FormProvider {...methods}>
        {/* tabs */}
        <Tab
          items={Object.values(items)}
          currentItem={tabSelected}
          setCurrentItem={setTabSelected}
          isQueryParam
        />
        <h1 className="font-semibold text-xl">
          {t('searchPage.resultsFor')}: {router?.query?.q}
        </h1>
        <div className="flex md:flex-row flex-col w-full items-start gap-12">
          <form
            method="post"
            onSubmit={onSubmit}
            className="rounded shadow p-6 xl:w-1/4 md:w-fit w-full bg-background-50 flex flex-col gap-6"
          >
            {router.isReady ? items[tabSelected]?.component : null}
            <div className="flex justify-between w-full">
              <Button
                tooltip={t('searchPage.reset')}
                onClick={() => resetFilters()}
                className="text-primary-500 h-fit py-3 px-9 w-full"
                type="button"
              >
                {t('searchPage.reset')}
              </Button>
              <Button
                tooltip={t('search')}
                className="btn-primary h-fit py-3 px-9 w-full"
                type="submit"
              >
                {t('search')}
              </Button>
            </div>
          </form>
          <SkeletonWrapper isLoading={loadingSearch} className="w-full">
            {searchData?.count > 0 ? (
              <SearchResults filtersData={filtersData} />
            ) : (
              <div className="w-full p-16 flex items-center justify-center">
                <InfoWithIcon
                  title={t('emptyStates.noResults.title')}
                  text={t('emptyStates.noResults.text')}
                  icon={faSearch}
                />
              </div>
            )}
          </SkeletonWrapper>
        </div>
      </FormProvider>
    </div>
  );
}
