import React from 'react';
import { Pagination } from '@dinbog/models';
import Post from '../shared/post/Post';
import { PostWithProfile } from '../../models/auxModels';

interface PostListProps {
  posts: PostWithProfile[];
  onSuccessLike?: () => void;
}

export default function PostList({ posts, onSuccessLike }: PostListProps) {
  return (
    <div className="flex flex-col gap-6 w-full">
      {posts?.map((post) => (
        <Post
          key={post._id}
          content={post}
          contentType="post"
          owner={post?.profile}
          onSuccessLike={onSuccessLike}
        />
      ))}
    </div>
  );
}
