import React from 'react';
import { Album, Book, Category, Video, VideoStats } from '@dinbog/models';
import { PlayCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faComment } from '@fortawesome/free-solid-svg-icons';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

interface MediaCardPreviewProps {
  item: Video | Book | Album;
  type: string;
  handleSelectItem: (item: Video | Book | Album) => void;
}

const getMediaSize = (type): string => {
  switch (type) {
    case 'video':
      return ' w-[200px] h-[144px]';
    case 'audio':
      return ' w-[200px] h-[200px]';
    case 'book':
      return ' w-[200px] h-[200px]';

    default:
      return '';
  }
};

export default function MediaCardPreview({
  item,
  type,
  handleSelectItem,
}: MediaCardPreviewProps) {
  const { i18n } = useTranslation();
  const categories = ((item as Album | Video)?.categories as Category[])?.slice(
    0,
    2
  ); // max two categories for preview

  return (
    <div className="flex flex-col gap-3 mx-auto sm:mx-0">
      <div className="w-full flex justify-between gap-2 items-center">
        <p className="text-neutral-500 text-xs">
          {dayjs(item?.createdAt).format('ll')}
        </p>
        <p className="text-xs text-[#097DC1] bg-[#E7F2F9] py-1 px-3 rounded-[3px] max-w-full break-words ">
          {categories
            ?.map((category) => (category?.name ?? {})[i18n.language ?? 'en'])
            .join(', ')}
          {((item as Album | Video)?.categories as Category[])?.length > 2 &&
            '...'}
        </p>
      </div>
      <button
        className=" relative rounded-md overflow-hidden "
        type="button"
        onClick={(e) => {
          e.preventDefault();
          handleSelectItem(item);
        }}
        key={item?._id}
      >
        <div className=" group absolute w-full h-full flex justify-center items-center">
          <div className=" group-hover:flex flex-row items-center justify-center gap-x-3  bg-black bg-opacity-40 active:bg-opacity-30 hidden opacity-100 text-text-white h-full w-full group-hover:z-10">
            <div className="flex flex-row items-center gap-x-1">
              <FontAwesomeIcon icon={faThumbsUp} className="w-4 h-4" />
              <span className="">{(item?.stats as VideoStats)?.likes}</span>
            </div>
            <div className="flex flex-row items-center gap-x-1">
              <FontAwesomeIcon icon={faComment} className="w-4 h-4" />
              <span className="">{(item?.stats as VideoStats)?.comments}</span>
            </div>
          </div>
          {type === 'video' && (
            <PlayCircleIcon className="w-10 h-10 group-hover:hidden  text-[#E7F2F9] z-10 " />
          )}
        </div>

        <div
          className={`${getMediaSize(
            type
          )} object-cover align-middle bg-gray-300`}
        >
          {item?.cover?.url ? (
            <img
              src={item?.cover?.url}
              alt={item?.title ?? ''}
              onError={(e) => {
                e.currentTarget.src = '';
              }}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full object-cover" />
          )}
        </div>
      </button>
      <p>{item?.title ?? ''}</p>
    </div>
  );
}
