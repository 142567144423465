import React from 'react';
import { Album, Track } from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import TrackPreview from './TrackPreview';
import TrackDetail from './TrackDetail';

function AudioContent({
  album,
  ownerSlug,
  onSuccessLike,
}: {
  album: Album;
  ownerSlug: string;
  onSuccessLike?: () => void;
}) {
  const { t } = useTranslation();
  const maxChars = 200; // max characters to show in the description
  const [showFullDescription, setShowFullDescription] = React.useState(false);
  const [tracksPlayingState, setTracksPlayingState] = React.useState<
    {
      track: Track;
      playing: boolean;
      selected: boolean;
      currentTime: number;
    }[]
  >(
    (album?.tracks as Track[])?.map((track) => ({
      track,
      playing: false,
      selected: false,
      currentTime: 0,
    }))
  );

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const truncatedContent = showFullDescription
    ? album?.description
    : album?.description?.slice(0, maxChars);

  React.useEffect(() => {
    setTracksPlayingState((prev) =>
      (album?.tracks as Track[])?.map((track, ii) => ({
        playing: false,
        selected: false,
        currentTime: 0,
        ...prev[ii],
        track,
      }))
    );
  }, [album?.tracks]);

  return (
    <div className="pb-6 mb-6 b border-b-[1px] border-neutral-200">
      {/* album cover, description and tracks */}
      <div className="w-full flex gap-6 py-6 flex-col md:flex-row items-center justify-center md:items-start">
        {/* left side (album cover and description) */}
        <div className=" flex flex-col gap-[10px] ">
          {/* album cover */}
          <img
            className="w-[200px] h-[200px] object-cover rounded-md"
            src={album?.cover?.url}
            alt={album?.title}
          />
          {/* album description */}
          <div className="description w-[200px] text-neutral-500  text-justify">
            {truncatedContent}
            {album?.description.length > maxChars && (
              <>
                {!showFullDescription ? '... ' : ' '}
                <button
                  type="button"
                  className="text-blue-500 cursor-pointer"
                  onClick={toggleDescription}
                >
                  {showFullDescription ? t('readLess') : t('readMore')}
                </button>
              </>
            )}
          </div>
        </div>
        {/* right side (tracks) */}
        <div className="w-full max-h-[350px] overflow-y-auto">
          {album?.tracks?.map((track: Track, idx) => (
            <TrackPreview
              key={idx + 1}
              track={track}
              idx={idx + 1}
              isPlaying={
                tracksPlayingState.find((tr) => tr.track._id === track?._id)
                  ?.playing
              }
              handleChangeState={(track_, isPlaying_) => {
                setTracksPlayingState(
                  tracksPlayingState.map((tr) => {
                    if (tr.track._id === track_._id) {
                      return {
                        ...tr,
                        playing: isPlaying_,
                        selected: true,
                      };
                    }
                    return {
                      ...tr,
                      playing: false,
                      selected: false,
                    };
                  })
                );
              }}
            />
          ))}
        </div>
      </div>
      {/* playing track */}
      {tracksPlayingState.length > 0 && (
        <TrackDetail
          track={
            tracksPlayingState?.find((tr) => tr.playing || tr.selected) ??
            tracksPlayingState[0]
          }
          ownerSlug={ownerSlug}
          setTracksPlayingState={setTracksPlayingState}
          album={album}
          onSuccessLikeAlbum={onSuccessLike}
        />
      )}
    </div>
  );
}

export default AudioContent;
