import React from 'react';
import { CheckFullIcon } from '@dinbog/ui';

interface ProgressBarProps {
  currentStep: number;
  steps: number;
}

export default function ProgressBar({
  currentStep = 1,
  steps,
}: ProgressBarProps) {
  return (
    <div className="py-4 flex justify-center items-center w-full">
      <div className="flex flex-row justify-center gap-2">
        {new Array(steps).fill(0)?.map((_, idx) => (
          <div key={idx} className="flex flex-row items-center gap-2">
            {currentStep > idx + 1 ? (
              <CheckFullIcon className="xl:h-14 xl:w-14 lg:h-10 lg:w-10 h-8 w-8 text-primary-500" />
            ) : (
              <div
                className={`rounded-full xl:h-14 xl:w-14 lg:w-10 lg:h-10 h-8 w-8 flex flex-row justify-center items-center font-semibold ${
                  currentStep < idx + 1
                    ? ' bg-text-white border-neutral-300 border-2 text-neutral-300'
                    : ' btn-primary'
                }`}
              >
                {idx + 1}
              </div>
            )}
            {idx + 1 < steps ? (
              <div className=" h-0.5 xl:w-14 lg:w-10 w-8 bg-neutral-300" />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
