import React from 'react';
import { VacantSpecification } from '@dinbog/models';
import { TagsInput } from '@dinbog/ui';

function SpecificationTagsInput({
  setSpecifications,
  specificationId,
  label,
  defaultTags,
}: {
  setSpecifications: React.Dispatch<
    React.SetStateAction<VacantSpecification[]>
  >;
  specificationId: string;
  label: string;
  defaultTags?: string[];
}) {
  const [tags, setTags] = React.useState<{ text: string; id: string }[]>(
    defaultTags.map((val, ii) => ({
      text: val,
      id: `${ii}`,
    })) ?? []
  );

  React.useEffect(() => {
    setSpecifications((prev) =>
      prev.map((spec) => ({
        ...spec,
        ...(spec._id === specificationId
          ? { multipleValues: tags.map((tag) => tag.text) }
          : {}),
      }))
    );
  }, [tags]);

  return (
    <label className="block w-full">
      <span className="text-sm font-semibold mb-2 ">{label}</span>
      <TagsInput tags={tags} updateTags={setTags} />
    </label>
  );
}

export default SpecificationTagsInput;
