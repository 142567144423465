import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@dinbog/ui';
import { Crop, DocumentModel } from '../../../../models';
import { useCropImage } from '../../../../hooks';
import CropArea from '../../../document/cropDocument/CropArea';

interface CropImageProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  image: DocumentModel[];
  updateURLs: React.Dispatch<React.SetStateAction<DocumentModel[]>>;
  cropProps: {
    defaultCrop: Crop;
    img: DocumentModel;
  };
  cropShape?: 'round' | 'rect';
}

function CropImage({
  setStep,
  image,
  updateURLs,
  cropShape,
  cropProps,
}: CropImageProps) {
  const { t } = useTranslation();
  const cropInfo = useCropImage(cropProps);

  React.useEffect(() => {
    // update src after crop
    if (cropInfo?.croppedImage) {
      updateURLs((prevState) => [
        {
          ...prevState[0],
          src: cropInfo.croppedImage,
        },
      ]);
      setStep(2);
    }
  }, [cropInfo?.croppedImage]);

  return (
    <>
      <div className="w-full h-auto ">
        <CropArea
          className="h-56"
          cropShape={cropShape}
          img={image.length > 0 ? image[0] : null}
          {...cropInfo}
        />
      </div>
      <div className="flex justify-end items-center gap-4 px-4">
        <Button
          tooltip={t('return')}
          className="text-primary-500"
          onClick={() => {
            setStep(0);
            updateURLs([]);
          }}
        >
          {t('return')}
        </Button>
        <Button
          tooltip={t('crop')}
          size="md"
          type="button"
          className="btn-primary"
          onClick={() => {
            cropInfo.completeCropImage();
          }}
        >
          {t('crop')}
        </Button>
      </div>
    </>
  );
}

export default CropImage;
