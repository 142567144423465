import React from 'react';
import { Modal } from '@dinbog/ui';
import DeleteConfirmation from './DeleteConfirmation';
import DeleteNotif from './DeleteNotif';

interface DeleteModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: any;
  onSubmit?: (e: any) => Promise<void>;
  current: number;
  disabled?: boolean;
}

export default function DeleteModal({
  isOpen = false,
  setOpen,
  user,
  onSubmit,
  current,
  disabled = false,
}: DeleteModalProps) {
  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 p-8"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      {current === 0 ? (
        <DeleteConfirmation
          setOpen={setOpen}
          onSubmit={onSubmit}
          disabled={disabled}
          userName={`${user?.profile?.firstName}${
            user?.profile?.lastName ? ` ${user?.profile?.lastName}` : ''
          }`}
        />
      ) : (
        <>
          <Modal.Header exitBtn />
          <DeleteNotif deletedUser={user?.profile} />
        </>
      )}
    </Modal>
  );
}
