import React from 'react';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Multimedia } from '@dinbog/models';

export default function FullPhoto({
  photo,
  setSelected,
}: {
  photo: Multimedia;
  setSelected?: React.Dispatch<React.SetStateAction<Multimedia | null>>;
}) {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-100 bg-black/80 p-8 flex flex-col">
      <button
        type="button"
        onClick={() => setSelected(null)}
        className="w-fit h-fit"
        aria-label="Close button"
      >
        <FontAwesomeIcon icon={faX} className="text-text-white w-6 h-6" />
      </button>
      <img
        className="h-full w-full object-contain justify-self-center self-center"
        src={photo?.url}
        alt={photo?.fileName}
      />
    </div>
  );
}
