import React from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './navbar/Navbar';

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  horizontalPaddingSize?: 'small' | 'medium' | 'large';
}

function Layout({ children, className, horizontalPaddingSize }: LayoutProps) {
  // set padding size
  const paddingClassName = React.useMemo(() => {
    if (horizontalPaddingSize === 'small')
      return 'pb-8 px-4 pt-24 md:pt-28 sm:px-6 md:px-8 lg:px-10';
    if (horizontalPaddingSize === 'medium')
      return 'pb-8 px-4 pt-24 md:pt-28 sm:px-10 md:px-14 md:px-16  ';
    if (horizontalPaddingSize === 'large')
      return 'pb-8 px-4 pt-24 md:pt-32 sm:px-20 md:px-36 lg:px-52 ';
    return 'px-0 lg:py-0 pt-16';
  }, [horizontalPaddingSize]);

  return (
    <main className="relative min-h-screen bg-background-50 text-text-black">
      <Navbar />
      <div className={`relative ${className} ${paddingClassName} flex w-full`}>
        <div className="mx-auto w-full">{children}</div>
      </div>
    </main>
  );
}

export default Layout;
