import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { User, Profile, FiltersInput, Connection } from '@dinbog/models';
import { axios } from '../../api';

export function useConnect() {
  const mutation = useMutation<
    Connection,
    AxiosError,
    { from: string; to: string; type: string }
  >(async (params) => {
    const { data } = await axios.post(`/api/users/connection/v1`, params);
    return data;
  });
  return mutation;
}

export function useDisconnect() {
  const mutation = useMutation<
    Connection,
    AxiosError,
    { from: string; to: string }
  >(async (params) => {
    const { data } = await axios.patch(
      `/api/users/connection/disconnect/v1`,
      params
    );
    return data;
  });
  return mutation;
}

export function useGetConnections(
  filters,
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['connections']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['connections', filters],
    async () => {
      if (filters) {
        const { data } = await axios.get<any>(
          `/api/users/connections/pagination/v1`,
          {
            params: {
              ...filters,
            },
          }
        );

        return data;
      }
    },
    options
  );
  return query;
}

export function useAcceptConnection() {
  const mutation = useMutation<
    Connection,
    AxiosError,
    { _id: string; user: string }
  >(async (params) => {
    const { data } = await axios.patch(
      `/api/users/connection/connect/v1`,
      params
    );
    return data;
  });
  return mutation;
}
