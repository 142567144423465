import React from 'react';
import { Category, Subcategory } from '@dinbog/models';
import { useSubcategories } from '../../../../hooks/api/subcategories';
import SubcategoryDropdown from './SubcategoryDropdown';

interface SubcategoryListProps {
  selectedCategories: Category[];
  selectedSubcategories: Subcategory[];
  append;
  remove;
}

export default function SubcategoryList({
  selectedCategories,
  selectedSubcategories,
  append,
  remove,
}: SubcategoryListProps) {
  const { data: allSubcategories, refetch } = useSubcategories({
    filter: JSON.stringify({
      category: { $in: selectedCategories.map((category) => category._id) },
    }),
  });

  const getSubcategoriesForCategory = (category: Category) =>
    allSubcategories?.filter(
      (subcategory) => subcategory.category === category._id
    );

  React.useEffect(() => {
    refetch();
  }, [selectedCategories]);

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-12 px-9 justify-between">
      {selectedCategories?.map((category) => (
        <SubcategoryDropdown
          key={`${category?._id}-subcategory`}
          category={category}
          subcategories={getSubcategoriesForCategory(category)}
          selectedSubcategories={selectedSubcategories}
          append={append}
          remove={remove}
        />
      ))}
    </div>
  );
}
