import React from 'react';
import SearchFilters from './SearchFilters';

export default function CategoryFilters({
  filters,
  setFilters,
}: {
  filters: {
    name: string;
    type: string;
    label: string;
    iterable: Array<any>;
  }[];
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        type: string;
        label: string;
        iterable: Array<any>;
      }[]
    >
  >;
}) {
  React.useEffect(() => {
    setFilters([
      {
        name: 'categories',
        type: 'select',
        label: 'Category',
        iterable: [], // query categorias
        // poner acá algo de los errores, el state, etc
      },
      {
        name: 'subcategory',
        type: 'select',
        label: 'Subcategory',
        iterable: [], // query subcategorias
        // poner acá algo de los errores, el state, etc
      },
    ]);
  }, []);
  return (
    <SearchFilters
      filters={filters?.filter(
        (f) => f?.name === 'category' || f?.name === 'subcategory'
      )}
    />
  );
}
