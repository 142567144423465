import React from 'react';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { CircledIcon } from '../../shared';

function PrivateProfileContent() {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full py-14 flex flex-col justify-center items-center gap-[36px]">
      <CircledIcon icon={faLock} />

      <div className="flex flex-col gap-4 justify-center items-center">
        <h1 className=" text-lg font-semibold ">
          {t('profile.private.title')}
        </h1>
        <p className="text-center">{t('profile.private.description')}</p>
      </div>
    </div>
  );
}

export default PrivateProfileContent;
