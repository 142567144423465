import { Pagination, Profile, User } from '@dinbog/models';
import { InfiniteScroll } from '@dinbog/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UserSelectSearchProps {
  usersData?: Pagination<User & { profile?: Profile }>;
  usersLoading?: boolean;
  onSelectUser: (selectedUser_: User) => void;
}

function UserConnectionList({
  usersData,
  usersLoading,
  onSelectUser,
}: UserSelectSearchProps) {
  const { t } = useTranslation();
  const [usersList, setUsersList] = React.useState(usersData?.items ?? []);
  const [pagination, setPagination] = React.useState({ page: 1, perPage: 30 });
  return (
    <InfiniteScroll
      data={usersData}
      setLoadedData={setUsersList}
      setPagination={setPagination}
      maxHeight={200}
      loading={usersLoading}
    >
      {usersList.map((user) => (
        <button
          type="button"
          key={user._id}
          className=" w-full flex items-center p-2 cursor-pointer hover:bg-neutral-100 focus:outline-none bg-white rounded"
          onClick={() => {
            onSelectUser(user);
          }}
        >
          <img
            src={
              user.profile?.photo?.url ??
              (user?.profile?.type === 'business'
                ? '/images/fallback/business.jpg'
                : '/images/fallback/user.png')
            }
            alt={`${user?.profile?.firstName} ${user?.profile?.lastName}`}
            className="w-8 h-8 rounded-full mr-2 border-[0.5px] border-solid border-neutral-300 object-cover"
          />
          {`${user?.profile?.firstName} ${user?.profile?.lastName ?? ''}`}
        </button>
      ))}
      {usersData?.count === 0 && (
        <span className="w-full ml-4 my-4 h-6 text-sm text-neutral-300 ">
          {t('post.noConnections')}
        </span>
      )}
    </InfiniteScroll>
  );
}

export default UserConnectionList;
