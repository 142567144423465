import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { User, WorkReference } from '@dinbog/models';
import { useRouter } from 'next/router';
import {
  useCreateWorkReference,
  useUpdateWorkReference,
} from '../../../hooks/api/workReferences';
import { useNotify, useUser } from '../../../hooks';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { WorkReferenceFormContent } from '../../shared';
import FormButtons from '../shared/FormButtons';

interface WorkReferenceInfoProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formsLength: number;
  data?: WorkReference;
}

export default function WorkReferenceInfo({
  currentStep = 6,
  setCurrentStep,
  formsLength,
  data = null,
}: WorkReferenceInfoProps) {
  const { t } = useTranslation();
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const router = useRouter();

  const notify = useNotify();
  const [disabled, setDisabled] = React.useState(false);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const methods = useForm<WorkReference>({
    defaultValues: {
      position: data?.position ?? '',
      startDate: data?.startDate
        ? dayjs(data?.startDate).format('YYYY-MM-DD')
        : null,
      endDate: data?.endDate ? dayjs(data?.endDate).format('YYYY-MM-DD') : null,
      company: data?.company ?? '',
      description: data?.description ?? '',
      companyName: data?.companyName ?? '',
      manager: data?.manager ?? '',
      privacy: data?.privacy ?? null,
      status: data?.status ?? 'pending',
      experience: data?.experience ?? undefined,
    },
  });

  // queries/mutations
  const queryClient = useQueryClient();
  const createWorkReferenceMutation = useCreateWorkReference();
  const updateWorkReferenceMutation = useUpdateWorkReference();
  const updateProfileMutation = useUpdateProfile();

  const onSubmit: SubmitHandler<Partial<WorkReference>> = async (
    _data: Partial<WorkReference>
  ) => {
    try {
      if (disabled) return;
      setDisabled(true);
      const promises = [
        (data
          ? updateWorkReferenceMutation
          : createWorkReferenceMutation
        ).mutateAsync({
          _id: data?._id ?? undefined,
          user: (user?.currentUser?.user as User)?._id,
          position: _data.position,
          startDate: _data.startDate,
          endDate: _data.endDate,
          company: _data.company !== '' ? _data.company : undefined,
          companyName: _data.companyName ?? '',
          description: _data.description ?? '',
          privacy: _data.privacy ?? '',
        }),
        updateProfileMutation.mutateAsync({
          user: (user?.currentUser?.user as User)?._id as string,
          // update registration step
          registrationStep: '7',
        }),
      ];
      setUser({
        ...user,
        currentUser: {
          ...user?.currentUser,
          registrationStep: '7',
        },
      });

      await Promise.all(promises).then(() => {
        queryClient.refetchQueries(['workReference', { user: user?._id }]);
        router.push('/sign-up/success');
      });
    } catch (err) {
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onSkip = async () => {
    let next = true;
    try {
      if (disabled) return;
      setDisabled(true);

      await updateProfileMutation.mutateAsync({
        user: (user?.currentUser?.user as User)?._id as string,
        // update registration step
        registrationStep: '7',
      });
      setUser({
        ...user,
        currentUser: {
          ...user?.currentUser,
          registrationStep: '7',
        },
      });
    } catch (err) {
      next = false;
      // return notify(t('auth.signUp.notifs.failure'), 'error');
      return notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
      if (next) {
        router.push('/sign-up/success');
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form method="post" onSubmit={methods?.handleSubmit(onSubmit)}>
        <div className="space-y-3">
          <h3 className="font-semibold text-lg">
            {t('auth.signUp.wizard.workReference.title')}
          </h3>
          <p>{t('auth.signUp.wizard.workReference.subtitle')}</p>
          <WorkReferenceFormContent disabled={disabled} />
        </div>
        <FormButtons
          disabled={disabled}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={formsLength}
          onSkip={onSkip}
        />
      </form>
    </FormProvider>
  );
}
