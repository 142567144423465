import React from 'react';
import { Button } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { PageInfo, Profile, User } from '@dinbog/models';
import { useRouter } from 'next/router';
import SearchInput from '../../../../shared/SearchInput';
import TalentList from './TalentList';
import { InfoWithIcon, SkeletonWrapper } from '../../../../shared';
import PastTalents from './PastTalents';
import TalentRequests from './TalentRequests';
import { useUser } from '../../../../../hooks';

export default function TalentTab({
  talentsData = [],
  loading = false,
  setPagination,
  setFilters,
  viewedUser,
}: {
  talentsData;
  loading: boolean;
  setPagination: React.Dispatch<React.SetStateAction<PageInfo>>;
  setFilters;
  viewedUser: {
    _id?: string;
    profile: Profile;
    relations?: { follow: string; connection: string };
  };
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentUser } = useUser();
  const [viewing, setViewing] = React.useState<'current' | 'past' | 'requests'>(
    'current'
  );

  const { slug } = router.query;

  React.useEffect(() => {
    setFilters((prevState) => {
      if (viewing === 'current') {
        return {
          ...prevState,
          status: 'approved',
          endDate: { $exists: false },
        };
      }
      if (viewing === 'past') {
        return { ...prevState, status: 'approved', endDate: { $exists: true } };
      }
      if (viewing === 'requests') {
        return { ...prevState, status: 'pending' };
      }
      return prevState;
    });
  }, [viewing]);

  const isOwner = currentUser?.accounts.some(
    (account) => account._id === (viewedUser?.profile?.user as User)?._id
  );

  if (viewing === 'past')
    return <PastTalents talents={talentsData} setViewing={setViewing} />;
  if (viewing === 'requests')
    return <TalentRequests talents={talentsData} setViewing={setViewing} />;
  return (
    <div className="">
      {/* search bar, past talents and requests */}
      <div className="flex md:flex-row flex-col-reverse justify-between gap-4">
        <div className="w-auto">
          {/* <SearchInput
            placeholder={t('search')}
            hasSearchIcon
            value=""
            onChange={() => console.log('on change')}
          /> */}
        </div>
        {/* past talents and requests */}
        {isOwner ? (
          <div className="flex gap-2">
            <Button
              className="text-primary-500"
              onClick={() => setViewing('past')}
            >
              View past talents
            </Button>
            <Button
              className="text-primary-500 flex gap-2 justify-center items-center"
              onClick={() => setViewing('requests')}
            >
              <span>View requests</span>
              {/* <div className="bg-primary-500 text-text-white rounded-full h-6 w-6 text-xs flex justify-center items-center">
             1
           </div> */}
            </Button>
          </div>
        ) : null}
      </div>
      {/* talents list */}
      {/* modificar mensaje según si el usuario que lo ve es el owner u otra persona */}
      <SkeletonWrapper isLoading={loading}>
        <div className="py-16">
          {talentsData?.items?.length > 0 ? (
            <TalentList talents={talentsData?.items} />
          ) : (
            <InfoWithIcon
              title={t('emptyStates.noTalents.title')}
              text={t('emptyStates.noTalents.text')}
              icon={faUsers}
            />
          )}
        </div>
      </SkeletonWrapper>
    </div>
  );
}
