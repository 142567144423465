import React from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'country-state-city';
import { Profile } from '@dinbog/models';
import { getFlag } from '../../../../lib/getFlag';
import ProfileRelationsCount from './ProfileRelationsCount';

interface ProfileSummaryInfoProps {
  user;
}

function ProfileSummaryInfo({ user }: ProfileSummaryInfoProps) {
  const { t, i18n } = useTranslation();
  const [showAllTalents, setShowAllTalents] = React.useState(false);

  const showSeparationDot = (idx: number) =>
    (idx !== 3 &&
      idx < user.profile.categories.length - 1 &&
      !showAllTalents) ||
    (idx !== user.profile.categories.length - 1 && showAllTalents);

  return (
    <div className="flex flex-col ml-6">
      <div className="flex gap-2">
        <span className="md:text-xl lg:text-2xl text-lg font-semibold text-text-black">
          {user?.profile?.firstName} {user?.profile?.lastName}
        </span>
        {/* show badge if user is verified */}
      </div>
      <div className="relative flex w-full flex-wrap text-text-gray">
        {user?.profile?.categories
          ?.slice(
            0,
            // eslint-disable-next-line no-nested-ternary
            !showAllTalents
              ? user?.profile?.categories?.length < 4
                ? user?.profile?.categories?.length
                : 4
              : user?.profile?.categories?.length
          )
          .map((category, idx) => (
            <span
              key={category._id}
              className="text-text-gray flex items-center truncate lg:text-base text-sm"
            >
              {category.name?.[i18n.language ?? 'en']}
              {showSeparationDot(idx) ? <span className="p-1"> | </span> : null}
            </span>
          ))}
        {user?.profile?.categories?.length > 4 ? (
          <button
            type="button"
            className="rounded ml-2 font-semibold text-primary-500 text-sm"
            onClick={() => setShowAllTalents(!showAllTalents)}
          >
            {showAllTalents ? t('less') : `... ${t('all')}`}
          </button>
        ) : null}
      </div>
      <div className="gap-1 flex text-text-gray lg:text-base text-sm">
        <span className="">
          {user?.profile?.address?.country
            ? getFlag(user?.profile?.address?.country)
            : null}
        </span>
        <span className="">
          {user?.profile?.address?.city ?? user?.profile?.business?.city},{' '}
          {Country.getCountryByCode(user?.profile?.address?.country)?.name ??
            Country.getCountryByCode(user?.profile?.business?.headquarter)
              ?.name}
        </span>
      </div>
      <div className="sm:block hidden">
        <ProfileRelationsCount user={user} />
      </div>
    </div>
  );
}

export default ProfileSummaryInfo;
