import React from 'react';

export function useScreenSize() {
  const [screenSize, setScreenSize] = React.useState(0);

  React.useEffect(() => {
    function updateScreenSize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener('resize', updateScreenSize);

    updateScreenSize();

    return () => window.removeEventListener('resize', updateScreenSize);
  }, [screenSize]);

  return { screenSize, setScreenSize };
}
