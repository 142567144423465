import React from 'react';
import { CheckIcon } from '@dinbog/ui';

interface OptionBoxProps {
  label: string;
  value: string;
  selected: boolean;
  onSelectOption: (value: any, label: string) => void;
}
export default function OptionBox({
  label,
  value,
  selected,
  onSelectOption,
}: OptionBoxProps) {
  const handleClick = () => {
    onSelectOption(value, label);
  };
  return (
    <button
      className="w-full bg-transparent border-none outline-none shadow-none text-text-black gap-5 hover:bg-neutral-200 focus:bg-neutral-200 active:bg-neutral-200 "
      type="button"
      onClick={handleClick}
    >
      <li className="p-2 rounded-md cursor-pointer transition-colors duration-300 text-sm  flex items-center justify-between">
        <div className="flex items-center gap-2 text-text-black">{label}</div>
        {selected && <CheckIcon className="h-4 text-primary-500" />}
      </li>
    </button>
  );
}
