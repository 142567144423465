import React from 'react';
import { Button } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';

interface FormButtonsProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formsLength: number;
  disabled?: boolean;
  canSkip?: boolean;
  onSkip?: () => void;
  onComplete?: any;
  onSubmit?: (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

export default function FormButtons({
  currentStep = 0,
  setCurrentStep,
  formsLength = 1,
  onComplete,
  onSubmit,
  disabled = false,
  canSkip = true,
  onSkip,
}: FormButtonsProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`flex sm:flex-row flex-col ${
        currentStep === 0 ? 'justify-end' : 'justify-between'
      } gap-2 pt-16 pb-4`}
    >
      {currentStep > 0 ? (
        <Button
          tooltip={t('auth.signUp.wizard.prevBtn')}
          className="text-primary-500 sm:w-40 w-full justify-self-start disabled:opacity-60"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            setCurrentStep((cs) => cs - 1);
          }}
        >
          {t('auth.signUp.wizard.prevBtn')}
        </Button>
      ) : null}
      {currentStep < formsLength ? (
        <div className="flex sm:flex-row flex-col gap-2">
          {currentStep !== 0 && canSkip ? (
            <Button
              tooltip={t('auth.signUp.wizard.skipBtn')}
              disabled={disabled}
              className="text-primary-500 sm:w-40 w-full disabled:opacity-60"
              onClick={(e) => {
                e.preventDefault();
                onSkip();
              }}
            >
              {t('auth.signUp.wizard.skipBtn')}
            </Button>
          ) : null}
          <Button
            disabled={disabled}
            className="btn-primary sm:w-40 w-full"
            type="submit"
            tooltip={t('auth.signUp.wizard.nextBtn')}
          >
            {t('auth.signUp.wizard.nextBtn')}
          </Button>
        </div>
      ) : null}
      {currentStep === formsLength ? (
        <div className="flex flex-row gap-x-2">
          <Button
            disabled={disabled}
            className="text-primary-500 sm:w-40 w-full disabled:opacity-60"
            onClick={(e) => {
              e.preventDefault();
              if (onSkip) onSkip();
            }}
            tooltip={t('auth.signUp.wizard.skipBtn')}
          >
            {t('auth.signUp.wizard.skipBtn')}
          </Button>
          <Button
            disabled={disabled}
            className="btn-primary sm:w-40 w-full"
            onClick={onComplete}
            type={onComplete ? 'button' : 'submit'}
            tooltip={t('auth.signUp.wizard.finishBtn')}
          >
            {t('auth.signUp.wizard.finishBtn')}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
