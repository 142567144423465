import React from 'react';
import { Button, Modal } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import InfoWithIcon from '../InfoWithIcon';

interface ConfirmationModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  text: string;
  disabled?: boolean;
  onSubmit: () => void;
  confirmButtonText: string;
}

export default function ConfirmationModal({
  isOpen = false,
  setOpen,
  title,
  text,
  disabled = false,
  onSubmit,
  confirmButtonText,
}: ConfirmationModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 p-8 flex flex-col gap-8 items-center"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div className="flex flex-col gap-8 items-center">
        <InfoWithIcon
          title={t(`${title}`)}
          text={t(`${text}`)}
          icon={faInfo}
          type="info"
        />
        <div className="flex flex-row w-full justify-evenly gap-4">
          <Button
            tooltip={t('unblock.noBtn')}
            disabled={disabled}
            className={`${' text-primary-500'} w-full active:opacity-70`}
            onClick={() => setOpen(false)}
          >
            {t('unblock.noBtn')}
          </Button>
          <Button
            tooltip={t(`${confirmButtonText}`)}
            disabled={disabled}
            className={`text-white ${'bg-primary-500'} active:opacity-70 w-full`}
            type="submit"
            onClick={onSubmit}
          >
            {t(`${confirmButtonText}`)}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
