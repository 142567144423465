import React from 'react';
import NProgress from 'nprogress';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { User } from '@dinbog/models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faPlus,
  faExchange,
  faCog,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import UserDropdownItem, { DropdownItem } from './UserDropdownItem';
import userFallback from '../../../../../../public/images/fallback/user.png';
import Dropdown from '../../../../../shared/dropdown';
import { useNotify, useUser } from '../../../../../../hooks';
import SwitchAccountCard from './switchAccount/SwitchAccountCard';
import { FadeAnimation } from '../../../../../shared';

function UserDropdown() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openSwitchAccount, setOpenSwitchAccount] = React.useState(false);
  const notify = useNotify();

  const { currentUser: user } = useUser();

  const { t } = useTranslation();

  const iconClass = 'w-4 h-4';

  const userDropdownItems: DropdownItem[] = [
    {
      customKey: 'profile',
      type: 'link',
      icon: <FontAwesomeIcon icon={faUser} className={iconClass} />,
      label: t('navbar.logged.userDropdown.profile'),
      href: '/profile',
    },
    /* {
      type: 'link',
      icon: <FontAwesomeIcon icon={faPlus} className={iconClass} />,
      label: t('navbar.logged.userDropdown.createBusinessPage'),
      href: '/business-page',
    }, */
    {
      customKey: 'switch',
      type: 'button',
      icon: <FontAwesomeIcon icon={faExchange} className={iconClass} />,
      label: t('navbar.logged.userDropdown.switchAccounts'),
      onClick: () => setOpenSwitchAccount(true),
    },
    {
      customKey: 'settings',
      type: 'link',
      icon: <FontAwesomeIcon icon={faCog} className={iconClass} />,
      label: t('navbar.logged.userDropdown.settings'),
      href: '/settings',
    },
    /* {
    customKey: 'support',
    type: 'link',
    icon: <WrenchScrewdriverIcon className={iconClass} />,
    label: 'Support',
    href: '/support',
  }, */
    {
      customKey: 'logOut',
      type: 'button',
      icon: <FontAwesomeIcon icon={faSignOutAlt} className={iconClass} />,
      label: t('navbar.logged.userDropdown.logOut'),
    },
  ];

  const handleSignOut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      await signOut({ callbackUrl: `/sign-in` });
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  const endedWizard = () =>
    (user?.currentUser?.type === 'person' &&
      ((user?.currentUser?.categories?.length > 0 &&
        user?.currentUser?.registrationStep === '7') ||
        (user?.currentUser?.categories?.length === 0 &&
          user?.currentUser?.registrationStep === '3'))) ||
    (user?.currentUser?.type === 'business' &&
      user?.currentUser?.registrationStep === '3');

  return (
    <Dropdown isOpen={isOpen} setIsOpen={setIsOpen}>
      <Dropdown.Button hasTriangleIcon>
        <img
          className={`${
            user?.currentUser?.type === 'person' ? 'rounded-full' : 'rounded'
          } w-8 h-8 object-cover`}
          src={
            user?.currentUser?.type === 'person'
              ? user?.currentUser?.photo?.url ?? userFallback.src
              : user?.currentUser?.photo?.url ?? userFallback.src
          }
          alt="user"
        />
      </Dropdown.Button>
      <Dropdown.ItemsContainer className="mt-4 flex flex-col gap-y-2">
        {openSwitchAccount ? (
          <SwitchAccountCard setOpen={setOpenSwitchAccount} />
        ) : (
          <FadeAnimation duration={0.5}>
            {userDropdownItems.map((item) =>
              (((user?.currentUser?.user as User)?._id as string) !==
                user?._id &&
                item?.customKey === 'logOut') ||
              (item?.customKey === 'settings' &&
                (!endedWizard() ||
                  (user?.currentUser?.type === 'business' &&
                    (user?.currentUser?.business?.members?.find(
                      (m) => m?.user === user?._id
                    )?.role === 'editor' ||
                      user?.currentUser?.business?.members?.find(
                        (m) => m?.user === user?._id
                      )?.status !== 'approved')))) ? null : (
                <UserDropdownItem
                  key={item?.customKey}
                  customKey={item?.label}
                  type={item?.type}
                  label={item?.label}
                  icon={item?.icon}
                  href={item?.href ?? ''}
                  onClick={
                    item?.customKey === 'logOut' ? handleSignOut : item.onClick
                  }
                />
              )
            )}
          </FadeAnimation>
        )}
      </Dropdown.ItemsContainer>
    </Dropdown>
  );
}

export default UserDropdown;
