import React from 'react';
import { Input } from '@dinbog/ui';
import { FieldErrors, useFormContext, UseFormRegister } from 'react-hook-form';
import { WorkReference, WorkReferenceFormValidation } from '@dinbog/models';
import { useTranslation } from 'react-i18next';

interface CompanyInputProps {
  formValidations: WorkReferenceFormValidation;
}

function CompanyInput({ formValidations }: CompanyInputProps) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<WorkReference>();
  return (
    <Input
      isRequired
      error={errors.companyName?.message as string}
      {...register('companyName', formValidations.companyName)}
      name="companyName"
      label={
        t('auth.signUp.wizard.workReference.newCompany.companyName.title') ?? ''
      }
      placeholder={
        t(
          'auth.signUp.wizard.workReference.newCompany.companyName.description'
        ) ?? ''
      }
    />
  );
}

export default CompanyInput;
