import React from 'react';
import { useTranslation } from 'react-i18next';
import FanAndTalentContent from './FanAndTalentContent';
import BusinessContent from './BusinessContent';

function PersonalInformationFormContent({
  type,
  disabled = false,
}: {
  type: 'talent' | 'business' | 'fan';
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const formValidations = {
    firstName: {
      required: t('required'),
    },
    currentCity: {
      required: t('required'),
    },
    currentState: {
      required: t('required'),
    },
    currentCountry: {
      required: t('required'),
    },
    description: {
      required: t('required'),
    },
    phone: {},
    ...((type === 'talent' || type === 'fan') && {
      private: {
        required: t('required'),
      },
      lastName: {
        required: t('required'),
      },
      gender: {
        required: t('required'),
      },
      birthCountry: {
        required: t('required'),
      },
      birthCity: {},
      birthState: {},
    }),
    ...(type === 'business' && {
      websiteUrl: {
        required: 'required',
        pattern: {
          value:
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
          message: 'Invalid website url',
        },
      },
    }),
  };

  if (type === 'business')
    return (
      <BusinessContent formValidations={formValidations} disabled={disabled} />
    );
  return (
    <FanAndTalentContent
      formValidations={formValidations}
      disabled={disabled}
    />
  );
}

export default PersonalInformationFormContent;
