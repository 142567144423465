import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import {
  User,
  Profile,
  ConnectionStatusEnum,
  FiltersInput,
} from '@dinbog/models';
import { axios } from '../../api';

export function useProfile(
  filters: FiltersInput & Partial<Profile>,
  options: Omit<
    UseQueryOptions<Profile, AxiosError, Profile, ['profile']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Profile, AxiosError>(
    ['profile', filters],
    async () => {
      const { data } = await axios.get<Profile>('/api/users/profile/v1', {
        params: { ...filters },
      });
      return data;
    },
    options
  );
  return query;
}

export function useUpdateProfile() {
  const mutation = useMutation<Profile, AxiosError, Partial<Profile>>(
    async (user) => {
      const { data } = await axios.patch(`/api/users/profile/v1`, user);
      return data;
    }
  );
  return mutation;
}

type ViewProfileType = {
  item: Profile;
  blocked: boolean;
  follow: 'none' | 'pending' | 'approved';
  connection: ConnectionStatusEnum;
  canView: boolean;
};

export function useViewProfile() {
  const mutation = useMutation<
    ViewProfileType,
    AxiosError,
    { to: string; from: string; search: boolean }
  >(async (_data) => {
    const { data } = await axios.post(`/api/users/view/v1`, _data);
    return data;
  });
  return mutation;
}
