/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import FadeAnimation from '../FadeAnimation';
import { useDropdown } from './Dropdown';

interface DropdownItemsContainer {
  children: React.ReactNode;
  className?: string;
}

function DropdownItemsContainer({
  children,
  className,
}: DropdownItemsContainer) {
  const [isOpen] = useDropdown();

  return (
    <div>
      {isOpen ? (
        <FadeAnimation duration={0.5}>
          <div
            className={`right-0 text-sm leading-normal z-50 bg-white shadow rounded px-4 py-2 ${
              isOpen ? 'absolute z-50' : 'hidden'
            }  ${className} `}
          >
            {children}
          </div>
        </FadeAnimation>
      ) : null}
    </div>
  );
}
export default DropdownItemsContainer;
