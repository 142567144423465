import { AxiosError } from 'axios';
import { useQuery, useMutation, UseQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import {
  User,
  Profile,
  Pagination,
  FiltersInput,
  Business,
  UserInformation,
} from '@dinbog/models';
import { axios } from '../../api';

export function useUserPagination(
  filters: FiltersInput & { filter?: any }, // filter in graphql format ( filter: {categories: { $or:[...] } )
  options: Omit<
    UseQueryOptions<
      Pagination<User & { profile?: Profile; business?: Business }>,
      AxiosError,
      Pagination<User & { profile?: Profile; business?: Business }>,
      ['users']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<
    Pagination<User & { profile?: Profile; business?: Business }>,
    AxiosError
  >(
    ['users', filters],
    async () => {
      const { data } = await axios.get<
        Pagination<User & { profile?: Profile; business?: Business }>
      >(`/api/users/pagination/v1`, {
        params: { ...filters },
      });
      return data;
    },
    options
  );
  return query;
}

export function useCreateUser() {
  const mutation = useMutation<User, AxiosError, Partial<User>>(
    async (newUser) => {
      const { data } = await axios.post('/api/users/v1', newUser);
      return data;
    }
  );
  return mutation;
}

export function useUserRecommendations(
  filters,
  options: Omit<
    UseQueryOptions<
      Pagination<User & { profile: Profile }>,
      AxiosError,
      Pagination<User & { profile: Profile }>,
      ['userRecommendations']
    >,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<Pagination<User & { profile: Profile }>, AxiosError>(
    ['userRecommendations', filters],
    async () => {
      const { data } = await axios.get<Pagination<User & { profile: Profile }>>(
        `/api/users/recommendations/v1`,
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useInviteUser() {
  const mutation = useMutation<
    { emails: string[]; invitedBy: string }, // invitedBy is the user id
    AxiosError,
    Partial<{ emails: string[]; invitedBy: string }>
  >(async (params) => {
    const { data } = await axios.post(`/api/users/invite-users/v1`, params);
    return data;
  });
  return mutation;
}

export function useGetUserInfo(
  filters: Partial<User>,
  options: Omit<
    UseQueryOptions<UserInformation, AxiosError, UserInformation, ['user']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<UserInformation, AxiosError>(
    ['user', filters?._id],
    async () => {
      const { data } = await axios.get<UserInformation>(
        `/api/users/information/v1`,
        {
          params: { ...filters },
        }
      );
      return data;
    },
    options
  );
  return query;
}

export function useUserRequests(
  filters: {
    user: string;
    page: number;
    perPage: number;
    type: 'received' | 'sent';
    token: string;
  },
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['userRequests']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['userRequests', filters],
    async () => {
      const { data } = await axios.get<any>(`/api/users/requests/v1`, {
        params: { ...filters },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      });
      return data;
    },
    options
  );
  return query;
}

export function useUserRequestsCount(
  filters: {
    user: string;
    token: string;
  },
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['userRequestsCount']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['userRequestsCount', filters],
    async () => {
      const { data } = await axios.get<any>(`/api/users/requests-count/v1`, {
        params: { ...filters },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      });
      return data;
    },
    options
  );
  return query;
}

// /users/verify-photo/v1
export function useVerifyPhoto(
  filters: {
    key: string;
  },
  options: Omit<
    UseQueryOptions<any, AxiosError, any, ['verifyPhoto']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useQuery<any, AxiosError>(
    ['verifyPhoto', filters],
    async () => {
      // for those case when the key is not provided (first render of profile picture)
      if (!filters.key) {
        return;
      }
      const { data } = await axios.get<any>(`/api/users/verify-photo/v1`, {
        params: { ...filters },
      });
      return data;
    },
    options
  );
  return query;
}

export function usePaginatedUserRequests(filters: {
  user: string;
  page: number;
  perPage: number;
  type: 'received' | 'sent';
  token: string;
}) {
  async function fetchPaginatedUserRequests({ pageParam = 1 }) {
    const response = await axios.get(`/api/users/requests/v1`, {
      params: { ...filters, page: pageParam },
      headers: {
        Authorization: `Bearer ${filters.token}`,
      },
    });
    return response.data as any;
  }

  const {
    data: InfiniteRequestData,
    fetchNextPage,
    isLoading: isLoadingInfiniteRequest,
    isError: isErrorInfiniteRequest,
    refetch: refetchInfiniteRequest,
    isRefetching,
  } = useInfiniteQuery<Pagination<Request>, unknown, Pagination<Request>>({
    queryKey: ['userRequestsPaginated', filters],
    queryFn: fetchPaginatedUserRequests,
    getNextPageParam: (lastPage) =>
      lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
    enabled: !!filters.token,
    cacheTime: 0,
    refetchInterval: 30000,
  });

  return {
    InfiniteRequestData,
    fetchNextPage,
    isLoadingInfiniteRequest,
    isErrorInfiniteRequest,
    refetchInfiniteRequest,
    isRefetching,
  };
}
