import React from 'react';

interface PortfolioResultItemProps {
  portfolioItem: any;
}

export default function PortfolioResultItem({
  portfolioItem,
}: PortfolioResultItemProps) {
  switch (portfolioItem?.type) {
    case 'Book':
      return (
        <a
          className=""
          href={`/${portfolioItem?.user?.slug}?tab=books&book=${portfolioItem?._id}`}
        >
          <img
            className="rounded h-44 w-44 object-cover"
            alt={portfolioItem?.cover?.filename}
            src={portfolioItem?.cover?.url}
          />
        </a>
      );
    case 'Video':
      return (
        <a
          className=""
          href={`/${portfolioItem?.user?.slug}?tab=videos&video=${portfolioItem?._id}`}
        >
          <img
            className="rounded h-44 w-44 object-cover"
            alt={portfolioItem?.cover?.filename}
            src={portfolioItem?.cover?.url}
          />
        </a>
      );
    case 'Album':
      return (
        <a
          className=""
          href={`/${portfolioItem?.user?.slug}?tab=audios&album=${portfolioItem?._id}`}
        >
          <img
            className="rounded h-44 w-44 object-cover"
            alt={portfolioItem?.cover?.filename}
            src={portfolioItem?.cover?.url}
          />
        </a>
      );
    case 'Track':
      return (
        <a
          className=""
          href={`/${portfolioItem?.user?.slug}?tab=audios&album=${portfolioItem?._id}`}
        >
          <img
            className="rounded h-44 w-44 object-cover"
            alt={portfolioItem?.album.cover?.filename}
            src={portfolioItem?.album.cover?.url}
          />
        </a>
      );
    default:
      return null;
  }
}
