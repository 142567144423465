import React from 'react';
import {
  ReportItemCollectionEnum,
  ReportTypeEnum,
  ServicesEnum,
  User,
  reportTypes,
} from '@dinbog/models';
import { Modal } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useCreateReport } from '../../../../hooks/api/reports';
import { useNotify, useUser } from '../../../../hooks';
import InfoWithIcon from '../../InfoWithIcon';
import ReportForm from './ReportForm';

interface ReportModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item?: string;
  itemIdToBeReported?: string;
  service?: ServicesEnum;
  itemOwner: string;
}

export default function ReportModal({
  isOpen = false,
  setOpen,
  item = 'post',
  itemIdToBeReported,
  service = 'user',
  itemOwner,
}: ReportModalProps) {
  const { currentUser } = useUser();
  const { t } = useTranslation();
  const notify = useNotify();
  const [current, setCurrent] = React.useState<number>(0);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const reportUserMutation = useCreateReport();

  const onSubmit = async (data) => {
    let next = false;

    const reportBody = {
      owner: (currentUser?.currentUser?.user as User)?._id as User,
      type: reportTypes[data.type] as ReportTypeEnum,
      details: data?.details,
      item: itemIdToBeReported,
      itemCollection: item as ReportItemCollectionEnum,
      service,
      itemOwner,
    };
    try {
      if (disabled) return;
      setDisabled(true);

      await reportUserMutation.mutateAsync(reportBody);
      next = true;
    } catch (err) {
      notify(err?.response?.data?.message, 'error');
      next = false;
    } finally {
      setDisabled(false);
      if (next) {
        setCurrent(current + 1);
      }
    }
  };

  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 p-8"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      {current === 0 ? (
        <ReportForm
          setOpen={setOpen}
          onSubmit={onSubmit}
          disabled={disabled}
          item={item}
        />
      ) : (
        <InfoWithIcon
          title={t('report.confirmationTitle')}
          text={t('report.confirmationText')}
          icon={faCheck}
        />
      )}
    </Modal>
  );
}
