import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonItem from '../../personCard';

interface BlockNotifProps {
  blockedUser;
}

export default function BlockNotif({ blockedUser }: BlockNotifProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 items-center pb-8">
      <div className="w-fit">
        <PersonItem user={{ profile: blockedUser }} isHorizontal>
          <div className="">
            <PersonItem.Header
              user={{
                profile: blockedUser,
              }}
            />
            <PersonItem.Body
              user={{
                profile: blockedUser,
              }}
              withLocation={!!blockedUser?.profile?.address?.country}
            />
          </div>
        </PersonItem>
      </div>
      <p className="text-center font-semibold text-xl">{t('block.notif')}</p>
    </div>
  );
}
