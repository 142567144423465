import React from 'react';
import { useTranslation } from 'react-i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@dinbog/ui';
import InfoWithIcon from '../../InfoWithIcon';

interface DeleteConfirmationProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (e: any) => Promise<void>;
  disabled: boolean;
  userName?: string;
}

export default function DeleteConfirmation({
  setOpen,
  onSubmit,
  disabled = false,
  userName = '',
}: DeleteConfirmationProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 items-center">
      <InfoWithIcon
        title={`${t('deleteMember.title')} ${userName}?`}
        text={t('deleteMember.text')}
        icon={faTrash}
        type="danger"
      />
      <div className="flex flex-row w-full justify-evenly gap-4">
        <Button
          tooltip={t('deleteMember.noBtn')}
          disabled={disabled}
          className={`${'text-danger-300'} w-full active:opacity-70`}
          onClick={() => setOpen(false)}
        >
          {t('deleteMember.noBtn')}
        </Button>
        <Button
          tooltip={t('deleteMember.yesBtn')}
          disabled={disabled}
          className={`text-white ${'bg-danger-300'} active:opacity-70 w-full`}
          type="submit"
          onClick={onSubmit}
        >
          {t('deleteMember.yesBtn')}
        </Button>
      </div>
    </div>
  );
}
