import React from 'react';
import { useTranslation } from 'react-i18next';
import RequestsList from './RequestsList';
import { useRequests } from '../../context/RequestContext';
import { InfoWithImage } from '../shared';

function RequestListSection() {
  const { t } = useTranslation();
  const {
    fetchRequestsNextPage,
    requestsData,
    refetchRequests,
    loading,
    isErrorInfiniteRequest,
    tabSelected,
    data,
  } = useRequests();

  const isErrorRequest =
    isErrorInfiniteRequest && tabSelected !== 'notifications';

  if (isErrorRequest) {
    return <span className="text-black">{t('requests.isError')}</span>;
  }

  const isRequestEmpty =
    !loading && data?.count === 0 && tabSelected !== 'notifications';

  if (isRequestEmpty) {
    return (
      <div className="w-full lg:w-[548px] xl:w-[600px] pt-16">
        <InfoWithImage
          image="/images/fallback/no-requests.png"
          title={t('emptyStates.noRequests.title')}
          text={t('emptyStates.noRequests.text')}
        />
      </div>
    );
  }

  const hasRequests =
    (!loading && requestsData?.count > 0 && tabSelected === 'received') ||
    tabSelected === 'sent';

  return hasRequests ? (
    <RequestsList
      data={requestsData}
      loading={loading}
      fetchNextPage={fetchRequestsNextPage}
      refetchRequests={refetchRequests}
    />
  ) : null;
}

export default RequestListSection;
