import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface DropdownItemProps {
  text: string;
  icon;
  selected?: boolean;
}

export default function DropdownItem({
  text,
  icon,
  selected = false,
}: DropdownItemProps) {
  return (
    <div
      className={`px-4 py-2 min-w-[200px] rounded justify-start items-center gap-3 inline-flex hover:text-primary-500 active:bg-background-300 active:text-primary-500 ${
        selected ? 'bg-background-300 text-primary-500' : 'text-text-black'
      }`}
    >
      {icon}
      <div className="text-base font-normal leading-normal">{text}</div>
    </div>
  );
}
