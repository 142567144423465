/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ThreeDotsIcon } from '@dinbog/ui';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { Profile, StatusEnum, WorkReference } from '@dinbog/models';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useNotify, useUser } from '../../../../../hooks';
import { useUpdateWorkReference } from '../../../../../hooks/api/workReferences';
import {
  DeleteMediaConfirmationModal,
  MediaOptionsDropdown,
  ReportModal,
} from '../../../../shared';

dayjs.extend(localizedFormat);
dayjs().format('L LT');

interface WorkItemProps {
  item: WorkReference;
  ii: number;
  count: number;
  user: any;
}

export default function WorkItem({ item, ii, count, user }: WorkItemProps) {
  const router = useRouter();
  const notify = useNotify();
  const { currentUser } = useUser();
  const { t } = useTranslation();
  const [openOptionsDropdown, setOpenOptionsDropdown] = React.useState(false);
  const [openDeleteForm, setOpenDeleteForm] = React.useState(false);
  const [openReportForm, setOpenReportForm] = React.useState(false);

  const updateWorkReferenceMutation = useUpdateWorkReference();
  const queryClient = useQueryClient();

  const onDelete = async () => {
    try {
      await updateWorkReferenceMutation.mutateAsync(
        {
          _id: item?._id ?? undefined,
          active: false,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(['workReferences']);
          },
        }
      );

      notify(t('profile.tabs.workReference.update.success'), 'success');
      setOpenDeleteForm(false);
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  const handleGoToUpdateForm = () => {
    setOpenOptionsDropdown(false);
    router.push(
      {
        pathname: `${router.pathname}`,
        query: {
          ...router.query,
          work: item?._id,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  const colorsBasedOnStatus = {
    approved: 'bg-primary-500',
    pending: 'bg-warning-400',
    rejected: 'bg-danger-400',
  };
  return (
    <div className="flex w-full gap-2 ml-6">
      <DeleteMediaConfirmationModal
        handleDelete={() => {
          onDelete();
        }}
        isOpen={openDeleteForm}
        setOpen={setOpenDeleteForm}
      />
      <ReportModal
        isOpen={openReportForm}
        setOpen={setOpenReportForm}
        item="work"
        itemOwner={user?.profile?.user}
      />
      {/* left side: company logo  */}
      <div className="flex flex-col gap-4 items-center ">
        <div
          className={`w-[1px] h-2 ${
            ii > 0 ? 'bg-[var(--bg-200,#E7F2F9)]' : 'bg-transparent mt-1'
          }`}
        />
        <div className="flex items-center relative">
          {currentUser?._id === user?._id && item?.status === 'pending' && (
            <div className="relative">
              <div
                data-tooltip-target="tooltip-animation"
                className={`w-3 h-3 rounded-full animate-pulse absolute -left-6 ${
                  colorsBasedOnStatus[item?.status]
                }`}
              />
              <div
                id="tooltip-animation"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                {t(`profile.tabs.workReference.status.${item?.status}`)}
                <div className="tooltip-arrow" data-popper-arrow />
              </div>
            </div>
          )}
          <div className="w-12 min-h-[48px] rounded-[5px] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.10)] bg-[var(--bg-200,#E7F2F9)] flex justify-center items-center">
            {(item?.company as unknown as Profile)?.photo ? (
              <img
                src={(item?.company as unknown as Profile)?.photo?.url}
                alt={(item?.company as unknown as Profile)?.firstName}
                className="w-12 h-12 object-cover rounded-[5px]"
              />
            ) : (
              <FontAwesomeIcon
                icon={faBuilding}
                className="h-[17.5px] text-primary-500"
              />
            )}
          </div>
        </div>
        {ii !== count - 1 && (
          <div className="w-[1px] h-full bg-[var(--bg-200,#E7F2F9)]" />
        )}
      </div>
      {/* right side: company info  */}
      <div className="py-6 md:px-8 px-4 w-full flex flex-col md:flex-row md:gap-8 gap-2 mb-6">
        {/* position, company name and date */}
        <div className="md:w-1/3">
          <div className="flex flex-col items-start justify-center">
            <p className="font-semibold">{item?.position}</p>
            <p className="font-light mb-3">{item?.companyName}</p>
            {/* {currentUser?._id === user?._id && (
              <span
                className={`w-fit h-fit text-sm ${
                  colorsBasedOnStatus[item?.status]
                }`}
              >
                {t(`profile.tabs.workReference.status.${item?.status}`)}
              </span>
            )} */}
          </div>
          <p>{(item?.company as unknown as Profile)?.firstName}</p>
          <p className=" text-sm text-neutral-500 ">
            {`${dayjs.utc(item?.startDate).format('ll')} - ${
              item?.endDate
                ? dayjs.utc(item?.endDate ?? '').format('ll')
                : t('profile.tabs.workReference.present')
            }`}
          </p>
        </div>
        {/* description, 3 dots button */}
        <div className="flex justify-between gap-4 w-full ">
          <p className="text-sm">{item?.description}</p>
          <div className="flex flex-col gap-2 h-full items-end justify-center">
            <div className="hidden md:block relative">
              <button
                className="flex flex-row"
                type="button"
                onClick={() => setOpenOptionsDropdown(!openOptionsDropdown)}
              >
                <ThreeDotsIcon className="h-3" />
              </button>
              {openOptionsDropdown ? (
                <MediaOptionsDropdown
                  setOpenMediaOptionsDropdown={setOpenOptionsDropdown}
                  onEdit={
                    router?.pathname === '/profile'
                      ? () => {
                          handleGoToUpdateForm();
                        }
                      : null
                  }
                  onDelete={
                    router?.pathname === '/profile'
                      ? () => {
                          setOpenDeleteForm(true);
                          setOpenOptionsDropdown(false);
                        }
                      : null
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden py-8 relative">
        <button
          className="flex flex-row"
          type="button"
          onClick={() => setOpenOptionsDropdown(!openOptionsDropdown)}
        >
          <ThreeDotsIcon className="h-3" />
        </button>
        {openOptionsDropdown ? (
          <MediaOptionsDropdown
            onEdit={
              router?.pathname === '/profile'
                ? () => {
                    handleGoToUpdateForm();
                  }
                : null
            }
            onDelete={
              router?.pathname === '/profile'
                ? () => {
                    setOpenDeleteForm(true);
                    setOpenOptionsDropdown(false);
                  }
                : null
            }
          />
        ) : null}
      </div>
    </div>
  );
}
