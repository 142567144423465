import { DocumentModel, Multimedia } from '@dinbog/models';

export const documentToMedia = (documents: DocumentModel[]): Multimedia[] =>
  documents.map(
    (doc) =>
      ({
        // _id: doc.id,
        fileName: doc.name,
        url: doc.src,
        ...(doc?.file?.type ? { type: doc?.file?.type } : {}),
      } as Multimedia)
  );

export const multimediaToDocument = (media: Multimedia[]): DocumentModel[] =>
  media.map((m) => ({
    // id: m._id,
    src: m?.url,
    name: m?.fileName,
  }));
