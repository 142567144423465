import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@dinbog/ui';
import { User } from '@dinbog/models';
import { PersonItem } from '../shared';
import { useUser } from '../../hooks';

interface RequestCardProps {
  request: any;
  onAcceptOrRejectRequest: (
    type: string,
    accepted: boolean,
    item: string
  ) => void;
  refetchRequests;
  isDropDown?: boolean;
}

export default function RequestCard({
  request,
  onAcceptOrRejectRequest,
  refetchRequests,
  isDropDown,
}: RequestCardProps) {
  const { t } = useTranslation();
  const { currentUser: user } = useUser();

  const cardStyle = isDropDown
    ? 'flex flex-col gap-2 w-full p-2'
    : 'flex flex-col md:flex-row justify-stretch items-center h-fit w-full p-2';
  if ((user?.currentUser?.user as User)?._id !== request?.from?._id)
    return (
      <div className={cardStyle}>
        <PersonItem
          isHorizontal
          size="sm"
          user={{ profile: request?.from?.profile }}
        >
          <div
            className={`flex flex-col gap-1 ${
              isDropDown ? 'min-w-[290px]' : 'w-auto'
            }`}
          >
            <PersonItem.Header user={{ profile: request?.from?.profile }} />
            <PersonItem.Body user={{ profile: request?.from?.profile }}>
              {' '}
              {request?.type === 'follow'
                ? t('requests.followReq')
                : t('requests.connectionReq')}
            </PersonItem.Body>
          </div>
        </PersonItem>
        <div className="ml-auto flex gap-x-2 justify-stretch w-1/2 md:w-auto">
          <Button
            tooltip={t('reject')}
            className="btn-secondary h-fit"
            size="sm"
            onClick={() =>
              onAcceptOrRejectRequest(
                request?.type,
                false,
                request?.from?._id as string
              )
            }
          >
            {' '}
            {t('reject')}
          </Button>
          <Button
            tooltip={t('accept')}
            className="btn-primary h-fit"
            size="sm"
            onClick={() =>
              onAcceptOrRejectRequest(request?.type, true, request?._id)
            }
          >
            {' '}
            {t('accept')}
          </Button>
        </div>
      </div>
    );
  return (
    <div className="h-fit w-full">
      <PersonItem
        isHorizontal
        size="sm"
        user={{ profile: request?.to?.profile }}
      >
        <div className="flex flex-col gap-1 w-full">
          <PersonItem.Body user={{ profile: request?.from?.profile }}>
            {request?.type === 'follow'
              ? t('requests.sentReqs.followReq')
              : t('requests.sentReqs.connectionReq')}{' '}
            <span className="font-semibold">
              {request?.to?.profile?.firstName} {request?.to?.profile?.lastName}
            </span>
          </PersonItem.Body>
        </div>
        <div className="ml-auto flex items-center gap-x-2 justify-self-end">
          <Button
            tooltip={t('profile.relations.requested')}
            className="btn-secondary h-fit"
            size="sm"
            onClick={() => {
              onAcceptOrRejectRequest(
                request?.type,
                false,
                request?.type === 'follow'
                  ? request?._id
                  : (request?.to?._id as string)
              );
            }}
          >
            {t('profile.relations.requested')}
          </Button>
        </div>
      </PersonItem>
    </div>
  );
}
