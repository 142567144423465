import React from 'react';
import { CategoryTypes, User, WorkReference } from '@dinbog/models';
import { validateString } from 'avilatek-utils';
import { useRouter } from 'next/router';
import ProfileInfoCard from '../shared/profileInfoCard/ProfileInfoCard';
import {
  useInfiniteWorkReferences,
  useWorkReferences,
} from '../../../hooks/api/workReferences';
import { AudioTab, VideoTab, WorkTab, BookTab, PostTab } from '../shared/tabs';
import { useVideos } from '../../../hooks/api/videos';
import { useCategories } from '../../../hooks/api/categories';
import { useAlbums } from '../../../hooks/api/albums';
import { useGetTalentPosts } from '../../../hooks/api/posts';
import { useBooks } from '../../../hooks/api/books';
import { Tab } from '../../shared';
import { useUser } from '../../../hooks';

interface TalentProfileProps {
  user: any;
  findUserBySlug?: () => void;
}

export default function TalentProfile({
  user,
  findUserBySlug = null,
}: TalentProfileProps) {
  const { currentUser } = useUser();
  const router = useRouter();
  const [tabSelected, setTabSelected] = React.useState('posts');
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 10,
  });

  const [categoryFilter, setCategoryFilter] = React.useState(''); // id of the category to filter media

  const {
    data: dataPosts,
    isLoading: isLoadingPosts,
    refetch: refetchPosts,
  } = useGetTalentPosts({
    ...pagination,
    user: user?._id,
    active: true,
    sort: 'createdAt',
    viewer: (currentUser?.profile?.user as User)?._id as string,
  });

  const { data: dataVideos, isLoading: loadingVideos } = useVideos(
    {
      user: user?._id as string,
      ...pagination,
      active: true,
      categories:
        validateString(categoryFilter) && tabSelected === 'videos'
          ? ([categoryFilter] as string[])
          : null, // only filter when the tab is selected
    },
    {
      enabled: tabSelected === 'videos' || categoryFilter === '',
    }
  );

  const { data: dataAlbums, isLoading: loadingAlbums } = useAlbums(
    {
      user: user?._id as string,
      ...pagination,
      active: true,
      categories:
        validateString(categoryFilter) && tabSelected === 'audios'
          ? ([categoryFilter] as string[])
          : null, // only filter when the tab is selected
    },
    {
      enabled: tabSelected === 'audios' || categoryFilter === '',
    }
  );

  const {
    data: dataWorks,
    fetchNextPage,
    isLoading: loadingWorks,
  } = useInfiniteWorkReferences(
    {
      filter: JSON.stringify({
        user: user?._id,
        status:
          user?._id !== ((currentUser?.profile?.user as User)?._id as string)
            ? 'approved'
            : undefined,
        active: true,
      }), // only show approved works if the user is not the owner
      sortBy: 'startDate',
      order: -1,
      ...pagination,
    },
    {
      enabled: tabSelected === 'works' || categoryFilter === '',
    }
  );

  const { data: dataBooks, isLoading: loadingBooks } = useBooks(
    {
      user: user?._id as string,
      ...pagination,
      active: true,
      categories:
        validateString(categoryFilter) && tabSelected === 'books'
          ? ([categoryFilter] as string[])
          : null, // only filter when the tab is selected
    },
    {
      enabled: tabSelected === 'books' || categoryFilter === '',
    }
  );

  const { data: dataCategories, isLoading: isLoadingCategories } =
    useCategories(
      {
        type: tabSelected?.replace(/s$/, '') as CategoryTypes,
      },
      'portfolios', // To get categories from ms-portfolios
      {
        refetchOnWindowFocus: false,
      }
    );

  const worksData = React.useMemo(() => {
    const pages = dataWorks?.pages || [];
    const flattenedItems = pages.reduce(
      (acc, page) => [...acc, ...page.items],
      [] as WorkReference[]
    );
    const totalCount = pages[0]?.count || 0;
    const pageInfo = pages[pages.length - 1]?.pageInfo;

    return {
      count: totalCount,
      items: flattenedItems,
      pageInfo,
    };
  }, [dataWorks]);

  const items = React.useMemo(
    () => ({
      posts: {
        title: 'Posts',
        number: dataPosts?.count ?? 0,
        itemKey: 'posts',
        component: (
          <PostTab
            postsData={dataPosts}
            loading={isLoadingPosts}
            setPagination={setPagination}
            onSuccessLike={() => {
              refetchPosts();
            }}
          />
        ),
      },
      books: {
        title: 'Books',
        number: dataBooks?.count ?? 0,
        itemKey: 'books',
        component: (
          <BookTab
            setPagination={setPagination}
            bookData={dataBooks}
            loading={loadingBooks}
            categories={dataCategories}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
          />
        ),
      },
      videos: {
        title: 'Videos',
        number: dataVideos?.count ?? 0,
        itemKey: 'videos',
        component: (
          <VideoTab
            setPagination={setPagination}
            videoData={dataVideos}
            loading={loadingVideos}
            categories={dataCategories}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
          />
        ),
      },
      audios: {
        title: 'Audios',
        number: dataAlbums?.count ?? 0,
        itemKey: 'audios',
        component: (
          <AudioTab
            setPagination={setPagination}
            albumData={dataAlbums}
            loading={loadingAlbums}
            categories={dataCategories}
            setCategoryFilter={setCategoryFilter}
            categoryFilter={categoryFilter}
          />
        ),
      },
      works: {
        title: 'CV',
        number: worksData.count ?? 0,
        itemKey: 'works',
        component: (
          <WorkTab
            workReferenceData={worksData}
            user={user} // the owner of the profile
            fetchNextPage={fetchNextPage}
          />
        ),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataPosts,
      dataVideos,
      dataAlbums,
      dataCategories,
      tabSelected,
      dataBooks,
      dataWorks,
    ]
  );

  React.useEffect(() => {
    setCategoryFilter(''); // reset filter if tab changes
    // reset pagination
    if (tabSelected !== router?.query?.tab) {
      setPagination((prev: { page: number; perPage: number }) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [tabSelected]);

  React.useEffect(() => {
    if (router?.query?.tab === 'talents') {
      router.push(
        {
          pathname: `${router.pathname}`,
          query: {
            tab: 'posts',
          },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router.query.tab]);

  return (
    <div className="space-y-12">
      <ProfileInfoCard user={user} findUserBySlug={findUserBySlug} />
      <div className="md:px-12 px-6">
        <Tab
          items={Object.values(items)}
          currentItem={tabSelected}
          setCurrentItem={setTabSelected}
          isQueryParam
        />
        <div className="sm:px-16 sm:py-12 py-6 px-0 w-full">
          {router.isReady ? items[tabSelected]?.component : null}
        </div>
      </div>
    </div>
  );
}
