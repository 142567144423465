import React from 'react';
import { Crop, Point, Area, DocumentModel } from '../models';
import getCroppedImg from '../utils/cropImage';

interface useCropImageProps {
  defaultCrop: Crop;
  img: DocumentModel;
}

export type TUseCropImage = {
  onCropChange: (crop: Point) => void;
  onZoomChange: (zoom: number) => void;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
  completeCropImage: () => void;
  croppedImage: string;
  crop: Crop;
};

function useCropImage({ defaultCrop, img }: useCropImageProps): TUseCropImage {
  const [crop, setCrop] = React.useState<Crop>(defaultCrop);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area>(null);
  const [croppedImage, setCroppedImage] = React.useState<string>(null);

  const onCropChange = (_crop: Point) => {
    setCrop({ ...crop, crop: _crop });
  };

  const onZoomChange = (zoom: number) => {
    setCrop({ ...crop, zoom });
  };

  const onCropComplete = React.useCallback(
    (croppedArea, _croppedAreaPixels) => {
      setCroppedAreaPixels(_croppedAreaPixels);
    },
    []
  );

  const completeCropImage = React.useCallback(async () => {
    try {
      const _croppedImage = await getCroppedImg(img.src, croppedAreaPixels);
      setCroppedImage(_croppedImage);
      return _croppedImage;
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return {
    onCropChange,
    onZoomChange,
    onCropComplete,
    completeCropImage,
    croppedImage,
    crop,
  };
}

export default useCropImage;
