import React, { createContext, useContext, useMemo } from 'react';
import { useInfiniteSearch } from '../hooks/api/search';
import { useUser } from '../hooks';
import { CollectionType } from '@dinbog/models';

type SearchContextType = {
  searchData: {
    count: number;
    items: any[];
    pageInfo: any;
  };
  fetchNextPage: () => void;
  loadingSearch: boolean;
  refetch: () => void;
};

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider: React.FC<{
  query: string;
  collection: CollectionType;
  filtersData: any;
  children: React.ReactNode;
}> = ({ children, query, collection, filtersData }) => {
  const { currentUser: user } = useUser();
  const pagination = {
    page: 1,
    perPage: 10,
  };
  const {
    data: dataSearch,
    fetchNextPage,
    isLoading: loadingSearch,
    refetch,
  } = useInfiniteSearch(
    {
      query,
      collection,
      filter: JSON.stringify(filtersData),
      ...pagination,
    },
    user?.token,
    { enabled: !!user?.token }
  );

  const searchData = useMemo(() => {
    const pages = dataSearch?.pages || [];
    const flattenedItems = pages.reduce(
      (acc, page) => [...acc, ...page.items],
      [] as any[]
    );
    const totalCount = pages[0]?.count || 0;
    const pageInfo = pages[pages.length - 1]?.pageInfo;

    return {
      count: totalCount,
      items: flattenedItems,
      pageInfo,
    };
  }, [dataSearch]);

  return (
    <SearchContext.Provider
      value={{ searchData, fetchNextPage, loadingSearch, refetch }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};
