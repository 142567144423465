import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNotify, useUser } from '../../../../../hooks';
import { useVideo, useUpdateVideo } from '../../../../../hooks/api/videos';
import { MediaProfileLayout } from '../../../../layout';
import VideoForm from './VideoForm';
import {
  DeleteMediaConfirmationModal,
  Post,
  ReportModal,
  SkeletonWrapper,
} from '../../../../shared';

function VideoProfile({ categories }) {
  const router = useRouter();
  const { currentUser: user } = useUser();
  const { slug: userSlug } = router.query;
  const notify = useNotify();
  const [update, setUpdate] = React.useState(false);
  const [openDeleteForm, setOpenDeleteForm] = React.useState(false);
  const [openReportForm, setOpenReportForm] = React.useState(false);
  const { t } = useTranslation();
  const { data, isLoading, refetch, isError } = useVideo(
    {
      currentUser: user?._id,
      _id: router.query.video as string,
    },
    { enabled: !!router.query.video }
  );

  if (isError && !router.query.video) {
    router.push('/profile?tab=videos');
  }

  const profileInfo = {
    user: {
      slug: userSlug as string,
    },
  };

  const dataWithSlugEqualstoId = {
    ...data,
    slug: router.query.video as string,
  };

  const updateVideoMutation = useUpdateVideo();
  const queryClient = useQueryClient();
  const onDelete = async () => {
    try {
      await updateVideoMutation.mutateAsync(
        {
          _id: data?._id ?? undefined,
          active: false,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(['videos']);
            notify(t('profile.tabs.video.update.success'), 'success');
            setOpenDeleteForm(false);
            router.push({
              pathname: `${router.pathname}`,
              query: {
                ...router.query,
                video: undefined,
              },
            });
          },
        }
      );
    } catch (err) {
      notify(err?.response?.data?.message || t('error'), 'error');
    }
  };

  console.log(data);
  return (
    <SkeletonWrapper isLoading={isLoading}>
      <MediaProfileLayout
        isOwner={router?.pathname === '/profile'}
        handleGoBack={() => {
          if (update) {
            return setUpdate(false);
          }

          router.push({
            pathname: `${router.pathname}`,
            query: {
              ...router.query,
              video: undefined,
            },
          });
        }}
        type={t('profile.tabs.video.title')}
        setOpenEditForm={setUpdate}
        setOpenDeleteForm={setOpenDeleteForm}
        setOpenReportForm={setOpenReportForm}
      >
        {update ? (
          <VideoForm
            data={data}
            categories={categories}
            setIsInForm={setUpdate}
          />
        ) : (
          <Post
            content={dataWithSlugEqualstoId}
            owner={profileInfo}
            contentType="video"
            onSuccessLike={() => refetch()}
          />
        )}
        <DeleteMediaConfirmationModal
          handleDelete={() => {
            onDelete();
          }}
          isOpen={openDeleteForm}
          setOpen={setOpenDeleteForm}
        />
        <ReportModal
          isOpen={openReportForm}
          setOpen={setOpenReportForm}
          item="video"
          itemIdToBeReported={router.query.video as string}
          service="portfolio"
          itemOwner={data?.user as string}
        />
      </MediaProfileLayout>
    </SkeletonWrapper>
  );
}

export default VideoProfile;
