import React from 'react';

interface InfiniteDropdownProps {
  items;
  fetchNextPage: any;
  children: (item: any, index?: number) => React.ReactNode;
  containerClassName?: string;
}

function InfiniteList({
  items,
  fetchNextPage,
  children,
  containerClassName = 'w-full',
}: InfiniteDropdownProps) {
  const observer = React.useRef<IntersectionObserver>();
  const lastOptionRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries.length > 0 && entries[0].isIntersecting) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [items]
  );
  return (
    <div className={containerClassName}>
      {items?.map((item, index) => (
        <div
          key={item._id}
          ref={index === items.length - 1 ? lastOptionRef : null}
        >
          {children(item, index)}
        </div>
      ))}
    </div>
  );
}

export default InfiniteList;
