/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Application, Pagination, Profile, User } from '@dinbog/models';
import { Button } from '@dinbog/ui';
import { useNotify } from '../../../../../../../hooks';
import { useUpdateApplication } from '../../../../../../../hooks/api/applications';
import { PersonItem } from '../../../../../../shared';
import UserInfoModal from './UserInfoModal';

export default function VacancyApplications({
  data,
  isLoading = false,
  tab = 'enrolled',
}: {
  data: Pagination<Application>;
  isLoading: boolean;
  tab: 'about' | 'enrolled' | 'preselected' | 'selected' | 'rejected';
}) {
  const notify = useNotify();
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const updateMutation = useUpdateApplication();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const manageApplication = async (
    applicationId: string,
    status: 'initial' | 'preselected' | 'selected' | 'rejected'
  ) => {
    setDisabled(true);
    try {
      const response = await updateMutation?.mutateAsync(
        {
          _id: applicationId,
          status,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries([
              'applications' /* , { vacant: router.query._id } */,
            ]);
            queryClient.refetchQueries([
              'vacant' /* , { vacant: router.query._id } */,
            ]);
          },
        }
      );
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const manageOpenDeets = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  return (
    <div className="flex flex-wrap w-full gap-4">
      {data?.items?.map((application) => (
        <div className="w-fit" key={(application?.user as Profile)?._id}>
          <PersonItem user={{ profile: application?.user }} isHorizontal>
            <button
              type="button"
              onClick={() =>
                manageOpenDeets((application?.user as Profile)?._id)
              }
              className=""
            >
              <PersonItem.Header user={{ profile: application?.user }} />
              <PersonItem.Body
                user={{
                  profile: application?.user,
                }}
              />
            </button>
            {tab !== 'selected' ? (
              <div className="flex gap-1 w-full">
                {tab !== 'rejected' ? (
                  <Button
                    tooltip={t('reject')}
                    className="btn-secondary w-full"
                    size="sm"
                    disabled={disabled}
                    onClick={() =>
                      manageApplication(application?._id, 'rejected')
                    }
                  >
                    {t('reject')}
                  </Button>
                ) : null}
                <Button
                  className="btn-primary w-full"
                  size="sm"
                  disabled={disabled}
                  onClick={() =>
                    manageApplication(
                      application?._id,
                      tab === 'preselected' ? 'selected' : 'preselected'
                    )
                  }
                >
                  {tab === 'preselected' ? 'Select' : 'Preselect'}
                </Button>
              </div>
            ) : null}
          </PersonItem>
        </div>
      ))}
      <UserInfoModal
        profileId={selectedUser}
        isOpen={isOpen}
        setOpen={setOpen}
      />
    </div>
  );
}
