import React from 'react';
import { useTranslation } from 'react-i18next';

export default function StatusTag({ status }: { status }) {
  const { t } = useTranslation();
  const sharedClassName = 'py-1 px-3 text-xs rounded h-fit text-center';
  switch (status) {
    case 'active':
      return (
        <div className={`${sharedClassName} text-text-white bg-success-200`}>
          {t('events.states.active')}
        </div>
      );
    case 'closed':
      return (
        <div className={`${sharedClassName} text-text-white bg-neutral-400`}>
          {t('events.states.closed')}
        </div>
      );
    case 'rejected':
      return (
        <div className={`${sharedClassName} text-text-white bg-danger-200`}>
          {t('events.casting.tabs.rejected')}
        </div>
      );
    case 'upcoming':
      return (
        <div
          className={`${sharedClassName} text-primary-500 bg-background-300`}
        >
          {t('events.states.upcoming')}
        </div>
      );
    default:
      break;
  }
}
