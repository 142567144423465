/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Button, InfiniteScroll, SpinnerIcon } from '@dinbog/ui';
import { useTranslation } from 'react-i18next';
import { PageInfo, Pagination, User } from '@dinbog/models';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useFollowUser, useUnfollowUser } from '../../hooks/api/follows';
import { useConnect, useDisconnect } from '../../hooks/api/connections';
import { useNotify, useUser } from '../../hooks';
import PersonItem from './personCard';
import ActionsButtons from '../profile/shared/actions/ActionsButtons';

interface UsersListProps {
  users: any[];
  relation: string;
  loading: boolean;
  setPagination: React.Dispatch<React.SetStateAction<Partial<PageInfo>>>;
  setUsers: React.Dispatch<React.SetStateAction<any[]>>;
  paginationData: Pagination<any>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  refectConnections: any;
  refetchFollowing: any;
}

export default function UsersList({
  users,
  relation,
  loading,
  setPagination,
  setUsers,
  paginationData,
  setOpen,
  refectConnections,
  refetchFollowing,
}: UsersListProps) {
  const { currentUser: user, updateCurrentUser } = useUser();

  const notify = useNotify();
  const router = useRouter();
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  // mutations
  const followMutation = useFollowUser();
  const unfollowMutation = useUnfollowUser();
  const connectMutation = useConnect();
  const disconnectMutation = useDisconnect();

  const queryClient = useQueryClient();

  // interactions handlers
  const handleFollow = async (toId: string) => {
    setDisabled(true);
    try {
      const _data = await followMutation.mutateAsync({
        from: (user?.currentUser?.user as User)?._id as string,
        to: toId,
      });
      if (_data) {
        queryClient.refetchQueries([
          'followers',
          { user: (user?.currentUser?.user as User)?._id as string },
        ]);
        queryClient.refetchQueries([
          'following',
          { user: (user?.currentUser?.user as User)?._id as string },
        ]);
        updateCurrentUser();
        refetchFollowing();
      }
      if (relation === 'connections') {
        refectConnections();
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleUnfollow = async (toId: string) => {
    setDisabled(true);
    try {
      const _data = await unfollowMutation.mutateAsync({
        from: (user?.currentUser?.user as User)?._id as string,
        to: toId,
      });
      if (_data) {
        queryClient.refetchQueries([
          'followers',
          { user: (user?.currentUser?.user as User)?._id as string },
        ]);
        queryClient.refetchQueries([
          'following',
          { user: (user?.currentUser?.user as User)?._id as string },
        ]);
        updateCurrentUser();
        refetchFollowing();
      }
      if (relation === 'connections') {
        refectConnections();
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleConnect = async (toId: string) => {
    setDisabled(true);
    try {
      const _data = await connectMutation.mutateAsync({
        from: (user?.currentUser?.user as User)?._id as string,
        to: toId,
        type: 'friend', // REVISAR
      });
      if (_data) {
        updateCurrentUser();
        refectConnections();
        refetchFollowing();
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const handleDisconnect = async (toId: string) => {
    setDisabled(true);
    try {
      const _data = await disconnectMutation.mutateAsync({
        from: (user?.currentUser?.user as User)?._id as string,
        to: toId,
      });
      if (_data) {
        refectConnections();
        updateCurrentUser();
        refetchFollowing();
      }
      if (relation === 'following') {
        refetchFollowing();
        refectConnections();
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onClick = (u) => {
    setOpen(false);
    router.push({
      pathname: '/[slug]',
      query: {
        slug: u?.profile?.user?.slug,
        tab: 'posts',
      },
    });
  };

  return (
    <InfiniteScroll
      loading={loading}
      setPagination={setPagination}
      data={paginationData}
      setLoadedData={setUsers}
      maxHeight={350}
    >
      {/* loading ? (
        <SpinnerIcon className=" w-10 h-10 text-gray-200 animate-spin  fill-primary-500" />
      ) : null */}
      {users?.map((u, idx) => (
        <PersonItem
          key={idx}
          isHorizontal
          user={{ profile: u?.profile }}
          size="sm"
          onClick={() => onClick(u)}
        >
          <div className="w-1/3 min-w-[81px] overflow-hidden">
            <button type="button" onClick={() => onClick(u)} className="">
              <PersonItem.Header user={{ profile: u?.profile }} />
              <PersonItem.Body user={{ profile: u?.profile }} />
            </button>
          </div>
          {((user?.currentUser?.user as User)?._id as string) !==
          u?.profile?.user?._id ? (
            <div className="h-fit w-[200px] flex justify-end">
              <ActionsButtons
                disabled={disabled}
                user={u}
                handleFollow={handleFollow}
                handleUnfollow={handleUnfollow}
                handleConnect={handleConnect}
                handleDisconnect={handleDisconnect}
                buttonClassName="min-w-[90px]"
              />
            </div>
          ) : null}
        </PersonItem>
      ))}
    </InfiniteScroll>
  );
}
