import React from 'react';

export default function SquaresLayout({ children }) {
  return (
    <div className="w-full md:h-screen flex flex-row lg:items-center justify-center md:px-[100px] py-16 px-16">
      <div className="z-0 absolute top-0 left-0 w-screen md:h-screen">
        <div className="bg-primary-500 rounded-bl-[100px] rounded-tr-[100px] h-[365px] w-[391px] opacity-10 absolute top-40 left-9" />
        <div className="bg-primary-500 rounded-bl-[100px] rounded-tr-[100px] h-[568px] w-[530px] opacity-5 absolute bottom-20 -right-9 md:block hidden" />
      </div>
      <div className="bg-white flex flex-col p-16 items-center gap-y-9 shadow-sm rounded-lg z-10 w-full h-fit">
        {children}
      </div>
    </div>
  );
}
