/* eslint-disable no-nested-ternary */
import React from 'react';
import { Profile, User } from '@dinbog/models';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useFollowUser, useUnfollowUser } from '../../../../hooks/api/follows';
import { useNotify, useUser } from '../../../../hooks';
import { PersonItem } from '../..';
import FollowDropdown from './FollowDropdown';

interface SuggestedUserItemProps {
  user_: User & {
    profile: Profile;
    following: boolean;
    requestedFollow: boolean;
    requestedConnection: boolean;
  };
  setUsersData?: any;
  refetch?: any;
}

export default function SuggestedUserItem({
  user_,
  setUsersData,
  refetch,
}: SuggestedUserItemProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const notify = useNotify();
  const { currentUser: user } = useUser();
  const [disabled, setDisabled] = React.useState(false);

  const followMutation = useFollowUser();
  const unfollowMutation = useUnfollowUser();

  const onFollow = async (e) => {
    if (disabled) return;
    try {
      setDisabled(true);
      const { id } = e.currentTarget.dataset;
      await followMutation.mutateAsync(
        {
          from: (user?.currentUser?.user as User)?._id as string,
          to: id,
        },
        {
          onSuccess: (data) => {
            if (setUsersData)
              setUsersData((prev) =>
                prev.map((u) => ({
                  ...u,
                  following:
                    data.to === u._id && data.accepted ? true : u.following,
                  requestedFollow:
                    data.to === u._id && !data.accepted
                      ? true
                      : u.requestedFollow,
                }))
              );
            if (refetch) refetch();
          },
        }
      );
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onUnfollow = async (e) => {
    if (disabled) return;
    try {
      setDisabled(true);
      const { id } = e.currentTarget.dataset;
      await unfollowMutation.mutateAsync(
        {
          from: (user?.currentUser?.user as User)?._id as string,
          to: id,
        },
        {
          onSuccess: (data) => {
            if (setUsersData)
              setUsersData((prev) =>
                prev.map((u) => ({
                  ...u,
                  following: data.to === u._id ? false : u.following,
                  requestedFollow:
                    data.to === u._id ? false : u.requestedFollow,
                }))
              );
            if (refetch) refetch();
          },
        }
      );
    } catch (err) {
      notify(err.response.data.message, 'error');
    } finally {
      setDisabled(false);
    }
  };

  const onClick = (u) => {
    router?.push(`/${u?.slug}`);
  };

  return (
    <div className="w-full flex items-center justify-between gap-3">
      <PersonItem
        cardPadding="p-0 items-center"
        isHorizontal
        user={{ profile: user_?.profile }}
        size="sm"
        onClick={() => onClick(user_)}
      >
        <button
          type="button"
          onClick={() => onClick(user_)}
          className=" text-left mr-auto w-full"
        >
          <span className="text-sm">
            {user_?.profile?.firstName} {user_?.profile?.lastName ?? ''}
          </span>
          <PersonItem.Body
            user={{ profile: user_?.profile }}
            textSize="text-xs mr-auto"
          />
        </button>
      </PersonItem>
      <div className="text-white bg-primary-500 rounded flex justify-center items-center pr-1">
        <button
          type="button"
          className={` rounded-l text-sm w-fit h-fit px-2 py-1 my-auto ${
            user?.currentUser?.categories?.length > 0 &&
            ' border border-y-0 border-l-0 border-r-1 border-white'
          }`}
          data-id={user_?._id}
          onClick={user_.following ? onUnfollow : onFollow}
          disabled={disabled}
        >
          {user_.following
            ? t('profile.relations.actions.unfollow')
            : user_.requestedFollow
            ? t('profile.relations.requested')
            : t('follow.follow')}
        </button>
        {user?.currentUser?.categories?.length > 0 && (
          <FollowDropdown toUser={user_} setUsersData={setUsersData} />
        )}
      </div>
    </div>
  );
}
