import React from 'react';
import InfiniteList from '../../shared/infinite-list/InfiniteList';
import UserResultItem from './UserResultItem';
import { useSearchContext } from '../../../context';

export default function UserResults() {
  const { searchData, fetchNextPage } = useSearchContext();

  return (
    <InfiniteList
      items={searchData?.items}
      fetchNextPage={fetchNextPage}
      containerClassName="flex flex-wrap gap-4"
    >
      {(userItem) => <UserResultItem userItem={userItem} />}
    </InfiniteList>
  );
}
