// /api/communications
import { AxiosError } from 'axios';
import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { axios } from '../../api';
import { Notification, Pagination } from '@dinbog/models';

const NOTIFICATIONS_API = '/api/communications/notificactions/v1';

interface Filters {
  page: number;
  perPage: number;
  token: string;
}

export function useUserNotifications(
  filters: Filters,
  options: Omit<
    UseInfiniteQueryOptions<any, AxiosError, any, ['userNotifications']>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const query = useInfiniteQuery<any, AxiosError>(
    ['userNotifications', filters],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get<any>(`/api/communications/notificactions/v1`, {
        params: { ...filters, page: pageParam },
        headers: {
          Authorization: `Bearer ${filters.token}`,
        },
      });
      return data;
    },
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    }
  );
  return query;
}

export function usePaginatedNotifications(user: any | null) {
  async function fetchPaginatedNotifications({ pageParam = 1 }) {
    const response = await axios.get(NOTIFICATIONS_API, {
      params: {
        page: pageParam,
        perPage: 10,
        token: user?.token,
      },
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
    return response.data as any;
  }

  const {
    data: InfiniteNotificationData,
    fetchNextPage,
    isLoading: isLoadingInfiniteNotification,
    isError: isErrorInfiniteNotification,
  } = useInfiniteQuery<
    Pagination<Notification>,
    unknown,
    Pagination<Notification>
  >({
    queryKey: ['notificationsPaginated'],
    queryFn: fetchPaginatedNotifications,
    getNextPageParam: (lastPage) =>
      lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.page + 1 : undefined,
    enabled: !!user?.token,
    cacheTime: 0,
  });

  return {
    InfiniteNotificationData,
    fetchNextPage,
    isLoadingInfiniteNotification,
    isErrorInfiniteNotification,
  };
}