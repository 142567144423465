import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { Category, Subcategory, User } from '@dinbog/models';
import { ACTIONS_TYPE } from '../../auth/talentSignUp/reducer';
import { ACTIONS_TYPE as BUSINESS_ACTIONS_TYPE } from '../../auth/businessSignUp/reducer';
import { useNotify, useUser } from '../../../hooks';
import { usePaginatedCategories } from '../../../hooks/api/categories';
import { useUpdateProfile } from '../../../hooks/api/profiles';
import { useUpdateBusiness } from '../../../hooks/api/businesses';
import FormButtons from '../../auth/shared/FormButtons';
import FadeAnimation from '../FadeAnimation';
import CategoryGrid from './CategoryGrid';
import SubcategoryList from './subcategory/SubcategoryList';
import { SkeletonWrapper } from '../skeleton';

interface CategorySelectionProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  state?: any;
  dispatch?: React.Dispatch<ACTIONS_TYPE | BUSINESS_ACTIONS_TYPE>;
  type: 'talent' | 'business';
  formsLength: number;
  updateMutation;
}

export default function CategorySelection({
  currentStep = 1,
  setCurrentStep,
  state,
  dispatch = () => {},
  type = 'talent',
  formsLength,
  updateMutation,
}: CategorySelectionProps) {
  const { currentUser: user, setCurrentUser: setUser } = useUser();
  const router = useRouter();
  const notify = useNotify();
  const { t } = useTranslation();
  const [pagination, setPagination] = React.useState({
    page: 1,
    perPage: 18,
  });

  const { data, status, isLoading, error, refetch } = usePaginatedCategories({
    ...pagination,
    filter: JSON.stringify({ type, active: true }),
  });
  const [categories, setCategories] = React.useState([]);
  const methods = useForm({
    defaultValues: {
      userCategories: user?.currentUser?.categories || [],
      userSubcategories: user?.currentUser?.subcategories || [],
    },
  });
  const {
    fields: selectedCategories,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: 'userCategories',
    rules: { maxLength: 10 },
  });

  const {
    fields: selectedSubcategories,
    append: appendSubcategory,
    remove: removeSubcategory,
  } = useFieldArray({
    control: methods.control,
    name: 'userSubcategories',
    rules: { maxLength: 100 },
  });

  const manageCategories = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.currentTarget.dataset;
    const category = categories?.filter((cat) => cat._id === id)[0];
    if (!(selectedCategories as Category[])?.find((c) => c?._id === id)) {
      // add to selectedCategories only if the 10 categories limit has not been reached
      if (selectedCategories?.length < 10) {
        append(category);
      } else {
        notify(t('auth.signUp.wizard.talentCategory.categoryLimit'), 'info'); //  info o warning?
      }
    } else {
      // remove from selectedCategories
      const idx = (selectedCategories as Category[])?.indexOf(
        (selectedCategories as Category[])?.find((c) => c?._id === id)
      );
      remove(idx);
    }
  };

  React.useEffect(() => {
    if (data) {
      setCategories(data?.items);
    }
  }, [data]);

  React.useEffect(() => {
    refetch();
  }, [pagination, refetch]);

  const onSubmit = async (e) => {
    let next = true;
    try {
      if (type === 'business' && e.userCategories.length === 0) {
        next = false;
        return notify(
          t('auth.signUp.wizard.business.notifs.noCategories'),
          'error'
        );
      }
      if (
        currentStep === 0 &&
        e.userCategories.length === 0 &&
        type === 'talent'
      ) {
        const _data = await updateMutation.mutateAsync({
          user: user?._id,
          categories: [],
          subcategories: [],
          registrationStep: '1',
        });
        if (!_data) {
          next = false;
          return notify(t('auth.signUp.notifs.failure'), 'error');
        }
        setUser({
          ...user,
          currentUser: {
            ...user?.currentUser,
            categories: [],
            subcategories: [],
          },
          ...(user?.currentUser?.type === 'person' ? { profile: _data } : {}),
        });
        // update currentStep
        setCurrentStep(currentStep + 1);
        // router.push('/sign-up/wizard');
      } else {
        const categoriesIds = e?.userCategories?.map((c) => c._id);
        const subcategoriesIds = e?.userSubcategories?.map((sc) => sc._id);

        const _data = await updateMutation.mutateAsync({
          user: (user?.currentUser?.user as User)?._id,
          categories: categoriesIds,
          subcategories: subcategoriesIds,
          registrationStep: '1',
        });
        if (!_data) {
          next = false;
          return notify(t('auth.signUp.notifs.failure'), 'error');
        }
        setUser({
          ...user,
          currentUser: {
            ...user?.currentUser,
            categories: _data?.categories,
            subcategories: _data?.subcategories,
          },
          ...(user?.currentUser?.type === 'person' ? { profile: _data } : {}),
        });
        // update currentStep
        setCurrentStep(currentStep + 1);
      }
    } catch (err) {
      notify(err.response.data.message, 'error');
    }
  };

  return (
    <form method="post" onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="gap-y-3 flex flex-col">
        <p className="text-center text-primary-500 font-semibold pt-8">
          {t('auth.signUp.wizard.talentCategory.selected')}:{' '}
          {selectedCategories.length}
        </p>
        <div className="flex flex-col gap-y-12">
          <div className="flex flex-col gap-y-4">
            <h4 className="font-semibold">
              {t('auth.signUp.wizard.talentCategory.categories')}
            </h4>
            <SkeletonWrapper isLoading={isLoading}>
              <CategoryGrid
                selectedCategories={selectedCategories}
                categories={categories}
                pagination={data?.pageInfo}
                setPagination={setPagination}
                manageCategories={manageCategories}
              />
            </SkeletonWrapper>
          </div>
          {selectedCategories?.length > 0 ? (
            <FadeAnimation>
              <div className="flex flex-col gap-y-4">
                <h4 className="font-semibold">
                  {t('auth.signUp.wizard.talentCategory.subcategories')}
                </h4>
                <SubcategoryList
                  selectedCategories={selectedCategories as Category[]}
                  selectedSubcategories={selectedSubcategories as Subcategory[]}
                  append={appendSubcategory}
                  remove={removeSubcategory}
                />
              </div>
            </FadeAnimation>
          ) : null}
        </div>
      </div>
      <FormButtons
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formsLength={formsLength}
        disabled={type === 'business' && selectedCategories.length === 0}
      />
    </form>
  );
}
