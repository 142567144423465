/* eslint-disable no-nested-ternary */
import React from 'react';
import { useUser } from '../../hooks';
import { SelectCategory } from './shared';
import FanSignUp from './fanSignUp/FanSignUp';
import TalentSignUp from './talentSignUp/TalentSignUp';

export default function FanAndTalentSignUp() {
  const { currentUser: user } = useUser();
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [formsLength, setFormsLength] = React.useState<number>(6);

  React.useEffect(() => {
    if (user?.currentUser?.categories?.length === 0) {
      setFormsLength(2);
    } else {
      setFormsLength(6);
    }
  }, [user?.currentUser?.categories?.length]);

  return (
    <div className="bg-background-50 rounded-sm shadow px-8 py-4 space-y-4 h-full">
      {currentStep === 0 ? (
        <SelectCategory
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          formsLength={formsLength}
        />
      ) : user?.currentUser?.categories?.length === 0 ? (
        <FanSignUp currentStep={currentStep} setCurrentStep={setCurrentStep} />
      ) : (
        <TalentSignUp
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
    </div>
  );
}
