import React from 'react';
import { Alert, Input, MultipleSelect, Select, TextArea } from '@dinbog/ui';
import {
  Category,
  IOption,
  SpecificationTemplate,
  SpecificationType,
  Subcategory,
  VacantSpecification,
  VacantSpecificationType,
  vacantStatusEnum,
} from '@dinbog/models';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import SpecificationsFormContent from './SpecificationsFormContent';

interface VacancyFormFieldsProps {
  categories: Category[];
  subcategories: Subcategory[];
  categoriesSelected: IOption[];
  subcategoriesSelected: IOption[];
  setCategoriesSelected: React.Dispatch<React.SetStateAction<IOption[]>>;
  setSubcategoriesSelected: React.Dispatch<React.SetStateAction<IOption[]>>;
  selectedSpecs: VacantSpecification[];
  setSelectedSpecs: React.Dispatch<React.SetStateAction<VacantSpecification[]>>;
  specs: SpecificationTemplate[];
  vacantSpecs: VacantSpecification[];
}

/**
 * This function returns the vacant specification type for a given specification type.
 * Converts the specification to its plural type (select to multiselect, number to range, etc) to define vacant requirements
 * @param type type of the specification template
 * @returns the vacant specification type
 */
function getVacantSpecType(type: SpecificationType): VacantSpecificationType {
  switch (type) {
    case 'select':
      return 'multiselect';
    case 'multiselect':
      return 'multiselect';
    case 'number':
      return 'range';
    case 'text':
      return 'tags';
    case 'boolean':
      return 'boolean';
    default:
      return type;
  }
}

function VacancyFormFields({
  categories,
  subcategories,
  categoriesSelected,
  subcategoriesSelected,
  setCategoriesSelected,
  setSubcategoriesSelected,
  specs,
  selectedSpecs,
  setSelectedSpecs,
  vacantSpecs,
}: VacancyFormFieldsProps) {
  const { t, i18n } = useTranslation();
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const formValidations = {
    title: { required: t('required') },
    description: { required: t('required') },
    status: { required: t('required') },
  };

  (selectedSpecs ?? []).forEach((spec) => {
    formValidations[spec._id] = { required: t('required') };
  });

  const translateVacantStatusEnum = () =>
    vacantStatusEnum.map((status) => ({
      name: t(`events.casting.vacancies.status.${status}`),
      key: status,
    }));

  function hasActorOrModel(
    categoriesInSelect: Array<{ name: string; _id: string }>
  ): boolean {
    return categoriesInSelect.some(
      (category) => category.name === t('actor') || category.name === t('model')
    );
  }

  const containsActorOrModel = hasActorOrModel(categoriesSelected);

  return (
    <div className="flex flex-col gap-7">
      <div className="flex lg:flex-row flex-col gap-7">
        <Input
          label={t('events.casting.vacancies.formFields.name')}
          name="title"
          error={errors?.title ? (errors.title?.message as string) : ''}
          {...register('title', formValidations.title)}
        />
        <Select
          name="status"
          error={errors?.status ? (errors.status?.message as string) : ''}
          {...register('status', formValidations.status)}
          label={t('events.casting.vacancies.formFields.status')}
          className={`w-full `}
        >
          <option key={-1} value="" disabled>
            Select...
          </option>
          {translateVacantStatusEnum()?.map((status) => (
            <option key={status.key} value={status.key}>
              {status.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex lg:flex-row flex-col gap-7">
        <MultipleSelect
          className="w-full"
          name="categories"
          defaultSelect={categoriesSelected}
          label={t('events.casting.vacancies.formFields.categories')}
          options={categories?.map((category) => ({
            name: category?.name?.[i18n?.language ?? 'en'] ?? '',
            _id: category._id,
          }))}
          onChangeSelect={(value) => {
            setCategoriesSelected([...value]);
          }}
        />
        <MultipleSelect
          className="w-full"
          name="subcategories"
          defaultSelect={subcategoriesSelected}
          label={t('events.casting.vacancies.formFields.subcategories')}
          options={subcategories?.map((subcategory) => ({
            name: subcategory?.name?.[i18n?.language ?? 'en'] ?? '',
            _id: subcategory._id,
          }))}
          onChangeSelect={(value) => {
            setSubcategoriesSelected([...value]);
          }}
        />
      </div>
      <TextArea
        className="h-24"
        label={t('events.casting.vacancies.formFields.description')}
        name="description"
        error={
          errors?.description ? (errors.description?.message as string) : ''
        }
        {...register('description', formValidations.description)}
      />
      {containsActorOrModel ? (
        <>
          <MultipleSelect
            className="w-full"
            name="specifications"
            defaultSelect={selectedSpecs?.map((spec) => ({
              _id: (spec.specificationTemplate as SpecificationTemplate)?._id,
              name: (spec.specificationTemplate as SpecificationTemplate)?.name
                ?.en,
            }))}
            label={t('events.casting.vacancies.formFields.specifications')}
            options={specs?.map((spec) => ({
              _id: spec?._id,
              name: spec?.name?.[i18n?.language ?? 'en'],
            }))}
            onChangeSelect={(value) => {
              setSelectedSpecs(
                [...value].map((spec, ii) => {
                  const specT = specs?.find((s) => s?._id === spec?._id);
                  return {
                    specificationTemplate: specT,
                    type: getVacantSpecType(specT?.type),
                    multipleValues: [],
                    required: false,
                    ...(vacantSpecs?.find(
                      (vs) =>
                        (vs?.specificationTemplate as SpecificationTemplate)
                          ?._id === specT?._id
                    ) ?? {}),
                    _id: specT?._id,
                  };
                })
              );
            }}
          />

          {selectedSpecs?.length > 0 ? (
            <div className="flex flex-col gap-4">
              <span className="text-primary-500 font-semibold ">
                {t('events.casting.vacancies.specifications')}
              </span>
              <div className=" ">
                <SpecificationsFormContent
                  specifications={selectedSpecs}
                  setSpecifications={setSelectedSpecs}
                />
              </div>
            </div>
          ) : (
            <Alert
              title={t('events.casting.vacancies.specsCTA')}
              text=""
              show
            />
          )}
        </>
      ) : null}
    </div>
  );
}

export default VacancyFormFields;
