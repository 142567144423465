import React from 'react';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Profile } from '@dinbog/models';
import { axios } from '../api';

export interface Account {
  _id: string;
  type: 'user' | 'business';
  email: string;
  profile?: Profile;
}

export interface CurrentUser {
  _id: string;
  profile?: Profile;
  currentUser?: Profile;
  accounts?: Account[];
  token: string;
}

export interface UserContextProps {
  currentUser: CurrentUser | null;
  loading: boolean;
  updateCurrentUser: () => Promise<void>;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser | null>>;
}

export const UserContext = React.createContext<UserContextProps | undefined>(
  undefined
);
interface UserContextProviderProps {
  children: React.ReactNode;
}

export function UserContextProvider({ children }: UserContextProviderProps) {
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);
  const session = useSession();
  const { i18n } = useTranslation();
  const router = useRouter();

  const updateCurrentUser = async () => {
    setLoading(true);
    if (
      session.status !== 'authenticated' ||
      (session?.data?.user as any)?.user?.permissionGroup
    ) {
      router.push('/sign-in');
      setCurrentUser(null);
      return;
    }

    const { data: dataCurrent } = await axios.post<{
      accounts: Account[];
      currentUser?: Profile;
      profile?: Profile;
    }>(
      '/api/users/current/v1',
      {},
      {
        headers: {
          Authorization: `Bearer ${session.data.user.token}`,
        },
      }
    );

    setCurrentUser({
      _id: session.data.user._id,
      currentUser: dataCurrent?.currentUser ?? null,
      profile: dataCurrent.profile ?? null,
      accounts: dataCurrent.accounts ?? null,
      token: session.data.user.token,
    });

    i18n?.changeLanguage(dataCurrent?.currentUser?.appLanguage ?? 'en');
    localStorage.setItem(
      'dinbog.lang',
      dataCurrent?.currentUser?.appLanguage ?? 'en'
    );

    setLoading(false);
  };

  React.useEffect(() => {
    if (session.status !== 'loading') {
      updateCurrentUser();
    }
  }, [session]);

  const value = React.useMemo(
    () => ({ currentUser, updateCurrentUser, loading, setCurrentUser }),
    [currentUser, loading, setCurrentUser]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
