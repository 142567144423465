import React from 'react';

function LoadingSuggestedUser() {
  return (
    <div className="animate-pulse flex space-x-4">
      <div className="rounded-full bg-slate-200 h-9 w-9 " />
      <div className="flex-1 space-y-3 py-1">
        <div className="h-2 bg-slate-200 rounded" />
        <div className="space-y-3">
          <div className="grid grid-cols-3 gap-4">
            <div className="h-2 bg-slate-200 rounded col-span-2" />
            <div className="h-2 bg-slate-200 rounded col-span-1" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingSuggestedUser;
